import React, { useEffect, useState } from 'react'
import './FreeTrialStrip.css'
import { useTranslation } from 'react-i18next'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { isMobile } from 'react-device-detect'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import { PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { CiTimer } from "react-icons/ci";
import { LuCrown } from "react-icons/lu";
import { FREE_TRIAL_STRIP_HEIGHT, SHOPEAKS_LOGO_WHITE_URL } from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/User'

export default function FreeTrialStrip({
    daysLeft = 0
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { userDetails } = useUserDetailsContext()
    const [isStripShown, setIsStripShown] = useState(false)

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { },
        onDialogClosedLeftButton: () => { },
        onDialogClosedRightButton: () => { }
    })

    useEffect(() => {
        setTimeout(() => setIsStripShown(true), 1000)
    }, [])

    function goToPricingPlans() {
        if (!window.location.pathname.startsWith(PRICING_PLANS_PAGE_URL)) {
            history.push(PRICING_PLANS_PAGE_URL)
        }
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <>
            {
                !isMobile && <div className={`free-trial-strip ${isStripShown ? 'shown' : ''}`} style={{height: `${FREE_TRIAL_STRIP_HEIGHT}px`}}>
                    <ImageBox
                        className='free-trial-strip-logo-image'
                        image={SHOPEAKS_LOGO_WHITE_URL}
                    />
                    <div className="free-trial-strip-content">
                        <div className="free-trial-strip-titles-section">
                            <CiTimer className='free-trial-strip-title-image' />
                            {
                                daysLeft > 0 ? <div className="free-trial-strip-title"><span style={{fontWeight: 'bold'}}>{t('FREE_TRIAL_STRIP_FIRST_PART_TITLE', { daysLeft })}</span> - {t('FREE_TRIAL_STRIP_SECOND_PART_TITLE')}</div> :
                                <div className="free-trial-strip-title ended">{t('FREE_TRIAL_STRIP_TRIAL_ENDED_TITLE')}</div>
                            }          
                        </div>
                        {
                            userDetails?.isAdmin && <div className="free-trial-strip-buttons-section">
                                <LoaderButton
                                    className="free-trial-strip-upgrade-button"
                                    onClick={goToPricingPlans}
                                    buttonText={<span><LuCrown className='free-trial-strip-title-image'/> {t('FREE_TRIAL_STRIP_UPGRADE_BUTTON_TEXT')}</span>}
                                /> 
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}