import React, { useEffect, useState } from 'react'
import './PasswordInput.css'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { useTranslation } from 'react-i18next'
import PasswordInputRule from 'components/PasswordInputRule/PasswordInputRule'
import { containsNumbers } from 'utils/utils'
import { USER_PASSWORD_MIN_LENGTH } from 'constants/general.constants'

/**
 * Represents a password input field with password guidelines.
 * Uses {@link EditFieldSectionInput} component for rendering the input field.
 * @param {string} title - The title for this password input field
 * @param {string} value - The value state for this input field
 * @param {function} setValue - A function for setting the value state of this input field
 * @param {function} setValidationStatus - A function for setting the validation status of the password input field
 */
export default function PasswordInput({
    title,
    value,
    setValue = () => { },
    setValidationStatus = () => { }
}) {
    const { t } = useTranslation()
    const [passwordContract, setPasswordContract] = useState({
        rule1: {
            instruction: t('PASSWORD_INPUT_RULE_1_INSTRUCTION', { lengthLimit: USER_PASSWORD_MIN_LENGTH }),
            fulfilled: false
        },
        rule2: {
            instruction: t('PASSWORD_INPUT_RULE_2_INSTRUCTION'),
            fulfilled: false
        }
    })

    useEffect(() => {
        // checking rule 1
        if (value?.length >= USER_PASSWORD_MIN_LENGTH) {
            setPasswordContract(prev => ({
                ...prev,
                rule1: {
                    ...prev.rule1,
                    fulfilled: true
                }
            }))
        } else {
            setPasswordContract(prev => ({
                ...prev,
                rule1: {
                    ...prev.rule1,
                    fulfilled: false
                }
            }))
        }

        // checking rule 2
        if (containsNumbers(value)) {
            setPasswordContract(prev => ({
                ...prev,
                rule2: {
                    ...prev.rule2,
                    fulfilled: true
                }
            }))
        } else {
            setPasswordContract(prev => ({
                ...prev,
                rule2: {
                    ...prev.rule2,
                    fulfilled: false
                }
            }))
        }
    }, [value])

    useEffect(() => {
        setValidationStatus(
            passwordContract.rule1.fulfilled &&
            passwordContract.rule2.fulfilled
        )
    }, [passwordContract])

    return (
        <div className='password-input-container'>
            <EditFieldSectionInput
                title={title}
                value={value}
                setValue={setValue}
                isPassword={true}
                hasValidation={true}
                enlargedTextField={true}
                id="password"
            />
            {
                value ? <div className='password-input-instruction-list'>
                    {
                        Object.keys(passwordContract).map(rule => <PasswordInputRule
                            key={passwordContract[rule].instruction + '_' + passwordContract[rule].fulfilled}
                            text={passwordContract[rule].instruction}
                            fulfilled={passwordContract[rule].fulfilled}
                        />)
                    }
                </div> : <div className="password-input-instruction">{t('PASSWORD_INPUT_INSTRUCTION', { lengthLimit: USER_PASSWORD_MIN_LENGTH })}</div>
            }
        </div>
    )
}