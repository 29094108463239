import { TableCell, TableRow } from '@mui/material'
import ImageBox from 'components/ImageBox/ImageBox'
import React from 'react'
import './ProductsTableRowView.css'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import CircularProgressBar from 'components/CircularProgressBar/CircularProgressBar'
import { styled } from '@mui/system'
import { PRODUCTS_TABLE_ROW_HEIGHT } from 'constants/general.constants'

/**
 * Represents a row view for the products table
 * @param {object} rowData - The data object of a specific row
 */
export default function ProductsTableRowView({
    rowData
}) {
    const { t } = useTranslation()

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide all borders
        '&:not(:last-child) td, & th': {
            border: 0,
        }
    }))

    const productTableCellStyle = {
        padding: '8px'
    }

    function getRankColor(rank) {
        switch (true) {
            case rank >= 0 && rank <= 20: return 'red'
            case rank >= 21 && rank <= 40: return '#b4c7e7'
            case rank >= 41 && rank <= 60: return '#ffc000'
            case rank >= 61 && rank <= 80: return '#ed7d31'
            case rank >= 81 && rank <= 100: return '#00b050'
        }
    }

    function renderRankCircle() {
        return <CircularProgressBar
            min={0}
            max={100}
            width={isMobile ? '50px' : '70px'}
            height={isMobile ? '50px' : '70px'}
            value={rowData?.ranking}
            archColor={getRankColor(rowData?.ranking)}
        />
    }

    function hasValue(value) {
        return ![null, undefined].includes(value)
    }

    return (
        <StyledTableRow style={{ height: `${PRODUCTS_TABLE_ROW_HEIGHT}px` }}>
            {
                isMobile ? <TableCell padding="normal" align={'left'} sx={productTableCellStyle}>
                    <div className='mobile-product-table-row-view'>
                        <div className="mobile-product-table-row-content">
                            {
                                <ImageBox
                                    image={rowData?.productImage}
                                    isImageFitCover={true}
                                    className='mobile-product-table-row-view-image'
                                    showBorder={true}
                                />
                            }
                            <div className="mobile-product-table-row-content-details">
                                {
                                    hasValue(rowData?.productName) && <MultiLineText
                                        text={rowData?.productName}
                                        className='mobile-product-table-row-view-title'
                                        lines={2}
                                    />
                                }
                                <div className="mobile-product-table-row-content-details-analytics">
                                    {
                                        hasValue(rowData?.viewCount) && <div className="mobile-product-table-row-content-details-analytics-data">
                                            <div className="mobile-product-table-row-content-details-analytics-data-value">{Math.max(rowData?.viewCount ?? 0, rowData?.addCount ?? 0, rowData?.buyCount ?? 0)}</div>
                                            <div className="mobile-product-table-row-content-details-analytics-data-label">{t('MOBILE_PRODUCTS_TABLE_ROW_VIEW_ANALYTICS_VIEW_LABEL')}</div>
                                        </div>
                                    }
                                    {
                                        hasValue(rowData?.addCount) && <div className="mobile-product-table-row-content-details-analytics-data">
                                            <div className="mobile-product-table-row-content-details-analytics-data-value">{Math.max(rowData?.addCount ?? 0, rowData?.buyCount ?? 0)}</div>
                                            <div className="mobile-product-table-row-content-details-analytics-data-label">{t('MOBILE_PRODUCTS_TABLE_ROW_VIEW_ANALYTICS_ADD_LABEL')}</div>
                                        </div>
                                    }
                                    {
                                        hasValue(rowData?.buyCount) && <div className="mobile-product-table-row-content-details-analytics-data">
                                            <div className="mobile-product-table-row-content-details-analytics-data-value">{rowData?.buyCount ?? 0}</div>
                                            <div className="mobile-product-table-row-content-details-analytics-data-label">{t('MOBILE_PRODUCTS_TABLE_ROW_VIEW_ANALYTICS_BUY_LABEL')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mobile-product-table-row-rank-container">
                            {renderRankCircle()}
                        </div>
                    </div>
                </TableCell> : <>
                    {(rowData?.productImage || rowData?.productName) && <TableCell padding="normal" align={'left'} sx={productTableCellStyle}>
                        <div className="product-table-row-view product-table-row-view-product">
                            <ImageBox
                                    image={rowData?.productImage}
                                    isImageFitCover={true}
                                    className='product-table-row-view-image'
                                    showBorder={true}
                            />
                            {
                                hasValue(rowData?.productName) && <MultiLineText
                                    text={rowData?.productName}
                                    className='product-table-row-view-title'
                                    lines={2}
                                />
                            }
                        </div>
                    </TableCell>}
                    {
                        hasValue(rowData?.viewCount) && <TableCell padding="normal" align='right'>
                            <div className='product-table-row-view product-table-row-view-view-count'>{Math.max(rowData?.viewCount ?? 0, rowData?.addCount ?? 0, rowData?.buyCount ?? 0)}</div>
                        </TableCell>
                    }
                    {
                        hasValue(rowData?.addCount) && <TableCell padding="normal" align='right'>
                            <div className='product-table-row-view product-table-row-view-add-count'>{Math.max(rowData?.addCount ?? 0, rowData?.buyCount ?? 0)}</div>
                        </TableCell>
                    }
                    {
                        hasValue(rowData?.buyCount) && <TableCell padding="normal" align='right'>
                            <div className='product-table-row-view product-table-row-view-buy-count'>{rowData?.buyCount ?? 0}</div>
                        </TableCell>
                    }
                    {
                        hasValue(rowData?.ranking) && <TableCell padding="normal" align='right'>
                            {renderRankCircle()}
                        </TableCell>
                    }
                </>
            }
        </StyledTableRow>
    )
}
