import React from 'react'

export default function InsightsImage(props) {
    return (
        <svg {...props} id="Layer_1" height="512" viewBox="0 0 512 512" width="512">
            <g>
                <path d="m63.021 465.151h43.499c22.357 0 40.546-18.189 40.546-40.546v-183.933c0-22.357-18.189-40.546-40.546-40.546h-43.499c-22.357 0-40.546 18.189-40.546 40.546v183.932c0 22.358 18.189 40.547 40.546 40.547zm-10.546-224.479c0-5.815 4.731-10.546 10.546-10.546h43.499c5.815 0 10.546 4.731 10.546 10.546v183.932c0 5.815-4.731 10.546-10.546 10.546h-43.499c-5.815 0-10.546-4.731-10.546-10.546z" />
                <path d="m234.251 465.151h43.499c22.357 0 40.546-18.189 40.546-40.546v-257.439c0-22.357-18.189-40.546-40.546-40.546h-43.499c-22.357 0-40.546 18.189-40.546 40.546v257.439c0 22.357 18.189 40.546 40.546 40.546zm-10.546-297.985c0-5.815 4.731-10.546 10.546-10.546h43.499c5.815 0 10.546 4.731 10.546 10.546v257.439c0 5.815-4.731 10.546-10.546 10.546h-43.499c-5.815 0-10.546-4.731-10.546-10.546z" />
                <path d="m405.48 465.151h43.499c22.357 0 40.546-18.189 40.546-40.546v-337.209c0-22.357-18.189-40.546-40.546-40.546h-43.499c-22.357 0-40.546 18.189-40.546 40.546v337.209c0 22.357 18.189 40.546 40.546 40.546zm-10.546-377.755c0-5.815 4.731-10.546 10.546-10.546h43.499c5.815 0 10.546 4.731 10.546 10.546v337.209c0 5.815-4.731 10.546-10.546 10.546h-43.499c-5.815 0-10.546-4.731-10.546-10.546z" />
            </g>
        </svg>
    )
}