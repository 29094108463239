import React from 'react'
import './Tabs.css'
import TabOption from 'components/TabOption/TabOption'
import { isMobile } from 'react-device-detect'

export default function Tabs({
    tabs = [],
    selectedTabOptionIndex,
    setSelectedTabOptionIndex,
    inputRef = null,
    hasTopLine = true,
    selectedBottomLine = false,
    optionPadding = isMobile ? '10px' : '20px 10px',
    isCentered = true,
    onTabOptionClick = () => { }
}) {
    function onTabClicked(tab, index) {
        setSelectedTabOptionIndex(index)
        onTabOptionClick(tab, index)
    }

    return (
        tabs.length > 0 && <div ref={inputRef} className={`${isMobile ? 'mobile-tabs-container' : 'tabs-container'} ${hasTopLine ? '' : 'no-top-line'}`}
            style={{ justifyContent: isCentered ? 'center' : 'start' }}>
            {
                tabs.map((tab, index) => <TabOption
                    key={index + tab?.text}
                    text={tab?.text}
                    image={tab?.image}
                    onClick={() => onTabClicked(tab, index)}
                    currentIndex={index}
                    selectedTabOptionIndex={selectedTabOptionIndex}
                    selectedBottomLine={selectedBottomLine}
                    optionPadding={optionPadding}
                />)
            }
        </div>
    )
}