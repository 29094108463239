
import Fab from "@mui/material/Fab"; 
import './FloatingButton.css'
import { useEffect, useState } from "react";
import { Slide } from "@mui/material";

export default function FloatingButton({
    icon = null,
    text = '',
    clickFunction = () => {}
}) {

    const [shown, setShown] = useState(false)

    useEffect(() => {
        setTimeout(() => setShown(true), 300)
    }, [])

    return (
        <Slide direction="up" in={shown} mountOnEnter unmountOnExit>
            <div className="floating-button-container">
                <Fab onClick={clickFunction} variant="extended" style={{background: "linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))", boxShadow: "rgba(0, 77, 255, 0.5) 0px 2px 16px"}}> 
                    <div className="floating-button-content">
                        {icon ?? null}
                        <div className="floating-button-container-text">{text}</div>
                    </div>
                </Fab> 
            </div>
      </Slide>
    )
}