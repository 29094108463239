import React from 'react'
import './DashboardDataContainer.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents a container for dashboard data
 * @param {string/number} width - The width of this container
 * @param {string/number} height - The height of this container
 * @param {string} title - The title of this container
 * @param {boolean} shouldSpreadItems - If true, the title and the content will be spread to the vertical edges.
 */
export default function DashboardDataContainer({
    width,
    height,
    title= '',
    shouldSpreadItems = false,
    children
}) {
    return (
        <div className='dashboard-data-container' style={{ width, height, justifyContent: shouldSpreadItems && 'space-between' }}>
            {title && <div className={isMobile ? 'mobile-dashboard-data-container-title' : 'dashboard-data-container-title'}>{title}</div>}
            {children}
        </div>
    )
}