import React from 'react'

export default function MenuCheckImage(props) {
    return (
        <svg
            {...props}
            height="512"
            viewBox="0 0 32 32"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
            id="fi_2989815"
        >
            <g id="e2f8f9b8-a03e-411f-8bbd-6147d54aeeb4" dataname="Layer 22">
                <path d="m17 9h-14a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2zm1 4a1 1 0 0 0 -1-1h-14a1 1 0 0 0 0 2h14a1 1 0 0 0 1-1zm-7 5a1 1 0 0 0 -1-1h-7a1 1 0 0 0 0 2h7a1 1 0 0 0 1-1zm9 7a1 1 0 0 0 .77-.37l9-11a1 1 0 1 0 -1.54-1.26l-8.23 10.06-4.22-5.07a1 1 0 1 0 -1.54 1.28l5 6a1 1 0 0 0 .76.36z">
                </path>
            </g>
        </svg>
    )
}
