import React from 'react'
import './ShopboardSocials.css'
import ShopboardSocialStatus from 'components/ShopboardSocialStatus/ShopboardSocialStatus'
import { useTranslation } from 'react-i18next'
import { INSTAGRAM_LOGO_URL, PINTEREST_LOGO_URL, SHOPBOARD_SOCIALS, TIKTOK_LOGO_URL } from 'constants/general.constants'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'

export default function ShopboardSocials({
    instagramPostsCount = null,
    instagramUsername = "",
    tiktokPostsCount = null,
    tiktokUsername = "",
    pinterestPostsCount = null,
    pinterestUsername = "",
    showImage = true
}) {
    const { t } = useTranslation()

    return (
        <div className={isMobile ? 'mobile-shopboard-socials' : 'shopboard-socials'}>
            <ShopboardSocialStatus
                title={!isMobile ? t('SHOPBOARD_SOCIALS_INSTAGRAM_TITLE') : ''}
                image={<ImageBox 
                    image={INSTAGRAM_LOGO_URL}
                    className='shopboard-social-status-image'
                />}
                postsCount={instagramPostsCount}
                social={SHOPBOARD_SOCIALS.INSTAGRAM}
                showImage={showImage}
                username={instagramUsername}
            />

            {!isMobile && <div className="vertical-separator-line"></div>}

            <ShopboardSocialStatus
                title={!isMobile ? t('SHOPBOARD_SOCIALS_TIKTOK_TITLE') : ''}
                image={<ImageBox 
                    image={TIKTOK_LOGO_URL}
                    className='shopboard-social-status-image'
                />}
                postsCount={tiktokPostsCount}
                social={SHOPBOARD_SOCIALS.TIKTOK}
                showImage={showImage}
                username={tiktokUsername}
            />

            {!isMobile && <div className="vertical-separator-line"></div>}

            <ShopboardSocialStatus
                title={!isMobile ? t('SHOPBOARD_SOCIALS_PINTEREST_TITLE') : ''}
                image={<ImageBox 
                    image={PINTEREST_LOGO_URL}
                    className='shopboard-social-status-image'
                />}
                postsCount={pinterestPostsCount}
                social={SHOPBOARD_SOCIALS.PINTEREST}
                showImage={showImage}
                username={pinterestUsername}
            />

        </div>
    )
}
