import React, { useState } from 'react'
import './AddInfluencerSelect.css'
import PaydinSelect from 'components/PaydinSelect/PaydinSelect'
import AddInfluencerOption from 'components/AddInfluencerOption/AddInfluencerOption'
import { IoIosArrowForward } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

/**
 * Represents a customized select element for picking an influencer.
 * @param {*} optionState - The select value state
 * @param {function} onOptionClick - A function for setting the select's value state
 * @param {array} options - The select's options list
 * @param {boolean} fullWidth - Determins whether the select element will stretch to the entire width available or not
 * @param {boolean} isDisabled - Detrermins whether this select is disabled or not
 * @param {boolean} isOptional - Detrermins whether this select is optional or not
 */
export default function AddInfluencerSelect({
    optionState,
    onOptionClick = () => { },
    options = [],
    fullWidth = false,
    isDisabled = false,
    isOptional = false
}) {
    const { t } = useTranslation()

    const [isAddInfluencerSelectOpen, setIsAddInfluencerSelectOpen] = useState(false)

    function normalizeOptions() {
        if (!options) {
            return []
        }

        const normalizedOptions = options.map(option => ({
            value: option.username,
            element: <AddInfluencerOption
                image={option?.image}
                username={option?.username}
            />
        }))

        normalizedOptions.unshift({
            value: '',
            element: <div className='add-influencer-select-no-influencer-option'>
                <div className="add-influencer-select-no-influencer-option-text">{t('ADD_INFLUENCER_SELECT_NO_INFLUENCER_SELECTED_TEXT')}</div>
            </div>
        })

        return normalizedOptions
    }

    function onClick() {
        setIsAddInfluencerSelectOpen(prev => {
            return !isDisabled && !prev && options?.length > 0
        })
    }

    function renderNoInfluencersPlaceholderElement() {
        return <div className="add-influencer-select-no-influencers-placeholder-element">{t('ADD_INFLUENCER_SELECT_NO_INFLUENCERS_PLACEHOLDER_TEXT')}</div>
    }

    function renderPlaceholderElement() {
        return <div className={isMobile ? "mobile-add-influencer-select-placeholder-element" : "add-influencer-select-placeholder-element"}>{t('ADD_INFLUENCER_SELECT_PLACEHOLDER_TEXT')} {isOptional ? t('OPTIONAL_INPUT_TITLE_SUFFIX') : ''}</div>
    }

    return (
        <div className={isMobile ? 'mobile-add-influencer-select-container' : 'add-influencer-select-container'} style={{ width: fullWidth ? '100%' : '300px' }} onClick={onClick}>
            <div className={isMobile ? "mobile-add-influencer-select-content" : "add-influencer-select-content"} style={{ padding: optionState ? '5px 20px' : '15px 20px' }}>
                <PaydinSelect
                    isOpen={isAddInfluencerSelectOpen}
                    isDisabled={isDisabled || options.length === 0}
                    placeholder={((options && options.length > 0) ? renderPlaceholderElement() : renderNoInfluencersPlaceholderElement())}
                    options={normalizeOptions()}
                    optionState={optionState}
                    onOptionClick={onOptionClick}
                    hideArrow={true}
                    textColor='hsl(229, 23%, 72%)'
                />
                {(!isDisabled && options.length > 0) && <IoIosArrowForward className="add-influencer-select-image" />}
            </div>
        </div>
    )
}