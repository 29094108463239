import { request_auth } from 'services/RequestWrapper'
import { environment } from 'conf'
import { 
    AUTH_API_URL, 
    LOGIN_API_URL, 
    REGISTER_API_URL, 
    PASSWORD_RESET_REQUEST_API_URL, 
    PASSWORD_RESET_API_URL, 
    UPDATE_USER_INFO_API_URL, 
    USER_RESET_API_URL, 
    VALIDATE_EMAIL_API_URL,
    VALIDATE_USERNAME_API_URL,
    ACCOUNT_VERIFICATION_API_URL,
    REGISTRATION_REQUEST_API_URL
} from 'constants/api.constants'

/*
    Verifies the user and recieves the data about the business and user.

    Method: [POST]
    URL: /auth/verify

*/
export function AuthAPI() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }
        request_auth(environment.base_url + AUTH_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Performs a user login using username and password.

    Method: [POST]
    URL: /auth/login

    Body params:
    ============
        (*) username - The username of the user
        (*) password - The password of the user
*/
export function LoginAPI(username, password) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username,
                password
            }
        }
        request_auth(environment.base_url + LOGIN_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Performs a user registration using username and password.

    Method: [POST]
    URL: /auth/register

    Body params:
    ============
        (*) username - The username of the user
        (*) password - The password of the user
        (*) name - The name of the user
        (*) email - The email of the user
*/
export function RegisterAPI(username, password, email) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username, 
                password,
                name: username,
                email,
            }
        }
        request_auth(environment.base_url + REGISTER_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Sends a request for resetting the user's password.

    Method: [POST]
    URL: /auth/password/reset/request

    Body params:
    ============
        (*) username - The username of the user
*/
export function ResetPasswordRequestAPI(username) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username,
            }
        }
        request_auth(environment.base_url + PASSWORD_RESET_REQUEST_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve();
                } else {
                    reject();
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

/*
    Resets the user's password and creates a new one.

    Method: [POST]
    URL: /auth/password/reset

    Body params:
    ============
        (*) token - The reset password token
        (*) new_password - The new password of the user
*/
export function ResetPasswordAPI(token, newPassword) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                token,
                new_password: newPassword
            }
        }
        request_auth(environment.base_url + PASSWORD_RESET_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    });
}

/*
    Updates the user's information.

    Method: [PUT]
    URL: /auth/update/info

    Body params:
    ============
        (*) name - The new name for the user
*/
export function UpdateUserInfo(name) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                name
            }
        }

        const url = environment.base_url + UPDATE_USER_INFO_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(reject)
    })
}

/*
    Sends a request for resetting the user's password.
    Used inside the user's account page.

    Method: [GET]
    URL: /auth/user/password/reset/request

*/
export function ResetUserPassword() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = environment.base_url + USER_RESET_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(reject)
    })
}

/*
    Checks the existance of the given username when signing up.

    Method: [POST]
    URL: /auth/validate/username

    Body params:
    ============
        (*) username - The new username
*/
export function ValidateUsername(username) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username
            }
        }

        const url = `${environment.base_url}${VALIDATE_USERNAME_API_URL}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Checks the existance of the given email when signing up.

    Method: [POST]
    URL: /auth/validate/email

    Body params:
    ============
        (*) email - The new email
*/
export function ValidateEmail(email) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                email
            }
        }

        const url = `${environment.base_url}${VALIDATE_EMAIL_API_URL}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Verifies the newly created account.

    Method: [POST]
    URL: auth/account/verify

    Body params:
    ============
        (*) token = The verification token received from the verification email
*/
export function VerifyAccount(token) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                token
            }
        }

        const url = environment.base_url + ACCOUNT_VERIFICATION_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(reject) 
    })
}

/*
    Makes a registration request for a new user.

    Method: [POST]
    URL: /auth/register/request

    Body params:
    ============
        (*) name - The name of the new user
        (*) email - The email of the new user
        (*) description - The description of the new user
*/
export function RequestRegistration(name, email, description) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                name,
                email,
                description
            }
        }

        const url = environment.base_url + REGISTRATION_REQUEST_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}