import React, { useEffect, useState } from 'react'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import useQueryParams from 'hooks/useQueryParams'
import { useUserDetailsContext } from 'contexts/User'
import { useParams } from 'react-router-dom'
import { useHistoryContext } from 'contexts/History'
import { SHOPBOARD_AUTH_PAGE_CODE_QUERY_PARAMETER, SHOPBOARD_PAGE_URL } from 'constants/routes.constants'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { useTranslation } from 'react-i18next'

export default function ShopboardAuthPage() {
    const { t } = useTranslation()
    const {
        shopboardAuthenticate
    } = useUserDetailsContext()
    const queryParams = useQueryParams()
    const { social } = useParams()
    const history = useHistoryContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        leftButtonClickHandler: () => { }
    })

    useEffect(() => {
        if (!queryParams.get(SHOPBOARD_AUTH_PAGE_CODE_QUERY_PARAMETER)) {
            history.replace(SHOPBOARD_PAGE_URL)
            return
        }

        shopboardAuthenticate(social, queryParams.get(SHOPBOARD_AUTH_PAGE_CODE_QUERY_PARAMETER), () => {
            history.replace(SHOPBOARD_PAGE_URL)
        }, () => {
            console.log('authentication instagram failed')
            setDialogState(prev => ({
                ...prev,
                isOpen: true,
                title: t('SHOPBOARD_AUTH_PAGE_AUTH_FAILURE_DIALOG_MESSAGE', { social }),
                leftButtonText: t('SHOPBOARD_AUTH_PAGE_AUTH_FAILURE_DIALOG_LEFT_BUTTON_TEXT'),
                rightButtonText: '',
                leftButtonClickHandler: () => history.replace(SHOPBOARD_PAGE_URL)
            }))
        })
    }, [])

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <div className='shopboard-auth-page'>
            {
                queryParams.get(SHOPBOARD_AUTH_PAGE_CODE_QUERY_PARAMETER) && <LoaderPage
                    isContained={true}
                    isFullScreen={true}
                />
            }
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                title={dialogState.title}
                message={dialogState.message}
                handleDialogClose={closeDialog}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                isLeftButtonWithLoader={false}
                closeOnLeftClick={false}
                rightButtonText={dialogState.rightButtonText}
                onLeftButtonClick={dialogState.leftButtonClickHandler}
            />
        </div>
    )
}