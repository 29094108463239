import './PaydinSelect.css'
import { MenuItem, Select } from '@mui/material'
import { EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_HEIGHT_PX, EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_MAX_COUNT } from 'constants/general.constants'
import PaydinSelectRenderedValue from 'components/PaydinSelectRenderedValue/PaydinSelectRenderedValue'

/**
 * Represents a custom drop down menu.
 * @param {string} optionState - The current selected option
 * @param {array} options - The options array for the drop down menu
 * @param {string} border - The border of this select element
 * @param {string} width - The width of this select element
 * @param {number} paddingTopPx - The top padding of the select element in pixels
 * @param {number} paddingBottomPx - The bottom padding of the select element in pixels
 * @param {number} paddingRightPx - The right padding of the select element in pixels
 * @param {number} paddingLeftPx - The left padding of the select element in pixels
 * @param {string} textColor - The text color of the select element
 * @param {boolean} hideArrow - Determins whether the default arrow of the select element is hidden or not
 * @param {function} onOptionClick - A callback to perform when an option from the drop down menu was clicked
 */
export default function PaydinSelect({
    isOpen,
    optionState,
    placeholder = null,
    options = [],
    border = null,
    width = '300px',
    paddingTopPx = 5,
    paddingBottomPx = 5,
    paddingRightPx = 10,
    paddingLeftPx = 10,
    textColor = 'black',
    hideArrow = false,
    isDisabled = false,
    onOptionClick = option => { },
    selectedOptionSuffix = '',
}) {

    const outlineStyles = {
        border: border ? border : 'none'
    }

    const inputStyles = {
        width: '200px',
        padding: `${paddingTopPx}px ${paddingRightPx}px ${paddingBottomPx}px ${paddingLeftPx}px`,
        color: textColor
    }

    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: (EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_HEIGHT_PX * EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_MAX_COUNT) + 15
            }
        }
    }

    function renderValue(selected) {
        if (placeholder && !selected) {
            return placeholder
        }

        return <PaydinSelectRenderedValue>
            {options.find(option => option.value === selected)?.element}
            <div>{selectedOptionSuffix}</div>
        </PaydinSelectRenderedValue>
    }

    return (
        hideArrow ?
            <Select
                disabled={isDisabled}
                open={isOpen}
                fullWidth
                displayEmpty={placeholder ? true : false}
                value={optionState}
                onChange={e => onOptionClick(e.target.value)}
                sx={{
                    '.MuiOutlinedInput-notchedOutline': outlineStyles,
                    '.MuiInputBase-input': inputStyles
                }}
                inputProps={{ IconComponent: () => null }}
                renderValue={(selected) => renderValue(selected)}
                MenuProps={menuProps}
            >
                {
                    options.map(option => <MenuItem key={option.value} value={option.value}>{option.element}</MenuItem>)
                }
            </Select> : <Select
                disabled={isDisabled}
                open={isOpen}
                fullWidth
                displayEmpty={placeholder ? true : false}
                value={optionState}
                onChange={e => onOptionClick(e.target.value)}
                sx={{
                    '.MuiOutlinedInput-notchedOutline': outlineStyles,
                    '.MuiInputBase-input': inputStyles
                }}
                renderValue={(selected) => renderValue(selected)}
                MenuProps={menuProps}
            >
                {
                    options.map(option => <MenuItem key={option.value} value={option.value}>{option.element}</MenuItem>)
                }
            </Select>
    )
}