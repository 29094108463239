import React from 'react'

export default function ShortsImage(props) {
    return (
        <svg
            {...props}
            fill="none"
            viewBox="0 0 20 20"
            height="15"
            width="15"
        >
            <path
                fill="#273144"
                fillRule="evenodd"
                d="M4.167 10.833H7.5c.917 0 1.667-.75 1.667-1.666v-5c0-.917-.75-1.667-1.667-1.667H4.167C3.25 2.5 2.5 3.25 2.5 4.167v5c0 .916.75 1.666 1.667 1.666zm0-1.666v-5H7.5v5H4.167zM4.167 17.5H7.5c.917 0 1.667-.75 1.667-1.667v-1.666c0-.917-.75-1.667-1.667-1.667H4.167c-.917 0-1.667.75-1.667 1.667v1.666c0 .917.75 1.667 1.667 1.667zm0-1.667v-1.666H7.5v1.666H4.167zM15.833 7.5H12.5c-.917 0-1.667-.75-1.667-1.667V4.167c0-.917.75-1.667 1.667-1.667h3.333c.917 0 1.667.75 1.667 1.667v1.666c0 .917-.75 1.667-1.667 1.667zM12.5 4.167v1.666h3.333V4.167H12.5zM12.5 17.5h3.333c.917 0 1.667-.75 1.667-1.667v-5c0-.916-.75-1.666-1.667-1.666H12.5c-.917 0-1.667.75-1.667 1.666v5c0 .917.75 1.667 1.667 1.667zm0-1.667v-5h3.333v5H12.5z"
                clipRule="evenodd"></path>
        </svg>
    )
}