import PaydinMuiAccordion from 'components/PaydinMuiAccordion/PaydinMuiAccordion'
import { useEffect, useState } from 'react'

/**
 * Represents an accordion, each accordion consists of data as described in {@link PaydinMuiAccordion}.
 * There's maximum 1 accordion opened at a time.
 * @param {string} borderColor - The border color of each accordion
 * @param {number} headerBorderRadiusPx - The border radius of the header of the order summary
 * @param {boolean} hasBorder - Determins whether the accordions will have border or not
 * @param {string} headerBackgroundColor - The header background color of each accordion
 * @param {string} contentBackgroundColor - The content background color of each accordion
 * @param {string} openAccordionName - Automatically opens the accordion with the name "openAccordionName", if none given, then all the accordions will be closed on start
 * @param {function} onExpanded - A callback function to perform when the expand state of the accordion is changed. Used as a fallback in case it doesn't get a callback from the "accordions" object array
 * @param {boolean} allowZeroExpands - Determins whether all the accordions can be closed at the same time or not
 * @param {array} accordions - The accordion objects array which describes the displayed accordions
 * 
 * Accordion object structure: 
 * {
 *      name: string, 
 *      header: DOMElement,
 *      content: DOMElement,
 *      contentPaddingPx: number
 * }
 * 
 *  name ==> The name of the accordion, necessary for the accordion functioning
 *  header ==> The header of the accordion
 *  content ==> The content of the accordion
 *  contentPaddingPx ==> The padding of the accordion's content in pixels
 */
export default function PaydinAccordion({
    borderColor = '',
    borderRadiusPx = 0,
    hasBorder = true,
    headerBackgroundColor = 'white',
    contentBackgroundColor = 'white',
    headerPadding = '10px',
    contentPadding = '10px',
    openAccordionName = '',
    onExpanded = null,
    allowZeroExpands = true,
    accordions = []
}) {
    const [expandedAccordionName, setExpandedAccordionName] = useState(openAccordionName)

    useEffect(() => {
        setExpandedAccordionName(openAccordionName)
    }, [openAccordionName])

    return (
        <div className='paydin-accordion-container'>
            {
                accordions.map(accordion => {
                    return <PaydinMuiAccordion
                        key={accordion?.name ?? ''}
                        header={accordion?.header ?? <></>}
                        content={accordion?.content ?? <></>}
                        name={accordion?.name ?? ''}
                        borderColor={borderColor}
                        hasBorder={hasBorder}
                        borderRadiusPx={borderRadiusPx}
                        headerPadding={headerPadding}
                        contentPadding={contentPadding}
                        headerBackgroundColor={headerBackgroundColor}
                        contentBackgroundColor={contentBackgroundColor}
                        allowZeroExpands={allowZeroExpands}
                        expandedState={accordion.expandedState ?? expandedAccordionName}
                        setExpandedState={accordion.setExpandedState ?? (onExpanded ?? setExpandedAccordionName)} />
                })
            }
        </div>
    )
}