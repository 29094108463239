import { getLinkProducts, getLinkProductsCount } from 'api/links/links'
import PaydinTable from 'components/PaydinTable/PaydinTable'
import ProductsTableHeaderView from 'components/ProductsTableHeaderView/ProductsTableHeaderView'
import ProductsTableRowView from 'components/ProductsTableRowView/ProductsTableRowView'
import { MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX, PRODUCTS_TABLE_ROWS_PER_PAGE, PRODUCTS_TABLE_ROW_HEIGHT_PX, PRODUCT_TABLE_HEADERS_IDS } from 'constants/general.constants'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { extractProductRowData } from 'utils/dataUtils'
import { BsBoxSeam } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import './ProductsTable.css'

/**
 * Represents a products table for a specific link.
 * @param {string} linkId - The id of the link 
 * @param {array} removedColumnIds - An array of column IDs that are required to remove from display
 */
export default function ProductsTable({
    linkId,
    removedColumnIds = [],
    setHasProducts = () => { }
}) {
    const { t } = useTranslation()

    const [totalProductsCount, setTotalProductsCount] = useState(0)
    const [nextSkip, setNextSkip] = useState(0)

    useEffect(() => {
        getLinkProductsCount(linkId)
            .then(data => {
                setTotalProductsCount(data?.count ?? 0)
                setHasProducts(data?.count > 0)
            })
            .catch(console.log)
    }, [])

    function createData(productImage, productName, viewCount, addCount, buyCount, ranking) {
        let data = {
            [PRODUCT_TABLE_HEADERS_IDS.IMAGE]: productImage,
            [PRODUCT_TABLE_HEADERS_IDS.TITLE]: productName,
            [PRODUCT_TABLE_HEADERS_IDS.VIEW]: viewCount,
            [PRODUCT_TABLE_HEADERS_IDS.ADD]: addCount,
            [PRODUCT_TABLE_HEADERS_IDS.BUY]: buyCount,
            [PRODUCT_TABLE_HEADERS_IDS.RANK]: ranking
        }

        if (removedColumnIds.length > 0) {
            Object.keys(data).forEach(columnId => {
                if (removedColumnIds.includes(columnId)) {
                    delete data[columnId]
                }
            })
        }

        return data
    }

    function normalizeTableData(items) {
        const normalizedData = []

        for (const product of items) {
            normalizedData.push(
                createData(
                    product?.images?.small?.src,
                    product?.title,
                    product?.view,
                    product?.add,
                    product?.buy,
                    product?.rank
                )
            )
        }

        return normalizedData
    }

    function getProductsData(count, skip, onSuccess = () => { }, onFailure = () => { }) {
        if (skip === 0) {
            count = count + 1
        }
        getLinkProducts(linkId, count, nextSkip)
            .then(data => {
                setNextSkip(data?.next_skip ?? skip)
                onSuccess(normalizeTableData(data?.products ? data.products.map(product => extractProductRowData(product)) : []))
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    return (
        <PaydinTable
            visibleRowsCount={PRODUCTS_TABLE_ROWS_PER_PAGE}
            borderRadius='10px'
            totalCount={totalProductsCount}
            rowHeightPx={isMobile ? MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX : PRODUCTS_TABLE_ROW_HEIGHT_PX}
            rowView={ProductsTableRowView}
            headerView={ProductsTableHeaderView}
            hideHeaderInMobile={true}
            dataGenerator={getProductsData}
            noItemsImage={<BsBoxSeam className='products-table-no-items-image' />}
            noItemsText={t('PRODUCTS_TABLE_NO_ITEMS_TO_DISPLAY_MESSAGE')}
            removedColumnIds={removedColumnIds}
        />
    )
}