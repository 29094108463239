import React from 'react'

export default function ShoppingBagImage1(props) {
    return (
        <svg {...props} fill="none" height="24" viewBox="0 0 24 24" width="24">
            <g fill="rgb(0,0,0)">
                <path d="m5 7.75c-.13807 0-.25.11193-.25.25v13c0 .1381.11193.25.25.25h14c.1381 0 .25-.1119.25-.25v-13c0-.13807-.1119-.25-.25-.25zm-1.75.25c0-.9665.7835-1.75 1.75-1.75h14c.9665 0 1.75.7835 1.75 1.75v13c0 .9665-.7835 1.75-1.75 1.75h-14c-.9665 0-1.75-.7835-1.75-1.75z" />
                <path d="m12 2.75c-1.5188 0-2.75 1.23122-2.75 2.75v3.5c0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75v-3.5c0-2.34721 1.90279-4.25 4.25-4.25 2.3472 0 4.25 1.90279 4.25 4.25v3.5c0 .41421-.3358.75-.75.75s-.75-.33579-.75-.75v-3.5c0-1.51878-1.2312-2.75-2.75-2.75z" />
            </g>
        </svg>
        // <svg {...props} fill="none" height="512" viewBox="0 0 32 32" width="512">
        //     <g stroke="rgb(0,0,0)">
        //         <path d="m11 13.25v-2.5c0-3.03757 2.2386-5.5 5-5.5s5 2.46243 5 5.5v2.5" strokeLinecap="round" />
        //         <path d="m7.3884 12.9119c.04664-.7928.7032-1.4119 1.49741-1.4119h14.22839c.7942 0 1.4508.6191 1.4974 1.4119l.6402 10.8826c.1182 2.0103-1.4802 3.7055-3.494 3.7055h-11.5156c-2.0138 0-3.61221-1.6952-3.49395-3.7055z" />
        //     </g>
        // </svg>
    )
}
