import React from 'react'
import './HomePageSection.css'

/**
 * Represents a section used inside the home page
 * @param {string} title - The section's title
 * @param {string} description - The section's description
 */
export default function HomePageSection({
    title,
    description,
    children
}) {
    return (
        <div className='home-page-section'>
            <div className="home-page-section-titles-container">
                {title && <div className="home-page-section-title">{title}</div>}
                {description && <div className="home-page-section-description">{description}</div>}
            </div>
            {children}
        </div>
    )
}