import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './AddProductsDialog.css'
import { ProductSearchAPI } from 'api/products/products'
import { isMobile } from 'react-device-detect'
import AddProductsDialogProduct from 'components/AddProductsDialogProduct/AddProductsDialogProduct'
import MultiSelectDialog from 'dialogs/MultiSelectDialog/MultiSelectDialog'
import { extractProductData } from 'utils/dataUtils'
import AddProductsFilters from 'components/AddProductsFilters/AddProductsFilters'
import { ADD_PRODUCTS_DIALOG_DESKTOP_COLUMN_COUNT, ADD_PRODUCTS_DIALOG_MOBILE_COLUMN_COUNT, ADD_PRODUCTS_DIALOG_RESET_FILTER_TRIGGER_DELAY_MILLISECONDS } from 'constants/general.constants'
import { useGeneralDataContext } from 'contexts/User'

/**
 * Represents the dialog for adding products to the link.
 * @param {boolean} isDialogOpen - Determins whether this dialog is open or not
 * @param {function} handleDialogClose - A function for closing this dialog
 * @param {array} selectedProducts - The list of products the user selected
 * @param {function} setSelectedProducts - A function that sets the list of selected products
 * @param {number} selectMaxLimit - The upper bound of items the user can select from the list. If equals to 0, then no limit applied
 * @param {number} selectMinLimit - The lower bound of items the user can select from the list. If equals to 0, then no limit applied
 * @param {function} searchResultFilter - A function for filtering the search results
 */
export default function AddProductsDialog({
    isDialogOpen,
    handleDialogClose,
    selectedProducts,
    setSelectedProducts,
    selectMaxLimit = 0,
    selectMinLimit = 0,
    searchResultFilter = items => items,
    onFinishProductsSelection = () => { },
    onCancel = () => { },
    leftButtonText = 'Cancel',
    rightButtonText = 'Done',
    hasContentTopbar = true,
    resetSelectedOnFinish = false,
    allowColorOptionSelect = false,
}) {
    const { t } = useTranslation()
    const {
        fetchProductsOptionsMap,
    } = useGeneralDataContext()

    const [didFiltersChange, setDidFiltersChange] = useState(false)
    const [searchFilters, setSearchFilters] = useState({
        active: true,
        draft: false,
        outOfStock: true,
        searchInTags: false
    })

    useEffect(() => {
        // In case not fetched before, fetch it before loading products
        if (allowColorOptionSelect) {
            fetchProductsOptionsMap()
        }
    }, [allowColorOptionSelect])

    function triggerFilterChange() {
        setDidFiltersChange(true)
        setTimeout(() => { // This is used to reset the filter triggering prop for reuse
            setDidFiltersChange(false)
        }, ADD_PRODUCTS_DIALOG_RESET_FILTER_TRIGGER_DELAY_MILLISECONDS)
    }

    const filterComponent = ({ filterRef }) => {
        return <AddProductsFilters
            filters={searchFilters}
            setFilters={setSearchFilters}
            filtersRef={filterRef}
            onFilterChange={triggerFilterChange}
        />
    }

    function fetchProducts(q, count, skip) {
        return ProductSearchAPI(q, count, skip, searchFilters)
    }
    
    return (
        <MultiSelectDialog
            selectedItems={selectedProducts}
            setSelectedItems={setSelectedProducts}
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            title={t('ADD_PRODUCTS_DIALOG_TITLE')}
            itemName={t('PRODUCT_ITEM_NAME', { count: selectMaxLimit})}
            viewItemComponent={AddProductsDialogProduct}
            filterComponent={filterComponent}
            columnsCount={isMobile ? ADD_PRODUCTS_DIALOG_MOBILE_COLUMN_COUNT : ADD_PRODUCTS_DIALOG_DESKTOP_COLUMN_COUNT}
            dataGenerator={fetchProducts}
            dataExtractor={extractProductData}
            filtersChanged={didFiltersChange}
            selectMaxLimit={selectMaxLimit}
            selectMinLimit={selectMinLimit}
            searchResultFilter={searchResultFilter}
            onFinishProductsSelection={onFinishProductsSelection}
            onCancel={onCancel}
            rightButtonText={rightButtonText}
            leftButtonText={leftButtonText}
            hasContentTopbar={hasContentTopbar}
            resetSelectedOnFinish={resetSelectedOnFinish}
            allowColorOptionSelect={allowColorOptionSelect}
        />
    )
}