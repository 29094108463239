import React, { useRef, useState } from 'react'
import './SignUpPage.css'
import { useTranslation } from 'react-i18next'
import { EMAIL_PATTERN_REGEX } from 'constants/regex.constants'
import {
    BOXED_MESSAGE_TYPES,
    INPUT_VALIDATION_INDICATIONS,
    USERNAME_FIELD_PREFIX,
    SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS,
} from 'constants/general.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useAuthContext } from 'contexts/User'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { containsWhitespace, formatUsername } from 'utils/utils'
import PrivaciesFooterText from 'components/PrivaciesFooterText/PrivaciesFooterText'
import { ValidateEmail, ValidateUsername } from 'api/auth/auth'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import CheckYourEmail from 'components/CheckYourEmail/CheckYourEmail'
import AlreadyHaveAccount from 'components/AlreadyHaveAccount/AlreadyHaveAccount'

export default function SignUpPage() {
    const { t } = useTranslation()
    const { register } = useAuthContext()

    const [isSignUpInProcess, setIsSignUpInProcess] = useState(false)
    const [inputState, setInputState] = useState({
        username: '',
        email: '',
        password: ''
    })
    const [boxedMessageState, setBoxedMessageState] = useState({
        isOpen: false,
        message: ''
    })
    const [usernameValidationState, setUsernameValidationState] = useState({
        isValidating: false,
        validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
    })
    const [emailValidationState, setEmailValidationState] = useState({
        isValidating: false,
        validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
    })
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [validationErrorState, setValidationErrorState] = useState({
        username: '',
        email: ''
    })
    const [isRegisteredSuccessfully, setIsRegisteredSuccessfully] = useState(false)

    const typingTimeoutRef = useRef(null)

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function setUsername(username) {
        clearTypingTimeout(typingTimeoutRef.current)

        setInputState(prev => {
            return {
                ...prev,
                username: formatUsername(username),
            }
        })

        if (username.length === 0) {
            setUsernameValidationState(prev => ({
                ...prev,
                validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
            }))
            return
        }

        typingTimeoutRef.current = setTimeout(() => {
            validateUsername(username)
        }, SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS)
    }

    function setEmail(email) {
        clearTypingTimeout(typingTimeoutRef.current)

        setInputState(prev => {
            return {
                ...prev,
                email: email.replace(/\s/g, ''),
            }
        })

        if (email.length === 0) {
            setEmailValidationState(prev => ({
                ...prev,
                validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
            }))
            return
        }

        typingTimeoutRef.current = setTimeout(() => {
            validateEmail(email)
        }, SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS)
    }

    function setPassword(password) {
        setInputState(prev => {
            return {
                ...prev,
                password,
            }
        })
    }

    function resetAllFields() {
        setInputState({
            username: '',
            email: '',
            password: ''
        })
        setUsernameValidationState(prev => ({
            ...prev,
            validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
        }))
        setEmailValidationState(prev => ({
            ...prev,
            validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
        }))
    }

    function handleSignUp() {
        setBoxedMessageState(prev => ({
            ...prev,
            isOpen: false
        }))
        setIsSignUpInProcess(true)
        register(inputState.username, inputState.password, inputState.email, () => {
            setIsSignUpInProcess(false)
            setIsRegisteredSuccessfully(true)
        }, _ => {
            setIsSignUpInProcess(false)
            setBoxedMessageState({
                isOpen: true,
                message: t('SIGN_UP_PAGE_SIGN_UP_ERROR_MESSAGE_TEXT')
            })
        })
    }

    function isUsernameValid() {
        return !containsWhitespace(inputState.username)
    }

    function isEmailValid(email) {
        return EMAIL_PATTERN_REGEX.test(email)
    }

    function isAbleToSignUp() {
        return (
            usernameValidationState.validationResult === INPUT_VALIDATION_INDICATIONS.VALID &&
            emailValidationState.validationResult === INPUT_VALIDATION_INDICATIONS.VALID &&
            isPasswordValid
        )
    }

    function validateUsername(username) {
        if (!isUsernameValid(username)) {
            setUsernameValidationState({
                isValidating: false,
                validationResult: INPUT_VALIDATION_INDICATIONS.INVALID
            })
            setValidationErrorState(prev => ({
                ...prev,
                username: t('SIGN_UP_PAGE_INCORRECT_USERNAME_FORMAT_VALIDATION_ERROR_MESSAGE')
            }))
        } else {
            setUsernameValidationState(prev => ({
                ...prev,
                isValidating: true
            }))
            ValidateUsername(username)
                .then(_ => {
                    setUsernameValidationState({
                        isValidating: false,
                        validationResult: INPUT_VALIDATION_INDICATIONS.VALID
                    })
                })
                .catch(error => {
                    setUsernameValidationState({
                        isValidating: false,
                        validationResult: INPUT_VALIDATION_INDICATIONS.INVALID
                    })
                    setValidationErrorState(prev => ({
                        username: t('SIGN_UP_PAGE_DUPLICATE_USERNAME_VALIDATION_ERROR_MESSAGE')
                    }))
                })
        }
    }

    function validateEmail(email) {
        if (!isEmailValid(email)) {
            setEmailValidationState({
                isValidating: false,
                validationResult: INPUT_VALIDATION_INDICATIONS.INVALID
            })
            setValidationErrorState(prev => ({
                ...prev,
                email: t('SIGN_UP_PAGE_INCORRECT_EMAIL_FORMAT_VALIDATION_ERROR_MESSAGE')
            }))
        } else {
            setEmailValidationState(prev => ({
                ...prev,
                isValidating: true
            }))
            ValidateEmail(email)
                .then(response => {
                    setEmailValidationState({
                        isValidating: false,
                        validationResult: INPUT_VALIDATION_INDICATIONS.VALID
                    })
                })
                .catch(error => {
                    setEmailValidationState({
                        isValidating: false,
                        validationResult: INPUT_VALIDATION_INDICATIONS.INVALID
                    })
                    setValidationErrorState(prev => ({
                        ...prev,
                        email: t('SIGN_UP_PAGE_DUPLICATE_EMAIL_VALIDATION_ERROR_MESSAGE')
                    }))
                })
        }
    }

    function onSignUpSuccessGoBackClick() {
        resetAllFields()
        setIsRegisteredSuccessfully(false)
    }

    return (
        <div className={`sign-up-page-container ${isRegisteredSuccessfully ? 'register-success' : ''}`}>
            {isRegisteredSuccessfully ? <CheckYourEmail
                username={inputState.username}
                email={inputState.email}
                onBackClick={onSignUpSuccessGoBackClick}
            /> : <form dir={t('direction.dir')} className="sign-up-page-content">
                <div className="sign-up-page-title auth-page-title">{t('SIGN_UP_PAGE_TITLE')}</div>
                {
                    boxedMessageState.isOpen && <BoxedMessage
                        message={boxedMessageState.message}
                        type={BOXED_MESSAGE_TYPES.ERROR} />
                }
                <div className="sign-up-page-fields-form">
                    <EditFieldSectionInput
                        placeholder={t('SIGN_UP_PAGE_USERNAME_INPUT_PLACEHOLDER')}
                        prefix={USERNAME_FIELD_PREFIX}
                        isEmbeddedPrefix={true}
                        value={inputState.username}
                        setValue={setUsername}
                        hasValidation={true}
                        isValidating={usernameValidationState.isValidating}
                        validationStatus={usernameValidationState.validationResult}
                        validationErrorMessage={validationErrorState.username}
                        enlargedTextField={true}
                        id="username"
                    />
                    <EditFieldSectionInput
                        title={t('SIGN_UP_PAGE_EMAIL_INPUT_PLACEHOLDER')}
                        value={inputState.email}
                        setValue={setEmail}
                        hasValidation={true}
                        isValidating={emailValidationState.isValidating}
                        validationStatus={emailValidationState.validationResult}
                        validationErrorMessage={validationErrorState.email}
                        enlargedTextField={true}
                        id="email"
                    />
                    <PasswordInput
                        title={t('SIGN_UP_PAGE_PASSWORD_INPUT_PLACEHOLDER')}
                        value={inputState.password}
                        setValue={setPassword}
                        setValidationStatus={setIsPasswordValid}
                    />
                </div>
                <LoaderButton
                    className="sign-up-page-sign-up-button"
                    buttonText={t('SIGN_UP_PAGE_SIGN_UP_BUTTON_TEXT')}
                    isLoading={isSignUpInProcess}
                    renderAsButton={true}
                    isDisabled={!isAbleToSignUp()}
                    onClick={handleSignUp}
                />
                <AlreadyHaveAccount />
                <PrivaciesFooterText />
            </form>}
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={false}
                leftButtonText={dialogState.leftButtonText}
                isLeftButtonWithLoader={false}
            />
        </div>
    )
}