import React, { useEffect, useMemo, useState } from 'react'
import './OptionValueMapper.css'
import { useTranslation } from 'react-i18next'
import OptionValueMapperEntry from 'components/OptionValueMapperEntry/OptionValueMapperEntry'
import { OPTION_VALUE_MAPPER_OPTION_TYPES, OPTION_VALUE_MAPPER_PAGE_COUNT, OPTION_VALUE_MAPPER_ROW_GAP, OPTION_VALUE_MAPPER_ROW_HEIGHT } from 'constants/general.constants'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { PiWarningCircle } from 'react-icons/pi'
import { MdArrowForwardIos } from 'react-icons/md'
import { isMobile } from 'react-device-detect'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'

/**
 * Represents an option mapper that enables customizing the option's data.
 * @param {object} option - The displayed option
 * @param {function} updateOptionMap - A function for updating the option map
 * @param {boolean} isDiscardTriggered - A flag indicating that all changes done in this mapper should be discarded
 */
export default function OptionValueMapper({
    option = {},
    updateOptionMap = () => { },
    isDiscardTriggered = false
}) {
    const { t } = useTranslation()
    const optionTypes = [
        {
            type: OPTION_VALUE_MAPPER_OPTION_TYPES.COLOR,
            name: t('OPTION_VALUE_MAPPER_OPTION_TYPE_COLOR')
        },
        {
            type: OPTION_VALUE_MAPPER_OPTION_TYPES.TEXT,
            name: t('OPTION_VALUE_MAPPER_OPTION_TYPE_TEXT')
        },
    ]

    const [currentOption, setCurrentOption] = useState(JSON.parse(option))
    const [selectedOptionType, setSelectedOptionType] = useState(JSON.parse(option)?.type)
    const [currentPage, setCurrentPage] = useState(0)
    const [contentExpanded, setContentExpanded] = useState(true)

    useEffect(() => {
        const parsedOption = JSON.parse(option)
        setCurrentOption(parsedOption)
        setSelectedOptionType(parsedOption?.type)
    }, [option])

    // This opens or closes every time type is changed in case not all are verified
    useEffect(() => {
        if (currentOption && selectedOptionType) {
            setContentExpanded(getNotVerifiedValuesCount(selectedOptionType) > 0)
        }
    }, [selectedOptionType])

    // This closes or opens on first render if all is verified
    useEffect(() => {
        if (currentOption && selectedOptionType) {
            setContentExpanded(getNotVerifiedValuesCount(selectedOptionType) > 0)
        }
    }, [])

    const totalValuesRawOrdered = useMemo(() => {
        const isSelectedTypeColor = selectedOptionType === OPTION_VALUE_MAPPER_OPTION_TYPES.COLOR
        const isSelectedTypeText = selectedOptionType === OPTION_VALUE_MAPPER_OPTION_TYPES.TEXT

        return [
            ...(Object.keys(currentOption.values).filter(valueName => ((isSelectedTypeColor && !currentOption?.values[valueName]?.hex_value) || (isSelectedTypeText && !currentOption?.values[valueName]?.text_value)))),
            ...(Object.keys(currentOption.values).filter(valueName => ((isSelectedTypeColor && currentOption?.values[valueName]?.hex_value) || (isSelectedTypeText && currentOption?.values[valueName]?.text_value))))
        ]
    }, [isDiscardTriggered, selectedOptionType])

    const totalValues = useMemo(() => {
        const isSelectedTypeColor = selectedOptionType === OPTION_VALUE_MAPPER_OPTION_TYPES.COLOR

        return totalValuesRawOrdered?.map(valueName => renderValueRow(selectedOptionType, valueName, currentOption.values[valueName], isSelectedTypeColor))
    }, [currentOption, totalValuesRawOrdered, selectedOptionType])

    const visibleValues = useMemo(() => {
        return totalValues.slice(currentPage * OPTION_VALUE_MAPPER_PAGE_COUNT, currentPage * OPTION_VALUE_MAPPER_PAGE_COUNT + OPTION_VALUE_MAPPER_PAGE_COUNT > totalValues?.length ? totalValues?.length : currentPage * OPTION_VALUE_MAPPER_PAGE_COUNT + OPTION_VALUE_MAPPER_PAGE_COUNT)
    }, [currentPage, totalValues])

    function getValue(selectedOptionType, valueObject) {
        switch (selectedOptionType) {
            case OPTION_VALUE_MAPPER_OPTION_TYPES.COLOR: return valueObject?.hex_value ?? ''
            case OPTION_VALUE_MAPPER_OPTION_TYPES.TEXT: return valueObject?.text_value ?? ''
            default: return ''
        }
    }

    function renderValueRow(selectedOptionType, valueName, valueObject, isColor) {
        return <OptionValueMapperEntry
            key={valueName}
            optionValueName={valueName}
            optionValue={getValue(selectedOptionType, valueObject)}
            isColor={isColor}
            updateOptionMap={value => updateOptionMap(selectedOptionType, valueName, value)}
        />
    }

    function onOptionTypeChange(type) {
        if (selectedOptionType !== type) {
            setSelectedOptionType(type)
            updateOptionMap(type)
            setCurrentPage(0)
        }
    }

    // TODO instead of counting all the non verified values every time, count it once and maintain it in a state and update it when updating the options map.
    function getNotVerifiedValuesCount(selectedOptionType) {
        if (!selectedOptionType || Object.keys(currentOption?.values).length === 0) {
            return 0
        }

        let counter = 0

        for (const valueObject of Object.values(currentOption?.values)) {
            if ((selectedOptionType === OPTION_VALUE_MAPPER_OPTION_TYPES.COLOR && !valueObject?.hex_value) || (selectedOptionType === OPTION_VALUE_MAPPER_OPTION_TYPES.TEXT && !valueObject?.text_value)) {
                counter++
            }
        }

        return counter
    }

    function getOptionMapperStatus(selectedOptionType) {
        const notVerifiedValuesCount = getNotVerifiedValuesCount(selectedOptionType)
        if (notVerifiedValuesCount === 0) {
            return {
                message: t('OPTION_VALUE_MAPPER_ALL_VERIFIED_STATUS'),
                image: <IoIosCheckmarkCircleOutline className='option-value-mapper-status-image' style={{ color: 'green' }} />,
                allValuesVerified: true
            }
        }

        return {
            message: t('OPTION_VALUE_MAPPER_NOT_ALL_VERIFIED_STATUS', { notVerifiedCount: notVerifiedValuesCount, total: (!currentOption || Object.keys(currentOption).length === 0 || Object.keys(currentOption?.values).length === 0) ? 0 : Object.keys(currentOption.values).length }),
            image: <PiWarningCircle className="option-value-mapper-status-image" />,
            allValuesVerified: false
        }
    }

    function goToNextPage() {
        setCurrentPage(prev => prev + 1)
    }
    
    function goToPreviousPage() {
        setCurrentPage(prev => prev - 1)
    }
    return (
        <div className='option-value-mapper-container'>
            <div className={isMobile ? "mobile-option-value-mapper-type-picker-container" : "option-value-mapper-type-picker-container"}>
                <div className="option-value-mapper-type-picker">
                    {
                        optionTypes.map(optionType => <div
                            key={optionType?.name}
                            className={`option-value-mapper-option-type ${selectedOptionType === optionType?.type ? 'selected' : ''}`}
                            onClick={() => onOptionTypeChange(optionType?.type)}
                        >
                            {optionType?.name}
                        </div>)
                    }
                </div>
                <div className="option-value-mapper-status">
                    {getOptionMapperStatus(selectedOptionType).image}
                    <div className="option-value-mapper-status-message" style={{ color: getOptionMapperStatus(selectedOptionType).allValuesVerified && 'green' }}>{getOptionMapperStatus(selectedOptionType).message}</div>
                </div>
            </div>
            <CollapsingElement
                expanded={contentExpanded}
            >
                <div className="option-value-mapper-values" style={{ height: totalValues.length > OPTION_VALUE_MAPPER_PAGE_COUNT && `${(OPTION_VALUE_MAPPER_ROW_HEIGHT * OPTION_VALUE_MAPPER_PAGE_COUNT) + (OPTION_VALUE_MAPPER_ROW_GAP * (OPTION_VALUE_MAPPER_PAGE_COUNT - 1))}px` }}>
                    {visibleValues}
                </div>
                <div className="option-value-mapper-pagination-section">
                    <div className="option-value-mapper-pagination">
                        <div className="option-value-mapper-pagination-state">{t('OPTION_VALUE_MAPPER_PAGINATION_STATE_TEXT', { fromValueRow: (currentPage * OPTION_VALUE_MAPPER_PAGE_COUNT) + 1, toValueRow: currentPage * OPTION_VALUE_MAPPER_PAGE_COUNT + visibleValues.length, totalRows: totalValues?.length })}</div>
                        <div className="option-value-mapper-pagination-controls">
                            <MdArrowForwardIos className={`option-value-mapper-pagination-control-image option-value-mapper-pagination-previous-page-image ${currentPage === 0 ? 'disabled' : ''}`} onClick={goToPreviousPage} />
                            <MdArrowForwardIos className={`option-value-mapper-pagination-control-image option-value-mapper-pagination-next-page-image ${visibleValues.length % OPTION_VALUE_MAPPER_PAGE_COUNT !== 0 || ((currentPage + 1) * OPTION_VALUE_MAPPER_PAGE_COUNT === totalValues.length) ? 'disabled' : ''}`} onClick={goToNextPage} />
                        </div>
                    </div>
                </div>
            </CollapsingElement>
            <div className="option-value-mapper-content-toggle-button" onClick={() => setContentExpanded(prev => !prev)}>{contentExpanded ? 'Hide all' : 'See all'}</div>
        </div>
    )
}
