import React from 'react'
import './InfluencerInsights.css'
import DashboardDataSingleValue from 'components/DashboardDataSingleValue/DashboardDataSingleValue'
import DashboardDataMultiValue from 'components/DashboardDataMultiValue/DashboardDataMultiValue'
import DashboardDataValueBreakdown from 'components/DashboardDataValueBreakdown/DashboardDataValueBreakdown'
import SalesBreakdownChart from 'components/SalesBreakdownChart/SalesBreakdownChart'
import DashboardDataContainer from 'components/DashboardDataContainer/DashboardDataContainer'
import { useTranslation } from 'react-i18next'
import { getCurrencySymbol } from 'services/currency'
import { formatNumber } from 'utils/utils'
import { INFLUENCER_INSIGHTS_AOV_HEIGHT, INFLUENCER_INSIGHTS_CR_HEIGHT, INFLUENCER_INSIGHTS_LINKS_COLOR, INFLUENCER_INSIGHTS_ORGANIC_COLOR, INFLUENCER_INSIGHTS_ROW_COLUMN_GAP, INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_HEIGHT, INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_WIDTH, INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_HEIGHT, INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH, INFLUENCER_INSIGHTS_SALES_BREAKDOWN_PIE_WIDTH, INFLUENCER_INSIGHTS_SHORTS_COLOR, INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_HEIGHT, INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_WIDTH, NUMBER_TYPES } from 'constants/general.constants'
import { isMobile } from 'react-device-detect'
import { useUserDetailsContext } from 'contexts/User'

/**
 * Represents an insights dashboard for a specific influencer's overall data.
 * @param {number} totalRevenue - The total revenue of the influencer
 * @param {number} commission - The commission of the influencer
 * @param {number} totalLinksSales - The total link sales transactions of the influencer
 * @param {number} totalShortsSales - The total short sales transactions of the influencer
 * @param {number} totalOrganicSales - The total external sales transactions of the influencer
 * @param {number} totalLinksRevenue - The total links revenue of the influencer
 * @param {number} totalShortsRevenue - The total shorts revenue of the influencer
 * @param {number} totalOrganicRevenue - The total external revenue of the influencer
 * @param {number} linksClicks - The total link clicks of the influencer
 * @param {number} shortsClicks - The total short clicks of the influencer
 * @param {string} currency - The required currency for display
 * @param {boolean} isLoadingData - Determins whether the influencer's data is being loaded or not
 */
export default function InfluencerInsights({
    totalRevenue,
    commission,
    totalLinksSales,
    totalShortsSales,
    totalOrganicSales,
    totalLinksRevenue,
    totalShortsRevenue,
    totalOrganicRevenue,
    linksClicks,
    shortsClicks,
    currency = 'USD',
    isLoadingData = true
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()

    function hasValuePermission(permission) {
        return userDetails?.isAdmin || permission
    }

    function renderSingleItems() {
        return <>
            {
                hasValuePermission(userDetails?.permissions?.revenueEnabled) && <DashboardDataSingleValue
                    title={t('INFLUENCER_INSIGHTS_TOTAL_REVENUE_TITLE')}
                    value={`${getCurrencySymbol(currency)} ${formatNumber(totalRevenue, NUMBER_TYPES.SHORT, false, 100_000)}`}
                    width={INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_WIDTH}
                    height={INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_HEIGHT}
                />
            }
            {
                hasValuePermission(userDetails?.permissions?.commissionEnabled) &&<DashboardDataSingleValue
                    title={t('INFLUENCER_INSIGHTS_COMMISSION_TITLE')}
                    value={`${getCurrencySymbol(currency)} ${formatNumber(commission, NUMBER_TYPES.SHORT, false, 100_000)}`}
                    width={INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_WIDTH}
                    height={INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_HEIGHT}
                />
            }
        </>
    }

    function renderSales() {
        return hasValuePermission(userDetails?.permissions?.salesEnabled) && <DashboardDataMultiValue
            title={t('INFLUENCER_INSIGHTS_SALES_TITLE')}
            dataArray={[
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_LINKS_TITLE'),
                    value: totalLinksSales,
                    bulletColor: INFLUENCER_INSIGHTS_LINKS_COLOR
                },
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_SHORTS_TITLE'),
                    value: totalShortsSales,
                    bulletColor: INFLUENCER_INSIGHTS_SHORTS_COLOR
                },
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_ORGANIC_TITLE'),
                    value: totalOrganicSales,
                    bulletColor: INFLUENCER_INSIGHTS_ORGANIC_COLOR
                }
            ]}
            width={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH}
            height={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_HEIGHT}
        />
    }

    function renderSalesBreakdown() {
        return hasValuePermission(userDetails?.permissions?.revenueEnabled) && <DashboardDataContainer
            title={t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_TITLE')}
            width={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_WIDTH}
            height={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_HEIGHT}
        >
            <SalesBreakdownChart
                linksRevenue={totalLinksRevenue}
                shortsRevenue={totalShortsRevenue}
                organicRevenue={totalOrganicRevenue}
                linksColor={INFLUENCER_INSIGHTS_LINKS_COLOR}
                shortsColor={INFLUENCER_INSIGHTS_SHORTS_COLOR}
                organicColor={INFLUENCER_INSIGHTS_ORGANIC_COLOR}
                currency={currency}
                dimens={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_PIE_WIDTH}
                isLoadingData={isLoadingData}
            />
        </DashboardDataContainer>
    }

    function renderAOVBreakdown() {
        return hasValuePermission(userDetails?.permissions?.revenueEnabled && userDetails?.permissions?.aovEnabled) && <DashboardDataValueBreakdown
            title={t('INFLUENCER_INSIGHTS_AOV_TITLE')}
            value={`${getCurrencySymbol(currency)} ${formatNumber(totalRevenue / (totalLinksSales + totalShortsSales + totalOrganicSales), NUMBER_TYPES.SHORT, true)}`}
            breakdownData={[
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_LINKS_TITLE'),
                    value: `${getCurrencySymbol(currency)} ${formatNumber(totalLinksRevenue / totalLinksSales, NUMBER_TYPES.SHORT)}`,
                    bulletColor: INFLUENCER_INSIGHTS_LINKS_COLOR
                },
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_SHORTS_TITLE'),
                    value: `${getCurrencySymbol(currency)} ${formatNumber(totalShortsRevenue / totalShortsSales, NUMBER_TYPES.SHORT)}`,
                    bulletColor: INFLUENCER_INSIGHTS_SHORTS_COLOR
                },
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_ORGANIC_TITLE'),
                    value: `${getCurrencySymbol(currency)} ${formatNumber(totalOrganicRevenue / totalOrganicSales, NUMBER_TYPES.SHORT)}`,
                    bulletColor: INFLUENCER_INSIGHTS_ORGANIC_COLOR
                }
            ]}
            width={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH}
            height={INFLUENCER_INSIGHTS_AOV_HEIGHT}
        />
    }

    function renderCR() {
        return hasValuePermission(userDetails?.permissions?.salesEnabled && userDetails?.permissions?.crEnabled) && <DashboardDataMultiValue
            title={t('INFLUENCER_INSIGHTS_CR_TITLE')}
            shouldSpreadItems={true}
            dataArray={[
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_LINKS_TITLE'),
                    value: `${formatNumber(totalLinksSales / linksClicks * 100, NUMBER_TYPES.PERCENTAGE)}%`,
                    bulletColor: INFLUENCER_INSIGHTS_LINKS_COLOR
                },
                {
                    name: t('INFLUENCER_INSIGHTS_SALES_BREAKDOWN_SHORTS_TITLE'),
                    value: `${formatNumber(totalShortsSales / shortsClicks * 100, NUMBER_TYPES.PERCENTAGE)}%`,
                    bulletColor: INFLUENCER_INSIGHTS_SHORTS_COLOR
                }
            ]}
            width={INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH}
            height={INFLUENCER_INSIGHTS_CR_HEIGHT}
        />
    }

    function renderDesktopInfluencerInsights() {
        return (<>
            <div className="influencer-insights-row">
                {renderSingleItems()}
                {renderSales()}
            </div>
            <div className="influencer-insights-row">
                {renderSalesBreakdown()}
                <div className="influencer-insights-column" style={{ width: INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH, height: INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_HEIGHT }}>
                    {renderAOVBreakdown()}
                    {renderCR()}
                </div>
            </div>
        </>)
    }

    function renderMobileInfluencerInsights() {
        return (<>
            <div className="mobile-influencer-insights-row">
                {renderSingleItems()}
            </div>
            {renderSales()}
            {renderSalesBreakdown()}
            {renderAOVBreakdown()}
            {renderCR()}
        </>)
    }

    return (
        <div className={isMobile ? 'mobile-influencer-insights' : 'influencer-insights'}>
            {isMobile ? renderMobileInfluencerInsights() : renderDesktopInfluencerInsights()}
        </div>
    )
}