import React from 'react'

export default function ShopboardImage(props) {
    return ( 
        <svg 
            {...props}
            height="512pt" 
            viewBox="0 0 512 512" 
            width="512pt"
        >
            <path d="m476.855469 307.148438-29.9375-29.933594-42.425781 42.425781 29.9375 29.933594c23.390624 23.394531 23.390624 61.464843 0 84.855469-23.390626 23.390624-61.460938 23.390624-84.855469 0l-157.214844-157.214844-42.425781 42.425781 157.214844 157.214844c46.859374 46.859375 122.847656 46.859375 169.707031 0s46.859375-122.847657 0-169.707031zm0 0"/><path d="m162.425781 434.429688c-23.394531 23.390624-61.464843 23.390624-84.855469 0-23.390624-23.390626-23.390624-61.460938 0-84.855469l157.214844-157.214844-42.425781-42.425781-157.214844 157.214844c-46.859375 46.859374-46.859375 122.847656 0 169.707031s122.847657 46.859375 169.707031 0l29.933594-29.9375-42.425781-42.425781zm0 0"/><path d="m349.574219 77.570312c23.394531-23.390624 61.464843-23.390624 84.855469 0 23.390624 23.390626 23.390624 61.460938 0 84.855469l-157.214844 157.214844 42.425781 42.425781 157.214844-157.214844c46.859375-46.859374 46.859375-122.847656 0-169.707031s-122.847657-46.859375-169.707031 0l-29.933594 29.9375 42.425781 42.425781zm0 0"/><path d="m65.082031 234.785156 42.425781-42.425781-29.9375-29.933594c-23.390624-23.394531-23.390624-61.464843 0-84.855469 23.390626-23.390624 61.460938-23.390624 84.855469 0l163.425781 163.425782 42.425782-42.425782-163.425782-163.425781c-46.859374-46.859375-122.847656-46.859375-169.707031 0s-46.859375 122.847657 0 169.707031zm0 0"/>
        </svg>
    )
}