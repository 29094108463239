import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import './App.css'
import {
	LOGIN_PAGE_URL,
	SIGN_UP_PAGE_URL,
	SIGN_UP_REQUEST_PAGE_URL,
	FORGOT_PASSWORD_PAGE_URL,
	EXTERNAL_AUTH_PAGE_URL,
	CREATE_NEW_PASSWORD_PAGE_URL,
	PRIVACY_POLICY_PAGE_URL,
	TERMS_PAGE_URL,
	ANALYTICS_PAGE_URL,
	ACCOUNT_PAGE_URL,
	APPEARANCE_PAGE_URL,
	LINKS_PAGE_URL,
	PRICING_PLANS_PAGE_URL,
	SHOPIFY_HELP_PAGE_URL,
	ADMIN_PAGES_URL_PREFIX,
	ACCOUNT_VERIFICATION_PAGE_URL,
	INFLUENCERS_PAGE_URL,
	INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
	LOGIN_INFLUENCER_PAGE_URL,
	SHORTS_PAGE_URL,
	LOGIN_STORE_PAGE_URL,
	REGISTER_STORE_PAGE_URL,
	SETTINGS_PAGE_URL,
	HOME_PAGE_URL,
	GOTO_HOMEPAGE_URLS,
	MAGIC_STORE_PAGE_URL,
	SHOPBOARD_PAGE_URL,
	SHOPBOARD_AUTH_PAGE_URL,
	MULTILINKS_PAGE_URL,
	TRENDS_PAGE_URL,
} from 'constants/routes.constants'
import HistoryProvider from 'contexts/History'
import RouteWrapper from 'layouts/RouteWrapper'
import LinksPage from 'pages/LinksPage/LinksPage'
import TrendsPage from 'pages/TrendsPage/TrendsPage'
import AuthLayout from 'layouts/RequiredAuthLayout'
import UserProvider from 'contexts/User'
import NotFound from 'pages/NotFound/NotFound'
import {
	APPLICATION_RECOVERY_TIMEOUT_MILISECONDS,
	NOTIFY_STORE_SUBSCRIPTION_FINISHED_MESSAGE
} from 'constants/general.constants'
import Splash from 'pages/Splash/Splash'
import SignUpPage from 'pages/SignUpPage/SignUpPage'
import ExternalAuthPage from 'pages/ExternalAuthPage/ExternalAuthPage'
import CreateNewPasswordPage from 'pages/CreateNewPasswordPage/CreateNewPasswordPage'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage'
import TermsAndConditionsPage from 'pages/TermsAndConditionsPage/TermsAndConditionsPage'
import ShopifyHelpPage from 'pages/ShopifyHelpPage/ShopifyHelpPage'
import AnalyticsPage from 'pages/AnalyticsPage/AnalyticsPage'
import AccountPage from 'pages/AccountPage/AccountPage'
import GeneralLayout from 'layouts/GeneralLayout/GeneralLayout'
import AppearancePage from 'pages/AppearancePage/AppearancePage'
import 'bootstrap/dist/css/bootstrap.min.css'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import PricingPlansPage from 'pages/PricingPlansPage/PricingPlansPage'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AccountVerificationPage from 'pages/AccountVerificationPage/AccountVerificationPage'
import SignUpRequestPage from 'pages/SignUpRequestPage/SignUpRequestPage'
import { isMobile } from 'react-device-detect'
import InfluencersPage from 'pages/InfluencersPage/InfluencersPage'
import ForgotInfluencerPassword from 'pages/ForgotInfluencerPassword/ForgotInfluencerPassword'
import ForgotMerchantPassword from 'pages/ForgotMerchantPassword/ForgotMerchantPassword'
import InfluencerLoginPage from 'pages/InfluencerLoginPage/InfluencerLoginPage'
import AdminLoginPage from 'pages/AdminLoginPage/AdminLoginPage'
import ShortsPage from 'pages/ShortsPage/ShortsPage'
import RegisterStorePage from 'pages/RegisterStorePage/RegisterStorePage'
import LoginStorePage from 'pages/LoginStorePage/LoginStorePage'
import SettingsPage from 'pages/SettingsPage/SettingsPage'
import HomePage from 'pages/HomePage/HomePage'
import MagicStorePage from 'pages/MagicStorePage/MagicStorePage'
import ShopboardPage from 'pages/ShopboardPage/ShopboardPage'
import ShopboardAuthPage from 'pages/ShopboardAuthPage/ShopboardAuthPage'
import MultilinksPage from 'pages/MultilinksPage/MultilinksPage'

function App() {

	const [isUserLoaded, setIsUserLoaded] = useState(false)
	const [isRecovery, setIsRecovery] = useState(false)
	const [isReady, setIsReady] = useState(false)
	const [isError, setIsError] = useState(false)

	useEffect(() => {

		setTimeout(() => { setIsRecovery(true) }, APPLICATION_RECOVERY_TIMEOUT_MILISECONDS)

		// This is used to hide all scroll bars in mobile devices
		if (isMobile) {
			const elHead = document.getElementsByTagName('head')[0]
			const elStyle = document.createElement('style')

			elHead.appendChild(elStyle)
			elStyle.innerHTML = '*::-webkit-scrollbar { display: none; }  body { -ms-overflow-style: none; scrollbar-width: none; }'
		}

		// catch general error and refresh the page
		// window.addEventListener("error", (event) => {
		//   window.location.replace(LINKS_PAGE_URL);
		// })

	}, [])

	useEffect(() => {
		// In case arrived here from shopify subscription
		if (window?.opener && isUserLoaded) {
			window?.opener.postMessage(NOTIFY_STORE_SUBSCRIPTION_FINISHED_MESSAGE, '*')
		}
	}, [isUserLoaded])

	useEffect(() => {
		if (isRecovery && !isUserLoaded) {
			setIsError(true)
		}
	}, [isRecovery])

	return (
		<>
			{
				isError ? <NotFound /> :
					<BrowserRouter>
						<Switch>
							<HistoryProvider>
								<UserProvider onUserLoaded={setIsUserLoaded} onReady={setIsReady}>
									{(!isUserLoaded || !isReady) && <Splash />}
									{isUserLoaded &&
										<>
											<RouteWrapper
												path={HOME_PAGE_URL}
												exact
												component={HomePage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={SHOPBOARD_AUTH_PAGE_URL}
												exact
												component={ShopboardAuthPage}
												layout={GeneralLayout}
											/>
											<RouteWrapper
												path={SHOPBOARD_PAGE_URL}
												exact
												component={ShopboardPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={MAGIC_STORE_PAGE_URL}
												exact
												component={MagicStorePage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={GOTO_HOMEPAGE_URLS}
												exact
												redirect={HOME_PAGE_URL}
											/>
											<RouteWrapper
												path={INFLUENCERS_PAGE_URL}
												exact
												component={InfluencersPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={SHORTS_PAGE_URL}
												exact
												component={ShortsPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={LINKS_PAGE_URL}
												exact
												component={LinksPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={MULTILINKS_PAGE_URL}
												exact
												component={MultilinksPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={TRENDS_PAGE_URL}
												exact
												component={TrendsPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={ANALYTICS_PAGE_URL}
												exact
												component={AnalyticsPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={ACCOUNT_PAGE_URL}
												exact
												component={AccountPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={APPEARANCE_PAGE_URL}
												exact
												component={AppearancePage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={PRICING_PLANS_PAGE_URL}
												exact
												component={PricingPlansPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={SHOPIFY_HELP_PAGE_URL}
												exact
												component={ShopifyHelpPage}
												layout={GeneralLayout}
											/>
											<RouteWrapper
												path={LOGIN_INFLUENCER_PAGE_URL}
												exact
												component={InfluencerLoginPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={LOGIN_STORE_PAGE_URL}
												exact
												component={LoginStorePage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={LOGIN_PAGE_URL}
												exact
												component={AdminLoginPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SETTINGS_PAGE_URL}
												exact
												component={SettingsPage}
												layout={DashboardLayout}
											/>
											<RouteWrapper
												path={SIGN_UP_PAGE_URL}
												exact
												component={SignUpPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={REGISTER_STORE_PAGE_URL}
												exact
												component={RegisterStorePage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={SIGN_UP_REQUEST_PAGE_URL}
												exact
												component={SignUpRequestPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={FORGOT_PASSWORD_PAGE_URL}
												exact
												component={ForgotMerchantPassword}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={INFLUENCER_FORGOT_PASSWORD_PAGE_URL}
												exact
												component={ForgotInfluencerPassword}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={CREATE_NEW_PASSWORD_PAGE_URL}
												exact
												component={CreateNewPasswordPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={EXTERNAL_AUTH_PAGE_URL}
												exact
												component={ExternalAuthPage}
												layout={AuthLayout}
											/>
											<RouteWrapper
												path={PRIVACY_POLICY_PAGE_URL}
												exact
												component={PrivacyPolicyPage}
												layout={GeneralLayout}
											/>
											<RouteWrapper
												path={TERMS_PAGE_URL}
												exact
												component={TermsAndConditionsPage}
												layout={GeneralLayout}
											/>
											<RouteWrapper
												path={ACCOUNT_VERIFICATION_PAGE_URL}
												exact
												component={AccountVerificationPage}
												layout={BaseLayout}
											/>
											<RouteWrapper
												path={ADMIN_PAGES_URL_PREFIX}
												exact
												redirect={HOME_PAGE_URL}
											/>
										</>
									}
								</UserProvider>
							</HistoryProvider>
						</Switch>
					</BrowserRouter>
			}
		</>
	)
}

export default App;
