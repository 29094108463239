import React, { useState } from 'react'
import './DashboardSidebar.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX, DASHBOARD_SIDEBAR_WIDTH_PX, SHOPEAKS_LOGO_URL, SHOPEAKS_SMALL_LOGO_URL } from 'constants/general.constants'
import HomePageImage from 'embeddedImages/HomePageImage'
import { styled } from '@mui/material/styles'
import {
    ANALYTICS_PAGE_URL,
    APPEARANCE_PAGE_URL,
    HOME_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    LINKS_PAGE_URL,
    MAGIC_STORE_PAGE_URL,
    MULTILINKS_PAGE_URL,
    SETTINGS_PAGE_URL,
    SHOPBOARD_PAGE_URL,
    SHOPIFY_HELP_PAGE_URL,
    SHORTS_PAGE_URL,
    TRENDS_PAGE_URL
} from 'constants/routes.constants'
import { useUserDetailsContext } from 'contexts/User'
import { useTranslation } from 'react-i18next'
import { BiLink } from 'react-icons/bi'
import ShortsImage from 'embeddedImages/ShortsImage'
import { FiUsers } from 'react-icons/fi'
import { RiPaletteLine } from 'react-icons/ri'
import { TbDeviceDesktopAnalytics, TbSettings2 } from 'react-icons/tb'
import { useHistoryContext } from 'contexts/History'
import MuiDrawer from '@mui/material/Drawer'
import UserMenu from 'components/UserMenu/UserMenu'
import { SlQuestion } from 'react-icons/sl'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { LiaShoppingBagSolid } from 'react-icons/lia'
import MagicStoreImage from 'embeddedImages/MagicStoreImage'
import { FaRegCalendarCheck } from "react-icons/fa";
import MultilinksImage from 'embeddedImages/MultilinksImage'
import ShopboardImage from 'embeddedImages/ShopboardImage'
import InfluencersImage from 'embeddedImages/InfluencersImage'
import TrendsImage from 'embeddedImages/TrendsImage'


export default function DashboardSidebar({
    open
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        hasShopifyStore,
        isEditing,
        endEditing
    } = useUserDetailsContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showDiscardChangesDialog(callback = () => { }) {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_TITLE'),
            message: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_MESSAGE'),
            leftButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: () => {
                history.goBack()
                setTimeout(() => {
                    callback()
                }, 100)
            }
        }))
    }

    function goToHome() {
        navigateTo(HOME_PAGE_URL)
    }

    function goToShopboard() {
        navigateTo(SHOPBOARD_PAGE_URL)
    }

    function goToMultilinks() {
        navigateTo(MULTILINKS_PAGE_URL)
    }

    function goToMagicStore() {
        navigateTo(MAGIC_STORE_PAGE_URL)
    }

    function goToLinks() {
        navigateTo(LINKS_PAGE_URL)
    }

    function goToShorts() {
        navigateTo(SHORTS_PAGE_URL)
    }

    function goToAppearance() {
        navigateTo(APPEARANCE_PAGE_URL)
    }

    function goToAnalytics() {
        navigateTo(ANALYTICS_PAGE_URL)
    }

    function goToTrends() {
        navigateTo(TRENDS_PAGE_URL)
    }

    function goToInfluencers() {
        navigateTo(INFLUENCERS_PAGE_URL)
    }

    function openSettingsPage() {
        navigateTo(SETTINGS_PAGE_URL)
    }

    function openHelpPage() {
        navigateTo(SHOPIFY_HELP_PAGE_URL)
    }

    function navigateTo(url) {
        if (window.location.pathname !== url) {
            if (isEditing()) {
                showDiscardChangesDialog(() => {
                    endEditing()
                    history.push(url)
                })
            } else {
                history.push(url)
            }
        }
    }

    function hasAnalyticsPermission() {
        return userDetails?.permissions?.clicksEnabled ||
            userDetails?.permissions?.salesEnabled ||
            userDetails?.permissions?.revenueEnabled ||
            userDetails?.permissions?.commissionEnabled
    }

    const openedMixin = (theme) => ({
        width: DASHBOARD_SIDEBAR_WIDTH_PX,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    })

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `${DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX}px !important`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    })

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: DASHBOARD_SIDEBAR_WIDTH_PX,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    )

    return (
        <>
            <Drawer
                variant="permanent"
                anchor="left"
                open={open}
            >
                <div className="dashboard-sidebar-container">
                    {open ? <ImageBox
                        className='dashboard-sidebar-image'
                        image={SHOPEAKS_LOGO_URL}
                    /> : <ImageBox
                        className='dashboard-sidebar-image sidebar-closed'
                        image={SHOPEAKS_SMALL_LOGO_URL}
                    />}
                    <UserMenu minimized={!open} />
                    <div className="dashboard-sidebar-content">
                        <div className="dashboard-sidebar-buttons-container">
                            <div onClick={goToHome} className={`dashboard-sidebar-button dashboard-sidebar-custom-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(HOME_PAGE_URL) ? 'selected' : ''}`}>
                                <HomePageImage className='dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_HOME_BUTTON_TEXT')}</div>
                            </div>
                            {(userDetails?.isAdmin) &&<div className="dashboard-sidebar-horizontal-line"></div>}
                            {/* <div onClick={goToMagicStore} className={`dashboard-sidebar-color-button dashboard-sidebar-button dashboard-sidebar-custom-button ${open ? '' : 'sidebar-closed'} ${window.location.pathname.startsWith(MAGIC_STORE_PAGE_URL) ? 'selected' : ''}`}>
                                <MagicStoreImage className='dashboard-sidebar-grad-button-image dashboard-sidebar-button-image' />
                                <div className={`dashboard-sidebar-grad-button-label dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_MAGIC_STORE_BUTTON_TEXT')}</div>
                            </div> */}
                            <div className='dashboard-sidebar-color-button-with-line-container'>
                                <div onClick={goToLinks} className={`dashboard-sidebar-color-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(LINKS_PAGE_URL) ? 'selected' : ''}`}>
                                    <BiLink className='dashboard-sidebar-button-image' />
                                    <div className={` dashboard-sidebar-color-button-label dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_SIDEBAR_LINKS_BUTTON_TEXT')}</div>
                                </div>
                            </div>
                            <div className='dashboard-sidebar-color-button-with-line-container'>
                                <div onClick={goToShorts} className={`dashboard-sidebar-color-button dashboard-sidebar-shorts-button dashboard-sidebar-button dashboard-sidebar-custom-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(SHORTS_PAGE_URL) ? 'selected' : ''}`}>
                                    <ShortsImage className={`dashboard-sidebar-shorts-button-image dashboard-sidebar-button-image ${!hasShopifyStore() ? 'disabled' : ''}`} />
                                    <div className={`dashboard-sidebar-color-button-label dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_SHORTS_BUTTON_TEXT')}</div>
                                </div>
                            </div>
                            {
                                userDetails?.isAdmin && <div className='dashboard-sidebar-color-button-with-line-container'>
                                    <div onClick={goToMultilinks} className={`dashboard-sidebar-color-button dashboard-sidebar-button dashboard-sidebar-custom-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(MULTILINKS_PAGE_URL) ? 'selected' : ''}`}>
                                        <MultilinksImage className={`dashboard-sidebar-color-button-image dashboard-sidebar-button-image ${!hasShopifyStore() ? 'disabled' : ''}`} />
                                        <div className={`dashboard-sidebar-color-button-label dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_MULTILINKS_BUTTON_TEXT')}</div>
                                    </div>
                                </div>
                            }
                            {
                                userDetails?.isAdmin && <div className='dashboard-sidebar-color-button-with-line-container'>
                                    <div onClick={goToShopboard} className={`dashboard-sidebar-color-button dashboard-sidebar-button dashboard-sidebar-custom-button ${open ? '' : 'sidebar-closed'}  ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(SHOPBOARD_PAGE_URL) ? 'selected' : ''}`}>
                                        <ShopboardImage className={`dashboard-sidebar-color-button-image dashboard-sidebar-button-image ${!hasShopifyStore() ? 'disabled' : ''}`} />
                                        <div className={`dashboard-sidebar-color-button-label dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_SHOPBOARD_BUTTON_TEXT')}</div>
                                    </div>
                                </div>
                            }
                            {
                                userDetails?.isAdmin && <div className='dashboard-sidebar-color-button-with-line-container'>
                                    <div onClick={goToInfluencers} className={`dashboard-sidebar-color-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(INFLUENCERS_PAGE_URL) ? 'selected' : ''}`}>
                                        <InfluencersImage className='dashboard-sidebar-color-button-image dashboard-sidebar-button-image' />
                                        <div className={`dashboard-sidebar-color-button-label dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_INFLUENCERS_BUTTON_TEXT')}</div>
                                    </div>
                                </div>
                            }
                            {(userDetails?.isAdmin) &&<div className="dashboard-sidebar-horizontal-line"></div>}
                            {
                                (userDetails?.isAdmin) && <div onClick={() => goToTrends(false)} className={`dashboard-sidebar-trends-button dashboard-sidebar-button dashboard-sidebar-custom-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(TRENDS_PAGE_URL) ? 'selected' : ''}`}>
                                    <TrendsImage className={`dashboard-sidebar-trends-button-image dashboard-sidebar-button-image ${!hasShopifyStore() ? 'disabled' : ''}`} />
                                    <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_TRENDS_BUTTON_TEXT')}</div>
                                </div>
                            }
                            {
                                (userDetails?.isAdmin || hasAnalyticsPermission()) && <div onClick={() => goToAnalytics(false)} className={`dashboard-sidebar-analytics-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(ANALYTICS_PAGE_URL) ? 'selected' : ''}`}>
                                    <TbDeviceDesktopAnalytics className='dashboard-sidebar-analytics-button-image dashboard-sidebar-button-image' />
                                    <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_ANALYTICS_BUTTON_TEXT')}</div>
                                </div>
                            }
                        </div>
                        <div className="dashboard-sidebar-buttons-container">
                            {
                                userDetails?.isAdmin && <div onClick={() => window.open('https://calendly.com/paylinkshop/15-min','_blank')} className={`dashboard-sidebar-button dashboard-sidebar-button-link ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(SETTINGS_PAGE_URL) ? 'selected' : ''}`}>
                                    <FaRegCalendarCheck className='dashboard-sidebar-button-image link' />
                                    <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'} link`} >{t("DASHBOARD_TOPBAR_BOOK_AN_EXPERT_BUTTON_TEXT")}</div>
                                </div>
                            }
                            {
                                userDetails?.isAdmin && <div onClick={goToAppearance} className={`dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(APPEARANCE_PAGE_URL) ? 'selected' : ''}`}>
                                    <RiPaletteLine className='dashboard-sidebar-button-image' />
                                    <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_APPEARANCE_BUTTON_TEXT')}</div>
                                </div>
                            }
                            {
                                userDetails?.isAdmin && <div onClick={openSettingsPage} className={`dashboard-sidebar-settings-button dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(SETTINGS_PAGE_URL) ? 'selected' : ''}`}>
                                    <TbSettings2 className='dashboard-sidebar-settings-button-image dashboard-sidebar-button-image' />
                                    <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_TOPBAR_SETTINGS_BUTTON_TEXT')}</div>
                                </div>
                            }
                            <div onClick={openHelpPage} className={`dashboard-sidebar-button ${open ? '' : 'sidebar-closed'} ${!hasShopifyStore() ? 'disabled' : ''} ${window.location.pathname.startsWith(SHOPIFY_HELP_PAGE_URL) ? 'selected' : ''}`}>
                                <SlQuestion className={`dashboard-sidebar-button-image ${!hasShopifyStore() ? 'disabled' : ''}`} />
                                <div className={`dashboard-sidebar-button-label ${open ? '' : 'sidebar-closed'}`}>{t('DASHBOARD_SIDEBAR_BUTTON_HELP')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={false}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={false}
                onRightButtonClick={dialogState.rightButtonClickHandler}
            />
        </>
    )
}