import React from 'react'
import './TabOption.css'
import { isMobile } from 'react-device-detect'

export default function TabOption({
    text = '',
    image = <></>,
    onClick = () => { },
    currentIndex = 0,
    selectedTabOptionIndex = 0,
    selectedBottomLine = false,
    optionPadding = isMobile ? '10px' : '20px 10px'
}) {
    return (
        <div className={`${isMobile ? 'mobile-tab-option-container' : 'tab-option-container'} ${currentIndex === selectedTabOptionIndex ? 'selected' : ''} ${selectedBottomLine ? 'selected-bottom-line' : ''}`} onClick={onClick}
            style={{ padding: optionPadding }}>
            {image}{text}
        </div>
    )
}