import React from 'react'
import './AddInfluencerOption.css'
import ImageBox from 'components/ImageBox/ImageBox'

/**
 * Represents an option inside {@link AddInfluencerSelect} options list.
 * @param {string} image - The image of the influencer
 * @param {string} username - The username of the influencer
 */
export default function AddInfluencerOption({
    image,
    username
}) {
    return (
        <div className='add-influencer-option'>
            <ImageBox
                image={image}
                className='add-influencer-option-image'
                roundImage={true}
            />
            <div className="add-influencer-option-username">{username}</div>
        </div>
    )
}
