import React, { useEffect, useState } from 'react'
import './PreviewLinkSection.css'
import ImageBox from 'components/ImageBox/ImageBox'
import LinkAnalyticsSection from 'components/LinkAnalyticsSection/LinkAnalyticsSection'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/utils'
import { getCurrencySymbol } from 'services/currency'
import { isDesktop, isMobile } from 'react-device-detect'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { copyToClipboard } from 'services/SystemService'
import { useHistoryContext } from 'contexts/History'
import { environment } from 'conf'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { DeleteLinkAPI, GetLinkAnalytics, GetLinkDetailsAPI } from 'api/links/links'
import { CONTENT_TYPES, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY, LINK_TYPES, NUMBER_TYPES, PAYDIN_DRAWER_TRANSITION_DURATION, PAYDIN_MENU_OPENING_DIRECTIONS, PRODUCT_TABLE_HEADERS_IDS, SECTION_TYPES, SHORT_INFO_URL_NO_CUSTOM_IMAGE_DIMENS_PX, SHORT_URL_SELECTION_TYPE, VIEW_MODES } from 'constants/general.constants'
import InfluencerStrip from 'components/InfluencerStrip/InfluencerStrip'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import LinkUrlTypeTag from 'components/LinkUrlTypeTag/LinkUrlTypeTag'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import { GetInfluencer } from 'api/influencers/influencers'
import ContentDialogLoader from 'components/ContentDialogLoader/ContentDialogLoader'
import { extractCollectionData, extractProductData } from 'utils/dataUtils'
import PreviewItemDataSection from 'components/PreviewItemDataSection/PreviewItemDataSection'
import ProductsTable from 'components/ProductsTable/ProductsTable'
import LinkAnalyticsCharts from 'components/LinkAnalyticsCharts/LinkAnalyticsCharts'
import { LINKS_PAGE_URL, SHORTS_PAGE_URL } from 'constants/routes.constants'
import LinkOrdersTable from 'components/LinkOrdersTable/LinkOrdersTable'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import { MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md'
import MobileLinkPreviewSimulator from 'components/MobileLinkPreviewSimulator/MobileLinkPreviewSimulator'
import { v4 as uuidV4 } from 'uuid'
import ItemsViewSection from 'components/ItemsViewSection/ItemsViewSection'
import PreviewLinkProductsListItem from 'components/PreviewLinkProductsListItem/PreviewLinkProductsListItem'
import DrawerStyledButtonList from 'components/DrawerStyledButtonList/DrawerStyledButtonList'

/**
 * Represents the section in which the link data is displayed.
 * @param {string} image - The image url of the link  
 * @param {string} description - The description of the link  
 * @param {number} linkViews - The number of views the link has
 * @param {number} linkViews - The number of views the link has
 * @param {number} linkRevenue - The total revenue of the link
 * @param {number} linkCheckoutsCompleted - The number of checkouts done on the link's products
 * @param {number} linkCheckoutRequests - The number of checkouts requests done on the link's products
 * @param {String} promocode - The link's promocode
 * @param {string} influencerUsername - The link's influencer username
 * @param {string} influencerImage - The link's influencer image
 * @param {string} urlSelectionType - Represents the way a url was picked. Relevant only for shorts
 * @param {string} linkCurrency - The currency of the link's products price which is set on link's creation
 */
export default function PreviewLinkSection({
    type,
    data = {},
    onDeleteLink = () => { },
    onSaveSuccess = () => { }
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { 
        userDetails,
        isFreeUserAndTrialEnded
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        openDrawer,
        closeDrawer,
        openFreeTrialDialog,
        finishedUpdatingObject
    } = useGeneralDataContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [contentDialogState, setContentDialogState] = useState({
        isOpen: false,
        contentType: CONTENT_TYPES.LINK
    })
    const [isLoadingLink, setIsLoadingLink] = useState(true)
    const [url, setUrl] = useState('')
    const [promocode, setPromocode] = useState('')
    const [selectedInfluencerUsername, setSelectedInfluencerUsername] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [selectedInfluencerImage, setSelectedInfluencerImage] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [linkData, setLinkData] = useState({
        products: []
    })
    const [urlSelectionType, setUrlSelectionType] = useState('')
    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedCollections, setSelectedCollections] = useState([])
    const [shouldDisplayProductsTable, setShouldDisplayProductsTable] = useState(true)
    const [linkAnalyticsData, setLinkAnalyticsData] = useState({
        isLoading: true,
        data: {},
        currency: '',
        views: 0,
        checkouts: 0,
        revenue: 0,
        checkoutRequests: 0,
        totalTax: 0
    })
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })

    useEffect(() => {
        loadLink()
    }, [])


    useEffect(() => {
        if (finishedUpdatingObject) {
            loadLink()
        }
    }, [finishedUpdatingObject, ])

    function loadLink() {
        GetLinkDetailsAPI(data?.linkId)
            .then(linkData => {
                let imagesWithIds = []
                if (type === LINK_TYPES.LOOK && linkData?.images && linkData.images?.length > 0) {
                    imagesWithIds = linkData.images.map(image => {
                        return {
                            src: image,
                            id: uuidV4()
                        }
                    })
                }
                const shoppingRedirectUrl = linkData?.shopping_redirect_url ?? ''
                const utmParams = {
                    UTMSource: linkData?.utm_params?.utm_source ?? '',
                    UTMMedium: linkData?.utm_params?.utm_medium ?? '',
                    UTMCampaign: linkData?.utm_params?.utm_campaign ?? '',
                    UTMTerm: linkData?.utm_params?.utm_term ?? '',
                    UTMContent: linkData?.utm_params?.utm_content ?? '',
                }
                const linkProductsSelectedOptions = linkData?.selected_options ?? {}
                const linkProducts = linkData?.products?.map(product => {
                    if (Object.keys(linkProductsSelectedOptions).includes(product?.inventory_id)) {
                        return extractProductData(product, linkProductsSelectedOptions[product?.inventory_id])
                    } else {
                        return extractProductData(product)
                    }
                }) ?? []

                setLinkData({
                    linkId: linkData?.link_id ?? '',
                    images: imagesWithIds,
                    description: linkData.description ?? '',
                    currency: linkData?.link_analytics?.currency ?? userDetails?.businessCurrency,
                    analytics: {
                        views: linkData?.link_analytics?.visits ??  0,
                        checkouts: linkData?.link_analytics?.checkouts ?? 0,
                        revenue: linkData?.link_analytics?.revenue ?? 0,
                        checkoutRequests: linkData?.link_analytics?.requested_checkouts ?? 0
                    },
                    promocode: {
                        code: linkData?.promocode?.code ?? ''
                    },
                    products: linkProducts,
                    title: linkData?.description ?? "", // consider the description as the shorts's title
                    shoppingRedirectUrl,
                    influencerUsername: linkData?.influencer_username,
                    utmParams,
                    selectedOptions: linkProductsSelectedOptions,
                })

                GetLinkAnalytics(linkData?.link_id)
                    .then(analyticsResponse => {
                        setLinkAnalyticsData({
                            isLoading: false,
                            data: (type === LINK_TYPES.SHORT ? analyticsResponse?.shorts_analytics?.data : analyticsResponse?.looks_analytics?.data) ?? {},
                            currency: analyticsResponse?.currency ?? '',
                            views: (type === LINK_TYPES.LOOK ? analyticsResponse?.looks_analytics?.visits : analyticsResponse?.shorts_analytics?.visits) ?? 0,
                            checkouts: (type === LINK_TYPES.LOOK ? analyticsResponse?.looks_analytics?.checkouts : analyticsResponse?.shorts_analytics?.checkouts) ?? 0,
                            revenue: (type === LINK_TYPES.LOOK ? analyticsResponse?.looks_analytics?.revenue : analyticsResponse?.shorts_analytics?.revenue) ?? 0,
                            checkoutRequests: (type === LINK_TYPES.LOOK ? analyticsResponse?.looks_analytics?.requested_checkouts : analyticsResponse?.shorts_analytics?.requested_checkouts) ?? 0,
                            totalTax: (type === LINK_TYPES.LOOK ? analyticsResponse?.looks_analytics?.total_tax : analyticsResponse?.shorts_analytics?.total_tax) ?? 0
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        setLinkAnalyticsData(prev => ({
                            ...prev,
                            isLoading: false
                        }))
                    })


                setUrlSelectionType(getUrlSelectionType(linkData))
                setSelectedProducts(linkData?.products ? linkData?.products?.map(product => extractProductData(product)) : [])
                setSelectedCollections(linkData?.collections ? linkData?.collections?.map(collection => extractCollectionData(collection)) : [])
                setPromocode(linkData?.promocode?.code ?? '')
                setUrl(shoppingRedirectUrl)
                
                if (userDetails?.isAdmin && linkData?.influencer_username) {
                    loadInfluencer(linkData?.influencer_username, linkData?.promocode?.code)
                } else {
                    setIsLoadingLink(false)
                }
            })
            .catch(error => {
                console.log(error)
                history.push(type === LINK_TYPES.SHORT ? SHORTS_PAGE_URL : LINKS_PAGE_URL)
            })
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function getUrlSelectionType(linkData) {
        if (linkData?.products && linkData?.products?.length > 0) {
            return SHORT_URL_SELECTION_TYPE.PRODUCT
        }

        if (linkData?.collections && linkData?.collections?.length > 0) {
            return SHORT_URL_SELECTION_TYPE.COLLECTION
        }

        return SHORT_URL_SELECTION_TYPE.CUSTOM
    }

    function loadInfluencer(influencerUsername, predefinedPromocode) {
        GetInfluencer(influencerUsername)
            .then(response => {
                setSelectedInfluencerUsername(influencerUsername)
                setSelectedInfluencerImage(response?.profile_image_url)
                setPromocode(predefinedPromocode ? predefinedPromocode : (response?.promocode ?? ''))
            })
            .finally(() => {
                setIsLoadingLink(false)
            })
    }

    function isValueEnabled(isValueEnabled) {
        return userDetails?.isAdmin || isValueEnabled
    }

    function closeContentDialog() {
        setContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function hasLinkImages() {
        return linkData?.images && linkData.images.length > 0
    }

    function isProductUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.PRODUCT
    }

    function isCollectionUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.COLLECTION
    }

    function getPreviewImage() {
        if (type === LINK_TYPES.LOOK) {
            if (hasLinkImages()) {
                return linkData?.images[0]?.src
            }
        } else {
            if (isProductUrlTypeSelected()) {
                return selectedProducts[0]?.imageUrl
            }

            if (isCollectionUrlTypeSelected()) {
                return selectedCollections[0]?.images?.medium?.src
            }
        }

        return ''
    }

    function edit() {
        if (isMobile) {
            closeDrawer()
        } else {
            optionsMenuState.onClose()
        }

        setTimeout(() => {
            if (isFreeUserAndTrialEnded()) {
                openFreeTrialDialog()
            } else {
                setContentDialogState({
                    isOpen: true,
                    contentType: type === LINK_TYPES.LOOK ? CONTENT_TYPES.LINK : CONTENT_TYPES.SHORT
                })
            }
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function copyLink() {
        if (isMobile) {
            closeDrawer()
        } else {
            optionsMenuState.onClose()
        }

        setTimeout(async () => {
            let url = ''
            let message = ''
            url = `${environment.frontend_url}${userDetails?.username}/${linkData?.linkId}`
            if (type === LINK_TYPES.LOOK) {
                message = t('LINK_COPIED_TO_CLIPBOARD')
            } else {
                message = t('SHORT_COPIED_TO_CLIPBOARD')
            }

            await copyToClipboard(url)
            showApprovalMessage(message)
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function onDeleteClick() {
        if (isMobile) {
            history.goBack()
            setTimeout(() => {
                showDeleteConfirmationDialog()
            }, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY)
        } else {
            optionsMenuState.onClose()
            showDeleteConfirmationDialog()
        }
    }

    function showDeleteConfirmationDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('PREVIEW_SHORT_DELETE_SHORT_DIALOG_TITLE'),
            message: type === LINK_TYPES.LOOK? t('PREVIEW_LINK_PAGE_DELETE_LINK_DIALOG_MESSAGE') : t('PREVIEW_SHORT_DELETE_SHORT_DIALOG_MESSAGE'),
            leftButtonText: t('PREVIEW_SHORT_DELETE_SHORT_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('PREVIEW_SHORT_DELETE_SHORT_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: deleteLink
        }))
    }

    function deleteLink() {
        DeleteLinkAPI(data?.linkId)
            .then(_ => {
                onDeleteLink(data?.linkId)
                setTimeout(() => {
                    history.goBack()
                }, 200)
            })
            .catch(error => {
                console.log(error)
                setDialogState(prev => ({
                    ...prev,
                    title: t('GENERAL_ERROR_DIALOG_TITLE'),
                    message: t('GENERAL_ERROR_DIALOG_MESSAGE'),
                    leftButtonText: t('GENERAL_ERROR_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: ''
                }))
            })
    }

    function openOptionsDrawer() {
        openDrawer(true, <>
            <div className="mobile-short-item-more-options-menu-container">
                <div className="mobile-short-item-more-options-menu-item-line">
                    <div className="mobile-short-item-more-options-menu-single-item" onClick={copyLink}>
                        <MdOutlineContentCopy className="mobile-short-item-more-options-menu-single-item-image" />
                        <div className="mobile-short-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK')}</div>
                    </div>
                </div>
                <DrawerStyledButtonList
                    buttons={[
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            image: <MdOutlineEdit className="mobile-short-item-more-options-menu-section-item-image" />,
                            onClick: edit
                        }] : []),
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            image: <RiDeleteBin6Line className="mobile-short-item-more-options-menu-section-item-image" />,
                            className: 'mobile-short-item-more-options-menu-section-item-delete-short',
                            onClick: onDeleteClick
                        }] : [])
                    ]}
                />
            </div>
        </>)
    }

    function renderLinkAnalytics() {
        return <div className="preview-link-section-link-analytics">
            <div className={isMobile ? "mobile-preview-link-section-link-analytics-data" : "preview-link-section-link-analytics-data"}>
                {isValueEnabled(userDetails?.permissions?.clicksEnabled) && <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_VIEWS_TITLE')}
                    value={formatNumber(Math.max(linkAnalyticsData?.views ?? 0, linkAnalyticsData?.checkoutRequests, linkAnalyticsData?.checkouts), NUMBER_TYPES.SHORT)}
                />}
                {type === LINK_TYPES.LOOK && isValueEnabled(userDetails?.permissions?.salesEnabled) && <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CHECKOUTS_REQUESTED_TITLE')}
                    value={formatNumber(Math.max(linkAnalyticsData?.checkoutRequests, linkAnalyticsData?.checkouts), NUMBER_TYPES.SHORT)}
                />}
                {isValueEnabled(userDetails?.permissions?.salesEnabled) && <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CHECKOUTS_COMPLETED_TITLE')}
                    value={formatNumber(linkAnalyticsData?.checkouts, NUMBER_TYPES.SHORT)}
                />}
                {isValueEnabled(userDetails?.permissions?.revenueEnabled) && <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_REVENUE_TITLE')}
                    value={`${getCurrencySymbol(linkAnalyticsData?.currency)}${formatNumber(linkAnalyticsData?.revenue, NUMBER_TYPES.SHORT)}`}
                />}
                {isValueEnabled(userDetails?.permissions?.salesEnabled && userDetails?.permissions?.clicksEnabled && userDetails?.permissions?.crEnabled) && <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CR_TITLE')}
                    value={`${formatNumber((linkAnalyticsData?.checkouts / linkAnalyticsData?.views) * 100, NUMBER_TYPES.PERCENTAGE)}%`}
                />}
                {isValueEnabled(userDetails?.permissions?.revenueEnabled && userDetails?.permissions?.aovEnabled) && <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CART_AVERAGE_TITLE')}
                    value={`${getCurrencySymbol(linkAnalyticsData?.currency)}${formatNumber(linkAnalyticsData?.revenue / linkAnalyticsData?.checkouts, NUMBER_TYPES.SHORT, true)}`}
                />}
            </div>
        </div>
    }

    function getTitle() {
        return type === LINK_TYPES.LOOK ? t('LINK_VIEW_TOPBAR_CENTER_TITLE_PREVIEW_LINK') : t('SHORT_VIEW_PREVIEW_SHORT_TITLE')
    }

    return (
        <div className={isMobile ? 'mobile-preview-link-container' : 'preview-link-container'}>
            <div className={isMobile ? 'mobile-preview-link-section-container' : 'preview-link-section-container'}>
                <div className={isMobile ? 'mobile-preview-link-section-details' : 'preview-link-section-details'} style={{ width: isMobile ? '100%' : (type === LINK_TYPES.SHORT ? '100%' : '70%') }}>
                    <ContentDialogTopbar
                        title={getTitle()}
                        onBackButtonClick={() => history.goBack()}
                        rightElement={
                            <BiDotsHorizontalRounded className='short-view-more-options-button' onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                        }
                    />
                    {isLoadingLink && <ContentDialogLoader />}
                    <div className={`${isMobile ? "mobile-preview-link-section-link-details-container" : "preview-link-section-link-details-container"} ${type === LINK_TYPES.SHORT ? 'short' : ''}`}>
                        <div className={isMobile ? "mobile-preview-link-section-link-details" : "preview-link-section-link-details"} style={{ height: !getPreviewImage() && 'fit-content' }}>
                            {
                                getPreviewImage() && <ImageBox
                                    className={`${isMobile ? 'mobile-preview-link-section-link-image' : 'preview-link-section-link-image'} ${urlSelectionType !== SHORT_URL_SELECTION_TYPE.CUSTOM ? 'enlarge' : ''}`}
                                    image={getPreviewImage()}
                                    enlargeImageOnClick={true}
                                    showBorder={type === LINK_TYPES.LOOK || urlSelectionType !== SHORT_URL_SELECTION_TYPE.CUSTOM}
                                />
                            }
                            <div className='preview-short-section-link-description-container' style={{ width: `calc(100% - (${getPreviewImage() ? SHORT_INFO_URL_NO_CUSTOM_IMAGE_DIMENS_PX : 0}px + ${getPreviewImage() ? '10px' : ''}))` }}>
                                {
                                    linkData?.description && <MultiLineText
                                        text={linkData?.description}
                                        className='preview-short-section-link-description'
                                        lines={1}
                                        oneWord={true}
                                    />
                                }
                                {
                                    !isMobile ? renderLinkAnalytics() : (type === LINK_TYPES.LOOK && <div className='mobile-preview-link-section-link-products-list'>
                                        <ItemsViewSection
                                            title={t('PREVIEW_LINK_PAGE_PRODUCTS_LIST_TITLE', { productsCount: linkData?.products.length })}
                                            data={linkData?.products}
                                            viewItemComponent={PreviewLinkProductsListItem}
                                            type={SECTION_TYPES.STRIP}
                                            hasPadding={false}
                                            paddingVertical='0px'
                                            hasTitlePadding={false}
                                            alignEnd={true}
                                            isExpandingFullHeight={true}
                                        />
                                    </div>)
                                }
                            </div>
                        </div>
                        {
                            type === LINK_TYPES.SHORT && <div className={isMobile ? 'mobile-preview-link-section-url-container' : 'preview-link-section-url-container'}>
                                <LinkUrlTypeTag
                                    type={urlSelectionType}
                                    hasHorizontalMargin={!isMobile}
                                />
                                <MultiLineText
                                    text={url}
                                    className='preview-link-section-url'
                                    lines={isMobile ? 2 : 1}
                                />
                            </div>
                        }
                        {isMobile && renderLinkAnalytics()}
                        {
                            !isLoadingLink && (
                                (userDetails?.isAdmin && (selectedInfluencerImage || selectedInfluencerUsername)) ? (
                                    <InfluencerStrip
                                        image={selectedInfluencerImage}
                                        username={selectedInfluencerUsername}
                                        promocode={promocode}
                                    />
                                ) : (
                                    promocode && <div className={isMobile ? "mobile-preview-link-section-promocode" : "preview-link-section-promocode"}>
                                        <div className='preview-link-section-promocode-label'>{t('PREVIEW_LINK_SECTION_PROMOCODE_SECTION_TITLE')}</div>
                                        <div className='preview-link-section-promocode-value'>{promocode}</div>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className={isMobile ? "mobile-preview-link-section-link-graphs" : "preview-link-section-link-graphs"} style={type === LINK_TYPES.SHORT ? { maxWidth: isMobile ? '100vw' : '55vw', paddingInline: isMobile ? '0' : '2.5vw' } : {}}>
                        {
                            shouldDisplayProductsTable && <PreviewItemDataSection
                                title={t('TOP_PERFORMING_PRODUCTS_SECTION_TITLE')}
                                NoBackgroundColor={true}
                                hasPadding={true}
                                width='100%'
                            >
                                <ProductsTable
                                    linkId={data?.linkId}
                                    setHasProducts={setShouldDisplayProductsTable}
                                    removedColumnIds={[
                                        ...(type === LINK_TYPES.LOOK ? [] : [PRODUCT_TABLE_HEADERS_IDS.VIEW, PRODUCT_TABLE_HEADERS_IDS.ADD]),
                                        ...(!userDetails?.isAdmin && !userDetails?.permissions?.revenueEnabled ? [PRODUCT_TABLE_HEADERS_IDS.BUY] : []),
                                    ]}
                                />
                            </PreviewItemDataSection>
                        }
                        {
                            userDetails?.isAdmin && <PreviewItemDataSection
                                title={t('RECENT_ORDERS_SECTION_TITLE')}
                                NoBackgroundColor={true}
                                hasPadding={true}
                                width='100%'
                            >
                                <LinkOrdersTable
                                    linkId={data?.linkId}
                                    salesCount={linkData?.analytics?.checkouts}
                                    allowOrderDetails={data?.allowOrderDetails ?? true} //Check if not None or undefined, if so default is true
                                />
                            </PreviewItemDataSection>
                        }
                        <LinkAnalyticsCharts
                            width='100%'
                            hasPadding={false}
                            noBackgroundColor={true}
                            chartsData={linkAnalyticsData.data}
                            isLoadingChartsData={linkAnalyticsData.isLoading}
                            currency={getCurrencySymbol(linkData?.currency)}
                            timeRangeName={type === LINK_TYPES.LOOK ? t('PAGE_VIEW_LINK_ANALYTICS_CHART_NO_DATA_TEXT_SUFFIX') : t('PAGE_VIEW_SHORT_ANALYTICS_CHART_NO_DATA_TEXT_SUFFIX')}
                        />
                    </div>
                </div>
                {
                    (!isMobile && type === LINK_TYPES.LOOK) && <div className="preview-link-section-mobile-preview">
                        <MobileLinkPreviewSimulator
                            mainImageUrl={hasLinkImages() ? linkData?.images[0]?.src : ''}
                            products={selectedProducts}
                            isLinkExpired={linkData?.isLinkExpired}
                        />
                    </div>
                }
            </div>
            {
                isDesktop && <PaydinMenu
                    anchorElement={optionsMenuState.anchorElement}
                    isOpen={optionsMenuState.isOpen}
                    onClose={optionsMenuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                    options={[
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            onClick: edit
                        }] : []),
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK'),
                            onClick: copyLink
                        },
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            className: 'short-view-options-menu-delete-option',
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            onClick: onDeleteClick
                        }] : [])
                    ]}
                />
            }
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                title={dialogState.title}
                message={dialogState.message}
                handleDialogClose={closeDialog}
                closeOnRightClick={false}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                isLeftButtonWithLoader={false}
                rightButtonText={dialogState.rightButtonText}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
            />
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeContentDialog}
                data={{
                    linkId: linkData?.linkId,
                    linkData
                }}
                contentType={contentDialogState.contentType}
                mode={VIEW_MODES.EDIT}
                onSaveSuccess={updatedLink => {
                    loadLink()
                    onSaveSuccess(updatedLink)
                }}
            />
        </div>
    )
}