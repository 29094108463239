import React, { useCallback, useEffect, useState } from 'react'
import './TrendsPage.css'
import { useTranslation } from 'react-i18next'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import { useHistoryContext } from 'contexts/History'
import ProductItem from 'components/ProductItem/ProductItem'
import Loader from 'components/Loader/Loader'
import { isMobile } from 'react-device-detect'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import { CONTENT_TYPES, TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX, TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX, VIEW_MODES } from 'constants/general.constants'
import { generateRandomInteger } from 'utils/utils'
import { FaPlus } from "react-icons/fa6";
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import { LINKS_PAGE_URL } from 'constants/routes.constants'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { VscArrowSwap } from "react-icons/vsc";
import ImageBox from 'components/ImageBox/ImageBox'
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from 'components/Tooltip/Tooltip'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'


export default function TrendsPage() {
    const { t } = useTranslation()

    const {
        userDetails,
        fetchTopPerformingProducts,
        fetchTopProductsCombinations,
        fetchComplementaryProducts,
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()
    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()
    const history = useHistoryContext()

    const [isLoadingTopPreforming, setIsLoadingTopPerforming] = useState(true)
    const [isLoadingTopCombinations, setIsLoadingTopCombinations] = useState(true)
    const [isLoadingComplementary, setIsLoadingComplementary] = useState(true)

    const [topPerformingProductsExpended, setTopPerformingProductsExpended] = useState(false)
    const [topCombinationsExpended, setTopCombinationsExpended] = useState(false)
    const [complementaryExpended, setComplementaryExpended] = useState(false)

    const [complementaryMainProduct, setComplementaryMainProduct] = useState(null)
    const [complementaryProducts, setComplementaryProducts] = useState([])

    const [quickInstantProducts, setQuickInstantProducts] = useState([])
    const [contentDialogState, setContentDialogState] = useState({ isOpen: false })

    const [allowComplementaryExpand, setAllowComplementaryExpand] = useState(false)

    const [productsDialogShown, setProductsDialogShown] = useState(false)

    useEffect(() => {
        if (userDetails?.trends?.topPerformingProducts && userDetails?.trends?.topPerformingProducts.length > 0) {
            setIsLoadingTopPerforming(false)
        } else {
            fetchTopPerformingProducts(() => setIsLoadingTopPerforming(false), () => setIsLoadingTopPerforming(false))
        }
        if (userDetails?.trends?.topProductsCombinations?.combinations && userDetails?.trends?.topProductsCombinations?.combinations.length > 0) {
            setIsLoadingTopCombinations(false)
        } else {
            fetchTopProductsCombinations(() => setIsLoadingTopCombinations(false), () => setIsLoadingTopCombinations(false))
        }
    }, [])

    useEffect(() => {
        if (!isLoadingTopPreforming && userDetails?.trends?.topPerformingProducts && userDetails?.trends?.topPerformingProducts.length > 0) {
            let index = generateRandomInteger(Math.min(3, userDetails?.trends?.topPerformingProducts.length))
            setIsLoadingComplementary(true)
            setAllowComplementaryExpand(false)
            setComplementaryMainProduct(userDetails?.trends?.topPerformingProducts[index])
            loadComplementaryProducts(userDetails?.trends?.topPerformingProducts[index].id)
        }
    }, [isLoadingTopPreforming])

    function loadComplementaryProducts(productId) {
        if (Object.keys(userDetails?.trends?.complementaryProducts).includes(productId)) {
            setComplementaryProducts(userDetails?.trends?.complementaryProducts[productId])
            setIsLoadingComplementary(false)
        } else {
            fetchComplementaryProducts(productId, (combinations) => {
                setComplementaryProducts(combinations)
                setIsLoadingComplementary(false)
            }, () => {
                setIsLoadingComplementary(false)
            })
        }
    }


    function quickInstantStore(products) {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            setQuickInstantProducts(products)
            setContentDialogState({isOpen: true})
        }
    }

    function closeContentDialog() {
        setContentDialogState({isOpen: false})
    }

    function onQuickInstantSuccess() {
        history.push(LINKS_PAGE_URL)
    }

    function closeAddProductDialog() {
        setProductsDialogShown(false)
    }

    function updateComplementaryMainProduct(products) {
        if (products && products?.length > 0) {
            setIsLoadingComplementary(true)
            setAllowComplementaryExpand(false)
            setComplementaryMainProduct(products[0])
            loadComplementaryProducts(products[0]?.id)
        }
    }


    function renderTopPerformingProducts() {
        return userDetails?.trends?.topPerformingProducts?.length > 0 ? userDetails?.trends?.topPerformingProducts?.map(product => <ProductItem
                key={product?.id}
                data={product}
                onItemClick={() => {}}
                imageWidth={`${TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX}px`}
                imageHeight={`${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}
                showSellingScore={true}
                score={product?.sellingScore}
            />) : (
                !isLoadingTopPreforming ? <div className="top-performing-products-no-items-found-container" style={{height: `${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}}>
                    <BsInfoCircle className={isMobile ? 'mobile-trends-page-no-items-icon' : 'trends-page-no-items-icon'}/>
                    <div className={isMobile ? "mobile-trends-page-no-items-found-title" : "trends-page-no-items-found-title"}>{t("TRENDS_PAGE_NO_TOP_PERFORMING_PRODUCTS")}</div>
                </div> :
                    <div className='top-performing-products-loader-container' style={{height: `${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}}>
                    <Loader styles={{ height: '20px', width: '20px', margin: 'auto' }} />
                </div>
            )
    }

    function renderTopCombinations() {
        return userDetails?.trends?.topProductsCombinations?.combinations?.length > 0 ? userDetails?.trends?.topProductsCombinations?.combinations?.map((combination, combinationIndex) => {
            let combinationProducts = combination?.map(productId => userDetails?.trends?.topProductsCombinations?.products?.find(product => product?.id === productId))
            return <div className='trends-page-top-combinations-conatainer' key={generateRandomInteger(1_000_000)}>
                <div className={isMobile ? 'mobile-trends-page-top-combinations-line-conatainer' : 'trends-page-top-combinations-line-conatainer'}>
                    <div className={isMobile? 'mobile-trends-page-top-combinations-products-conatainer' : 'trends-page-top-combinations-products-conatainer'}>
                        {
                            combinationProducts?.map((product, productIndex) => {
                                return <div className='trends-page-top-combinations-product-container' key={generateRandomInteger(1_000_000)} style={{width: `calc(${isMobile ? 2.5 : 1} * ${TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX}`}}>
                                        <ProductItem
                                            key={product.id}
                                            data={product}
                                            onItemClick={() => {}}
                                            imageWidth={`${TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX}px`}
                                            imageHeight={`${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}
                                        />
                                    {productIndex < combination.length - 1 &&  <FaPlus className='trends-page-top-combination-plus-icon' style={isMobile ? {marginBottom: `calc(0.6 * ${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px)`} : {}}/>}
                                </div>
                            })
                        }
                    </div>
                    <div className='trends-page-top-combinations-button-conatainer'>
                        <div className="trends-page-quick-instant-store-button" onClick={() => quickInstantStore(combinationProducts)}>{t('TRENDS_PAGE_QUICK_LINK_BUTTON_TEXT')}</div>
                    </div>
                </div>
                {combinationIndex < userDetails?.trends?.topProductsCombinations?.combinations.length - 1 && <div className="trends-page-horizontal-line"></div>}
            </div>
        }) : (
            !isLoadingTopCombinations ? <div className="top-performing-products-no-items-found-container" style={{height: `${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}}>
                <BsInfoCircle className={isMobile ? 'mobile-trends-page-no-items-icon' : 'trends-page-no-items-icon'}/>
                <div className={isMobile ? "mobile-trends-page-no-items-found-title" : "trends-page-no-items-found-title"}>{t("TRENDS_PAGE_NO_TOP_PERFORMING_PRODUCTS")}</div>
            </div> :
                <div className='top-performing-products-loader-container' style={{height: `${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}}>
                <Loader styles={{ height: '20px', width: '20px', margin: 'auto' }} />
            </div>
        )
    }


    const complementaryProductsLineRef = useCallback(node => {
        // Callbeck on ref to 
        if (node && node?.clientHeight > TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX + 37) {
            setAllowComplementaryExpand(true)
        }
    }, [])


    function renderProductComplementary() {
        return <div className={isMobile ? 'mobile-complementary-products-container' : 'complementary-products-container'}>
            <div className={isMobile ? 'mobile-trends-page-complementary-main-product-container' : 'trends-page-complementary-main-product-container'}>
                <ImageBox
                    className='trends-page-complementary-main-product-image'
                    image={complementaryMainProduct?.imageUrl}
                    isImageFitCover={true}
                    showGreyImageBackground={true}
                    styles={isMobile ? {width: '160px', height: '200px'} : {width: '200px', height: '240px'}}
                />
                <div className={isMobile ? 'mobile-trends-page-complementary-main-product-title-container' : 'trends-page-complementary-main-product-title-container'}>
                    <MultiLineText
                        text={complementaryMainProduct?.title}
                        className='trends-page-complementary-main-product-title'
                        lines={3}
                    />
                    {!isMobile ? <Tooltip
                        image={<VscArrowSwap className='trends-page-complementary-main-product-swap-icon' onClick={() => setProductsDialogShown(true)} />}
                        tooltipText={t("TRENDS_PAGE_COMPLEMENTARY_SWAP_ICON_TOOLTIP_TEXT")}
                        displayBubble={!isMobile}
                    /> : <div className='trends-page-complementary-switch-product-button-conatainer'>
                        <div className="trends-page-complementary-swap-product-swap-button" onClick={() => setProductsDialogShown(true)}>
                            <VscArrowSwap className='trends-page-complementary-main-button-product-swap-icon' onClick={() => setProductsDialogShown(true)} />
                                {t('TRENDS_PAGE_CHANGE_PRODUCT_BUTTON_TEXT')}
                        </div>
                    </div>}
                </div>
            </div>
            {
                !isLoadingComplementary ? <div className={isMobile ? 'mobile-trends-page-complementary-complementary-products-container' : 'trends-page-complementary-complementary-products-container'}>
                    <CollapsingElement
                            expanded={isMobile ? true : complementaryExpended}
                            collapsedSize={`${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX + 37}px`}
                            unmountOnExit={false}
                        >   
                            <div className={isMobile ? 'mobile-trends-page-complementary-line-conatainer' : 'trends-page-complementary-line-conatainer'} ref={complementaryProductsLineRef}>
                            {
                                complementaryProducts?.length > 0 ? complementaryProducts?.map(combination => {
                                    return <div className='trends-page-complementary-product-container' key={generateRandomInteger(1_000_000)} style={{width: `calc(${isMobile ? 2.5 : 1} * ${TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX}`}}>
                                            <ProductItem
                                                key={combination?.product.id}
                                                data={combination?.product}
                                                onItemClick={() => {}}
                                                imageWidth={`${TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX}px`}
                                                imageHeight={`${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX}px`}
                                                showSellingScore={true}
                                                score={combination?.combinationScore}
                                            />
                                            <div className='trends-page-complementary-button-conatainer'>
                                                <div className="trends-page-quick-complementary-instant-store-button" onClick={() => quickInstantStore([complementaryMainProduct, combination?.product])}>{t(isMobile ? 'TRENDS_PAGE_QUICK_LINK_BUTTON_MOBILE_TEXT' : 'TRENDS_PAGE_QUICK_LINK_BUTTON_TEXT')}</div>
                                            </div>
                                    </div>
                                }) : <div className="complementary-products-no-items-found-container" style={{height: `${(isMobile ? 1.6 * TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX : TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX) + 37}px`}}>
                                    <BsInfoCircle className={isMobile ? 'mobile-trends-page-no-items-icon' : 'trends-page-no-items-icon'}/>
                                    <div className={isMobile ? "mobile-trends-page-no-items-found-title" : "trends-page-no-items-found-title"}>{t("TRENDS_PAGE_NO_COMPLEMENTARY_PRODUCTS")}</div>
                                </div>
                            }
                        </div>  
                    </CollapsingElement>
                    {!isMobile && complementaryProducts?.length > 0 && allowComplementaryExpand && <div className="trends-page-content-toggle-button" onClick={() => setComplementaryExpended(prev => !prev)}>{complementaryExpended ? 'Hide' : 'See all'}</div>}
                </div> : <div className={isMobile ? 'mobile-complementary-products-loader-container' : 'complementary-products-loader-container'} style={{height: `${(isMobile ? 1.6 * TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX : TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX) + 37}px`}}>
                    <Loader styles={{ height: '20px', width: '20px', margin: 'auto' }} />
                </div>
            }
        </div>
    }



    return (
        <div className={isMobile ? "mobile-trends-page-container" : "trends-page-container"}>
            {
                !isMobile ? <div className="trends-page-topbar">
                    <div className="trends-page-topbar-store-name-container">
                        <div className={"trends-page-topbar-title"}>{t('TRENDS_PAGE_TITLE')}</div>
                    </div>
                </div> : <ContentDialogTopbar
                    title={t('TRENDS_PAGE_TITLE')}
                    onBackButtonClick={() => history.goBack()}
                    rightElement={<></>}
                />
            }
            <div className='trends-page-section' style={isMobile ? {marginTop: '20px'} : null}>
                <div className="trends-page-section-titles-container">
                    <div className="trends-page-section-titles-div">
                        <div className="trends-page-section-title">{"Top Performing Products"}</div>
                        <div className="trends-page-section-description">{"See your top performing products based on your last orders."}</div>
                    </div>
                    {!isMobile && userDetails?.trends?.topPerformingProducts?.length > 0 && <div className="trends-page-content-toggle-button" onClick={() => setTopPerformingProductsExpended(prev => !prev)}>{topPerformingProductsExpended ? 'Hide' : 'See all'}</div>}
                </div>
                {isMobile ? <div className='mobile-top-performing-products-container'>
                    <div className='mobile-top-performing-products-strip'>
                        {renderTopPerformingProducts()}
                    </div>
                </div> : <CollapsingElement
                    expanded={topPerformingProductsExpended}
                    collapsedSize={`${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX + 5}px`}
                    unmountOnExit={false}
                >   
                    <div className='top-performing-products-container'>
                        {renderTopPerformingProducts()}
                    </div>
                </CollapsingElement>}
            </div>
            <div className='trends-page-section'>
                <div className="trends-page-section-titles-container">
                    <div className="trends-page-section-titles-div">
                        <div className="trends-page-section-title">{"Best Combinations"}</div>
                        <div className="trends-page-section-description">{"Identify common products bundeles and enhance cross-selling strategies."}</div>
                    </div>
                    {!isMobile && userDetails?.trends?.topPerformingProducts?.length > 0 && <div className="trends-page-content-toggle-button" onClick={() => setTopCombinationsExpended(prev => !prev)}>{topCombinationsExpended ? 'Hide' : 'See all'}</div>}
                </div>
                <CollapsingElement
                    expanded={topCombinationsExpended}
                    collapsedSize={`calc(${userDetails?.trends?.topPerformingProducts?.length > 1 ? (isMobile ? 3 : 2) : 1} * ${isMobile ? 1.8 : 1} * ${TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX + 30}px)`}
                    unmountOnExit={false}
                >   
                    <div className='top-combinations-products-container'>
                        {renderTopCombinations()}
                    </div>
                </CollapsingElement>
            </div>
            {
                complementaryMainProduct && <div className='trends-page-section'>
                    <div className="trends-page-section-titles-container">
                        <div className="trends-page-section-titles-div">
                            <div className="trends-page-section-title">{"Complementary Products"}</div>
                            <div className="trends-page-section-description">{"List the top products frequently bought alongside."}</div>
                        </div>
                    </div>
                    {renderProductComplementary()}
                </div>
            }
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeContentDialog}
                mode={VIEW_MODES.CREATE}
                data={{
                    linkData: {
                        products: quickInstantProducts
                    },
                }}
                contentType={CONTENT_TYPES.LINK}
                onSaveSuccess={onQuickInstantSuccess}
            />
            <AddProductsDialog
                isDialogOpen={productsDialogShown}
                handleDialogClose={closeAddProductDialog}
                selectedProducts={[complementaryMainProduct]}
                setSelectedProducts={updateComplementaryMainProduct}
                selectMaxLimit={1}
                selectMinLimit={1}
            />
        </div>
    )
}