import React, { useEffect, useRef, useState } from 'react'
import './DatePicker.css'
import ReactDatePicker from 'react-datepicker'
import { subDays } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css"
import { getFormattedDateRange, getFormattedShortDateRange } from 'utils/dateUtils'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import ItemsViewSection from 'components/ItemsViewSection/ItemsViewSection'
import DateIntervalOption from 'components/DateIntervalOption/DateIntervalOption'
import { ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES, DATE_PICKER_DATE_INTERVAL_OPTION_WIDTH_PX, SECTION_TYPES } from 'constants/general.constants'

import './GeneralReactDatePickerCustomStyles.css'
if (isMobile) { // This imports a custom stylings for the core component of the date picker wrapper component, that are required only in mobile mode.
    import('./MobileReactDatePickerCustomStyles.css')
}

/**
 * Represents a date picker.
 * Used to select a date range by either the displayed calendar or
 * a predefined date interval options.
 * @param {date} selectedStartDate - The currently selected start date
 * @param {date} selectedEndDate - The currently selected end date
 * @param {array} dateIntervals - The array of the date interval options
 * @param {string} selectedDateInterval - The currently selected date interval option name
 * @param {function} onCancelClick - A function to perform whenever the cancel button is clicked
 * @param {function} onUpdateClick - A function to perform whenever the update button is clicked
 */
export default function DatePicker({
    selectedStartDate = null,
    selectedEndDate = null,
    dateIntervals = [],
    selectedDateInterval = '',
    onCancelClick = () => { },
    onUpdateClick = () => { }
}) {
    const { t } = useTranslation()

    const [isAbleToUpdate, setIsAbleToUpdate] = useState(false)
    const [selectedDates, setSelectedDates] = useState({
        selectedStartDate,
        selectedEndDate,
        selectedDateInterval,
    })

    const dateIntervalsStripRef = useRef(null)

    useEffect(() => {
        if (dateIntervalsStripRef?.current) {
            const selectedDateIntervalIndex = dateIntervals.findIndex(interval => interval?.name === selectedDateInterval)
            dateIntervalsStripRef?.current?.scrollBy(selectedDateIntervalIndex * DATE_PICKER_DATE_INTERVAL_OPTION_WIDTH_PX, 0)
        }
    }, [])

    useEffect(() => {
        setIsAbleToUpdate(
            selectedDateInterval === ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME ||
            Object.values(ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES).includes(selectedDateInterval) || (
                selectedStartDate !== null && selectedEndDate !== null
            )

        )
    }, [selectedDateInterval, selectedStartDate, selectedEndDate])

    function handleDateIntervalSelection(newStartDate, newEndDate) {
        setIsAbleToUpdate(true)
        setSelectedDates({
            selectedDateInterval: t('DATE_PICKER_CUSTOM_DATE_RANGE_SELECTED_INTERVAL_NAME'),
            selectedStartDate: newStartDate,
            selectedEndDate: newEndDate
        })
    }

    function isLifetimeRangeSelected() {
        return selectedDateInterval === ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME
    }

    function getDateRangeDisplayText() {
        return isLifetimeRangeSelected() ? ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME : getFormattedDateRange(selectedDates.selectedStartDate, selectedDates.selectedEndDate)
    }

    function getShortDateRangeDisplayText() {
        return isLifetimeRangeSelected() ? ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME : getFormattedShortDateRange(selectedDates.selectedStartDate, selectedDates.selectedEndDate)
    }

    function selectDateInterval(interval) {
        const selectedDateInterval = dateIntervals.find(interval1 => interval1?.name === interval)
        if (selectedDateInterval) {
            setSelectedDates(prev => ({
                ...prev,
                selectedStartDate: selectedDateInterval?.timeRange[0],
                selectedEndDate: selectedDateInterval?.timeRange[1],
                selectedDateInterval: interval
            }))
        }
    }

    function onUpdate() {
        onUpdateClick(selectedDates.selectedStartDate, selectedDates.selectedEndDate, selectedDates.selectedDateInterval)
    }

    return (
        <div className={isMobile ? "mobile-date-picker-container" : "date-picker-container"}>
            {
                isMobile && <div className="date-picker-top-bar">
                    <div style={{ width: '25%' }}>
                        <div className="date-picker-top-bar-cancel-button" onClick={onCancelClick}>{t('DATE_PICKER_CANCEL_BUTTON_TEXT')}</div>
                    </div>
                    <div style={{ width: '50%' }}>
                        <div className='date-picker-top-bar-status'>{getShortDateRangeDisplayText()}</div>
                    </div>
                    <div style={{ width: '25%' }}>
                        <div className={`date-picker-top-bar-update-button ${!isAbleToUpdate ? 'disabled' : ''}`} onClick={onUpdate}>{t('DATE_PICKER_UPDATE_BUTTON_TEXT')}</div>
                    </div>
                </div>
            }
            <div className={isMobile ? "mobile-date-picker-predefined-date-intervals" : "date-picker-predefined-date-intervals"}>
                {
                    isMobile ? <ItemsViewSection
                        data={dateIntervals.map(interval => ({ ...interval, isSelected: interval?.name === selectedDates.selectedDateInterval }))}
                        viewItemComponent={DateIntervalOption}
                        type={SECTION_TYPES.STRIP}
                        hasPadding={false}
                        onItemClick={selectDateInterval}
                        itemsSliderRef={dateIntervalsStripRef}
                    /> : dateIntervals.map(interval => <DateIntervalOption
                        key={interval.id}
                        item={{
                            name: interval?.name,
                            isSelected: interval?.name === selectedDates.selectedDateInterval
                        }}
                        onItemClick={selectDateInterval}
                    />)
                }
            </div>
            <div className={isMobile ? "mobile-date-picker-selection-panel" : "date-picker-selection-panel"}>
                <ReactDatePicker
                    selected={selectedDates.selectedStartDate ?? subDays(new Date(), 30)}
                    startDate={selectedDates.selectedStartDate}
                    endDate={selectedDates.selectedEndDate}
                    onChange={([newStartDate, newEndDate]) => handleDateIntervalSelection(newStartDate, newEndDate)}
                    inline
                    selectsRange
                    monthsShown={2}
                    maxDate={new Date()}
                    minDate={new Date('2023/01/01')}
                />
                {
                    !isMobile && <div className="date-picker-bottom-bar">
                        <div className="date-picker-status-bar">{getDateRangeDisplayText()}</div>
                        <div className="date-picker-buttons">
                            <div className="date-picker-cancel-button" onClick={onCancelClick}>{t('DATE_PICKER_CANCEL_BUTTON_TEXT')}</div>
                            <div className={`date-picker-update-button ${!isAbleToUpdate ? 'disabled' : ''}`} onClick={onUpdate}>{t('DATE_PICKER_UPDATE_BUTTON_TEXT')}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}