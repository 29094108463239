import React from 'react'
import './MobileRecentActivitySection.css'

/**
 * Represents a recent activity section for mobile mode only.
 * @param {string} title - The section's title
 * @param {string} buttonText - The section's button's text
 * @param {function} onButtonClick - A function to perform whenever this section's button is clicked
 */
export default function MobileRecentActivitySection({
    title,
    buttonText,
    onButtonClick = () => { },
    children
}) {
    return (
        <div className='mobile-recent-activity-section'>
            <div className="mobile-recent-activity-section-titles-container">
                <div className="mobile-recent-activity-section-title">{title}</div>
                <div className="mobile-recent-activity-section-button" onClick={onButtonClick}>{buttonText}</div>
            </div>
            <div className="mobile-recent-activity-section-content">
                {children}
            </div>
        </div>
    )
}