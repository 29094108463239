import React from 'react'
import './AnalyticsDataCard.css'
import Card from 'components/Card/Card'
import Tooltip from 'components/Tooltip/Tooltip'
import { MdOutlineInfo } from 'react-icons/md'
import BusinessAnalyticsDataLoader from 'components/BusinessAnalyticsDataLoader/BusinessAnalyticsDataLoader'

/**
 * Represents an analytics data, card-like container.
 * Uses the {@link Card} component for card looking style.
 * @param {string} title - The container's title
 * @param {string} value - The container's data value state
 * @param {string} tooltipText - The text for the tooltip of this card
 * @param {number} paddingPx - The container's padding in pixels
 * @param {boolean} isTooltipHorizontalSwap - Determins whether the tooltip text bubble is inversed horizontally or not
 * @param {boolean} isDataLoading - Determins whether the analytics data is loading or not
 * @param {object} numberStyles - Determins styles override for the value
 */
export default function AnalyticsDataCard({
    title,
    value,
    tooltipText,
    paddingPx = 25,
    isTooltipHorizontalSwap = false,
    isDataLoading = false,
    numberStyles = {}
}) {

    function renderValue() {
        return isDataLoading ? <BusinessAnalyticsDataLoader /> : value
    }

    return (
        <Card
            paddingPx={paddingPx}
        >
            <div className="analytics-data-card-title-container">
                <div className="analytics-data-card-title">{title}</div>
                <Tooltip
                    image={<MdOutlineInfo className='analytics-data-card-image' />}
                    tooltipText={tooltipText}
                    swapHorizontal={isTooltipHorizontalSwap}
                />
            </div>
            <div className="analytics-data-card-value" style={numberStyles}>{renderValue()}</div>
        </Card>
    )
}