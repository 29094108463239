import React, { useEffect, useRef } from 'react'
import './MultiLineText.css'

/**
 * Represents a text which can spread over a given number of lines.
 * @param {string} text - The displayed text
 * @param {string} className - The displayed text's class name
 * @param {number} lines - The given number of lines to spread the text over
 */
export default function MultiLineText({
    text = '',
    className = '',
    lines = 1,
    treatAsHtml = false,
    style = {},
    onClick = () => {}
}) {
    const divRef = useRef(null)

    useEffect(() => {
        if (divRef?.current) {
            if (treatAsHtml) {
                divRef.current.innerHTML = text
            } else {
                divRef.current.innerText = text
            }
        }
    }, [text])
    
    return (
        <div 
            ref={divRef} 
            className={lines === 1 ? `one-line-text ${className}` : `multi-line-text one-word ${className}`} 
            style={{ ...style, WebkitLineClamp: lines }}
            onClick={onClick}
        >
        </div>
    )
}