import React from 'react'

export default function MultilinksImage(props) {
    return (
        <svg 
            {...props} 
            viewBox="0 0 32 32" 
            width="512" 
            height="512"
        >
            <path d="M21.12,18.93h-3a1.07,1.07,0,0,1-1.07-1.07v-8a1.07,1.07,0,0,1,1.07-1.07h3a3.2,3.2,0,0,1,3.2,3.2v3.72A3.2,3.2,0,0,1,21.12,18.93ZM19.2,16.8h1.92a1.07,1.07,0,0,0,1.07-1.07V12a1.07,1.07,0,0,0-1.07-1.07H19.2Z"/><path d="M11.64,8.81A3.2,3.2,0,0,0,8.44,12v5.85a1.07,1.07,0,1,0,2.13,0v-.75H12.8v.75a1.07,1.07,0,1,0,2.13,0V12A3.23,3.23,0,0,0,11.64,8.81ZM10.57,15V12a1.09,1.09,0,0,1,1.16-1.07A1.07,1.07,0,0,1,12.8,12v3Z"/><path d="M26.67,2.13H5.33A5.34,5.34,0,0,0,0,7.46V20.27a5.34,5.34,0,0,0,5.33,5.33h9.6v2.13H12.8a1.07,1.07,0,0,0,0,2.13h6.4a1.07,1.07,0,0,0,0-2.13H17.07v-3.2h0A1.07,1.07,0,0,0,16,23.47H5.33a3.2,3.2,0,0,1-3.2-3.2V7.46a3.2,3.2,0,0,1,3.2-3.2H26.67a3.2,3.2,0,0,1,3.2,3.2V20.27a3.2,3.2,0,0,1-3.2,3.2H19.2a1.07,1.07,0,0,0,0,2.13h7.47A5.34,5.34,0,0,0,32,20.27V7.46A5.34,5.34,0,0,0,26.67,2.13Z"/>
        </svg>
            
    )
}