import Loader from 'components/Loader/Loader'
import React from 'react'
import { isMobile } from 'react-device-detect'

/**
 * Represents the loader of the influencer's links/shorts list.
 */
export default function InfluencerLinksShortsLoader() {
    return (
        <Loader styles={{
            width: isMobile ? '20px' : '30px',
            height: isMobile ? '20px' : '30px',
            position: 'absolute',
            left: 0,
            right: 0,
            margin: `${isMobile ? '20px' : '30px'} auto 0 auto`,
            zIndex: 80
        }} />
    )
}
