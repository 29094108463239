import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * A custom hook that simplifies usage of query parameters.
 * @returns the current page's query parameters
 */
export default function useQueryParams() {
    const location = useLocation()
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location])

    return queryParams
}