import './PaydinMuiAccordion.css'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { PAYDIN_ACCORDION_HEADER_GAP_PX } from 'constants/general.constants';

/**
 * Represents a single accordion, based on the material UI accordion.
 * 
 * @param {string} name - The name of this accordion
 * @param {DOMElement} header - The header of this accordion
 * @param {DOMElement} content - The content of this accordion
 * @param {string} expandedState - The state that controlls the opening & closing of this accordion
 * @param {function} setExpandedState - The setter function of the 'expandedState' state.
 *      The value for this prop can be given from 3 different sources:
 *          1. The 'setExpandedState' field inside an object passed through the 'accordions' array, see {@link PaydinAccordion}
 *          2. The 'onExpanded' prop given to {@link PaydinAccordion}
 *          3. The 'setExpandedAccordionName' setter function for the inner state inside {@link PaydinAccordion} component
 *      
 *      NOTE: Make sure that whatever function is passed for this prop, it eventually must change the 'expandedState'.
 * 
 * @param {boolean} allowZeroExpands - Determins whether all the accordions can be closed at the same time or not
 * @param {string} borderColor - The border color of this accordion
 * @param {string} headerBackgroundColor - The background color of this accorion's header
 * @param {string} contentBackgroundColor - The background color of this accorion's content
 * @param {boolean} hasBorder - Determins whether this accordion has border or not
 * @param {number} borderRadiusPx - The border radius of this accordion in pixels
 * @param {number} borderWidthPx - The border width of this accordion in pixels
 * @param {string} headerTextColor - The text color of this accordion's header
 * @param {number} headerHeightPx - The height of this accordion's header in pixels
 * @param {string} contentTopBorderColor - The color of the top border of this accordion's content
 * @param {number} contentTopBorderWidthPx - The width of the top border of this accordion's content in pixels
 * @param {number} contentPaddingPx - The padding of this accordion's content in pixels
 * @param {boolean} disabled - Determins whether this accordion is disabled or not
 * @param {number} headerContentArrowGapPx - The gap between the header content and the arrow, in pixels
 */
export default function PaydinMuiAccordion({
    name,
    header,
    content,
    expandedState,
    setExpandedState,
    allowZeroExpands = true,
    borderColor = 'black',
    headerBackgroundColor = 'white',
    contentBackgroundColor = 'white',
    hasBorder = true,
    borderRadiusPx = 0,
    borderWidthPx = 1,
    headerTextColor = 'black',
    contentTopBorderColor = '#e3e3e3',
    contentTopBorderWidthPx = 1,
    headerPadding = '',
    contentPadding = '',
    disabled = false,
    headerContentArrowGapPx = PAYDIN_ACCORDION_HEADER_GAP_PX
}) {
    const { t } = useTranslation()

    function handleChange(accordionName) {
        return (event, newExpanded) => {
            if (allowZeroExpands)
                setExpandedState(newExpanded ? accordionName : '')
            else 
                setExpandedState(accordionName)
        }
    }

    return (
        <MuiAccordion
            disabled={disabled}
            disableGutters={true}
            elevation={0}
            square={false}
            expanded={(expandedState === name)}
            onChange={handleChange(name)}
            sx={{
                ...(hasBorder ? { border: `${borderWidthPx}px solid ${borderColor}` } : {}),
                width: '100%',
                margin: 'auto',
                direction: t('direction.dir'),
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                }
            }}>
            <MuiAccordionSummary
                // expandIcon={t('direction.dir') === 'rtl' ? <IoIosArrowBack className='paydin-mui-accordion-expand-image' /> : <IoIosArrowForward className='paydin-mui-accordion-expand-image' />}
                sx={{
                    // gap: headerContentArrowGapPx * 0.125,
                    // color: headerTextColor,
                    // height: `${headerHeightPx}px`,
                    padding: headerPadding,
                    borderTopLeftRadius: `${borderRadiusPx}px`,
                    borderTopRightRadius: `${borderRadiusPx}px`,
                    ...((expandedState !== name) ? {
                        borderBottomLeftRadius: `${borderRadiusPx}px`,
                        borderBottomRightRadius: `${borderRadiusPx}px`,
                        backgroundColor: 'transparent',
                    } : {
                        backgroundColor: headerBackgroundColor,
                    }),
                    minHeight: 0,
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: t('direction.dir') === 'rtl' ? 'rotate(-90deg)' : 'rotate(90deg)',
                    },
                    '& .MuiAccordionSummary-content': {
                        margin: '0',
                    },
                }}>
                {header}
            </MuiAccordionSummary>
            <MuiAccordionDetails
                sx={{
                    padding: contentPadding,
                    // borderTop: `${contentTopBorderWidthPx}px solid ${contentTopBorderColor}`,
                    backgroundColor: contentBackgroundColor,
                    borderBottomLeftRadius: `${borderRadiusPx}px`,
                    borderBottomRightRadius: `${borderRadiusPx}px`
                }}>
                {content}
            </MuiAccordionDetails>
        </MuiAccordion>
    )
}