import React from 'react'
import './Tooltip.css'
import { RxQuestionMark } from 'react-icons/rx'
import { isMobile } from 'react-device-detect'

/**
 * Represents a tooltip element that displays an information bubble when hovering over it.
 * @param {string} tooltipText - The information bubble text content
 */
export default function Tooltip({
    image,
    tooltipText,
    width = null,
    height = null,
    swapHorizontal = false,
    displayBubble = true,
    oneLine = false
}) {
    return (
        <>
            {
                tooltipText && <div className="tooltip-container" style={{ width: width ?? '20px', height: height ?? '20px' }}>
                    {image ? <div className='tooltip-content'>{image}</div> : <RxQuestionMark className="tooltip-image" />}
                    {
                        displayBubble && <div 
                            className={`${isMobile ? 'mobile-tooltip-bubble' : 'tooltip-bubble'} ${swapHorizontal ? 'horizontal-swap' : ''}`}
                            style={oneLine ? {width: 'auto', whiteSpace: 'nowrap'} : {}}
                        >
                                {tooltipText}
                        </div>
                    }
                </div>
            }
        </>
    )
}