import React from 'react'
import './NoItemsFound.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'

/**
 * Represents a generic "no items found" view for indicating that there's no entries available to display.
 * This component can be customized
 * @param {string} className - The view's class name
 * @param {string/DOMElement} image - The image to display on the upper side of the view
 * @param {string} title - The view's title
 * @param {string} subtitle - The view's subtitle
 * @param {string} buttonText - The view's button's text
 * @param {boolean} isEmbeddedIcon - Determins whether the image is passed as a standalone component or as an image url
 * @param {boolean} showButton - Determins whether the lower button will be shown or not
 * @param {string} addItemButtonClickHandler - A function to perform when clicking on the bottom button
 */
export default function NoItemsFound({
    className = '',
    image = '',
    title = '',
    subtitle = '',
    buttonText = '',
    buttonStyles = {},
    isEmbeddedIcon = false,
    showButton = true,
    addItemButtonClickHandler = () => { }
}) {
    return (
        <div className={`no-items-found-container ${className}`}>
            {
                isEmbeddedIcon ?
                    image :
                    <ImageBox
                        image={image}
                        className="no-items-found-container-image"
                    />
            }
            <div className="no-items-found-titles-container">
                <div className={isMobile ? "mobile-no-items-found-titles-container-title" : "no-items-found-titles-container-title"}>{title}</div>
                {subtitle && <div className="no-items-found-titles-container-subtitle">{subtitle}</div>}
            </div>
            {showButton && <div className="no-items-found-add-item-button" onClick={addItemButtonClickHandler} style={buttonStyles}>{buttonText}</div>}
        </div>
    )
}