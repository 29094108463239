import React, { useEffect, useRef, useState } from 'react'
import './LoginPage.css'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import {
    AUTH_QUERY_PARAMETER_KEY,
    EXTERNAL_AUTH_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    HOME_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER,
    LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER,
    LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER,
    LOGIN_INFLUENCER_PAGE_URL,
    LOGIN_PAGE_URL,
} from 'constants/routes.constants'
import {
    BOXED_MESSAGE_TYPES,
    USERNAME_FIELD_PREFIX,
    USER_PASSWORD_MIN_LENGTH
} from 'constants/general.constants'
import { getQueryVariable } from 'services/SystemService'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useAuthContext } from 'contexts/User'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import PrivaciesFooterText from 'components/PrivaciesFooterText/PrivaciesFooterText'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { formatUsername } from 'utils/utils'
import DontHaveAccount from 'components/DontHaveAccount/DontHaveAccount'

export default function LoginPage({
    isInfluencerLogin = false
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { login } = useAuthContext()

    const [isAbleToLogin, setIsAbleToLogin] = useState(false)
    const [isLoginInProcess, setIsLoginInProcess] = useState(false)
    const [inputState, setInputState] = useState({
        username: '',
        influencerUsername: '',
        password: ''
    })
    const [loginFailed, setLoginFailed] = useState(false)
    const [shouldAutoLogin, setShouldAutoLogin] = useState(false)

    const passwordInputRef = useRef(null)

    useEffect(() => {
        if (isInfluencerLogin) {
            const merchant = getQueryVariable(LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER, "")
            const influencerUsername = getQueryVariable(LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER, "")
            const influencerPassword = getQueryVariable(LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER, "")
            setShouldAutoLogin(merchant && influencerUsername && influencerPassword)

            setInputState(prev => ({
                ...prev,
                username: merchant,
                influencerUsername,
                password: influencerPassword
            }))
        }
    }, [])

    useEffect(() => {
        if (isInfluencerLogin && shouldAutoLogin) {
            handleLogin(() => {
                const merchant = getQueryVariable(LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER, "")
                const influencerUsername = getQueryVariable(LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER, "")

                setInputState({
                    username: merchant,
                    influencerUsername,
                    password: ''
                })
            })
            return
        }

        setIsAbleToLogin(
            isUsernameValid() &&
            isPasswordValid() &&
            (isInfluencerLogin ? isInfluencerUsernameValid() : true) // conditionally depend on the influencer username to allow login
        )
    }, [inputState])

    function isUsernameValid() {
        return inputState.username.length > 0
    }

    function isInfluencerUsernameValid() {
        return inputState.influencerUsername.length > 0
    }

    function isPasswordValid() {
        return inputState.password.length >= USER_PASSWORD_MIN_LENGTH
    }

    function resetAllFields() {
        setInputState({
            username: '',
            influencerUsername: '',
            password: ''
        })
    }

    function handleLogin(onFailure = () => { }) {
        passwordInputRef.current.blur()

        setIsLoginInProcess(true)
        setLoginFailed(false)
        login(inputState.username, inputState.influencerUsername, inputState.password, isInfluencerLogin, (isSuccess) => {
            if (isSuccess) {
                if (!isInfluencerLogin && getQueryVariable(AUTH_QUERY_PARAMETER_KEY)) {
                    history.push(EXTERNAL_AUTH_PAGE_URL + window.location.search)
                } else {
                    history.push(HOME_PAGE_URL)
                }
            } else {
                setLoginFailed(true)
                setIsLoginInProcess(false)
                setShouldAutoLogin(false)
                onFailure()
            }
        })
    }

    function navigateToForgotPassword() {
        history.push(isInfluencerLogin ? INFLUENCER_FORGOT_PASSWORD_PAGE_URL : FORGOT_PASSWORD_PAGE_URL)
    }

    function setPassword(password) {
        setInputState(prev => {
            return {
                ...prev,
                password,
            }
        })
    }

    function setUsername(username) {
        setInputState(prev => {
            return {
                ...prev,
                username: formatUsername(username),
            }
        })
    }

    function setInfluencerUsername(influencerUsername) {
        setInputState(prev => {
            return {
                ...prev,
                influencerUsername: formatUsername(influencerUsername),
            }
        })
    }

    function switchToMerchantLogin() {
        resetAllFields()
        history.push(LOGIN_PAGE_URL)
    }

    function switchToInfluencerLogin() {
        resetAllFields()
        history.push(LOGIN_INFLUENCER_PAGE_URL)
    }

    return (
        <>
            <div className='login-page-container'>
                <form dir={t('direction.dir')} className="login-page-content">
                    <div className="login-page-title auth-page-title">{t('LOGIN_PAGE_TITLE')}</div>
                    {
                        loginFailed && <BoxedMessage
                            message={t('LOGIN_PAGE_LOGIN_ERROR_MESSAGE_TEXT')}
                            type={BOXED_MESSAGE_TYPES.ERROR} />
                    }
                    <div className="login-page-fields-form">
                        <EditFieldSectionInput
                            title={isInfluencerLogin ? t('LOGIN_PAGE_INFLUENCER_LOGIN_MERCHANT_INPUT_PLACEHOLDER') : t('LOGIN_PAGE_USERNAME_INPUT_PLACEHOLDER')}
                            prefix={isInfluencerLogin ? null : USERNAME_FIELD_PREFIX}
                            isEmbeddedPrefix={true}
                            value={inputState.username}
                            setValue={setUsername}
                            enlargedTextField={true}
                            id="username"
                        />
                        {
                            isInfluencerLogin && <EditFieldSectionInput
                                title={t('LOGIN_PAGE_INFLUENCER_USERNAME_INPUT_PLACEHOLDER')}
                                value={inputState.influencerUsername}
                                setValue={setInfluencerUsername}
                                enlargedTextField={true}
                                id="influencer-username"
                            />
                        }
                        <EditFieldSectionInput
                            title={t('LOGIN_PAGE_PASSWORD_INPUT_PLACEHOLDER')}
                            inputRef={passwordInputRef}
                            value={inputState.password}
                            setValue={setPassword}
                            isPassword={true}
                            hasValidation={true}
                            enlargedTextField={true}
                            id="password"
                            titleButton={<>
                                <div className="login-page-forgot-password-button" onClick={navigateToForgotPassword}>{t('LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_TEXT')}</div>
                            </>}
                        />
                    </div>
                    <LoaderButton
                        className="login-page-login-button"
                        buttonText={t('LOGIN_PAGE_LOGIN_BUTTON_TEXT')}
                        isLoading={isLoginInProcess}
                        renderAsButton={true}
                        isDisabled={!isAbleToLogin}
                        onClick={handleLogin} />

                    <div className="login-page-login-mode-switch-button" onClick={isInfluencerLogin ? switchToMerchantLogin : switchToInfluencerLogin}>{isInfluencerLogin ? t('LOGIN_PAGE_MERCHANT_LOGIN_BUTTON_TEXT') : t('LOGIN_PAGE_INFLUENCER_LOGIN_BUTTON_TEXT')}</div>
                    <DontHaveAccount />
                </form>
                <PrivaciesFooterText />
            </div>
        </>
    )
}