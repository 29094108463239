import { request_auth } from 'services/RequestWrapper';
import { environment } from "conf";
import {
    LINKS_API_ROUTE,
    EDIT_LINK_API_URL_SUFFIX,
    CREATE_LINK_API_URL_SUFFIX,
    DELETE_LINK_API_URL_SUFFIX,
    OVERVIEW_API_URL_SUFFIX,
    LINK_ANALYTICS_API_URL_PREFIX,
    ARCHIVE_LINKS_API_URL_PREFIX,
    ARCHIVE_LINKS_API_URL_SUFFIX,
    INSIGHTS_REQUEST_API_URL,
    ANALYTICS_API_SUFFIX,
    MULTILINKS_API_ROUTE
} from 'constants/api.constants';

export function GetLinksAPI(businessId, searchQuery, count, skip, linkType) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }

        const url = `${environment.base_url}${LINKS_API_ROUTE}/${businessId}?q=${searchQuery}&count=${count}&skip=${skip}&link_type=${linkType}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetMultilinksAPI(businessId, searchQuery, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }

        const url = `${environment.base_url}${MULTILINKS_API_ROUTE}/${businessId}?q=${searchQuery}&count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetLinkDetailsAPI(linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }
        request_auth(environment.base_url + LINKS_API_ROUTE + `/${linkId}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}


export function GetMultilinkDetailsAPI(multilinkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }
        request_auth(environment.base_url + MULTILINKS_API_ROUTE + `/${multilinkId}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function updateLookDetails(businessId, linkId, images, inventoryIds, selectedOptions, collectionsIds, promocode, linkDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType) {
    return UpdateLinkDetailsAPI(businessId, linkId, images, inventoryIds, selectedOptions, collectionsIds, promocode, linkDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType)
}

export function updateShortDetails(businessId, linkId, promocode, linkDescription, shortImage, selectedProductIds, selectedCollectionIds, shoppingRedirectURL, influencerUsername, utmParams, linkType) {
    return UpdateLinkDetailsAPI(businessId, linkId, [shortImage], selectedProductIds, {}, selectedCollectionIds, promocode, linkDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType)
}

function UpdateLinkDetailsAPI(businessId, linkId, images, inventoryIds, selectedOptions, collectionsIds, promocode, linkDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                images,
                inventory_ids: inventoryIds,
                selected_options: selectedOptions,
                collection_ids: collectionsIds,
                promocode,
                description: linkDescription,
                business_id: businessId,
                shopping_redirect_url: shoppingRedirectURL,
                influencer_username: influencerUsername,
                utm_params: {
                    utm_source: utmParams.UTMSource,
                    utm_medium: utmParams.UTMMedium,
                    utm_campaign: utmParams.UTMCampaign,
                    utm_term: utmParams.UTMTerm,
                    utm_content: utmParams.UTMContent
                },
                link_type: linkType
            }
        }
        request_auth(environment.base_url + LINKS_API_ROUTE + `/${linkId}/` + EDIT_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    });
}

export function UpdateMultilinkDetailsAPI(businessId, multilinkId, title, splitterType, promocode, utmParams, linksOptions) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId,
                title,
                splitter_type: splitterType,
                promocode,
                utm_params: {
                    utm_source: utmParams?.UTMSource ?? '',
                    utm_medium: utmParams?.UTMMedium ?? '',
                    utm_campaign: utmParams?.UTMCampaign ?? '',
                    utm_term: utmParams?.UTMTerm ?? '',
                    utm_content: utmParams?.UTMContent ?? '',
                },
                links_options: linksOptions?.map(linkOption => {
                    return {
                        value: linkOption?.value,
                        device_option: linkOption?.deviceOption,
                        link_id: linkOption?.linkId,
                        link_resource: {
                            images: linkOption?.linkData?.images,
                            inventory_ids: linkOption?.linkData?.inventoryIds,
                            collection_ids: linkOption?.linkData?.collectionIds,
                            shopping_redirect_url: linkOption?.linkData?.shoppingRedirectUrl,
                            link_type: linkOption?.linkData?.linkType,
                            selected_options: linkOption?.linkData?.selectedOptions
                        },
                    }
                })
            }
        }
        request_auth(environment.base_url + MULTILINKS_API_ROUTE + `/${multilinkId}/` + EDIT_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    });
}

export function createLookDetails(businessId, images, inventoryIds, selectedOptions, collectionsIds, promocode, linkDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType) {
    return CreateLinkDetailsAPI(businessId, images, inventoryIds, selectedOptions, collectionsIds, promocode, linkDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType)
}

export function createShortDetails(businessId, promocode, shortDescription, shortImage, selectedProductIds, selectedCollectionIds, shoppingRedirectURL, influencerUsername, utmParams, linkType) {
    return CreateLinkDetailsAPI(businessId, [shortImage], selectedProductIds, {}, selectedCollectionIds, promocode, shortDescription, shoppingRedirectURL, influencerUsername, utmParams, linkType)
}



function CreateLinkDetailsAPI(businessId, images, inventoryIds, selectedOptions, collectionsIds, promocode, linkDescription, shoppingURL, influencerUsername, utmParams, linkType) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                images,
                inventory_ids: inventoryIds,
                selected_options: selectedOptions,
                collection_ids: collectionsIds,
                promocode,
                description: linkDescription,
                business_id: businessId,
                shopping_redirect_url: shoppingURL,
                influencer_username: influencerUsername,
                utm_params: {
                    utm_source: utmParams?.UTMSource ?? '',
                    utm_medium: utmParams?.UTMMedium ?? '',
                    utm_campaign: utmParams?.UTMCampaign ?? '',
                    utm_term: utmParams?.UTMTerm ?? '',
                    utm_content: utmParams?.UTMContent ?? '',
                },
                link_type: linkType
            }
        }
        request_auth(environment.base_url + LINKS_API_ROUTE + '/' + CREATE_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    });
}


export function CreateMultilinkDetailsAPI(businessId, title, splitterType, promocode, utmParams, linksOptions) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId,
                title,
                splitter_type: splitterType,
                promocode,
                utm_params: {
                    utm_source: utmParams?.UTMSource ?? '',
                    utm_medium: utmParams?.UTMMedium ?? '',
                    utm_campaign: utmParams?.UTMCampaign ?? '',
                    utm_term: utmParams?.UTMTerm ?? '',
                    utm_content: utmParams?.UTMContent ?? '',
                },
                links_options: linksOptions?.map(linkOption => {
                    return {
                        value: linkOption?.value,
                        device_option: linkOption?.deviceOption,
                        link_resource: {
                            images: linkOption?.linkData?.images,
                            inventory_ids: linkOption?.linkData?.inventoryIds,
                            collection_ids: linkOption?.linkData?.collectionIds,
                            shopping_redirect_url: linkOption?.linkData?.shoppingRedirectUrl,
                            link_type: linkOption?.linkData?.linkType,
                            selected_options: linkOption?.linkData?.selectedOptions
                        },
                    }
                })
            }
        }
        request_auth(environment.base_url + MULTILINKS_API_ROUTE + '/' + CREATE_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            });
    });
}


/*
    Deletes a link.

    Method: [POST]
    URL: /admin/v1/links/<linkId>/close

    Route params:
    =============
        (*) linkId - The id of the link
*/
export function DeleteLinkAPI(linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }

        request_auth(environment.base_url + LINKS_API_ROUTE + `/${linkId}` + '/' + DELETE_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}


/*
    Deletes a multilink.

    Method: [POST]
    URL: /admin/v1/links/multi/<linkId>/close

    Route params:
    =============
        (*) linkId - The id of the link
*/
export function DeleteMultilinkAPI(multilinkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }

        request_auth(environment.base_url + MULTILINKS_API_ROUTE + `/${multilinkId}` + '/' + DELETE_LINK_API_URL_SUFFIX, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}


/*
    Retrieves analytics' data of a specific business.

    Method: [GET]
    URL: /admin/v1/insights/overview

    Query params:
    ==============
        (*) fromDate - The start of the time range of the analytics data
        (*) toDate - The end of the time range of the analytics data
*/
export function GetBusinessAnalytics(businessId, fromDate = null, toDate = null) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${INSIGHTS_REQUEST_API_URL}${OVERVIEW_API_URL_SUFFIX}${fromDate !== null && toDate !== null ? `?from_date=${fromDate}&to_date=${toDate}` : ''}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves analytics' data of a specific link.

    Method: [GET]
    URL: /admin/v1/links/analytics/<linkId>/overview

    Route params:
    =============
        (*) linkId - The id of the link
    
    Query params:
    ==============
        (*) fromDate - The start of the time range of the analytics data
        (*) toDate - The end of the time range of the analytics data
*/
export function GetLinkAnalytics(linkId, fromDate = null, toDate = null) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${LINK_ANALYTICS_API_URL_PREFIX}/${linkId}${OVERVIEW_API_URL_SUFFIX}${fromDate !== null && toDate !== null ? `?from_date=${fromDate}&to_date=${toDate}` : ''}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the archived links.

    Method: [PUT]
    URL: /admin/v1/links/<businessId>/archive

    Route params:
    =============
        (*) businessId - The id of the current business

    Body params:
    ============
        (*) lastKey - A key indicating whether there are more links left to retreive
*/
export function GetArchiveLinks(businessId, lastKey) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                last_key: lastKey
            }
        }

        const url = `${environment.base_url}${ARCHIVE_LINKS_API_URL_PREFIX}/${businessId}${ARCHIVE_LINKS_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the links of a specific influencer.

    Method: [POST]
    URL: /admin/v1/links/<businessId>/influencer/<influencerUsername>

    Route params:
    =============
        (*) businessId - The id of the current business
        (*) influencerUsername - The username of the requested influencer
    
    Body params:
    ============
        (*) count - The amount of links to retreive in every page
        (*) skip - The amount of links the server should skip before retreiving links
        (*) linkType - The requested links' type
*/
export function GetInfluencerLinks(businessId, influencerUsername, count, skip, linkType = null) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                count,
                skip,
                ...(linkType ? { link_type: linkType } : {})
            }
        }

        const url = `${environment.base_url}${ARCHIVE_LINKS_API_URL_PREFIX}/${businessId}/influencer/${influencerUsername}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the link's products.

    Method: [GET]
    URL: /admin/v1/links/analytics/{linkId}/products

    Query params:
    =============
        (*) count - The number of products in a single page
        (*) skip - The number of products to skip
*/
export function getLinkProducts(linkId, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${LINKS_API_ROUTE}${ANALYTICS_API_SUFFIX}/${linkId}/products?count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the link's products' total count.

    Method: [GET]
    URL: /admin/v1/links/analytics/{linkId}/products/count
*/
export function getLinkProductsCount(linkId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${LINKS_API_ROUTE}${ANALYTICS_API_SUFFIX}/${linkId}/products/count`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the link's orders.

    Method: [GET]
    URL: /admin/v1/links/analytics/{linkId}/orders

    Query params:
    =============
        (*) count - The number of products in a single page
        (*) skip - The number of products to skip
*/
export function getLinkOrders(linkId, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${LINKS_API_ROUTE}${ANALYTICS_API_SUFFIX}/${linkId}/orders?count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives a specific order of a link.

    Method: [GET]
    URL: /admin/v1/links/analytics/{linkId}/orders/{orderId}/details

*/
export function getLinkOrder(linkId, orderId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${environment.base_url}${LINKS_API_ROUTE}${ANALYTICS_API_SUFFIX}/${linkId}/orders/${orderId}/details`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}