import React from 'react'
import './ShopboardSocialMediaPostProductsTag.css'
import { useTranslation } from 'react-i18next'
import { FiPlusCircle } from 'react-icons/fi'
import ItemsViewSection from 'components/ItemsViewSection/ItemsViewSection'
import { SECTION_TYPES } from 'constants/general.constants'
import ShopboardSocialMediaPostProduct from 'components/ShopboardSocialMediaPostProduct/ShopboardSocialMediaPostProduct'
import { isMobile } from 'react-device-detect'

export default function ShopboardSocialMediaPostProductsTag({
    taggedProducts = [],
    onTagProducts = () => { }
}) {
    const { t } = useTranslation()

    return (
        <div className={isMobile ? 'mobile-shopboard-social-media-post-products-tag-container' : 'shopboard-social-media-post-products-tag-container'}>
            <div className="shopboard-social-media-post-products-tag-title-container">
                <div className={isMobile ? "mobile-shopboard-social-media-post-products-tag-title" : "shopboard-social-media-post-products-tag-title"}>{isMobile ? t('MOBILE_SHOPBOARD_SOCIAL_MEDIA_POST_PRODUCTS_TAG_TITLE', { productsCount: taggedProducts.length }) : t('SHOPBOARD_SOCIAL_MEDIA_POST_PRODUCTS_TAG_TITLE')}</div>
                <FiPlusCircle className='shopboard-social-media-post-products-tag-tag-products-button' onClick={onTagProducts} />
            </div>
            <ItemsViewSection
                data={taggedProducts}
                viewItemComponent={ShopboardSocialMediaPostProduct}
                type={SECTION_TYPES.STRIP}
                hasPadding={false}
                paddingVertical='0px'
            />
        </div>
    )
}