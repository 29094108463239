import React, { useState } from 'react'
import './UserMenu.css'
import { FaCaretDown } from 'react-icons/fa6'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import { DASHBOARD_SIDEBAR_USER_MENU_BORDER_RADIUS_PX, DASHBOARD_SIDEBAR_USER_MENU_WIDTH, PAYDIN_MENU_OPENING_DIRECTIONS, PAYDI_MENU_TRANSITION_DURATION_MILLISECONDS, SHOPIFY_LOGO_URL } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import { ACCOUNT_PAGE_URL, LOGIN_PAGE_URL, PRICING_PLANS_PAGE_URL, PRIVACY_POLICY_PAYLINK_SITE_URL, TERMS_PAYLINK_SITE_URL } from 'constants/routes.constants'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import UserMenuListItem from 'components/UserMenuListItem/UserMenuListItem'
import MultiLineText from 'components/MultiLineText/MultiLineText'

export default function UserMenu({
    minimized = false
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails
    } = useUserDetailsContext()
    const {
        logout
    } = useAuthContext()

    const [userMenuState, setUserMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })

    function openMenu(e) {
        setUserMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: closeMenu
        })
    }

    function closeMenu() {
        setUserMenuState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function navigateTo(url, redirect = false) {
        if (window.location.pathname !== url) {
            closeMenu()
            setTimeout(() => {
                if (redirect) {
                    window.location.href = url
                } else {
                    history.push(url)
                }
            }, PAYDI_MENU_TRANSITION_DURATION_MILLISECONDS)
        }
    }

    function openTerms() {
        navigateTo(TERMS_PAYLINK_SITE_URL, true)
    }

    function openPrivacy() {
        navigateTo(PRIVACY_POLICY_PAYLINK_SITE_URL, true)
    }

    function openMyAccount() {
        navigateTo(ACCOUNT_PAGE_URL)
    }

    function openPlansPage() {
        navigateTo(PRICING_PLANS_PAGE_URL)
    }

    function onLogout() {
        logout()
        history.replace(LOGIN_PAGE_URL)
    }

    function getUserImage() {
        return userDetails?.isAdmin ? userDetails?.businessLogo : userDetails?.influencerImageUrl
    }

    function getUserUsername() {
        return userDetails?.isAdmin ? userDetails?.username : userDetails?.influencerUsername
    }

    return (
        <>
            <div className={`user-strip ${minimized ? 'minimized' : ''}`} onClick={openMenu}>
                <div className={`user-strip-data ${minimized ? 'minimized' : ''}`}>
                    {
                        getUserImage() ? <ImageBox
                            image={getUserImage()}
                            className={`user-strip-business-logo ${minimized ? 'minimized' : ''}`}
                            showBorder={true}
                            roundImage={true}
                        /> : <div className={`user-strip-business-logo ${minimized ? 'minimized' : ''}`}>
                            <div className="user-strip-user-initials">{getUserUsername() ? getUserUsername()[0] : ''}</div>
                        </div>
                    }
                    {!minimized && <MultiLineText
                        text={userDetails?.isAdmin ? userDetails?.username : userDetails?.influencerUsername}
                        className='user-strip-username'
                        lines={1}
                        oneWord={true}
                    />}
                </div>
                {!minimized && <FaCaretDown className='user-strip-arrow-image' />}
            </div>
            <PaydinMenu
                widthPx={DASHBOARD_SIDEBAR_USER_MENU_WIDTH}
                isOpen={userMenuState.isOpen}
                anchorElement={userMenuState.anchorElement}
                onClose={userMenuState.onClose}
                direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                borderRadius={DASHBOARD_SIDEBAR_USER_MENU_BORDER_RADIUS_PX}
            >
                <div className="user-menu-container">
                    <div className="user-menu-section">
                        <div className="user-menu-section-title">{userDetails?.isAdmin ? t('USER_MENU_ACCOUNTS_SECTION_TITLE') : t('USER_INFUENCER_MENU_ACCOUNTS_SECTION_TITLE')}</div>
                        <div className="user-menu-section-list">
                            <UserMenuListItem
                                title={userDetails?.isAdmin ? userDetails?.businessName : userDetails?.username}
                                image={
                                    userDetails?.isAdmin ? <ImageBox
                                        className="user-menu-section-list-item-image-container"
                                        imageClassName="user-menu-section-list-item-image"
                                        image={SHOPIFY_LOGO_URL}
                                        showBorder={true}
                                        showImageBorderRadius={true}
                                        borderRadiusPx={7}
                                    /> : (userDetails?.businessLogo && <ImageBox
                                        className="user-menu-section-list-item-image-container merchant-logo"
                                        imageClassName="user-menu-section-list-item-image"
                                        image={userDetails?.businessLogo}
                                        borderRadiusPx={7}
                                    />)
                                }
                                subscriptionPlan={userDetails?.subscriptionPlan}
                                buttonText={t('USER_MENU_ACCOUNTS_LIST_ITEM_UPGRADE_BUTTON_TEXT')}
                                onButtonClick={openPlansPage}
                            />
                        </div>
                    </div>
                    <div className="separator-line user-menu-separator-line"></div>
                    <div className="user-menu-buttons-section">
                        <div className="user-menu-privacy-terms-container">
                            <div className="user-menu-privacy-terms-button" onClick={openPrivacy}>{t('USER_MENU_PRIVACY_BUTTON_TEXT')}</div>
                            <div className="user-menu-privacy-terms-button" onClick={openTerms}>{t('USER_MENU_TERMS_BUTTON_TEXT')}</div>
                        </div>
                        <div className="user-menu-buttons-container">
                            <div className="user-menu-button" onClick={openMyAccount}>{t('USER_MENU_ACCOUNT_BUTTON_TEXT')}</div>
                            <div className="user-menu-button" onClick={onLogout}>{t('USER_MENU_LOGOUT_BUTTON_TEXT')}</div>
                        </div>
                    </div>
                </div>
            </PaydinMenu>
        </>
    )
}