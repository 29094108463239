import { ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES, TIME_UNITS } from "constants/general.constants"

export function calculateDateRange(timeIntervalName, timeIntervalTimeUnit, timeIntervalTimeValue) {
    let todayDate = null
    let targetDate = null
    if (timeIntervalName !== ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME) {
        todayDate = new Date()
        targetDate = addTimeToDate(todayDate, timeIntervalTimeUnit, -timeIntervalTimeValue)
    }

    return [targetDate, todayDate]
}

export function getAnalyticsDateString(date) {
    if (!date)
        return null
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
}

export function addTimeToDate(date, timeUnit, value) {
    const targetDate = new Date(date)
    switch (timeUnit) {
        case TIME_UNITS.SECOND:
            targetDate.setSeconds(targetDate.getSeconds() + value)
            break

        case TIME_UNITS.MINUTE:
            targetDate.setMinutes(targetDate.getMinutes() + value)
            break

        case TIME_UNITS.HOUR:
            targetDate.setHours(targetDate.getHours() + value)
            break

        case TIME_UNITS.DAY:
            targetDate.setDate(targetDate.getDate() + value)
            break

        case TIME_UNITS.MONTH:
            targetDate.setMonth(targetDate.getMonth() + value)
            targetDate.setDate(targetDate.getDate() + 1)
            break;

        default: // TIME_UNITS.YEAR
            targetDate.setFullYear(targetDate.getFullYear() + value)
            targetDate.setDate(targetDate.getDate() + 1)
            break
    }

    return targetDate
}

/**
 * Turns a given date into a string description of it in the following format:
 * MMM dd, YYYY - e.g., Jan 3, 2013; Sep 6, 2021 etc.
 * @param {Date} date - The date to format
 * @returns a string representation of the formatted date
 */
export function getFormattedDate(date) {
    return new Intl.DateTimeFormat("en-US", { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
}

/**
 * Turns a given date into a string description of it in the following format:
 * MMM dd, YY - e.g., Jan 3, 13; Sep 6, 21 etc.
 * @param {Date} date - The date to format
 * @returns a string representation of the formatted date
 */
export function getFormattedShortYearDate(date) {
    return new Intl.DateTimeFormat("en-US", { day: 'numeric', month: 'short', year: '2-digit' }).format(date)
}

/**
 * Returns a string representation of a date range from "fromDate" to "toDate".
 * It formats each given date using {@link getFormattedShortYearDate} function and returns their range.
 * @param {string} fromDate - The date at the start of the range
 * @param {string} toDate - The date at the end of the range
 */
export function getFormattedShortYearDateRange(fromDate, toDate) {
    return `${getFormattedShortYearDate(fromDate)} - ${getFormattedShortYearDate(toDate)}`
}

/**
 * Turns a given date into a string description of it in the following format:
 * MMM dd - e.g., Jan 3, Sep 6 etc.
 * @param {Date} date - The date to format
 * @returns a string representation of the formatted date
 */
export function getFormattedShortDate(date) {
    return new Intl.DateTimeFormat("en-US", { day: 'numeric', month: 'short' }).format(date)
}

/**
 * Returns a string representation of a date range from "fromDate" to "toDate".
 * It formats each given date using {@link getFormattedDate} function and returns their range.
 * @param {string} fromDate - The date at the start of the range
 * @param {string} toDate - The date at the end of the range
*/
export function getFormattedDateRange(fromDate, toDate) {
    if (fromDate === null || toDate === null) {
        return `${fromDate ? getFormattedDate(fromDate) : ''} - ${toDate ? getFormattedDate(toDate) : ''}`
    }
    
    return `${getFormattedDate(fromDate)} - ${getFormattedDate(toDate)}`
}

/**
 * Returns a string representation of a date range from "fromDate" to "toDate".
 * It formats each given date using {@link getFormattedShortDate} function and returns their range.
 * If the start & end date has a different year, the formatted dates will have 2 digit year
 * @param {string} fromDate - The date at the start of the range
 * @param {string} toDate - The date at the end of the range
*/
export function getFormattedShortDateRange(fromDate, toDate) {
    if (fromDate === null || toDate === null || fromDate.getFullYear() === toDate.getFullYear()) {
        return `${fromDate ? getFormattedShortDate(fromDate) : ''} - ${toDate ? getFormattedShortDate(toDate) : ''}`
    }
    
    return getFormattedShortYearDateRange(fromDate, toDate)
}

export function getFormattedDateTime(date, shouldShowYear = true) {
    return new Intl.DateTimeFormat("en-US", { ...(shouldShowYear ? { year: "numeric" } : {}), month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: false }).format(date)
}