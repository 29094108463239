import React, { useState } from 'react'
import './ShopboardSocialStatus.css'
import { useTranslation } from 'react-i18next'
import { useUserDetailsContext } from 'contexts/User'
import { SHOPBOARD_SOCIALS } from 'constants/general.constants'
import Loader from 'components/Loader/Loader'
import { isMobile } from 'react-device-detect'
import SocialConnectButton from 'components/SocialConnectButton/SocialConnectButton'

export default function ShopboardSocialStatus({
    title = '',
    image = '',
    postsCount = null,
    social = SHOPBOARD_SOCIALS.INSTAGRAM,
    showStyledButton = true,
    showImage = true,
    username = "",
    titleStyles={}
}) {
    const { t } = useTranslation()
    const {
        shopboardConnect
    } = useUserDetailsContext()

    const [isConnecting, setIsConnecting] = useState(false)

    function onConnetClick() {
        setIsConnecting(true)
        shopboardConnect(social, error => setIsConnecting(false))
    }

    function renderStatus() {
        if (!isMobile && isConnecting) {
            return <div className='shopboard-social-status-loader-container'>
                <Loader styles={{ width: '15px', height: '15px', margin: 'auto' }} />
            </div>
        }

        if (postsCount === null) {
            // TODO - Add when disabling pinterest
            // if (social === SHOPBOARD_SOCIALS.PINTEREST) {
            //     return <div className='shopboard-social-status-username-count'>
            //         {(isMobile && showStyledButton) ? <SocialConnectButton social={social} stretchWidth={true} /> : <div className="shopboard-social-status-connect-button-disabled">{"Coming soon"}</div>}
            //     </div>
            // }
            return <div className='shopboard-social-status-username-count'>
                {(isMobile && showStyledButton) ? <SocialConnectButton social={social} stretchWidth={true} /> : <div onClick={onConnetClick} className="shopboard-social-status-connect-button">{t('SHOPBOARD_SOCIALS_STATUS_CONNECT_BUTTON_TEXT')}</div>}
            </div>
        }

        if (postsCount === -1) {
            return <div className='shopboard-social-status-username-count'>
                <div className="shopboard-social-status-posts-count">{username.slice(0, 15)}</div>
                <div className="shopboard-social-status-posts-count">Syncing</div>
            </div>
        }

        return <div className='shopboard-social-status-username-count'>
            <div className="shopboard-social-status-posts-count">{username.slice(0, 15)}</div>
            <div className="shopboard-social-status-posts-count">{t('SHOPBOARD_SOCIALS_STATUS_POSTS_QUANTITY_TEXT', { postsCount })}</div>
        </div>
    }

    return (
        <div className='shopboard-social-status'>
            {title && <div className="shopboard-social-status-title" style={titleStyles}>{title}</div>}
            <div className="shopboard-social-status-content">
                {(!isMobile || showImage) && image}
                {renderStatus()}
            </div>
        </div>
    )
}