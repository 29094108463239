import React, { useState } from 'react'
import './SocialConnectButton.css'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useTranslation } from 'react-i18next'
import { INSTAGRAM_BLACK_WHITE_LOGO_URL, PINTEREST_BLACK_WHITE_LOGO_URL, SHOPBOARD_SOCIALS, TIKTOK_BLACK_WHITE_LOGO_URL } from 'constants/general.constants'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import ImageBox from 'components/ImageBox/ImageBox'

export default function SocialConnectButton({
    social = SHOPBOARD_SOCIALS.INSTAGRAM,
    stretchWidth = true
}) {
    const { t } = useTranslation()
    const {
        shopboardConnect,
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()
    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()

    const [isConnecting, setIsConnecting] = useState(false)

    const buttonContainerStyles = {
        width: stretchWidth ? '100%' : 'fit-content',
        ...(social === SHOPBOARD_SOCIALS.INSTAGRAM ? {backgroundImage: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)'} : {backgroundColor: getButtonBackgroundColor(social)})
    }

    function onConnectClick() {
        setIsConnecting(true)
        shopboardConnect(social)
    }

    function getButtonImageUrl(social) {
        switch (social) {
            case SHOPBOARD_SOCIALS.INSTAGRAM: return INSTAGRAM_BLACK_WHITE_LOGO_URL
            case SHOPBOARD_SOCIALS.TIKTOK: return TIKTOK_BLACK_WHITE_LOGO_URL
            default: return PINTEREST_BLACK_WHITE_LOGO_URL
        }
    }

    function getButtonBackgroundColor(social) {
        switch (social) {
            case SHOPBOARD_SOCIALS.TIKTOK: return 'black'
            default: return '#d13d3a'
        }
    }

    function getButtonText(social) {
        switch (social) {
            case SHOPBOARD_SOCIALS.INSTAGRAM: return t('SHOPBOARD_PAGE_INSTAGRAM_TAB_TEXT')
            case SHOPBOARD_SOCIALS.TIKTOK: return t('SHOPBOARD_PAGE_TIKTOK_TAB_TEXT')
            default: return t('SHOPBOARD_PAGE_PINTEREST_TAB_TEXT')
        }
    }

    return (
        <LoaderButton
            className='shopboard-connect-button'
            styles={buttonContainerStyles}
            buttonText={<div style={{
                display: 'flex',
                gap: '15px'
            }}>
                <ImageBox
                    image={getButtonImageUrl(social)}
                    className='shopboard-connect-button-image'
                />
                {/* TODO - Add comment part if for disabling pinterest */}
                <div className="shopboard-connect-button-text">{/* social === SHOPBOARD_SOCIALS.PINTEREST ? "Coming soon" :  */t('SHOPBOARD_CONNECT_BUTTON_TEXT', { social: getButtonText(social) })}</div>
            </div>}
            isLoading={isConnecting}
            // TODO - Add comment part for disabling pinterest
            onClick={/* social === SHOPBOARD_SOCIALS.PINTEREST ? () => {} :  */onConnectClick}
        />
    )
}
