import React, { useEffect, useRef, useState } from 'react'
import './LoginStorePage.css'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import { useAuthContext } from 'contexts/User'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import PrivaciesFooterText from 'components/PrivaciesFooterText/PrivaciesFooterText'
import { BOXED_MESSAGE_TYPES, NOTIFY_STORE_CONNECT_FINISH_MESSAGE, USERNAME_FIELD_PREFIX, USER_PASSWORD_MIN_LENGTH } from 'constants/general.constants'
import { formatUsername } from 'utils/utils'
import { AUTH_QUERY_PARAMETER_KEY, FORGOT_PASSWORD_PAGE_URL, HOME_PAGE_URL, PAYLINK_SITE_URL, REGISTER_STORE_PAGE_URL } from 'constants/routes.constants'
import { getQueryVariable } from 'services/SystemService'
import { loginAndConnectStore, verifyStore } from 'api/store/store'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import { LocalSharedPreferences } from 'services/SharedPreferences'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { AUTH_TOKEN_KEY_LOCAL_STORAGE } from 'constants/storage.constants'
import DontHaveAccount from 'components/DontHaveAccount/DontHaveAccount'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'

export default function LoginStorePage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { verify } = useAuthContext()

    const [isAbleToLogin, setIsAbleToLogin] = useState(false)
    const [isLoginInProcess, setIsLoginInProcess] = useState(false)
    const [isVerifyingStore, setIsVerifyingStore] = useState(true)
    const [isStoreVerified, setIsStoreVerified] = useState(false)
    const [inputState, setInputState] = useState({
        username: '',
        password: ''
    })
    const [auth, setAuth] = useState('')
    const [boxedMessageState, setBoxedMessageState] = useState({
        isOpen: false,
        message: ''
    })
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        onLeftButtonClick: () => { }
    })

    const passwordInputRef = useRef(null)

    useEffect(() => {
        const authParam = getQueryVariable(AUTH_QUERY_PARAMETER_KEY, "")
        if (!authParam) {
            navigateToPaylinkshopSite()
            return
        }

        setAuth(authParam)
        setIsVerifyingStore(true)
        verifyStore(authParam)
            .then(_ => {
                setIsVerifyingStore(false)
                setIsStoreVerified(true)
            })
            .catch(error => {
                console.log(error)
                setIsVerifyingStore(false)
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('LOGIN_STORE_PAGE_VERIFY_STORE_FAILED_DIALOG_TITLE'),
                    message: t('LOGIN_STORE_PAGE_VERIFY_STORE_FAILED_DIALOG_MESSAGE'),
                    leftButtonText: t('LOGIN_STORE_PAGE_VERIFY_STORE_FAILED_DIALOG_RIGHT_BUTTON_TEXT'),
                    onLeftButtonClick: navigateToPaylinkshopSite
                }))
            })
    }, [])

    useEffect(() => {
        setIsAbleToLogin(
            isUsernameValid() &&
            isPasswordValid()
        )
    }, [inputState])

    function navigateToPaylinkshopSite() {
        window.location.href = PAYLINK_SITE_URL
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function isUsernameValid() {
        return inputState.username.length > 0
    }

    function isPasswordValid() {
        return inputState.password.length >= USER_PASSWORD_MIN_LENGTH
    }

    function handleLogin() {
        passwordInputRef.current.blur()

        setIsLoginInProcess(true)
        loginAndConnectStore(auth, inputState.username, inputState.password)
            .then(response => {
                LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, response?.data?.token)
                verify(() => {
                    if (window?.opener) {
                        window.opener.postMessage(NOTIFY_STORE_CONNECT_FINISH_MESSAGE, '*')
                    }
                    setIsLoginInProcess(false)
                    history.push(HOME_PAGE_URL)
                }, () => {
                    setIsLoginInProcess(false)
                    navigateToPaylinkshopSite()
                })
            })
            .catch(_ => {
                setIsLoginInProcess(false)
                setBoxedMessageState({
                    isOpen: true,
                    message: t('LOGIN_STORE_PAGE_REGISTER_ERROR_MESSAGE_TEXT')
                })
            })
    }

    function navigateToForgotPassword() {
        history.push(FORGOT_PASSWORD_PAGE_URL)
    }

    function setPassword(password) {
        setInputState(prev => {
            return {
                ...prev,
                password,
            }
        })
    }

    function setUsername(username) {
        setInputState(prev => {
            return {
                ...prev,
                username: formatUsername(username),
            }
        })
    }

    return (
        <div className='login-store-page-container'>
            {isVerifyingStore && <LoaderPage isFullScreen={true} />}
            {
                isStoreVerified && <form dir={t('direction.dir')} className="login-page-content">
                    <div className="login-page-title auth-page-title">{t('LOGIN_PAGE_TITLE')}</div>
                    {
                        boxedMessageState.isOpen && <BoxedMessage
                            message={boxedMessageState.message}
                            type={BOXED_MESSAGE_TYPES.ERROR}
                        />
                    }
                    <div className="login-page-fields-form">
                        <EditFieldSectionInput
                            title={t('LOGIN_PAGE_USERNAME_INPUT_PLACEHOLDER')}
                            prefix={USERNAME_FIELD_PREFIX}
                            isEmbeddedPrefix={true}
                            value={inputState.username}
                            setValue={setUsername}
                            enlargedTextField={true}
                            id="username"
                        />
                        <EditFieldSectionInput
                            title={t('LOGIN_PAGE_PASSWORD_INPUT_PLACEHOLDER')}
                            inputRef={passwordInputRef}
                            value={inputState.password}
                            setValue={setPassword}
                            isPassword={true}
                            hasValidation={true}
                            enlargedTextField={true}
                            id="password"
                            titleButton={<>
                                <div className="login-page-forgot-password-button" onClick={navigateToForgotPassword}>{t('LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_TEXT')}</div>
                            </>}
                        />
                    </div>
                    <LoaderButton
                        className="login-page-login-button"
                        buttonText={t('LOGIN_STORE_PAGE_LOGIN_BUTTON_TEXT')}
                        isLoading={isLoginInProcess}
                        renderAsButton={true}
                        isDisabled={!isAbleToLogin}
                        onClick={handleLogin} />

                    <DontHaveAccount navigationUrl={`${REGISTER_STORE_PAGE_URL}?${AUTH_QUERY_PARAMETER_KEY}=${auth}`} />
                    <PrivaciesFooterText />
                </form>
            }
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                leftButtonText={dialogState.leftButtonText}
                onLeftButtonClick={dialogState.onLeftButtonClick}
                isLeftButtonWithLoader={false}
                closeOnLeftClick={false}
                isCloseOnTouchOutside={false}
            />
        </div>
    )
}