import React from 'react'
import './LinkAnalyticsCharts.css'
import { isMobile } from 'react-device-detect'
import ActivityChart from 'components/ActivityChart/ActivityChart'
import CommerceChart from 'components/CommerceChart/CommerceChart'
import { useUserDetailsContext } from 'contexts/User'
import { useTranslation } from 'react-i18next'
import PreviewItemDataSection from 'components/PreviewItemDataSection/PreviewItemDataSection'

/**
 * Represents an analytics charts data for a specific look or short.
 * @param {array} chartsData - The array of the data to present in the charts
 * @param {boolean} isLoadingChartsData - Determins whether the charts data is loading or not
 * @param {string} currency - The currency symbol for the charts display
 * @param {string} width - The width for the charts display
 * @param {string} timeRangeName - The display name for the selected time range
 * @param {boolean} noBackgroundColor - If true, the graphs background color is removed
 */
export default function LinkAnalyticsCharts({
    chartsData,
    isLoadingChartsData,
    currency,
    width = '100%',
    timeRangeName = '',
    noBackgroundColor = false,
    hasPadding = true
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()

    function isValueEnabled(value) {
        return userDetails?.isAdmin || value
    }

    return (
        <div className={isMobile ? "mobile-link-analytics-charts" : "link-analytics-charts"} style={{ width: width ?? null, padding: hasPadding ? '20px' : '0' }}>
            {
                (isValueEnabled(userDetails?.permissions?.clicksEnabled) || isValueEnabled(userDetails?.permissions?.salesEnabled)) && <PreviewItemDataSection
                    title={t('ACTIVITY_CHART_TITLE')}
                    NoBackgroundColor={isMobile || noBackgroundColor}
                    hasPadding={!isMobile}
                >
                    <ActivityChart
                        data={chartsData}
                        isDataLoading={isLoadingChartsData}
                        timeRangeName={timeRangeName}
                    />
                </PreviewItemDataSection>
            }
            {
                isValueEnabled(userDetails?.permissions?.revenueEnabled) && <PreviewItemDataSection
                    title={t('COMMERCE_CHART_TITLE')}
                    NoBackgroundColor={isMobile || noBackgroundColor}
                    hasPadding={!isMobile}
                >
                    <CommerceChart
                        data={chartsData}
                        currency={currency}
                        isDataLoading={isLoadingChartsData}
                        timeRangeName={timeRangeName}
                    />
                </PreviewItemDataSection>
            }
        </div>
    )
}