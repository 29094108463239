import React, { useEffect, useRef, useState } from 'react'
import './LinkItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import {
    CONTENT_TYPES,
    EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY,
    NUMBER_TYPES,
    PAYDIN_DRAWER_TRANSITION_DURATION,
    PAYDIN_MENU_OPENING_DIRECTIONS,
    VIEW_MODES
} from 'constants/general.constants'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { BrowserView, isMobile } from 'react-device-detect'
import { environment } from 'conf'
import { DeleteLinkAPI } from 'api/links/links'
import { copyToClipboard } from 'services/SystemService'
import { MdContentCopy, MdOpenInNew, MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import { formatNumber } from 'utils/utils'
import DrawerStyledButtonList from 'components/DrawerStyledButtonList/DrawerStyledButtonList'

/**
 * Represents a link item.
 * @param {string} linkId - The id of this link item 
 * @param {Image/string} image - The image of this link item 
 * @param {number} createdAt - The epoch time number representing the creation date of this link
 * @param {number} views - The number of views for this link
 * @param {number} revenue - The revenue of this link
 * @param {string} currency - The currency of this link
 * @param {string} influencerUsername - The currency of this link
 * @param {function} onDeleteLink - A callback function to invoke after the link was deleted
 */
export default function LinkItem({
    linkId,
    image,
    defaultImage,
    createdAt,
    views,
    title,
    influencerUsername,
    influencerImage,
    elementRef = null,
    onDeleteLink = () => { },
    onUpdateLink = () => { },
    allowOrderDetails = true,
}) {
    const { t } = useTranslation()
    const { 
        userDetails,
        isFreeUserAndTrialEnded, 
    } = useUserDetailsContext()
    const history = useHistoryContext()
    const {
        showApprovalMessage,
        openDrawer,
        closeDrawer,
        openFreeTrialDialog,
    } = useGeneralDataContext()

    const [isLinkDeletionDialogOpen, setIsLinkDeletionDialogOpen] = useState(false)
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })
    const [linkContentDialogState, setLinkContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.PREVIEW
    })

    const linkItemTitlesContainerRef = useRef(null)
    const linkItemTitleRef = useRef(null)

    useEffect(() => {
        linkItemTitleRef.current.style.maxWidth = `${linkItemTitlesContainerRef?.current?.clientWidth}px`
    }, [])

    function getDisplayImage(image, defaultImage) {
        if (image) {
            return image
        }

        if (defaultImage) {
            return defaultImage
        }

        return ''
    }

    function getFormattedDate(epochTime) {
        const date = new Date(epochTime)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function previewLink(closeDialog = false) {
        if (isMobile) {
            if (closeDialog) {
                history.goBack()
            }
        } else {
            if (closeDialog) {
                optionsMenuState.onClose()
            }
        }

        if (closeDialog) {
            setTimeout(() => {
                setLinkContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.PREVIEW
                })
            }, 200)
        } else {
            setLinkContentDialogState({
                isOpen: true,
                mode: VIEW_MODES.PREVIEW
            })
        }
    }

    function editLink() {
        if (isMobile) {
            history.goBack()
        } else {
            optionsMenuState.onClose()
        }
        
        setTimeout(() => {
            if (isFreeUserAndTrialEnded()) {
                openFreeTrialDialog()
            } else {
                setLinkContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.EDIT
                })
            }
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    async function copyLink(closeDialog = false) {
        if (isMobile) {
            if (closeDialog)
                closeDrawer()
        } else {
            if (closeDialog)
                optionsMenuState.onClose()
        }

        const url = `${environment.frontend_url}${userDetails?.username}/${linkId}`
        await copyToClipboard(url)
        showApprovalMessage(t('LINK_COPIED_TO_CLIPBOARD'))
    }

    function openDeleteLinkConfirmationDialog() {
        if (isMobile) {
            closeDrawer()
            setTimeout(() => {
                setIsLinkDeletionDialogOpen(true)
            }, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY)
        } else {
            optionsMenuState.onClose()
            setIsLinkDeletionDialogOpen(true)
            history.addBackHandler(() => setIsLinkDeletionDialogOpen(false))
        }
    }

    function deleteLink() {
        DeleteLinkAPI(linkId)
            .then(response => {
                onDeleteLink(linkId)
                history.goBack()
            })
            .catch(error => {
                console.log(error)
            })
    }

    function closeDialog() {
        setIsLinkDeletionDialogOpen(false)
    }

    function openOptionsDrawer() {
        openDrawer(true, <>
            <div className="mobile-link-item-more-options-menu-container">
                <div className="mobile-link-item-more-options-menu-item-line">
                    <div className="mobile-link-item-more-options-menu-single-item" onClick={() => copyLink(true)}>
                        <MdOutlineContentCopy className="mobile-link-item-more-options-menu-single-item-image" />
                        <div className="mobile-link-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK')}</div>
                    </div>
                    <div className="mobile-link-item-more-options-menu-single-item" onClick={() => previewLink(true)}>
                        <MdOpenInNew className="mobile-link-item-more-options-menu-single-item-image" />
                        <div className="mobile-link-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN')}</div>
                    </div>
                </div>
                <DrawerStyledButtonList
                    buttons={[
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            image: <MdOutlineEdit className="mobile-link-item-more-options-menu-section-item-image" />,
                            onClick: editLink
                        }] : []),
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            image: <RiDeleteBin6Line className="mobile-link-item-more-options-menu-section-item-image" />,
                            onClick: openDeleteLinkConfirmationDialog,
                            color: 'red'
                        }] : [])
                    ]}
                />
            </div>
        </>)
    }

    function getTitle() {
        if (!title)
            return 'L' // a random letter for giving the containing div a content to preserve its height
        return title?.length > EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT ? title?.slice(0, EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT) : title
    }

    function closeLinkContentDialog() {
        setLinkContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <div ref={elementRef} className={isMobile ? 'mobile-link-item-container' : 'link-item-container'}>
            <div className="link-item-content">
                <div className={isMobile ? 'mobile-link-item-details-container' : 'link-item-details-container'}>
                    <ImageBox
                        image={getDisplayImage(image, defaultImage)}
                        alt='link image'
                        className='link-item-image'
                        showGreyImageBackground={true}
                        onImageClickCallback={() => previewLink(false)}
                        isImageFitCover={true}
                    />
                    <div className="link-item-details">
                        <div className="link-item-details-lower-row">
                            <div className="link-item-created-at-expired-container">
                                <div ref={linkItemTitlesContainerRef} className="link-item-titles-container">
                                    <div className="link-item-title-container">
                                        <div ref={linkItemTitleRef} className={isMobile ? "mobile-link-item-title" : "link-item-title"} onClick={() => previewLink(false)} style={{ visibility: title ? 'visible' : 'hidden' }}>{getTitle()}</div>
                                        <div className="link-item-buttons-container">
                                            <MdContentCopy className='link-item-copy-link-button-image' onClick={() => copyLink(false)} />
                                            <BiDotsVerticalRounded className={isMobile ? 'mobile-link-item-statistics-more-options-button' : 'link-item-statistics-more-options-button'} onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                                        </div>
                                    </div>
                                    <div className={isMobile ? "mobile-link-item-created-at-text" : "link-item-created-at-text"}>{t('LINKS_PAGE_CREATED_AT_TEXT', { createdAtDate: getFormattedDate(createdAt) })}</div>
                                </div>
                            </div>
                            <div className="link-item-bottom-container">
                                <div className="link-item-statistics-container" onClick={() => previewLink(false)}>
                                    <div className="link-item-statistics-views-container detail-container">
                                        <div className="link-item-statistics-views-title link-item-statistics-title">{t('LINKS_PAGE_STATISTICS_VIEWS_TITLE')}</div>
                                        <div className='link-item-statistics-views-value link-item-statistics-value' style={{ fontSize: isMobile ? '19px' : '21px' }}>{formatNumber(views, NUMBER_TYPES.SHORT)}</div>
                                    </div>
                                </div>
                                {influencerImage && <ImageBox
                                    image={influencerImage}
                                    className='preview-link-section-influencer-image-container'
                                    imageClassName='preview-link-section-influencer-image'
                                    roundImage={true}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BrowserView>
                <PaydinMenu
                    anchorElement={optionsMenuState.anchorElement}
                    isOpen={optionsMenuState.isOpen}
                    onClose={optionsMenuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.LEFT}
                    options={[
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN'),
                            onClick: () => previewLink(true)
                        },
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            onClick: editLink
                        }] : []),
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK'),
                            onClick: () => copyLink(true)
                        },
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            className: 'link-item-options-menu-delete-option',
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            onClick: openDeleteLinkConfirmationDialog
                        }] : [])
                    ]}
                />
            </BrowserView>
            <PaydinDialog
                title={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_TITLE')}
                message={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_MESSAGE')}
                isDialogOpen={isLinkDeletionDialogOpen}
                handleDialogClose={closeDialog}
                leftButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                rightButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                onRightButtonClick={deleteLink}
                closeOnRightClick={false}
                isLeftButtonWithLoader={false}
            />
            <ContentDialog
                isDialogOpen={linkContentDialogState.isOpen}
                handleDialogClose={closeLinkContentDialog}
                data={{
                    linkId,
                    allowOrderDetails,
                }}
                mode={linkContentDialogState.mode}
                onDelete={onDeleteLink}
                onSaveSuccess={onUpdateLink}
                contentType={CONTENT_TYPES.LINK}
            />
        </div>
    )
}