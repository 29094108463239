import ImageBox from 'components/ImageBox/ImageBox'
import { FREE_MODE_DIALOG_IMAGE_URL, PAYDIN_DIALOG_TRANSITION_DURATION } from 'constants/general.constants'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import React from 'react'
import { isMobile } from 'react-device-detect'
import './FreeModeDialog.css'
import { Trans, useTranslation } from 'react-i18next'
import SpeechBubbleLightningBoltImage from 'embeddedImages/SpeechBubbleLightningBoltImage'
import { IoIosPeople } from 'react-icons/io'
import { BiCategory } from 'react-icons/bi'
import { LuCrown } from 'react-icons/lu'
import { useHistoryContext } from 'contexts/History'
import { PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { useUserDetailsContext } from 'contexts/User'

export default function FreeModeDialog({
    isDialogOpen,
    handleDialogClose = () => { },
    replace_text_to_max_breach = false,
    title='',
    subtitle='',
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { userDetails } = useUserDetailsContext()

    function goToPricingPlans() {
        history.goBack()
        setTimeout(() => {
            history.push(PRICING_PLANS_PAGE_URL)
        }, PAYDIN_DIALOG_TRANSITION_DURATION)
    }

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            height={!isMobile ? "70dvh" : "100dvh"}
            minHeight={!isMobile ? "70dvh" : "100dvh"}
            width={!isMobile ? "65vw" : "100vw"}
            hasNoWidthLimit={true}
            hasNoHeightLimit={true}
            borderRadiusPx={isMobile ? 0 : 12}
            paddingPx={0}
            margin='0px'
            dialogLayout={<>
                <div className={isMobile ? "mobile-free-mode-dialog-content" : "free-mode-dialog-content"}>
                    <div className={isMobile ? "mobile-free-mode-dialog-content-left-section" : "free-mode-dialog-content-left-section"}>
                        <div className={isMobile ? "mobile-free-mode-dialog-content-left-section-top-section" : "free-mode-dialog-content-left-section-top-section"}>
                            {
                                replace_text_to_max_breach ? 
                                <>
                                    <div className={isMobile ? "mobile-free-mode-dialog-content-left-section-title" : "free-mode-dialog-content-left-section-title"}>{title}</div>
                                    <div className={isMobile ? "mobile-free-mode-dialog-content-left-section-subtitle" : "free-mode-dialog-content-left-section-subtitle"}>{subtitle}</div>
                                </> : <>
                                    <div className={isMobile ? "mobile-free-mode-dialog-content-left-section-title" : "free-mode-dialog-content-left-section-title"}>{t('FREE_MODE_DIALOG_TITLE')}</div>
                                    <div className={isMobile ? "mobile-free-mode-dialog-content-left-section-subtitle" : "free-mode-dialog-content-left-section-subtitle"}>{t('FREE_MODE_DIALOG_SUBTITLE')}</div>
                                    <div className="free-mode-dialog-content-options-container">
                                        <div className="free-mode-dialog-content-option">
                                            <SpeechBubbleLightningBoltImage className='free-mode-dialog-content-option-image' />
                                            <div className={isMobile ? "mobile-free-mode-dialog-content-option-text" : "free-mode-dialog-content-option-text"}>{t('FREE_MODE_DIALOG_INFO_OPTION_1_TEXT')}</div>
                                        </div>
                                        <div className="free-mode-dialog-content-option">
                                            <IoIosPeople className='free-mode-dialog-content-option-image' />
                                            <div className={isMobile ? "mobile-free-mode-dialog-content-option-text" : "free-mode-dialog-content-option-text"}>{t('FREE_MODE_DIALOG_INFO_OPTION_2_TEXT')}</div>
                                        </div>
                                        <div className="free-mode-dialog-content-option">
                                            <BiCategory className='free-mode-dialog-content-option-image' />
                                            <div className={isMobile ? "mobile-free-mode-dialog-content-option-text" : "free-mode-dialog-content-option-text"}>{t('FREE_MODE_DIALOG_INFO_OPTION_3_TEXT')}</div>
                                        </div>
                                        {
                                            userDetails?.isAdmin && <div className="free-mode-dialog-content-option">
                                                <div className='free-mode-dialog-content-option-image' />
                                                <div className={isMobile ? "mobile-free-mode-dialog-content-option-text" : "free-mode-dialog-content-option-text"}>
                                                    <Trans
                                                        i18nKey={'FREE_MODE_DIALOG_INFO_OPTION_4_TEXT'}
                                                        components={{
                                                            mark: <div className='free-mode-dialog-content-option-4-learn-more-text' onClick={goToPricingPlans} />
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            { userDetails?.isAdmin && <div className="free-mode-dialog-content-cancel-anytime-text">{t('FREE_MODE_DIALOG_CANCEL_ANYTIME_TEXT')}</div> }
                        </div>
                        {
                            userDetails?.isAdmin && <div className={isMobile ? "mobile-free-mode-dialog-content-upgrade-button" : "free-mode-dialog-content-upgrade-button"} onClick={goToPricingPlans}>
                                <LuCrown className='free-mode-dialog-content-upgrade-button-image' />
                                <div className={isMobile ? "mobile-free-mode-dialog-content-upgrade-button-text" : "free-mode-dialog-content-upgrade-button-text"}>{t('FREE_MODE_DIALOG_UPGRADE_BUTTON_TEXT')}</div>
                            </div>
                        }
                    </div>
                    <div className={isMobile ? "mobile-free-mode-dialog-content-right-section" : "free-mode-dialog-content-right-section"}>
                        <ImageBox
                            image={FREE_MODE_DIALOG_IMAGE_URL}
                            className='free-mode-dialog-content-image'
                        />
                    </div>
                </div>
            </>}
        />
    )
}