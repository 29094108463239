import React, { useState } from 'react'
import './UTMFormInput.css'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import FloatingElement from 'components/FloatingElement/FloatingElement'
import { UTM_FORM_INPUT_UTM_PARAM_ADDITIONS_OPTIONS_LIST } from 'constants/general.constants'

/**
 * Represents a UTM parameter input field inside the {@link UTMForm} component.
 * @param {string} title - The title for this input field
 * @param {string} placeholder - The placeholder for this input field
 * @param {string} value - The value state for this input field
 * @param {string} setValue - A function for setting the value state for this input field
 * @param {string} isOptional - Determins whether this input field is optional or not
 * @param {string} inputClassName - A class for the input
 */
export default function UTMFormInput({
    title,
    placeholder,
    value,
    setValue,
    isOptional = false,
    inputClassName = ''
}) {
    const { t } = useTranslation()

    const [isParametersMenuOpen, setIsParametersMenuOpen] = useState(false)

    function handleInput(e) {
        setValue(e.target.value)
    }

    function onAddParameterButtonClick() {
        setIsParametersMenuOpen(prev => !prev)
    }

    function onUTMParamAdditionClick(UTMAddition) {
        setValue(`${value}${UTMAddition?.template}`)
        hideUTMAdditionsMenu()
    }

    function hideUTMAdditionsMenu() {
        setIsParametersMenuOpen(false)
    }

    return (
        <div className={isMobile ? 'mobile-utm-form-input-container' : 'utm-form-input-container'}>
            <div className="utm-form-input-title-container">
                <div className="utm-form-input-title">{title} {isOptional ? t('OPTIONAL_INPUT_TITLE_SUFFIX') : ''}</div>
                <div className="utm-form-input-add-parameter-button-container">
                    <div className="utm-form-input-add-parameter-button" onClick={onAddParameterButtonClick}>{t('UTM_FORM_INPUT_ADD_PARAMETER_BUTTON_TEXT')}</div>
                    <FloatingElement
                        isShown={isParametersMenuOpen}
                        hide={hideUTMAdditionsMenu}
                    >
                        <div className="utm-form-input-utm-param-additions-menu">
                            {
                                UTM_FORM_INPUT_UTM_PARAM_ADDITIONS_OPTIONS_LIST.map(option => <div
                                    className={isMobile ? "mobile-utm-form-input-utm-param-addition" : "utm-form-input-utm-param-addition"}
                                    key={option?.template + "__" + option?.description}
                                    onClick={() => onUTMParamAdditionClick(option)}
                                >
                                    <div className={isMobile ? "mobile-utm-form-input-utm-param-addition-title" : "utm-form-input-utm-param-addition-title"}>{option?.template}</div>
                                    <div className={isMobile ? "mobile-utm-form-input-utm-param-addition-description" : "utm-form-input-utm-param-addition-description"}>{option?.description}</div>
                                </div>)
                            }
                        </div>
                    </FloatingElement>
                </div>
            </div>
            <input
                className={`${isMobile ? 'mobile-utm-form-input' : 'utm-form-input'} ${inputClassName}`}
                value={value}
                onChange={handleInput}
                placeholder={placeholder}
            />
        </div>
    )
}