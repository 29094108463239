import React from 'react'
import './DashboardDataValueBreakdown.css'
import DashboardDataContainer from 'components/DashboardDataContainer/DashboardDataContainer'

/**
 * Represents a value breakdown dashboard data view. Uses {@link DashboardDataContainer}.
 * @param {string/number} width - The width of this container
 * @param {string/number} height - The height of this container
 * @param {string} title - The title of this view
 * @param {boolean} shouldSpreadItems - If true, the title and the content will be spread to the vertical edges.
 * @param {array} dataArray - The array of values to display
 */
export default function DashboardDataValueBreakdown({
    width,
    height,
    title = '',
    value = '',
    breakdownData = []
}) {
    return (
        <DashboardDataContainer
            title={title}
            width={width}
            height={height}
        >
            <div className="dashboard-data-value-breakdown-container">
                <div className="dashboard-data-value-breakdown-value">{value}</div>
                <div className="dashboard-data-value-breakdown-content">
                    {
                        breakdownData.map(data => <div key={data?.name} className='dashboard-data-value-breakdown-item'>
                            <div className="dashboard-data-value-breakdown-item-title-container">
                                <div className="dashboard-data-value-breakdown-item-bullet" style={{ backgroundColor: data?.bulletColor }}></div>
                                <div className="dashboard-data-value-breakdown-item-title">{data?.name}</div>
                            </div>
                            <div className="dashboard-data-value-breakdown-item-value">{data?.value}</div>
                        </div>)
                    }
                </div>
            </div>
        </DashboardDataContainer>
    )
}