import PaydinRadioButtonGroup from 'components/PaydinRadioButtonGroup/PaydinRadioButtonGroup'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Represents a radio group for the shop swap section in settings page.
 * Uses {@link PaydinRadioButtonGroup} component.
 * @param {string} autoSwapEnabled - The currently selected radio button's value
 * @param {function} setAutoSwap - A function for setting currently selected radio button's value
 */
export default function AutoSwapRadioGroup({
    autoSwapEnabled,
    setAutoSwap = () => { }
}) {
    const { t } = useTranslation()

    function onSelectedValueChange(value) {
        setAutoSwap(value === 'on')
    }

    return (
        <PaydinRadioButtonGroup
            selectedValue={autoSwapEnabled ? 'on' : 'off'}
            onSelectedValueChange={onSelectedValueChange}
            radioButtons={[
                {
                    label: t('AUTO_SWAP_RADIO_GROUP_BUTTON_OFF_LABEL'),
                    value: 'off'
                },
                {
                    label: t('AUTO_SWAP_RADIO_GROUP_BUTTON_ON_LABEL'),
                    value: 'on'
                }
            ]}
        />
    )
}