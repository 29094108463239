import { environment } from "conf"
import { COLLECTIONS_API_BASE_URL, SEARCH_ROUTE } from 'constants/api.constants'
import { request_auth } from "services/RequestWrapper"

const baseUrl = environment.base_url + COLLECTIONS_API_BASE_URL

/*
    Retrieves all collections.
    Method: [GET]
    URL: /admin/v1/collections/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function getCollections(q, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}${SEARCH_ROUTE}?q=${q}&count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}