import React from 'react'

export default function HomePageImage(props) {
    return (
        <svg
            {...props}
            height="512"
            viewBox="0 0 32 32"
            width="512"
        >
            <g
                id="_9-Home"
                dataname="9-Home"
            >
                <path d="m21.82 30h-11.64a5 5 0 0 1 -5.18-4.76v-6.24a1 1 0 0 1 2 0v6.24a3 3 0 0 0 3.18 2.76h11.64a3 3 0 0 0 3.18-2.76v-6.24a1 1 0 0 1 2 0v6.24a5 5 0 0 1 -5.18 4.76zm7.89-13.29a1 1 0 0 0 0-1.42l-13-13a1 1 0 0 0 -1.42 0l-13 13a1 1 0 0 0 1.42 1.42l12.29-12.3 12.29 12.3a1 1 0 0 0 1.42 0z" />
            </g>
        </svg>
    )
}
