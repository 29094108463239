import React from 'react'
import './AnalyticsStrip.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader/Loader'
import { formatNumber } from 'utils/utils'
import { ANALYTICS_STRIP_LIFETIME_REVENUE_DISPLAY_THRESHOLD, NUMBER_TYPES } from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/User'

/**
 * Represents an analytics strip which shows the given analytics.
 * @param {object} analytics - The analytics data to display
 * @param {string} currency - The currency for the given analytics
 * @param {boolean} isLoadingAnalytics - Determins whether the analytics data is being loaded
 * @param {ref} containerRef - A ref for the container element
 */
export default function AnalyticsStrip({
    analytics = {},
    currency = '',
    isLoadingAnalytics = true,
    containerRef = null
}) {
    const { t } = useTranslation()
    const { 
        userDetails,
    } = useUserDetailsContext()

    function renderAnalyticsValue(value) {
        return isLoadingAnalytics ? <Loader styles={{ width: '15px', height: '15px' }} /> : value
    }

    function hasClicksPermission() {
        return userDetails?.isAdmin || userDetails?.permissions?.clicksEnabled
    }

    function hasSalesPermission() {
        return userDetails?.isAdmin || userDetails?.permissions?.salesEnabled
    }

    function hasRevenuePermission() {
        return userDetails?.isAdmin || userDetails?.permissions?.revenueEnabled
    }

    function shouldDisplayRevenue() {
        return hasRevenuePermission() && analytics?.revenue >= ANALYTICS_STRIP_LIFETIME_REVENUE_DISPLAY_THRESHOLD
    }

    function hasAnalyticsPermissions() {
        return hasClicksPermission() || hasSalesPermission() || shouldDisplayRevenue()
    }

    return (
        <>
            {
                hasAnalyticsPermissions() && <div ref={containerRef} className={isMobile ? "mobile-links-page-lifetime-analytics-brief" : "links-page-lifetime-analytics-brief"}>
                    {
                        hasClicksPermission() && <div className={isMobile ? "mobile-links-page-lifetime-analytics-brief-data-container" : "links-page-lifetime-analytics-brief-data-container"}>
                            <div className="links-page-lifetime-analytics-brief-clicks-dot"></div>
                            <div className={isMobile ? "mobile-links-page-lifetime-analytics-brief-data" : "links-page-lifetime-analytics-brief-data"}>
                                <div className="links-page-lifetime-analytics-brief-data-value">{renderAnalyticsValue(formatNumber(analytics?.clicks, NUMBER_TYPES.NORMAL))}</div>
                                <div className="links-page-lifetime-analytics-brief-data-title">{t('LINKS_PAGE_STATISTICS_VIEWS_TITLE')}</div>
                            </div>
                        </div>
                    }
                    {
                        hasSalesPermission() && <div className={isMobile ? "mobile-links-page-lifetime-analytics-brief-data-container" : "links-page-lifetime-analytics-brief-data-container"}>
                            <div className="links-page-lifetime-analytics-brief-sales-dot"></div>
                            <div className={isMobile ? "mobile-links-page-lifetime-analytics-brief-data" : "links-page-lifetime-analytics-brief-data"}>
                                <div className="links-page-lifetime-analytics-brief-data-value">{renderAnalyticsValue(formatNumber(analytics?.sales, NUMBER_TYPES.NORMAL))}</div>
                                <div className="links-page-lifetime-analytics-brief-data-title">{t('LINKS_PAGE_STATISTICS_SALES_TITLE')}</div>
                            </div>
                        </div>
                    }
                    {
                        shouldDisplayRevenue() && <div className={isMobile ? "mobile-links-page-lifetime-analytics-brief-data-container" : "links-page-lifetime-analytics-brief-data-container"}>
                            <div className="links-page-lifetime-analytics-brief-revenue-dot"></div>
                            <div className={isMobile ? "mobile-links-page-lifetime-analytics-brief-data" : "links-page-lifetime-analytics-brief-data"}>
                                <div className="links-page-lifetime-analytics-brief-data-value">{renderAnalyticsValue(`${currency}${formatNumber(analytics?.revenue, NUMBER_TYPES.NORMAL)}`)}</div>
                                <div className="links-page-lifetime-analytics-brief-data-title">{t('LINKS_PAGE_STATISTICS_REVENUE_TITLE')}</div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}