import React from 'react'
import './PaydinSwitch.css'
import Switch from '@mui/material/Switch';

/**
 * Represents a custom switch base on MUI {@link Switch}.
 * @param {number} label - The label for this switch
 * @param {number} labelClassName - A class for the switch's label
 * @param {boolean} disabled - Determins whether the switch is disabled or not
 * @param {boolean} checked - Determins whether the switch is checked or not
 * @param {function} setChecked - A function that sets the checked status of this switch
 */
export default function PaydinSwitch({
    label = '',
    className = '',
    labelClassName = null,
    disabled = false,
    checked = false,
    setChecked = () => { },
    checkedBackgroundColor = "#65C466"
}) {
    return (
        <div className={`paydin-switch-container ${className}`} onClick={disabled ? () => { } : () => setChecked(!checked)}>
            <Switch
                disabled={disabled}
                checked={checked}
                onChange={event => setChecked(event.target.checked)}
                sx={{
                    width: '46px',
                    height: '26px',
                    padding: '0',
                    "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 0.3,
                        transitionDuration: '300ms',
                        '&.Mui-checked': {
                            transform: 'translateX(20px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                backgroundColor: checkedBackgroundColor,
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5,
                            },
                        },
                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                            color: '#33cf4d',
                            border: '6px solid #fff',
                        },
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            color: '#f5f5f5',
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.7,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 22,
                        height: 22,
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 26 / 2,
                        backgroundColor: '#E9E9EA',
                        opacity: 1,
                        transition: 'background-color 500ms',
                    },
                }}
            />
            {label && <div className={`paydin-switch-label ${labelClassName ?? ''}`}>{label}</div>}
        </div>
    )
}