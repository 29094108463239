import React from 'react'

export default function ShortUrlSelectionCollectionTypeImage(props) {
    return (
        <svg
            {...props}
            version="1.1"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 4000 4000"
        >
            <g transform="matrix(-6.123233995736766e-17,1,-1,-6.123233995736766e-17,3990,4.547473508864641e-13)">
                <path
                    d="M2437.5 2525h-1925c-144.74 0-262.5-117.749-262.5-262.5v-1575C250 542.749 367.76 425 512.5 425h1925c144.74 0 262.5 117.749 262.5 262.5v1575c0 144.751-117.76 262.5-262.5 262.5zM512.5 600c-48.247 0-87.5 39.243-87.5 87.5v1575c0 48.257 39.253 87.5 87.5 87.5h1925c48.247 0 87.5-39.243 87.5-87.5v-1575c0-48.257-39.253-87.5-87.5-87.5z"
                    fill="#000000"
                    opacity="1"
                    dataoriginal="#000000"
                    className=""></path>
                <path
                    d="M2962.5 3050h-1925c-144.74 0-262.5-117.749-262.5-262.5 0-48.322 39.178-87.5 87.5-87.5s87.5 39.178 87.5 87.5c0 48.257 39.253 87.5 87.5 87.5h1925c48.247 0 87.5-39.243 87.5-87.5v-1575c0-48.257-39.253-87.5-87.5-87.5-48.322 0-87.5-39.178-87.5-87.5s39.178-87.5 87.5-87.5c144.74 0 262.5 117.749 262.5 262.5v1575c0 144.751-117.76 262.5-262.5 262.5z"
                    fill="#000000"
                    opacity="1"
                    dataoriginal="#000000"
                    className=""></path>
                <path
                    d="M3487.5 3575h-1925c-144.74 0-262.5-117.749-262.5-262.5 0-48.322 39.178-87.5 87.5-87.5s87.5 39.178 87.5 87.5c0 48.257 39.253 87.5 87.5 87.5h1925c48.247 0 87.5-39.243 87.5-87.5v-1575c0-48.257-39.253-87.5-87.5-87.5-48.322 0-87.5-39.178-87.5-87.5s39.178-87.5 87.5-87.5c144.74 0 262.5 117.749 262.5 262.5v1575c0 144.751-117.76 262.5-262.5 262.5z"
                    fill="#000000"
                    opacity="1"
                    dataoriginal="#000000"
                    className=""></path>
            </g>
        </svg>
    )
}
