import React from 'react'
import './OrderProduct.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { getCurrencySymbol } from 'services/currency'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { isMobile } from 'react-device-detect'
import { formatNumber } from 'utils/utils'
import { NUMBER_TYPES } from 'constants/general.constants'

/**
 * Represents a product of a link order transaction.
 * @param {string} title - The product's title
 * @param {string} imageUrl - The product's image URL
 * @param {number} price - The product's price
 * @param {number} quantity - The product's quantity
 * @param {string} variantTitle - The product's variant's title
 * @param {string} currency - The product's currency code
 */
export default function OrderProduct({
    title,
    imageUrl,
    price,
    quantity,
    variantTitle,
    currency
}) {

    function getVariantTitle(variantTitle) {
        if (variantTitle && variantTitle.toLowerCase() !== 'none') {
            return variantTitle
        }
        return ""
    }

    return (
        <div className={isMobile ? 'mobile-link-order-product' : 'link-order-product'}>
            <div className={isMobile ? "mobile-link-order-product-image-titles-container" : "link-order-product-image-titles-container"}>
                {
                    isMobile ? <>
                        <ImageBox
                            image={imageUrl}
                            className='mobile-link-order-product-image'
                            showBorder={true}
                            isImageFitCover={true}
                        />
                        <div className="mobile-link-order-product-titles-container">
                            <MultiLineText
                                text={title}
                                className='mobile-link-order-product-title'
                                lines={1}
                                oneWord={true}
                            />
                            <div className='mobile-link-order-product-variant-title-container'>
                                {
                                    getVariantTitle(variantTitle) && <MultiLineText
                                        text={getVariantTitle(variantTitle)}
                                        className='mobile-link-order-product-variant-title'
                                        lines={1}
                                        oneWord={true}
                                    />
                                }
                            </div>
                        </div>
                    </> : <>
                        <ImageBox
                            image={imageUrl}
                            className='link-order-product-image'
                            showBorder={true}
                            isImageFitCover={true}
                        />
                        <MultiLineText
                            text={title}
                            className='link-order-product-title'
                            lines={2}
                            oneWord={true}
                        />
                    </>
                }
            </div>
            {
                !isMobile && <div className='link-order-product-variant-title-container'>
                    {
                        (variantTitle && variantTitle !== 'None') && <MultiLineText
                            text={variantTitle}
                            className='link-order-product-variant-title'
                            lines={2}
                            oneWord={true}
                        />
                    }
                </div>
            }
            <div className={isMobile ? 'mobile-link-order-product-price-details' : "link-order-product-price-details"}>{`${getCurrencySymbol(currency)}${formatNumber(price / quantity, NUMBER_TYPES.PRICE)} x ${quantity}`}</div>
            <div className={isMobile ? 'mobile-link-order-product-total-price' : "link-order-product-total-price"}>{`${getCurrencySymbol(currency)}${formatNumber(price, NUMBER_TYPES.PRICE)}`}</div>
        </div>
    )
}