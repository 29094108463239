import React from 'react'
import './UrlSelectionType.css'
import { isMobile } from 'react-device-detect'
import ImageBox from 'components/ImageBox/ImageBox'
import { SHORT_URL_SELECTION_TYPE_ELEMENT_IMAGE_BORDER_RADIUS, SHORT_URL_SELECTION_TYPE_SELECTED_ELEMENT_BORDER_WIDTH } from 'constants/general.constants'
import MultiLineText from 'components/MultiLineText/MultiLineText'

export default function UrlSelectionType({
    image: Image,
    dimens = 0,
    title = '',
    isSelected = false,
    imageClassName = '',
    onClick = () => { },
    hasChangedData = false,
    isDisabled = false,
    
}) {
    const containerStyles = {
        width: dimens,
        height: dimens,
        borderRadius: `${SHORT_URL_SELECTION_TYPE_ELEMENT_IMAGE_BORDER_RADIUS}px`,
        borderWidth: `${SHORT_URL_SELECTION_TYPE_SELECTED_ELEMENT_BORDER_WIDTH}px`,
        background: `${isDisabled ? 'linear-gradient(to right top, white, white 48.6%, #e1e1e1 49.3%, #e1e1e1 50.7%, white 51.4%, white)' : 'white'}`,
        pointerEvents: isDisabled ? 'none' : 'auto',
        cursor: isDisabled ? 'auto' : 'pointer',
    }

    return (
        hasChangedData ? <div className='selected-url-selection-type' onClick={!isDisabled ? onClick : null} style={containerStyles}>
            <ImageBox
                image={Image}
                className='selected-url-selection-type-image'
                isImageFitCover={true}
                showImageBorderRadius={true}
                showImageMask={true}
                borderRadiusPx={SHORT_URL_SELECTION_TYPE_ELEMENT_IMAGE_BORDER_RADIUS - SHORT_URL_SELECTION_TYPE_SELECTED_ELEMENT_BORDER_WIDTH}
            />
            {title && <MultiLineText
                text={title}
                className={isMobile ? 'mobile-selected-url-selection-type-title' : 'selected-url-selection-type-title'}
                lines={2}
                oneWord={true}
            />}
        </div> : <div className={`url-selection-type ${isSelected ? 'selected' : ''}`} onClick={!isDisabled ? onClick : null} style={containerStyles}>
            {Image && <Image className={`url-selection-type-image ${imageClassName}`} />}
            {title && <div className={isMobile ? "mobile-url-selection-type-title" : "url-selection-type-title"}>{title}</div>}
        </div>
    )
}