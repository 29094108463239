import React, { useEffect, useRef, useState } from 'react'
import './ShortsPage.css'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import ImageBox from 'components/ImageBox/ImageBox'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import AnalyticsStrip from 'components/AnalyticsStrip/AnalyticsStrip'
import ShopifyConnect from 'components/ShopifyConnect/ShopifyConnect'
import { useFetchLinksContext, useGeneralDataContext, useIsUserLoadedContext, useUserDetailsContext } from 'contexts/User'
import ShortItem from 'components/ShortItem/ShortItem'
import { useTranslation } from 'react-i18next'
import { HOME_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'
import { EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS, NO_IMAGE_PLACEHOLDER_URL, LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT, LINK_TYPES, PAGE_DEFAULT_MAX_WIDTH, SHORT_ITEM_HEIGHT_PX, SHORTS_PAGE_SHORTS_FILTER_COUNT, VIEW_MODES, CONTENT_TYPES, DEFAULT_CURRENCY_CODE } from 'constants/general.constants'
import { GetBusinessAnalytics } from 'api/links/links'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'
import ShortsImage from 'embeddedImages/ShortsImage'
import Loader from 'components/Loader/Loader'
import { getCurrencySymbol } from 'services/currency'
import { extractExtendedInfluencerAnalytics, extractLinkData } from 'utils/dataUtils'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'

/**
 * Represents the user's shorts page.
 */
export default function ShortsPage() {

    const { t } = useTranslation()
    const history = useHistoryContext()
    const { 
        userDetails, 
        setUserDetails,
        setRecentShorts,
        isFreeUserAndTrialEnded
    } = useUserDetailsContext()
    const {
        finishedUpdatingObject,
        finishedCreatingObject,
        objectToSave,
        openFreeTrialDialog
    } = useGeneralDataContext()
    const { fetchLinks } = useFetchLinksContext()
    const isUserLoaded = useIsUserLoadedContext()

    const [isLoadingShorts, setIsLoadingShorts] = useState(true)
    const [isLoadingMoreShorts, setIsLoadingMoreShorts] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [skip, setSkip] = useState(0)
    const [defaultShorts, setDefaultShorts] = useState([])
    const [shortSearchResult, setShortSearchResult] = useState([])
    const [hasMoreShorts, setHasMoreShorts] = useState(true)
    const [shouldShowNoShorts, setShouldShowNoShorts] = useState(false)
    const [lifetimeAnalyticsState, setLifetimeAnalyticsState] = useState({
        isLoading: true,
        clicks: 0,
        sales: 0,
        revenue: 0,
        totalTax: 0
    })
    const [isFirstRender, setIsFirstRender] = useState(true) // used to control the appearance of the loader only in the first render
    const [isLinkContentDialogOpen, setIsLinkContentDialogOpen] = useState(false)

    const typingTimeoutRef = useRef(null)
    const topbarRef = useRef(null)
    const linksListRef = useRef(null)
    const analyticsStripRef = useRef(null)

    useEffect(() => {
        if (hasShopifyStore()) {
            if (isUserLoaded) {
                // when the splash is no longer shown, load the short again to refresh the short collection
                fetchFirstShorts()
            }

            if (userDetails?.analytics?.shortsLifetimeAnalytics) {
                setLifetimeAnalyticsState({
                    isLoading: false,
                    clicks: userDetails?.analytics?.shortsLifetimeAnalytics?.clicks,
                    sales: userDetails?.analytics?.shortsLifetimeAnalytics?.sales,
                    revenue: userDetails?.analytics?.shortsLifetimeAnalytics?.revenue,
                    totalTax: userDetails?.analytics?.shortsLifetimeAnalytics?.totalTax,
                    currency: getCurrencySymbol(userDetails?.analytics?.currency)
                })
            } else {
                GetBusinessAnalytics(userDetails?.businessId)
                    .then(response => {
                        const linksClicks = response?.looks_analytics?.visits ?? 0
                        const linksSales = response?.looks_analytics?.checkouts ?? 0
                        const linksCheckouts = response?.looks_analytics?.requested_checkouts ?? 0
                        const linksRevenue = response?.looks_analytics?.revenue ?? 0
                        const linksTotalTax = response?.looks_analytics?.total_tax ?? 0
                        const linksData = response?.looks_analytics?.data ?? []

                        const shortsClicks = response?.shorts_analytics?.visits ?? 0
                        const shortsSales = response?.shorts_analytics?.checkouts ?? 0
                        const shortsCheckouts = response?.shorts_analytics?.requested_checkouts ?? 0
                        const shortsRevenue = response?.shorts_analytics?.revenue ?? 0
                        const shortsTotalTax = response?.shorts_analytics?.total_tax ?? 0
                        const shortsData = response?.shorts_analytics?.data ?? []

                        const currency = response?.currency ?? DEFAULT_CURRENCY_CODE
                        setLifetimeAnalyticsState({
                            isLoading: false,
                            clicks: shortsClicks,
                            sales: shortsSales,
                            checkouts: shortsCheckouts,
                            revenue: shortsRevenue,
                            totalTax: shortsTotalTax,
                            currency: getCurrencySymbol(currency)
                        })
                        setUserDetails(prev => ({
                            ...prev,
                            analytics: {
                                ...prev.analytics,
                                currency: currency,
                                linksLifetimeAnalytics: {
                                    clicks: linksClicks,
                                    sales: linksSales,
                                    checkouts: linksCheckouts,
                                    revenue: linksRevenue,
                                    totalTax: linksTotalTax,
                                    data: linksData
                                },
                                shortsLifetimeAnalytics: {
                                    clicks: shortsClicks,
                                    sales: shortsSales,
                                    checkouts: shortsCheckouts,
                                    revenue: shortsRevenue,
                                    totalTax: shortsTotalTax,
                                    data: shortsData
                                },
                                influencerInsights: extractExtendedInfluencerAnalytics(response?.summary_insights ?? {})
                            }
                        }))
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        } else {
            history.replace(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        setShouldShowNoShorts(false)
        setLinks([], true, true)
        setIsLoadingShorts(true)
        setHasMoreShorts(true)
        clearTypingTimeout(typingTimeoutRef.current)
        if (searchQuery) {
            typingTimeoutRef.current = setTimeout(() => {
                fetchLinks(searchQuery, 0, false, LINK_TYPES.SHORT, linksData => {
                    setShouldShowNoShorts(true)
                    setLinks(linksData?.links, true, true)
                    setHasMoreShorts(linksData?.links.length === SHORTS_PAGE_SHORTS_FILTER_COUNT)
                    setIsLoadingShorts(false)
                })
            }, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS)
        } else {
            setShortSearchResult(defaultShorts)
            if (!isFirstRender) { // On the first render, the next line will be executed unless we check for first render, because in this case 'searchQuery' is falsy
                setIsLoadingShorts(false)
            }
        }
        setIsFirstRender(false)

        return () => {
            clearTypingTimeout(typingTimeoutRef.current)
        }
    }, [searchQuery])

    useEffect(() => {
        if (finishedUpdatingObject) {
            updateShort(objectToSave)
        } else if (finishedCreatingObject) {
            addNewShort(objectToSave)
        }
    }, [finishedUpdatingObject, finishedCreatingObject])

    function fetchFirstShorts() {
        setIsLoadingShorts(true)
        fetchLinks(searchQuery, 0, true, LINK_TYPES.SHORT, linksData => {
            setShouldShowNoShorts(true)
            setDefaultShortsList(linksData?.links)
            setLinks(linksData?.links, false, true)
            setHasMoreShorts(linksData?.links.length === SHORTS_PAGE_SHORTS_FILTER_COUNT)
            setIsLoadingShorts(false)
        })
    }

    function setDefaultShortsList(links) {
        setDefaultShorts([...(links.map(link => extractLinkData(link)))])
    }

    function setLinks(links, shouldResetSkip = false, shouldOverrideLinks = false) {
        if (!links) {
            return
        }

        if (shouldOverrideLinks) {
            setShortSearchResult([...(links.map(link => extractLinkData(link)))])
        } else {
            setShortSearchResult(prev => ([
                ...prev,
                ...(links.map(link => extractLinkData(link)))
            ]))
        }
        if (shouldResetSkip)
            setSkip(0)
        else
            setSkip(prev => prev + links.length)
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function clearSearchQuery() {
        setSearchQuery('')
    }

    function handleChange(e) {
        setSearchQuery(e.target.value)
    }

    function onAddLinkButtonClick() {
        setIsLinkContentDialogOpen(true)
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    function hasNoShorts() {
        return !defaultShorts || defaultShorts.length === 0
    }

    function hasNoShortsResults() {
        return !shortSearchResult || shortSearchResult.length === 0
    }

    function renderShortsContent() {
        if (hasNoShorts()) {
            return <>
                {
                    shouldShowNoShorts && <NoItemsFound
                        isEmbeddedIcon={true}
                        image={<ShortsImage className='shorts-page-no-shorts-image' />}
                        title={t('SHORTS_PAGE_NO_SHORTS_TITLE')}
                        buttonText={t('SHORTS_PAGE_NO_SHORTS_CREATE_LINK_BUTTON_TEXT')}
                        showButton={userDetails?.isAdmin}
                        addItemButtonClickHandler={onAddLinkButtonClick}
                    />
                }
            </>
        } else {
            if (searchQuery && hasNoShortsResults()) {
                return <>
                    {
                        shouldShowNoShorts && <div className={isMobile ? 'mobile-shorts-page-no-results-found-container' : 'shorts-page-no-results-found-container'}>
                            <div className="shorts-page-no-results-found-content">
                                <div className="shorts-page-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_NO_RESULTS_FOUND_TEXT')}</div>
                            </div>
                        </div>
                    }
                </>
            }
            return <div className={isMobile ? 'mobile-shorts-page-content-shorts-list' : 'shorts-page-content-shorts-list'}>
                {
                    shortSearchResult?.map(short => <ShortItem
                        key={short?.linkId}
                        shortId={short?.linkId}
                        image={short?.images ? short?.images[0] : ''}
                        defaultImage={short?.defaultImage}
                        influencerImage={short?.influencerImage}
                        views={short?.views}
                        checkouts={short?.checkouts}
                        revenue={short?.revenue}
                        createdAt={short?.createdAt}
                        currency={short?.currency}
                        isLinkExpired={short?.isLinkExpired}
                        influencerUsername={short?.influencerUsername}
                        title={short?.title}
                        url={short?.shoppingRedirectUrl}
                        urlType={short?.urlType}
                        onDeleteLink={deleteShort}
                    />)
                }
            </div>
        }
    }

    function deleteShort(shortId) {
        setShortSearchResult(prev => prev.filter(short => short?.linkId !== shortId))
        setDefaultShorts(prev => prev.filter(short => short?.linkId !== shortId))
        setIsLoadingShorts(false)
        setRecentShorts([])
    }

    function updateShort(updatedShort) {
        setShortSearchResult(prev => prev.map(short => short?.linkId === updatedShort.linkId ? updatedShort : short))
        setDefaultShorts(prev => prev.map(short => short?.linkId === updatedShort.linkId ? updatedShort : short))
    }

    function addNewShort(newShort) {
        setShortSearchResult(prev => [newShort, ...prev])
        setDefaultShorts(prev => [newShort, ...prev])
    }

    function handleLinksScroll(e) {
        const scrollPosition = e.target.scrollTop
        const thresholdScrollHeight = linksListRef?.current?.clientHeight - (LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT * SHORT_ITEM_HEIGHT_PX) + (topbarRef?.current?.clientHeight + analyticsStripRef?.current?.clientHeight) - (isMobile ? 0.4 * window.innerHeight : 0)
        if (!isLoadingMoreShorts && hasMoreShorts && scrollPosition + e.target.clientHeight >= thresholdScrollHeight) {
            setIsLoadingMoreShorts(true)
            fetchLinks(searchQuery, shortSearchResult?.length, false, LINK_TYPES.SHORT, linksData => {
                setLinks(linksData?.links, false, false)
                setHasMoreShorts(linksData?.links.length === SHORTS_PAGE_SHORTS_FILTER_COUNT)
                setIsLoadingMoreShorts(false)
            })
        }
    }

    function renderLoaderRow() {
        return <div className={isMobile ? 'mobile-shorts-page-loader-row' : 'shorts-page-loader-row'}>
            <Loader styles={{
                width: isMobile ? '25px' : '30px',
                height: isMobile ? '25px' : '30px'
            }} />
        </div>
    }

    function closeContentDialog() {
        setIsLinkContentDialogOpen(false)
    }

    return (
        <>
            <BrowserView>
                <div onScroll={handleLinksScroll} className='shorts-page-container' style={{ height: hasShopifyStore() ? null : '100vh', maxWidth: hasShopifyStore() ? '100%' : `${PAGE_DEFAULT_MAX_WIDTH}px`, margin: '0 auto' }}>
                    {
                        hasShopifyStore() ? (
                            <>
                                {isLoadingShorts && <DashboardLoader />}
                                <div ref={topbarRef} className="shorts-page-topbar">
                                    <div className="shorts-page-topbar-store-name-container">
                                        {
                                            userDetails?.businessLogo ? <ImageBox
                                                className='shorts-page-topbar-store-image'
                                                image={userDetails?.businessLogo}
                                            /> : <div className="shorts-page-topbar-store-name">{userDetails?.businessName ?? ""}</div>
                                        }
                                    </div>
                                    <div className="shorts-page-topbar-buttons-container">
                                        {
                                            !hasNoShorts() && <>
                                                <div className="shorts-page-topbar-search-box" style={{ width: isMobile ? '100%' : '300px' }}>
                                                    <input className='shorts-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                                    {
                                                        searchQuery.length === 0 ?
                                                            <CiSearch className='shorts-page-topbar-search-box-image' /> :
                                                            <IoCloseOutline className='edit-link-section-add-products-dialog-topbar-search-box-image close' onClick={clearSearchQuery} />
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) && <div className="shorts-page-topbar-create-new-link-button shorts-page-topbar-button" onClick={onAddLinkButtonClick}>{t('SHORTS_PAGE_CREATE_NEW_SHORT_BUTTON_TEXT')}</div>}
                                    </div>
                                </div>
                                <AnalyticsStrip
                                    analytics={lifetimeAnalyticsState}
                                    currency={lifetimeAnalyticsState?.currency}
                                    isLoadingAnalytics={lifetimeAnalyticsState.isLoading}
                                    containerRef={analyticsStripRef}
                                />
                                <div ref={linksListRef} className={`shorts-page-content ${hasNoShorts() ? 'no-shorts-content' : ''}`}>
                                    {renderShortsContent()}
                                    {isLoadingMoreShorts && renderLoaderRow()}
                                </div>
                            </>
                        ) : <ShopifyConnect hasShopifyStore={false} />
                    }
                </div>
            </BrowserView>

            <MobileView>
                {
                    hasShopifyStore() ? <>
                        {isLoadingShorts && <DashboardLoader />}
                        <div onScroll={handleLinksScroll} className='mobile-shorts-page-container'>
                            {
                                !hasNoShorts() && <>
                                    <div ref={topbarRef} className='mobile-shorts-page-topbar-search-box-container'>
                                        <div className="mobile-shorts-page-topbar-search-box">
                                            <input className='shorts-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                            {
                                                searchQuery.length === 0 ?
                                                    <CiSearch className='shorts-page-topbar-search-box-image' /> :
                                                    <IoCloseOutline className='shorts-page-topbar-search-box-image close' onClick={clearSearchQuery} />
                                            }
                                        </div>
                                    </div>
                                    <div className="separator-line"></div>
                                </>
                            }
                            <AnalyticsStrip
                                analytics={lifetimeAnalyticsState}
                                currency={lifetimeAnalyticsState?.currency}
                                isLoadingAnalytics={lifetimeAnalyticsState.isLoading}
                                containerRef={analyticsStripRef}
                            />
                            <div ref={linksListRef} className={`mobile-shorts-page-content ${hasNoShorts() ? 'mobile-no-shorts-content' : ''}`}>
                                {renderShortsContent()}
                                {isLoadingMoreShorts && renderLoaderRow()}
                            </div>
                        </div>
                    </> : <ShopifyConnect hasShopifyStore={false} />
                }
            </MobileView>
            <ContentDialog
                isDialogOpen={isLinkContentDialogOpen}
                handleDialogClose={closeContentDialog}
                mode={VIEW_MODES.CREATE}
                contentType={CONTENT_TYPES.SHORT}
            />
        </>
    )
}