import React from 'react'
import './TodoList.css'
import PaydinAccordion from 'components/PaydinAccordion/PaydinAccordion'
import { isMobile } from 'react-device-detect'
import { GoCheckCircleFill } from 'react-icons/go'

/**
 * Represents a todo list.
 * @param {array} tasks - The list of tasks to perform
 * @param {string} expandedTaskName - The currently expanded task. A task is expanded after clicking on it
 * @param {function} onTaskClick - A function to perform whenever a task is clicked
 */
export default function TodoList({
    tasks = [],
    expandedTaskName = '',
    onTaskClick = () => { }
}) {

    function renderTaskHeader(title, isDone) {
        return <div className='todo-list-task-header'>
            {isDone ? <GoCheckCircleFill className="todo-list-task-header-checked-mark" /> : <div className="todo-list-task-header-unchecked-mark"></div>}
            <div className="todo-list-task-header-title">{title}</div>
        </div>
    }

    function renderTaskContent(description, buttonText, buttonClickHandler, image) {
        return <div className='todo-list-task-content'>
            <div className="todo-list-task-details">
                <div className="todo-list-task-content-description">{description}</div>
                <div className="todo-list-task-content-button" onClick={buttonClickHandler}>{buttonText}</div>
            </div>
            {
                (!isMobile && image) && <div className="todo-list-task-image">
                    {image}
                </div>
            }
        </div>
    }

    return (
        <div className={isMobile ? "mobile-todo-list-container" : "todo-list-container"}>
            <PaydinAccordion
                allowZeroExpands={false}
                borderColor={'transparent'}
                headerBackgroundColor={'#f3f3f3'}
                contentBackgroundColor={'#f3f3f3'}
                openAccordionName={expandedTaskName}
                borderRadiusPx={7}
                headerPadding={'0 5px'}
                contentPadding={'0 5px 10px 5px'}
                onExpanded={onTaskClick}
                accordions={tasks.map(task => ({
                    name: task?.name,
                    header: renderTaskHeader(task?.title, task?.isDone),
                    content: renderTaskContent(task?.description, task?.buttonText, task?.onButtonClick, task?.image)
                }))}
            />
        </div>
    )
}