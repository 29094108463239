import React, { useState } from 'react'
import './PaydinColorPicker.css'
import { ChromePicker } from 'react-color'
import FloatingElement from 'components/FloatingElement/FloatingElement'

/**
 * Represents a color picker for the store settings.
 * @param {string} title - The color title
 * @param {string} color - The current color
 * @param {function} setColor - A function for setting the current color
 */
export default function PaydinColorPicker({
    title,
    color,
    showDescription = true,
    dimens = null,
    setColor = () => { }
}) {

    const [isColorPickerShown, setIsColorPickerShown] = useState(false)

    function onColorChange(color) {
        setColor(color.hex)
    }

    return (
        <div className='store-settings-color-picker'>
            <div className="store-settings-color-frame-container">
                <div className="store-settings-color-frame" style={{ backgroundColor: color, height: `${dimens}px` }} onClick={() => setIsColorPickerShown(true)}></div>
                <FloatingElement
                    isShown={isColorPickerShown}
                    hide={() => setIsColorPickerShown(false)}
                >
                    <div className='store-settings-color-picker-dialog-container'>
                        <ChromePicker
                            color={color}
                            onChange={onColorChange}
                            disableAlpha={true}
                        />
                    </div>
                </FloatingElement>
            </div>
            {
                showDescription && <div className="store-settings-color-description">
                    <div className="store-settings-color-description-title">{title}</div>
                    <div className="store-settings-color-description-value">{color}</div>
                </div>
            }
        </div>
    )
}