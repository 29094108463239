import React, { useEffect, useState } from 'react'
import './AnalyticsPage.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/utils'
import { GetBusinessAnalytics } from 'api/links/links'
import { useUserDetailsContext } from 'contexts/User'
import {
    ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS,
    ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES,
    ANALYTICS_TAB_OPTIONS,
    ANALYTICS_TAB_OPTIONS_WITH_SHOPBOARD,
    DEFAULT_CURRENCY_CODE,
    INFLUENCER_TAB_OPTIONS,
    INFLUENCER_TAB_OPTIONS_COMMISSION_ONLY,
    NUMBER_TYPES
} from 'constants/general.constants'
import AnalyticsDataCard from 'components/AnalyticsDataCard/AnalyticsDataCard'
import { getCurrencySymbol } from 'services/currency'
import TimeSelect from 'components/TimeSelect/TimeSelect'
import Tabs from 'components/Tabs/Tabs'
import { getAnalyticsDateString } from 'utils/dateUtils'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import { useHistoryContext } from 'contexts/History'
import InfluencerInsights from 'components/InfluencerInsights/InfluencerInsights'
import { extractExtendedInfluencerAnalytics } from 'utils/dataUtils'
import LinkAnalyticsCharts from 'components/LinkAnalyticsCharts/LinkAnalyticsCharts'
import { HOME_PAGE_URL } from 'constants/routes.constants'
import { getShopboardAnalytics } from 'api/shopboard/shopboard'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'

export default function AnalyticsPage() {
    const { t } = useTranslation()
    const { 
        userDetails, 
        setUserDetails,
        hasShopifyStore,
        checkShopboardStatus,
        isShopboardConnected,
    } = useUserDetailsContext()
    const history = useHistoryContext()

    const [overviewData, setOverviewData] = useState({
        currency: DEFAULT_CURRENCY_CODE,
        links: {
            visits: 0,
            checkouts: 0,
            cr: 0,
            revenue: 0,
            data: []
        },
        shorts: {
            visits: 0,
            checkouts: 0,
            cr: 0,
            revenue: 0,
            data: []
        },
        shopboard: {
            visits: 0,
            checkouts: 0,
            cr: 0,
            revenue: 0,
            data: []
        }
    })
    const [overviewTimeIntervalName, setOverviewTimeIntervalName] = useState(userDetails?.isAdmin ? ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS : ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH)
    const [datePickerState, setDatePickerState] = useState({
        startDate: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(timeInterval => timeInterval?.name === (userDetails?.isAdmin ? ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS : ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH)).timeRange[0],
        endDate: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(timeInterval => timeInterval?.name === (userDetails?.isAdmin ? ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS : ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH)).timeRange[1]
    })
    const [shouldApplyDateRange, setShouldApplyDateRange] = useState(true)
    const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(false)
    const [selectedTabOptionIndex, setSelectedTabOptionIndex] = useState(0)
    const [isLoadingTabs, setIsLoadingTabs] = useState(true)

    useEffect(() => {
        if (!hasShopifyStore()) {
            history.replace(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        if (shouldApplyDateRange) {
            // In case shopboard status was not yet checked
            if (userDetails?.isAdmin && !userDetails?.shopboard?.synced) {
                setShouldApplyDateRange(false)
                checkShopboardStatus(() => {
                    setShouldApplyDateRange(true)
                })
                return
            } 

            if (isLifetimeRangeSelected() && userDetails?.analytics?.linksLifetimeAnalytics && userDetails?.analytics?.shortsLifetimeAnalytics && (!isShopboardConnected() || userDetails?.shopboard?.analytics?.synced)) {
                setOverviewData({
                    links: {
                        visits: userDetails?.analytics?.linksLifetimeAnalytics?.clicks,
                        completedCheckouts: userDetails?.analytics?.linksLifetimeAnalytics?.sales,
                        requestedCheckouts: userDetails?.analytics?.linksLifetimeAnalytics?.checkouts,
                        revenue: userDetails?.analytics?.linksLifetimeAnalytics?.revenue,
                        cr: userDetails?.analytics?.linksLifetimeAnalytics?.clicks > 0 ? (userDetails?.analytics?.linksLifetimeAnalytics?.sales / userDetails?.analytics?.linksLifetimeAnalytics?.clicks) * 100 : 0,
                        data: userDetails?.analytics?.linksLifetimeAnalytics?.data,
                        totalTax: userDetails?.analytics?.linksLifetimeAnalytics?.totalTax
                    },
                    shorts: {
                        visits: userDetails?.analytics?.shortsLifetimeAnalytics?.clicks,
                        completedCheckouts: userDetails?.analytics?.shortsLifetimeAnalytics?.sales,
                        requestedCheckouts: userDetails?.analytics?.shortsLifetimeAnalytics?.checkouts,
                        revenue: userDetails?.analytics?.shortsLifetimeAnalytics?.revenue,
                        cr: userDetails?.analytics?.shortsLifetimeAnalytics?.clicks > 0 ? (userDetails?.analytics?.shortsLifetimeAnalytics?.sales / userDetails?.analytics?.shortsLifetimeAnalytics?.clicks) * 100 : 0,
                        data: userDetails?.analytics?.shortsLifetimeAnalytics?.data,
                        totalTax: userDetails?.analytics?.shortsLifetimeAnalytics?.totalTax
                    },
                    shopboard: {
                        visits: typeof userDetails?.shopboard?.analytics?.visits === 'number' ? userDetails?.shopboard?.analytics?.visits : 0,
                        completedCheckouts: typeof userDetails?.shopboard?.analytics?.sales === 'number' ? userDetails?.shopboard?.analytics?.sales : 0,
                        requestedCheckouts: typeof userDetails?.shopboard?.analytics?.checkouts === 'number' ? userDetails?.shopboard?.analytics?.checkouts : 0,
                        revenue: typeof userDetails?.shopboard?.analytics?.revenue === 'number' ? userDetails?.shopboard?.analytics?.revenue : 0,
                        cr: typeof userDetails?.shopboard?.analytics?.visits === 'number' && typeof userDetails?.shopboard?.analytics?.sales === 'number' && userDetails?.shopboard?.analytics?.visits > 0 ? (userDetails?.shopboard?.analytics?.sales / userDetails?.shopboard?.analytics?.visits) * 100 : 0,
                        data: userDetails?.shopboard?.analytics?.data,
                        totalTax: typeof userDetails?.shopboard?.analytics?.totalTax === 'number' ? userDetails?.shopboard?.analytics?.totalTax : 0,
                    },
                    influencerInsights: userDetails?.analytics?.influencerInsights,
                    currency: userDetails?.analytics?.currency
                })
            } else {
                setIsLoadingAnalytics(true)
                GetBusinessAnalytics(userDetails?.businessId, getAnalyticsDateString(datePickerState.startDate), getAnalyticsDateString(datePickerState.endDate))
                    .then(response => {
                        const overviewAnalytics = {
                            links: {
                                visits: response?.looks_analytics?.visits ?? 0,
                                completedCheckouts: response?.looks_analytics?.checkouts ?? 0,
                                requestedCheckouts: response?.looks_analytics?.requested_checkouts ?? 0,
                                revenue: response?.looks_analytics?.revenue ?? 0,
                                cr: (response?.looks_analytics?.visits && response?.looks_analytics?.visits > 0) ? ((response?.looks_analytics?.checkouts ?? 0) / (response?.looks_analytics?.visits)) * 100 : 0,
                                data: response?.looks_analytics?.data ?? [],
                                totalTax: response?.looks_analytics?.total_tax ?? 0
                            },
                            shorts: {
                                visits: response?.shorts_analytics?.visits ?? 0,
                                completedCheckouts: response?.shorts_analytics?.checkouts ?? 0,
                                requestedCheckouts: response?.shorts_analytics?.requested_checkouts ?? 0,
                                revenue: response?.shorts_analytics?.revenue ?? 0,
                                cr: (response?.shorts_analytics?.visits && response?.shorts_analytics?.visits > 0) ? ((response?.shorts_analytics?.checkouts ?? 0) / (response?.shorts_analytics?.visits)) * 100 : 0,
                                data: response?.shorts_analytics?.data ?? [],
                                totalTax: response?.shorts_analytics?.total_tax ?? 0
                            },
                            shopboard: {
                                visits: response?.shopboard_analytics?.visits ?? 0,
                                completedCheckouts: response?.shopboard_analytics?.checkouts ?? 0,
                                requestedCheckouts: response?.shopboard_analytics?.requested_checkouts ?? 0,
                                revenue: response?.shopboard_analytics?.revenue ?? 0,
                                cr: (response?.shopboard_analytics?.visits && response?.shopboard_analytics?.visits > 0) ? ((response?.shopboard_analytics?.checkouts ?? 0) / (response?.shopboard_analytics?.visits)) * 100 : 0,
                                data: response?.shopboard_analytics?.data ?? [],
                                totalTax: response?.shopboard_analytics?.total_tax ?? 0
                            },
                            influencerInsights: extractExtendedInfluencerAnalytics(response?.summary_insights ?? {}),
                            currency: response?.currency ?? DEFAULT_CURRENCY_CODE,
                        }
                        setOverviewData(overviewAnalytics)
                        if (isLifetimeRangeSelected()) {
                            setUserDetails(prev => ({
                                ...prev,
                                analytics: {
                                    ...prev.analytics,
                                    currency: overviewAnalytics?.currency,
                                    linksLifetimeAnalytics: {
                                        clicks: overviewAnalytics?.links?.visits,
                                        sales: overviewAnalytics?.links?.completedCheckouts,
                                        checkouts: overviewAnalytics?.links?.requestedCheckouts,
                                        revenue: overviewAnalytics?.links?.revenue,
                                        totalTax: overviewAnalytics?.links?.totalTax,
                                        data: overviewAnalytics?.links?.data
                                    },
                                    shortsLifetimeAnalytics: {
                                        clicks: overviewAnalytics?.shorts?.visits,
                                        sales: overviewAnalytics?.shorts?.completedCheckouts,
                                        checkouts: overviewAnalytics?.shorts?.requestedCheckouts,
                                        revenue: overviewAnalytics?.shorts?.revenue,
                                        totalTax: overviewAnalytics?.shorts?.totalTax,
                                        data: overviewAnalytics?.shorts?.data
                                    },
                                    influencerInsights: overviewAnalytics?.influencerInsights,
                                },
                                shopboard: {
                                    ...prev.shopboard,
                                    analytics: {
                                        ...prev.shopboard.analytics,
                                        synced: true,
                                        visits: overviewAnalytics?.shopboard?.visits ?? '-',
                                        sales: overviewAnalytics?.shopboard?.completedCheckouts ?? '-',
                                        checkouts: overviewAnalytics?.shopboard?.requestedCheckouts ?? '-',
                                        cr: overviewAnalytics?.shopboard?.cr ?? '-',
                                        revenue: overviewAnalytics?.shopboard?.revenue ?? '-',
                                        totalTax: overviewAnalytics?.shopboard?.totalTax,
                                        data: overviewAnalytics?.shopboard?.data ?? [],
                                        currency: overviewAnalytics?.currency,
                                    },
                                }
                            }))
                        }
                    })
                    .finally(error => {
                        setIsLoadingAnalytics(false)
                    })
            }
            setShouldApplyDateRange(false)
            setIsLoadingTabs(false)
        }
    }, [shouldApplyDateRange])

    function isLifetimeRangeSelected() {
        return datePickerState.startDate === null && datePickerState.endDate === null
    }

    function isLinksTabSelected() {
        return selectedTabOptionIndex === (userDetails?.isAdmin ? 0 : 1)
    }

    function isShortsTabSelected() {
        return selectedTabOptionIndex === (userDetails?.isAdmin ? 1 : 2)
    }

    function isShopboardTabSelected() {
        return userDetails?.isAdmin && selectedTabOptionIndex === 2
    }

    function isNotLast24HoursSelected() {
        return overviewTimeIntervalName !== ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_24_HOURS
    }

    function influencerTabsPermissionsAllowed() {
        return userDetails?.permissions?.clicksEnabled || userDetails?.permissions?.salesEnabled || userDetails?.permissions?.revenueEnabled
    }

    function isValueEnabled(isValueEnabled) {
        return userDetails?.isAdmin || isValueEnabled
    }

    function onDateRangeSelected(startDate, endDate) {
        setDatePickerState({
            startDate,
            endDate
        })
    }

    function getLinkDataOverviewForCurrentTab() {
        if (isLinksTabSelected()) {
            return overviewData?.links
        } else if (isShortsTabSelected()) {
            return overviewData?.shorts
        } else {
            return overviewData?.shopboard
        }
    }

    function renderAnalyticsContent() {
        if (isLinksTabSelected() || isShortsTabSelected() || isShopboardTabSelected()) {
            return <>
                <div className={isMobile ? "mobile-analytics-page-overall-statistics" : "analytics-page-overall-statistics"}>
                    {isValueEnabled(userDetails?.permissions?.clicksEnabled) && <AnalyticsDataCard
                        title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_VISITS')}
                        value={formatNumber(getLinkDataOverviewForCurrentTab()?.visits, NUMBER_TYPES.SHORT)}
                        tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_VISITS_TOOLTIP_TEXT')}
                        isDataLoading={isLoadingAnalytics}
                    />}
                    {(isValueEnabled(userDetails?.permissions?.salesEnabled) && isLinksTabSelected()) && <AnalyticsDataCard
                        title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REQUESTED_CHECKOUTS')}
                        value={formatNumber(Math.max(getLinkDataOverviewForCurrentTab()?.requestedCheckouts, getLinkDataOverviewForCurrentTab()?.completedCheckouts), NUMBER_TYPES.SHORT)}
                        tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REQUESTED_CHECKOUTS_TOOLTIP_TEXT')}
                        isTooltipHorizontalSwap={isMobile}
                        isDataLoading={isLoadingAnalytics}
                    />}
                    {isValueEnabled(userDetails?.permissions?.salesEnabled) && <AnalyticsDataCard
                        title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_COMPLETED_CHECKOUTS')}
                        value={formatNumber(getLinkDataOverviewForCurrentTab()?.completedCheckouts, NUMBER_TYPES.SHORT)}
                        tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_COMPLETED_CHECKOUTS_TOOLTIP_TEXT')}
                        isDataLoading={isLoadingAnalytics}
                    />}
                    {isValueEnabled(userDetails?.permissions?.salesEnabled && userDetails?.permissions?.clicksEnabled && userDetails?.permissions?.crEnabled) && <AnalyticsDataCard
                        title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_CR')}
                        value={`${formatNumber(getLinkDataOverviewForCurrentTab()?.cr, NUMBER_TYPES.PERCENTAGE)}%`}
                        tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_CR_TOOLTIP_TEXT')}
                        isTooltipHorizontalSwap={isMobile}
                        isDataLoading={isLoadingAnalytics}
                    />}
                    {isValueEnabled(userDetails?.permissions?.revenueEnabled) && <AnalyticsDataCard
                        title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REVENUE')}
                        value={`${getCurrencySymbol(overviewData?.currency)}${formatNumber(getLinkDataOverviewForCurrentTab()?.revenue, NUMBER_TYPES.SHORT)}`}
                        tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REVENUE_TOOLTIP_TEXT')}
                        isDataLoading={isLoadingAnalytics}
                    />}
                    {isValueEnabled(userDetails?.permissions?.salesEnabled && userDetails?.permissions?.revenueEnabled && userDetails?.permissions?.aovEnabled) && <AnalyticsDataCard
                        title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_AOV')}
                        value={`${getCurrencySymbol(overviewData?.currency)}${formatNumber(getLinkDataOverviewForCurrentTab()?.revenue / getLinkDataOverviewForCurrentTab()?.completedCheckouts, NUMBER_TYPES.SHORT, true)}`}
                        tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_AOV_TOOLTIP_TEXT')}
                        isTooltipHorizontalSwap={isMobile}
                        isDataLoading={isLoadingAnalytics}
                    />}
                </div>
                <div className={isMobile ? "mobile-analytics-page-charts-container" : "analytics-page-charts-container"}>
                    {
                        isNotLast24HoursSelected() && <LinkAnalyticsCharts
                            chartsData={getLinkDataOverviewForCurrentTab()?.data}
                            isLoadingChartsData={isLoadingAnalytics}
                            currency={getCurrencySymbol(overviewData?.currency)}
                            timeRangeName={overviewTimeIntervalName.toLowerCase()}
                            noBackgroundColor={true}
                        />
                    }
                </div>
            </>
        }

        return <InfluencerInsights
            totalRevenue={overviewData?.influencerInsights?.totalRevenue}
            commission={overviewData?.influencerInsights?.commission}
            totalLinksSales={overviewData?.influencerInsights?.salesLinks}
            totalShortsSales={overviewData?.influencerInsights?.salesShorts}
            totalOrganicSales={overviewData?.influencerInsights?.salesOrganic}
            totalLinksRevenue={overviewData?.influencerInsights?.revenueLinks}
            totalShortsRevenue={overviewData?.influencerInsights?.revenueShorts}
            totalOrganicRevenue={overviewData?.influencerInsights?.revenueOrganic}
            linksClicks={overviewData?.influencerInsights?.linksClicks}
            shortsClicks={overviewData?.influencerInsights?.shortsClicks}
            currency={overviewData?.influencerInsights?.currency}
            isLoadingData={isLoadingAnalytics}
        />
    }

    return (
        <>
            <div className={isMobile ? 'mobile-analytics-page-container' : 'analytics-page-container'}>
                {isLoadingTabs && <DashboardLoader />}
                <div className={isMobile ? "mobile-analytics-page-title-container" : "analytics-page-title-container"}>
                    {
                        isMobile ? <ContentDialogTopbar
                            title={t('ANALYTICS_PAGE_TITLE')}
                            onBackButtonClick={() => history.goBack()}
                            rightElement={<></>}
                        /> : <div className="analytics-page-title dashboard-page-title">{t('ANALYTICS_PAGE_TITLE')}</div>
                    }
                    <TimeSelect
                        selectedStartDate={datePickerState.startDate}
                        selectedEndDate={datePickerState.endDate}
                        timeIntervals={ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS}
                        setStartEndDates={onDateRangeSelected}
                        selectedTimeInterval={overviewTimeIntervalName}
                        onTimeIntervalSelect={setOverviewTimeIntervalName}
                        setShouldApplyDateRange={setShouldApplyDateRange}
                    />
                </div>
                <div>
                    <Tabs
                        selectedTabOptionIndex={selectedTabOptionIndex}
                        setSelectedTabOptionIndex={setSelectedTabOptionIndex}
                        tabs={userDetails?.isAdmin ? (!isShopboardConnected() ? ANALYTICS_TAB_OPTIONS : ANALYTICS_TAB_OPTIONS_WITH_SHOPBOARD) : (influencerTabsPermissionsAllowed() ? INFLUENCER_TAB_OPTIONS : INFLUENCER_TAB_OPTIONS_COMMISSION_ONLY)}
                    />
                    <div className="analytics-page-content">
                        {renderAnalyticsContent()}
                    </div>
                </div>
            </div>
        </>
    )
}