import FloatingElement from 'components/FloatingElement/FloatingElement'
import React from 'react'
import './FloatingMenu.css'

/**
 * Represents a floating menu.
 * Uses {@link FloatingElement} to display a menu inside it.
 * @param {JSX} menuLayout - A custom jsx element to display as the menu's content. Ignores 'menuOptions' if provided
 * @param {array} menuOptions - An array of options to display as the menu's content. Ignored if 'menuLayout' is provided
 * @param {boolean} isShown - Determins whether the floating menu is shown or not
 * @param {function} hide - A function for hiding the floating menu
 * @param {number} buttonHeight - The height of the triggering button, in pixels. Used to adjust the 'top' property of the floating menu accordingly.
 */
export default function FloatingMenu({
    menuLayout = null,
    menuOptions = [],
    isShown = false,
    hide = () => { },
    buttonHeight = 0,
    menuClassName = ''
}) {

    const onClickHandle = (option) => {
        hide()
        option?.onClick()
    }

    return (
        <FloatingElement
            isShown={isShown}
            hide={hide}
        >
            {
                menuLayout ?? (
                    <div className={`floating-menu-container ${menuClassName}`} style={{ top: `${buttonHeight}px` }}>
                        {
                            menuOptions.map(option => <div key={option?.text} className={`floating-menu-option ${option?.className ?? ''}`} onClick={() => onClickHandle(option)}>
                                {option?.image}
                                <div className={`floating-menu-option-text ${option?.textClassName}`}>{option?.text}</div>
                            </div>)
                        }
                    </div>
                )
            }
        </FloatingElement>
    )
}
