import React, { useState } from 'react'
import './InfluencerItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader/Loader'
import { isMobile } from 'react-device-detect'
import { formatNumber } from 'utils/utils'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import { CONTENT_TYPES, NUMBER_TYPES, VIEW_MODES } from 'constants/general.constants'

/**
 * Represents an influencer card displaying its details.
 * @param {string} image - The image of the influencer
 * @param {string} username - The username of the influencer
 * @param {number} clicks - The amount of the clicks for the influencer
 * @param {number} sales - The amount of sales for the influencer
 * @param {boolean} isLoadingAnalytics - Determins whether the influencer's analytics are being loaded or not
 */
export default function InfluencerItem({
    image,
    username,
    clicks,
    sales,
    isLoadingAnalytics,
    onDelete = () => { },
    onUpdate = () => { }
}) {
    const { t } = useTranslation()

    const [linkContentDialogState, setLinkContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.PREVIEW
    })

    function previewInfluencer() {
        setLinkContentDialogState({
            isOpen: true,
            mode: VIEW_MODES.PREVIEW
        })
    }

    function closeLinkContentDialog() {
        setLinkContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <div className={isMobile ? 'mobile-influencer-item-container' : 'influencer-item-container'} onClick={previewInfluencer}>
            <ImageBox
                image={image}
                className='influencer-item-image'
                roundImage={true}
                isImageFitCover={true}
                onImageClickCallback={() => { }}
            />
            <div className="influencer-item-content">
                <div className="influencer-item-name">{username}</div>
                <div className="influencer-item-analytics">
                    {/* <div className="influencer-item-analytics-section-container">
                        <div className="influencer-item-analytics-section-title">{t('INFLUENCERS_ITEM_CLICKS_COUNT_TITLE')}</div>
                        <div className="influencer-item-analytics-section-value-container">
                            {
                                isLoadingAnalytics ? <Loader
                                    styles={{
                                        width: '15px',
                                        height: '15px',
                                    }}
                                /> : <div className="influencer-item-analytics-section-value">{formatNumber(clicks, NUMBER_TYPES.SHORT)}</div>
                            }
                        </div>
                    </div> */}
                    <div className="influencer-item-analytics-section-container">
                        <div className="influencer-item-analytics-section-title">{t('INFLUENCERS_ITEM_SALES_COUNT_TITLE')}</div>
                        <div className="influencer-item-analytics-section-value-container">
                            {
                                isLoadingAnalytics ? <Loader
                                    styles={{
                                        width: '15px',
                                        height: '15px',
                                    }}
                                /> : <div className="influencer-item-analytics-section-value">{formatNumber(sales, NUMBER_TYPES.SHORT)}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ContentDialog
                isDialogOpen={linkContentDialogState.isOpen}
                handleDialogClose={closeLinkContentDialog}
                data={{
                    influencerUsername: username
                }}
                contentType={CONTENT_TYPES.INFLUENCER}
                mode={linkContentDialogState.mode}
                onDelete={onDelete}
                onSaveSuccess={onUpdate}
            />
        </div>
    )
}