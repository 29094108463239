import React from 'react'
import './CheckYourEmail.css'
import { BsEnvelope } from "react-icons/bs"
import { useTranslation } from 'react-i18next'
import AlreadyHaveAccount from 'components/AlreadyHaveAccount/AlreadyHaveAccount'

/**
 * Represents a page that notifies the user about a validation email sent to his mailbox.
 * @param {string} username - The username to display
 * @param {string} email - The email to display
 * @param {function} onBackClick - A function to perform whenever the "Go back" button was clicked
 */
export default function CheckYourEmail({
    username,
    email,
    onBackClick = () => { }
}) {
    const { t } = useTranslation()

    return (
        <div className='check-your-email-container'>
            <div className="check-your-email-message-section check-your-email-section">
                <BsEnvelope className='check-your-email-image' />
                <div className="check-your-email-message">{t('CHECK_YOUR_EMAIL_MESSAGE_TEXT', { username, email })}</div>
                <div className="check-your-email-go-back-button" onClick={onBackClick}>{t('CHECK_YOUR_EMAIL_GO_BACK_BUTTON_TEXT')}</div>
            </div>
            <div className="check-your-email-lower-section check-your-email-section">
                <AlreadyHaveAccount />
            </div>
        </div>
    )
}