import React, { useEffect, useState } from 'react'
import './LinkView.css'
import { isMobile } from 'react-device-detect'
import MobileLinkPreviewSimulator from 'components/MobileLinkPreviewSimulator/MobileLinkPreviewSimulator'
import { NO_IMAGE_PLACEHOLDER_URL, VIEW_MODES, LINK_TYPES, CREATE_LINK_REDIRECT_TO_PAGE_DELAY, SEPERATE_GO_BACK_FOR_SAFARY_DELAY } from 'constants/general.constants'
import EditLinkSection from 'components/EditLinkSection/EditLinkSection'
import { isInputValueValid } from 'services/SystemService'
import { useTranslation } from 'react-i18next'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import {
    INFLUENCERS_PAGE_URL,
    LINKS_PAGE_URL,
} from 'constants/routes.constants'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import { useHistoryContext } from 'contexts/History'
import { GetLinkDetailsAPI, createLookDetails, updateLookDetails } from 'api/links/links'
import { isObjectEmpty, uploadFile } from 'utils/utils'
import { logger } from 'services/CloudLogger'
import { v4 as uuidV4 } from 'uuid'
import { GetInfluencer, GetInfluencers } from 'api/influencers/influencers'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import { addTimeToDate } from 'utils/dateUtils'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { extractCollectionData, extractLinkData, extractProductData } from 'utils/dataUtils'
import ContentDialogLoader from 'components/ContentDialogLoader/ContentDialogLoader'

/**
 * Represents the portion of the screen that displays the preview/edit/create section and the mobile simulator section.
 * @param {string} mode - The mode for this view. It controls what will be displayed. Can accept one the following: VIEW_MODES.PREVIEW, VIEW_MODES.EDIT & VIEW_MODES.CREATE
 */
export default function LinkView({
    mode,
    data
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        setRecentLinks,
        // isFreeUser,
        beginEditing,
        endEditing
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        setObjectToSave,
        setFinishedCreatingObject,
        setFinishedUpdatingObject
    } = useGeneralDataContext()
    const history = useHistoryContext()

    const [isLoadingLink, setIsLoadingLink] = useState(true)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { },
    })
    const [linkData, setLinkData] = useState({
        images: []
    })
    const [shouldAddUTMs, setShouldAddUTMs] = useState(false)
    const [utmParams, setUtmParams] = useState({
        UTMSource: '',
        UTMMedium: '',
        UTMCampaign: '',
        UTMTerm: '',
        UTMContent: ''
    })
    const [userSelectedProducts, setUserSelectedProducts] = useState(data?.linkData?.products ?? [])
    const [userSelectedCollections, setUserSelectedCollections] = useState([])
    const [promocodeState, setPromocodeState] = useState({
        code: '',
    })
    const [linkDescription, setLinkDescription] = useState('')
    const [shoppingUrl, setShoppingUrl] = useState('')
    const [isSavingLink, setIsSavingLink] = useState(false)
    const [linkPropertiesModified, setLinkPropertiesModified] = useState(hasLinkData())
    const [isAbleToSaveChanges, setIsAbleToSaveChanges] = useState(false)
    const [influencers, setInfluencers] = useState([])
    const [originalInfluencerUsername, setOriginalInfluencerUsername] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [selectedInfluencerUsername, setSelectedInfluencerUsername] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [selectedInfluencerImage, setSelectedInfluencerImage] = useState('')
    const [didUploadImages, setDidUploadImages] = useState(null)
    const [hasSavedInfluencer, setHasSavedInfluencer] = useState(false)
    const [isInfluencerChanged, setIsInfluencerChanged] = useState(false)

    useEffect(() => {
        loadLinkDetails()
    }, [])

    useEffect(() => {
        if (linkPropertiesModified) {
            beginEditing()
            addDiscardChangesDialogBackHandler()
        } else {
            endEditing()
        }
    }, [linkPropertiesModified])

    useEffect(() => {
        setIsAbleToSaveChanges(
            linkPropertiesModified &&
            userSelectedProducts && userSelectedProducts.length > 0
        )
    }, [linkPropertiesModified, userSelectedProducts, linkDescription, shoppingUrl, linkData.images, selectedInfluencerUsername])

    function addDiscardChangesDialogBackHandler() {
        setTimeout(() => {
            history.addBackHandler(() => {
                setDialogState({
                    isOpen: true,
                    handleDialogClose: closeDialog,
                    title: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_TITLE'),
                    message: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_MESSAGE'),
                    leftButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
                    leftButtonClickHandler: addDiscardChangesDialogBackHandler,
                    rightButtonClickHandler: () => {
                        endEditing()
                        history.goBack()
                    },
                    isRightButtonWithLoader: false
                })
            })
        }, 300)
    }

    function hasLinkData() {
        return !isObjectEmpty(data) && 'linkId' in data && Object.keys(data).length > 1 && mode !== VIEW_MODES.EDIT
    }

    function loadLinkDetails() {
        if (mode !== VIEW_MODES.CREATE) {
            if (data?.linkData && !isObjectEmpty(data?.linkData)) {
                populateLinkData(data?.linkData, true)
            } else {
                GetLinkDetailsAPI(data?.linkId)
                    .then(linkData => {
                        populateLinkData(linkData)
                    })
                    .catch(error => {
                        console.log(error)
                        history.push(LINKS_PAGE_URL)
                    })
            }
        } else if (mode === VIEW_MODES.CREATE) {
            if (data?.linkData && !isObjectEmpty(data?.linkData)) {
                populateLinkData(data?.linkData, true)
            }

            const utmParams = {
                UTMSource: userDetails?.settings?.utmParams?.UTMSource ?? '',
                UTMMedium: userDetails?.settings?.utmParams?.UTMMedium ?? '',
                UTMCampaign: userDetails?.settings?.utmParams?.UTMCampaign ?? '',
                UTMTerm: userDetails?.settings?.utmParams?.UTMTerm ?? '',
                UTMContent: userDetails?.settings?.utmParams?.UTMContent ?? '',
            }
            setUtmParams(utmParams)
            setShouldAddUTMs(hasUtmParams(utmParams))

            if (userDetails?.isAdmin) {
                GetInfluencers()
                    .then(response => {
                        if (data?.influencerUsername) {
                            setSelectedInfluencerUsername(data?.influencerUsername)
                        }
                        setInfluencers(response?.filter(influencer => influencer?.is_active)?.map(influencer => {
                            if (data?.influencerUsername === influencer?.username) {
                                setPromocodeState({
                                    code: influencer?.promocode ?? ''
                                })
                            }
                            return {
                                image: influencer?.profile_image_url ?? NO_IMAGE_PLACEHOLDER_URL,
                                username: influencer?.username ?? '',
                                isActive: influencer?.is_active ?? false,
                                promocode: influencer?.promocode ?? ''
                            }
                        }))
                    })
                    .finally(() => {
                        setIsLoadingLink(false)
                    })
            } else {
                setPromocodeState({
                    code: userDetails?.promocode ?? ''
                })
                setIsLoadingLink(false)
            }
        } else {
            setIsLoadingLink(false)
        }
    }

    function populateLinkData(linkData, fromProdefinedData = false) {
        if (mode === VIEW_MODES.CREATE && fromProdefinedData && ('linkId' in linkData && Object.keys(linkData).length > 1 || !('linkId' in linkData) && Object.keys(linkData).length > 0)) {
            setLinkPropertiesModified(true)
        }

        let imagesWithIds = []
        if (mode === VIEW_MODES.CREATE) {
            if (linkData?.images && linkData.images?.length > 0) {
                setImage(typeof linkData?.images[0] === 'string' ? linkData?.images[0] : linkData?.images[0]?.src)
            }
        } else {
            if (linkData?.images && linkData.images?.length > 0) {
                imagesWithIds = linkData.images.map(image => {
                    if (typeof image === 'string') {
                        return {
                            src: image,
                            id: uuidV4()
                        }
                    }
                    return image
                })
            }
        }
        const shoppingRedirectUrl = fromProdefinedData ? linkData?.shoppingRedirectUrl : linkData?.shopping_redirect_url
        const linkTitle = linkData?.description ?? "" // consider the description as the shorts's title
        const utmParams = {
            UTMSource: fromProdefinedData ? linkData?.utmParams?.UTMSource : (linkData?.utm_params?.utm_source ?? ''),
            UTMMedium: fromProdefinedData ? linkData?.utmParams?.UTMMedium : (linkData?.utm_params?.utm_medium ?? ''),
            UTMCampaign: fromProdefinedData ? linkData?.utmParams?.UTMCampaign : (linkData?.utm_params?.utm_campaign ?? ''),
            UTMTerm: fromProdefinedData ? linkData?.utmParams?.UTMTerm : (linkData?.utm_params?.utm_term ?? ''),
            UTMContent: fromProdefinedData ? linkData?.utmParams?.UTMContent : (linkData?.utm_params?.utm_content ?? ''),
        }
        const influencerUsername = fromProdefinedData ? linkData?.influencerUsername : linkData?.influencer_username
        const linkProductsSelectedOptions = fromProdefinedData ? linkData?.selectedOptions : linkData?.selected_options
        const linkProducts = (fromProdefinedData ? linkData?.products : linkData?.products?.map(product => {
            if (Object.keys(linkProductsSelectedOptions).includes(product?.inventory_id)) {
                return extractProductData(product, linkProductsSelectedOptions[product?.inventory_id])
            } else {
                return extractProductData(product)
            }
        })) ?? []
            
        setLinkData({
            linkId: fromProdefinedData ? linkData?.linkId : (linkData?.link_id ?? ''),
            images: imagesWithIds,
            description: linkData.description ?? '',
            currency: fromProdefinedData ? linkData?.currency : (linkData?.link_analytics?.currency ?? userDetails?.businessCurrency),
            analytics: {
                views: fromProdefinedData ? linkData?.analytics?.views : (linkData?.link_analytics?.visits ?? 0),
                checkouts: fromProdefinedData ? linkData?.analytics?.checkouts : (linkData?.link_analytics?.checkouts ?? 0),
                revenue: fromProdefinedData ? linkData?.analytics?.revenue : (linkData?.link_analytics?.revenue ?? 0),
                checkoutRequests: fromProdefinedData ? linkData?.analytics?.checkoutRequests : (linkData?.link_analytics?.requested_checkouts ?? 0)
            },
            promocode: fromProdefinedData ? linkData?.promocode?.code : (linkData?.promocode?.code ?? ''),
            products: linkProducts,
            title: linkTitle,
            shoppingRedirectUrl,
            influencerUsername,
            utmParams,
            selectedOptions: linkProductsSelectedOptions,
        })

        if (userDetails?.isAdmin && influencerUsername) {
            loadInfluencer(influencerUsername, linkData?.promocode?.code)
        } else if (userDetails?.isAdmin && !influencerUsername) {
            GetInfluencers()
                .then(response => {
                    setInfluencers(response?.filter(influencer => influencer?.is_active)?.map(influencer => ({
                        image: influencer?.profile_image_url ?? NO_IMAGE_PLACEHOLDER_URL,
                        username: influencer?.username ?? '',
                        isActive: influencer?.is_active ?? false,
                        promocode: influencer?.promocode ?? ''
                    })))
                })
                .finally(() => {
                    setIsLoadingLink(false)
                })
        }

        setUserSelectedProducts(linkProducts)
        setUserSelectedCollections((fromProdefinedData ? linkData?.collections : linkData?.collections?.map(collection => extractCollectionData(collection))) ?? [])
        setPromocodeState(prev => ({
            ...prev,
            code: linkData?.promocode?.code ?? ''
        }))
        setLinkDescription(linkTitle)
        setShoppingUrl(shoppingRedirectUrl)
        setUtmParams(utmParams)
        setShouldAddUTMs(hasUtmParams(utmParams))
        setIsLoadingLink(false)
    }

    function loadInfluencer(influencerUsername, predefinedPromocode) {
        GetInfluencer(influencerUsername)
            .then(response => {
                setOriginalInfluencerUsername(influencerUsername)
                setSelectedInfluencerUsername(influencerUsername)
                setSelectedInfluencerImage(response?.profile_image_url)
                setInfluencers([{
                    image: response?.profile_image_url,
                    username: response?.username ?? '',
                    isActive: response?.is_active ?? false,
                    promocode: response?.promocode ?? ''
                }])
                setPromocodeState({
                    code: predefinedPromocode ? predefinedPromocode : (response?.promocode?.code ?? '')
                })
            })
            .finally(() => {
                setIsLoadingLink(false)
            })
    }

    function hasUtmParams(utmParams) {
        return (
            (![null, undefined].includes(utmParams?.UTMSource) && utmParams?.UTMSource?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMMedium) && utmParams?.UTMMedium?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMCampaign) && utmParams?.UTMCampaign?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMTerm) && utmParams?.UTMTerm?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMContent) && utmParams?.UTMContent?.length > 0)
        )
    }

    function hasLinkImages() {
        return linkData?.images && linkData.images.length > 0
    }

    async function uploadImages(images) {
        new Promise(async (resolve, _) => {
            const imagesToSave = images?.map(image => image?.src)
            await Promise.all(imagesToSave.map(async (image, i) => {
                await uploadFile(image, null, url => {
                    imagesToSave[i] = url
                }, () => {
                    console.log('an error as occurred during file upload...')
                })
            }))

            setLinkData(prev => ({
                ...prev,
                images: imagesToSave?.map(url => ({
                    src: url,
                    id: uuidV4()
                }))
            }))

            setDidUploadImages(true)
            resolve()
        })
    }

    useEffect(() => { // triggered by clicking on save button or by uploading the images
        if (didUploadImages !== false && isSavingLink) {
            performSaveLink()
        }
    }, [didUploadImages, isSavingLink])

    function performSaveLink() {
        const productsIds = userSelectedProducts?.map(selectedProduct => selectedProduct.id)
        const selectedProductsOptions = userSelectedProducts?.reduce((acc, selectedProduct) => {
            if (Object.keys(selectedProduct?.selectedOptions).length > 0)
                return { ...acc, [selectedProduct.id]: selectedProduct?.selectedOptions}
            return acc
        }, {})
        const collectionsIds = userSelectedCollections?.map(selectedCollection => selectedCollection.id)
        const shopUrl = shoppingUrl ? shoppingUrl : `${t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_PREFIX')}${userDetails?.domain}`

        if (!linkData?.images.some(image => image?.src?.startsWith('blob'))) {
            const promocode = {
                code: promocodeState.code === '' ? null : promocodeState.code
            }

            const imagesToSave = linkData?.images?.filter(image => ![null, undefined, ''].includes(image?.src)).map(image => image?.src)

            if (mode === VIEW_MODES.CREATE) {

                createLookDetails(userDetails.businessId, imagesToSave, productsIds, selectedProductsOptions, collectionsIds, promocode, linkDescription, shopUrl, selectedInfluencerUsername, utmParams, LINK_TYPES.LOOK)
                    .then(newLink => {
                        setObjectToSave(extractLinkData(newLink))
                        setLinkPropertiesModified(false)
                        history.goBackStepsNoPerform()
                        setTimeout(() => {
                            history.goBack()
                        }, SEPERATE_GO_BACK_FOR_SAFARY_DELAY)
                        setTimeout(() => {
                            setRecentLinks([])
                            if (window.location.pathname !== LINKS_PAGE_URL && window.location.pathname !== INFLUENCERS_PAGE_URL) {
                                history.push(LINKS_PAGE_URL)
                            } else {
                                setFinishedCreatingObject(true)
                                setTimeout(() => {
                                    setFinishedCreatingObject(false)
                                }, 200)
                            }
                        }, CREATE_LINK_REDIRECT_TO_PAGE_DELAY)
                    }).catch((error) => {
                        logger.error(`Error - edit link failure - ${error}`)
                        showSaveDetailsErrorMessage()
                        setIsSavingLink(false)
                    })
            } else {
                updateLookDetails(userDetails.businessId, linkData.linkId, imagesToSave, productsIds, selectedProductsOptions, collectionsIds, promocode, linkDescription, shopUrl, selectedInfluencerUsername, utmParams, LINK_TYPES.LOOK)
                    .then(updatedLink => {
                        setObjectToSave(extractLinkData(updatedLink))
                        setIsSavingLink(false)
                        showApprovalMessage(t('EDIT_LINK_SECTION_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
                        setLinkPropertiesModified(false)
                        history.goBackStepsNoPerform()
                        if (isInfluencerChanged) {
                            setHasSavedInfluencer(true)
                        }
                        setFinishedUpdatingObject(true)
                        setTimeout(() => {
                            setFinishedUpdatingObject(false)
                        }, 200)
                    }).catch((error) => {
                        logger.error(`Error - edit link failure - ${error}`)
                        showSaveDetailsErrorMessage()
                        setIsSavingLink(false)
                    })
            }
        } else {
            setDialogState({
                isOpen: true,
                handleDialogClose: closeDialog,
                title: t('UPLOAD_FILE_ERROR_DIALOG_TITLE'),
                leftButtonText: t('UPLOAD_FILE_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
                rightButtonText: null,
                rightButtonClickHandler: () => { },
                isRightButtonWithLoader: false
            })
            setIsSavingLink(false)
        }
    }

    async function save() {
        setIsSavingLink(true) // only changes the state of the loader of the save button in order to trigger the useEffect that's listening to this state
    }

    function showSaveDetailsErrorMessage() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_TITLE'),
            leftButtonText: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonText: null,
            rightButtonClickHandler: () => { },
            isRightButtonWithLoader: false
        })
    }

    function setLinkUTMSource(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setLinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMSource: UTMValue
            }))
        }
    }

    function setLinkUTMMedium(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setLinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMMedium: UTMValue
            }))
        }
    }

    function setLinkUTMCampaign(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setLinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMCampaign: UTMValue
            }))
        }
    }

    function setLinkUTMTerm(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setLinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMTerm: UTMValue
            }))
        }
    }

    function setLinkUTMContent(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setLinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMContent: UTMValue
            }))
        }
    }

    function setSelectedLinkInfluencerUsername(username) {
        setLinkPropertiesModified(true)
        setSelectedInfluencerUsername(username)
    }

    function setSelectedProducts(products) {
        setLinkPropertiesModified(true)
        setUserSelectedProducts(products)
    }

    function setSelectedCollections(collections) {
        setLinkPropertiesModified(true)
        setUserSelectedCollections(collections)
    }

    function setDescription(linkDescription) {
        setLinkPropertiesModified(true)
        setLinkDescription(linkDescription)
    }

    function setPromocode(promocode) {
        setLinkPropertiesModified(true)
        setPromocodeState(prev => ({
            ...prev,
            code: promocode
        }))
    }

    function setImage(imageFile) {
        setDidUploadImages(false)
        setLinkPropertiesModified(true)
        setLinkData(prev => {
            const imagesToSave = [{
                // Determins here whether the imageFile is a blob image url or an image file object
                src: typeof imageFile === 'string' && imageFile.startsWith('blob') ? imageFile : URL.createObjectURL(imageFile),
                id: uuidV4()
            }]
            uploadImages(imagesToSave)

            return ({
                ...prev,
                images: imagesToSave
            })
        })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function resetUTMParams() {
        setUtmParams({
            UTMSource: '',
            UTMMedium: '',
            UTMCampaign: '',
            UTMTerm: '',
            UTMContent: ''
        })
        setLinkPropertiesModified(true)
    }

    function setShouldAddUTMParams(value) {
        if (!value) {
            resetUTMParams()
        }

        setShouldAddUTMs(value)
    }

    function renderContent() {
        return <EditLinkSection
            image={linkData?.images[0]?.src}
            setImage={setImage}
            selectedProducts={userSelectedProducts}
            setSelectedProducts={setSelectedProducts}
            selectedCollections={userSelectedCollections}
            setSelectedCollections={setSelectedCollections}
            description={linkDescription}
            setDescription={setDescription}
            promocode={promocodeState.code}
            setPromocode={setPromocode}
            linkPropertiesModified={linkPropertiesModified}
            influencers={influencers}
            originalInfluencerUsername={originalInfluencerUsername}
            selectedInfluencerUsername={selectedInfluencerUsername}
            setSelectedInfluencerUsername={setSelectedLinkInfluencerUsername}
            mode={mode}
            shouldAddUTMs={shouldAddUTMs}
            setShouldAddUTMs={setShouldAddUTMParams}
            utmParams={utmParams}
            setUTMSource={setLinkUTMSource}
            setUTMMedium={setLinkUTMMedium}
            setUTMCampaign={setLinkUTMCampaign}
            setUTMTerm={setLinkUTMTerm}
            setUTMContent={setLinkUTMContent}
            hasSavedInfluencer={hasSavedInfluencer}
            setIsInfluencerChanged={setIsInfluencerChanged}
        />
    }

    function getTopbarCenterTitle() {
        switch (mode) {
            case VIEW_MODES.EDIT: return t('LINK_VIEW_TOPBAR_CENTER_TITLE_EDIT_LINK')
            case VIEW_MODES.CREATE: return t('LINK_VIEW_TOPBAR_CENTER_TITLE_CREATE_LINK')
            default: return t('LINK_VIEW_TOPBAR_CENTER_TITLE_PREVIEW_LINK')
        }
    }

    function renderTopbarSaveButton() {
        switch (mode) {
            case VIEW_MODES.CREATE: return t('LINK_VIEW_TOPBAR_CREATE_BUTTON_TEXT')
            case VIEW_MODES.EDIT: return t('LINK_VIEW_TOPBAR_SAVE_BUTTON_TEXT')
            default: return ''
        }
    }

    return (
        <>
            {isLoadingLink && <ContentDialogLoader />}
            <div className={isMobile ? "mobile-link-view-container" : "link-view-container"}>
                <ContentDialogTopbar
                    title={getTopbarCenterTitle()}
                    onBackButtonClick={() => history.goBack()}
                    rightElement={<>
                        <LoaderButton
                            className='page-view-topbar-save-button'
                            buttonText={renderTopbarSaveButton()}
                            isLoading={isSavingLink}
                            loaderCircleColor='#0095f6'
                            isDisabled={!isAbleToSaveChanges || isSavingLink}
                            onClick={save}
                        />
                    </>}
                />
                <div className={isMobile ? "mobile-link-view-content" : "link-view-content"}>
                    <div className={isMobile ? "mobile-link-view-details-and-simulator" : "link-view-details-and-simulator"}>
                        <div className={`${isMobile ? "mobile-link-view-details-container" : "link-view-details-container"}`}>
                            {
                                isMobile ? renderContent() : <div className="link-view-details-content">{renderContent()}</div>
                            }
                        </div>
                        {
                            !isMobile && <div className="link-view-preview-container">
                                <MobileLinkPreviewSimulator
                                    mainImageUrl={hasLinkImages() ? linkData?.images[0]?.src : ''}
                                    products={userSelectedProducts}
                                />
                            </div>
                        }
                    </div>
                </div>
                <PaydinDialog
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    title={dialogState.title}
                    message={dialogState.message}
                    closeOnRightClick={true}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    isLeftButtonWithLoader={false}
                    isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    onLeftButtonClick={dialogState.leftButtonClickHandler}
                />
            </div>
        </>
    )
}