import React, { useEffect, useRef, useState } from 'react'
import './RegisterStorePage.css'
import { Trans, useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import { EMAIL_PATTERN_REGEX } from 'constants/regex.constants'
import {
    BOXED_MESSAGE_TYPES,
    INPUT_VALIDATION_INDICATIONS,
    USERNAME_FIELD_PREFIX,
    SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS,
    NOTIFY_STORE_CONNECT_FINISH_MESSAGE,
} from 'constants/general.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useAuthContext } from 'contexts/User'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { containsWhitespace, formatUsername } from 'utils/utils'
import PrivaciesFooterText from 'components/PrivaciesFooterText/PrivaciesFooterText'
import { ValidateUsername } from 'api/auth/auth'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import AlreadyHaveAccount from 'components/AlreadyHaveAccount/AlreadyHaveAccount'
import { registerAndConnectStore, verifyStore } from 'api/store/store'
import { getQueryVariable } from 'services/SystemService'
import { AUTH_QUERY_PARAMETER_KEY, LOGIN_STORE_PAGE_URL, PAYLINK_SITE_URL } from 'constants/routes.constants'
import { LocalSharedPreferences } from 'services/SharedPreferences'
import { AUTH_TOKEN_KEY_LOCAL_STORAGE } from 'constants/storage.constants'
import LoaderPage from 'pages/LoaderPage/LoaderPage'

export default function RegisterStorePage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { verify } = useAuthContext()

    const [isSignUpInProcess, setIsSignUpInProcess] = useState(false)
    const [isVerifyingStore, setIsVerifyingStore] = useState(true)
    const [isStoreVerified, setIsStoreVerified] = useState(false)
    const [inputState, setInputState] = useState({
        username: '',
        email: '',
        password: ''
    })
    const [boxedMessageState, setBoxedMessageState] = useState({
        isOpen: false,
        message: '',
        type: BOXED_MESSAGE_TYPES.ERROR
    })
    const [usernameValidationState, setUsernameValidationState] = useState({
        isValidating: false,
        validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
    })
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [validationErrorState, setValidationErrorState] = useState({
        username: '',
        email: ''
    })
    const [auth, setAuth] = useState('')

    const typingTimeoutRef = useRef(null)

    useEffect(() => {
        const authParam = getQueryVariable(AUTH_QUERY_PARAMETER_KEY, "")
        if (!authParam) {
            navigateToPaylinkshopSite()
            return
        }

        setAuth(authParam)
        setIsVerifyingStore(true)
        verifyStore(authParam)
            .then(response => {
                setEmail(response?.data?.email ?? '')
                setIsVerifyingStore(false)
                setIsStoreVerified(true)
            })
            .catch(error => {
                console.log(error)
                setIsVerifyingStore(false)
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('REGISTER_STORE_PAGE_VERIFY_STORE_FAILED_DIALOG_TITLE'),
                    message: t('REGISTER_STORE_PAGE_VERIFY_STORE_FAILED_DIALOG_MESSAGE'),
                    leftButtonText: t('REGISTER_STORE_PAGE_VERIFY_STORE_FAILED_DIALOG_RIGHT_BUTTON_TEXT'),
                    onLeftButtonClick: navigateToPaylinkshopSite
                }))
            })
    }, [])

    function navigateToPaylinkshopSite() {
        window.location.href = PAYLINK_SITE_URL
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function setUsername(username) {
        clearTypingTimeout(typingTimeoutRef.current)

        setInputState(prev => {
            return {
                ...prev,
                username: formatUsername(username),
            }
        })

        if (username.length === 0) {
            setUsernameValidationState(prev => ({
                ...prev,
                validationResult: INPUT_VALIDATION_INDICATIONS.NO_INDICATION
            }))
            return
        }

        typingTimeoutRef.current = setTimeout(() => {
            validateUsername(username)
        }, SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS)
    }

    function setEmail(email) {
        setInputState(prev => {
            return {
                ...prev,
                email: email.replace(/\s/g, ''),
            }
        })
    }

    function setPassword(password) {
        setInputState(prev => {
            return {
                ...prev,
                password,
            }
        })
    }

    function handleSignUp() {
        setBoxedMessageState(prev => ({
            ...prev,
            isOpen: false
        }))
        setIsSignUpInProcess(true)
        registerAndConnectStore(auth, inputState.username, inputState.email, inputState.password)
            .then(response => {
                LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, response?.data?.token)
                setBoxedMessageState({
                    isOpen: true,
                    message: t('REGISTER_STORE_PAGE_REGISTER_SUCCESS_MESSAGE_TEXT'),
                    type: BOXED_MESSAGE_TYPES.INFO
                })
                if (window?.opener) {
                    window.opener.postMessage(NOTIFY_STORE_CONNECT_FINISH_MESSAGE, '*')
                }
                navigateToPaylinkshopSite()
            })
            .catch(_ => {
                setIsSignUpInProcess(false)
                setBoxedMessageState({
                    isOpen: true,
                    message: t('REGISTER_STORE_PAGE_REGISTER_ERROR_MESSAGE_TEXT'),
                    type: BOXED_MESSAGE_TYPES.ERROR
                })
            })
    }

    function isUsernameValid() {
        return !containsWhitespace(inputState.username)
    }

    function isEmailValid(email) {
        return EMAIL_PATTERN_REGEX.test(email)
    }

    function isAbleToSignUp() {
        return (
            usernameValidationState.validationResult === INPUT_VALIDATION_INDICATIONS.VALID &&
            isEmailValid(inputState.email) &&
            isPasswordValid
        )
    }

    function validateUsername(username) {
        if (!isUsernameValid(username)) {
            setUsernameValidationState({
                isValidating: false,
                validationResult: INPUT_VALIDATION_INDICATIONS.INVALID
            })
            setValidationErrorState(prev => ({
                ...prev,
                username: t('REGISTER_STORE_PAGE_INCORRECT_USERNAME_FORMAT_VALIDATION_ERROR_MESSAGE')
            }))
        } else {
            setUsernameValidationState(prev => ({
                ...prev,
                isValidating: true
            }))
            ValidateUsername(username)
                .then(_ => {
                    setUsernameValidationState({
                        isValidating: false,
                        validationResult: INPUT_VALIDATION_INDICATIONS.VALID
                    })
                })
                .catch(error => {
                    setUsernameValidationState({
                        isValidating: false,
                        validationResult: INPUT_VALIDATION_INDICATIONS.INVALID
                    })
                    setValidationErrorState(prev => ({
                        username: <Trans
                            i18nKey='REGISTER_STORE_PAGE_DUPLICATE_USERNAME_VALIDATION_ERROR_MESSAGE'
                            components={{
                                p: <div onClick={navigate} className='register-store-page-username-validation-login-button' />
                            }}
                        />
                    }))
                })
        }
    }

    function navigate() {
        history.push(getLoginStoreNavigationUrl())
    }

    function getLoginStoreNavigationUrl() {
        return `${LOGIN_STORE_PAGE_URL}?${AUTH_QUERY_PARAMETER_KEY}=${auth}`
    }

    return (
        <div className='register-store-page-container'>
            {isVerifyingStore && <LoaderPage isFullScreen={true} />}
            {isStoreVerified &&
                <form dir={t('direction.dir')} className="register-store-page-content">
                    <div className="register-store-page-title auth-page-title">{t('REGISTER_STORE_PAGE_TITLE')}</div>
                    {
                        boxedMessageState.isOpen && <BoxedMessage
                            message={boxedMessageState.message}
                            type={boxedMessageState.type}
                        />
                    }
                    <div className="register-store-page-fields-form">
                        <EditFieldSectionInput
                            title={t('REGISTER_STORE_EMAIL_INPUT_PLACEHOLDER')}
                            value={inputState.email}
                            setValue={setEmail}
                            enlargedTextField={true}
                            isDisabled={true}
                            id="email"
                        />
                        <EditFieldSectionInput
                            title={t('REGISTER_STORE_USERNAME_INPUT_TITLE')}
                            placeholder={t('REGISTER_STORE_USERNAME_INPUT_PLACEHOLDER')}
                            prefix={USERNAME_FIELD_PREFIX}
                            isEmbeddedPrefix={true}
                            value={inputState.username}
                            setValue={setUsername}
                            hasValidation={true}
                            isValidating={usernameValidationState.isValidating}
                            validationStatus={usernameValidationState.validationResult}
                            validationErrorMessage={validationErrorState.username}
                            enlargedTextField={true}
                            id="username"
                        />
                        <PasswordInput
                            title={t('REGISTER_STORE_PASSWORD_INPUT_PLACEHOLDER')}
                            value={inputState.password}
                            setValue={setPassword}
                            setValidationStatus={setIsPasswordValid}
                        />
                    </div>
                    <LoaderButton
                        className="register-store-page-register-button"
                        buttonText={t('REGISTER_STORE_PAGE_SIGN_UP_BUTTON_TEXT')}
                        isLoading={isSignUpInProcess}
                        renderAsButton={true}
                        isDisabled={!isAbleToSignUp()}
                        onClick={handleSignUp}
                    />
                    <AlreadyHaveAccount navigationUrl={getLoginStoreNavigationUrl()} />
                    <PrivaciesFooterText />
                </form>
            }
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                leftButtonText={dialogState.leftButtonText}
                onLeftButtonClick={dialogState.onLeftButtonClick}
                isLeftButtonWithLoader={false}
                closeOnLeftClick={false}
                isCloseOnTouchOutside={false}
            />
        </div>
    )
}