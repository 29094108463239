import React, { useEffect, useState } from 'react'
import './MultilinkView.css'
import {
    INPUT_VALIDATION_INDICATIONS,
    VIEW_MODES,
    CREATE_LINK_REDIRECT_TO_PAGE_DELAY,
    SEPERATE_GO_BACK_FOR_SAFARY_DELAY,
    MULTILINK_SUBLINK_SELECTION_TYPE,
    MULTILINK_SUBLINK_TYPES,
} from 'constants/general.constants'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { useHistoryContext } from 'contexts/History'
import { CreateMultilinkDetailsAPI, GetMultilinkDetailsAPI, UpdateMultilinkDetailsAPI } from 'api/links/links'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import { MULTILINKS_PAGE_URL } from 'constants/routes.constants'
import { logger } from 'services/CloudLogger'
import { isInputValueValid } from 'services/SystemService'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { extractCollectionData, extractMultilinkPreviewData, extractProductData } from 'utils/dataUtils'
import ContentDialogLoader from 'components/ContentDialogLoader/ContentDialogLoader'
import { isObjectEmpty } from 'utils/utils'
import EditMultilinkSection from 'components/EditMultilinkSection/EditMultilinkSection'

/**
 * epresents a portion of the screen that displays the multilink's edit/create panel.
 * @param {string} mode - The mode of this view, it determins whether it is creating or editing a multilink, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 */
export default function MultilinkView({
    mode,
    data
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        // isFreeUser,
        beginEditing,
        endEditing
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        setObjectToSave,
        setFinishedCreatingObject,
        setFinishedUpdatingObject
    } = useGeneralDataContext()

    const [isLoadingMultilink, setIsLoadingMultilink] = useState(true)
    const [isButtonProcessing, setIsButtonProcessing] = useState(false)
    const [isAbleToSaveMultilink, setIsAbleToSaveMultilink] = useState(false)
    const [multilinkPropertiesModified, setMultilinkPropertiesModified] = useState(false)
    const [title, setTitle] = useState('')
    const [urls, setUrls] = useState(['', ''])
    const [splittingValues, setSplittingValues] = useState([50,50])
    const [deviceOptions, setDeviceOptions] = useState(['ALL','ALL'])
    const [promocode, setPromocode] = useState('')
    const [shouldAddUTMs, setShouldAddUTMs] = useState(false)
    const [utmParams, setUtmParams] = useState({
        UTMSource: '',
        UTMMedium: '',
        UTMCampaign: '',
        UTMTerm: '',
        UTMContent: ''
    })
    const [urlsInputValidationState, setUrlsInputValidationState] = useState([{
        status: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        isValidating: false
    }, {
        status: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        isValidating: false
    }])
    const [multilinkData, setMultilinkData] = useState(data?.multilinkData)


    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { }
    })
    const [urlSelectionTypes, setUrlSelectionTypes] = useState(['',''])
    const [selectedProducts, setSelectedProducts] = useState([[],[]])
    const [selectedCollections, setSelectedCollections] = useState([[],[]])

    useEffect(() => {
        loadMultilinkDetails()
    }, [])

    useEffect(() => {
        if (multilinkPropertiesModified) {
            beginEditing()
            addDiscardChangesDialogBackHandler()
        } else {
            endEditing()
        }
    }, [multilinkPropertiesModified])

    useEffect(() => {
        setIsAbleToSaveMultilink(
            multilinkPropertiesModified && title &&
            urls.every((url, index) => (
                isCustomUrlTypeSelected(index) && 
                urlsInputValidationState[index].status === INPUT_VALIDATION_INDICATIONS.VALID) || 
                isProductUrlTypeSelected(index) || 
                isCollectionUrlTypeSelected(index) || 
                isInstantStoreTypeSelected(index)
            )
        )
    }, [multilinkPropertiesModified, urlsInputValidationState, urlSelectionTypes, title])

    function populateMultilinkData(multilinkData) {
        const utmParams = {
            UTMSource: multilinkData?.utmParams?.UTMSource ?? (multilinkData?.linksOptions && multilinkData?.linksOptions?.length > 0 ? multilinkData?.linksOptions[0]?.linkData?.utmParams?.UTMSource : (multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_source ?? '') : '')),
            UTMMedium: multilinkData?.utmParams?.UTMMedium ?? (multilinkData?.linksOptions && multilinkData?.linksOptions?.length > 0 ? multilinkData?.linksOptions[0]?.linkData?.utmParams?.UTMMedium : (multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_medium ?? '') : '')),
            UTMCampaign: multilinkData?.utmParams?.UTMCampaign ?? (multilinkData?.linksOptions && multilinkData?.linksOptions?.length > 0 ? multilinkData?.linksOptions[0]?.linkData?.utmParams?.UTMCampaign : (multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_campaign ?? '') : '')),
            UTMTerm: multilinkData?.utmParams?.UTMTerm ?? (multilinkData?.linksOptions && multilinkData?.linksOptions?.length > 0 ? multilinkData?.linksOptions[0]?.linkData?.utmParams?.UTMTerm : (multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_term ?? '') : '')),
            UTMContent: multilinkData?.utmParams?.UTMContent ?? (multilinkData?.linksOptions && multilinkData?.linksOptions?.length > 0 ? multilinkData?.linksOptions[0]?.linkData?.utmParams?.UTMContent : (multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_content ?? '') : '')),
        }
        const promocode = (multilinkData?.promocode?.code ?? multilinkData?.promocode) ?? (multilinkData?.linksOptions && multilinkData?.linksOptions?.length > 0 ? (multilinkData?.linksOptions[0]?.linkData?.promocode?.code ?? (multilinkData?.linksOptions[0]?.linkData?.promocode ?? '')) : (multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.promocode?.code ?? (multilinkData?.links_options[0]?.link_data?.promocode ?? '')) : ''))

        const linksOptions = (multilinkData?.linksOptions ?? (multilinkData?.links_options ?? [])).map((linkOption, index) => {
            const linkProductsSelectedOptions = linkOption?.linkData?.selectedOptions ?? linkOption?.link_data?.selected_options
            const linkProducts = (linkOption?.linkData?.products ?? linkOption?.link_data?.products?.map(product => {
                if (Object.keys(linkProductsSelectedOptions).includes(product?.inventory_id)) {
                    return extractProductData(product, linkProductsSelectedOptions[product?.inventory_id])
                } else {
                    return extractProductData(product)
                }
            })) ?? []
            return {
                linkData: {
                    shoppingRedirectUrl: linkOption?.linkData?.shoppingRedirectUrl ?? (linkOption?.link_data?.shopping_redirect_url ?? ''),
                    description: linkOption.linkData?.description ?? (linkOption.link_data?.description ?? ''),
                    views: linkOption?.linkData?.views ?? (linkOption?.link_data?.visits ?? 0),
                    checkouts: linkOption?.linkData?.checkouts ?? (linkOption?.link_data?.checkouts ?? 0),
                    revenue: linkOption?.linkData?.revenue ?? (linkOption?.link_data?.revenue ?? 0),
                    linkType: linkOption?.linkData?.linkType ?? (linkOption?.link_data?.link_type ??  ''),
                    urlType: linkOption?.linkData?.urlType ?? (linkOption?.link_data?.url_type ??  ''),
                    linkDefaultImage: linkOption?.linkData?.linkDefaultImage ?? (linkOption?.link_data?.link_default_image ??  ''),
                    products: linkProducts,
                    collections: (linkOption?.linkData?.collections ?? (linkOption?.link_data?.collections ?? [])).map(collection => extractCollectionData(collection)),
                    selectedOptions: linkProductsSelectedOptions,
                },
                value: linkOption?.value ?? 0,
                linkId: linkOption?.linkData?.linkId ?? (linkOption?.link_data?.link_id ?? ''),
                deviceOption: linkOption.deviceOption ?? (linkOption?.device_option ?? 'ALL'),
            }
        })

        setMultilinkData({
            multilinkId: multilinkData?.multilinkId ?? (multilinkData?.multilink_id ?? ''),
            title: multilinkData.title ?? '',
            currency: multilinkData?.currency ?? (multilinkData?.link_analytics?.currency ?? userDetails?.businessCurrency),
            promocode: promocode,
            title: multilinkData?.title ?? "",
            linksOptions,
            utmParams
        })

        
        setUrlSelectionTypes(getUrlSelectionTypes(linksOptions))
        setSelectedProducts(linksOptions?.map(linkOption => linkOption?.linkData?.products))
        setSelectedCollections(linksOptions?.map(linkOption => linkOption?.linkData?.collections))
        setPromocode(promocode)
        setTitle(multilinkData?.title ?? '')
        setUrls(linksOptions?.map(linkOption => linkOption?.linkData?.shoppingRedirectUrl))
        setDeviceOptions(linksOptions?.map(linkOption => linkOption?.deviceOption))
        setSplittingValues(linksOptions?.map(linkOption => linkOption?.value))
        setUtmParams(utmParams)
        setShouldAddUTMs(hasUtmParams(utmParams))
        setIsLoadingMultilink(false)
    }

    function loadMultilinkDetails() {
        if (mode !== VIEW_MODES.CREATE) {
            if (data?.multilinkData && !isObjectEmpty(data?.multilinkData)) {
                populateMultilinkData(data?.multilinkData)
            }
            GetMultilinkDetailsAPI(data?.multilinkId)
                .then(multilinkData => {
                    populateMultilinkData(multilinkData)
                })
                .catch(error => {
                    console.log(error)
                    history.push(MULTILINKS_PAGE_URL)
                })
        } else {
            const utmParams = {
                UTMSource: userDetails?.settings?.utmParams?.UTMSource ?? '',
                UTMMedium: userDetails?.settings?.utmParams?.UTMMedium ?? '',
                UTMCampaign: userDetails?.settings?.utmParams?.UTMCampaign ?? '',
                UTMTerm: userDetails?.settings?.utmParams?.UTMTerm ?? '',
                UTMContent: userDetails?.settings?.utmParams?.UTMContent ?? '',
            }
            setUtmParams(utmParams)
            setShouldAddUTMs(hasUtmParams(utmParams))
            setIsLoadingMultilink(false)
        }
    }

    function getUrlSelectionTypes(linksOptions) {
        return linksOptions?.map(linkOption => {
            if (linkOption?.linkData?.linkType == MULTILINK_SUBLINK_TYPES.SHORT) {
                if (linkOption?.linkData?.products && linkOption?.linkData?.products?.length > 0) {
                    return MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT
                }
        
                if (linkOption?.linkData?.collections && linkOption?.linkData?.collections?.length > 0) {
                    return MULTILINK_SUBLINK_SELECTION_TYPE.COLLECTION
                }
        
                return MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM
            }
            return MULTILINK_SUBLINK_SELECTION_TYPE.LOOK
        })
    }

    function hasUtmParams(utmParams) {
        return (
            (![null, undefined].includes(utmParams?.UTMSource) && utmParams?.UTMSource?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMMedium) && utmParams?.UTMMedium?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMCampaign) && utmParams?.UTMCampaign?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMTerm) && utmParams?.UTMTerm?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMContent) && utmParams?.UTMContent?.length > 0)
        )
    }

    function getTitle() {
        switch (mode) {
            case VIEW_MODES.CREATE: return t('MULTILINKS_VIEW_CREATE_NEW_MULTILINKS_TITLE')
            case VIEW_MODES.EDIT: return t('MULTILINKS_VIEW_EDIT_MULTILINKS_TITLE')
            default: return t('MULTILINKS_VIEW_PREVIEW_MULTILINKS_TITLE')
        }
    }
    function getLinkImage(index) {
        if (isProductUrlTypeSelected(index)) {
            return selectedProducts[index][0]?.imageUrl
        }

        if (isCollectionUrlTypeSelected(index)) {
            return selectedCollections[index][0]?.images?.medium?.src
        }

        return null
    }

    function setMultilinkTitle(title) {
        setMultilinkPropertiesModified(true)
        setTitle(title)
    }

    function setMultilinkPromocode(promocode) {
        setMultilinkPropertiesModified(true)
        setPromocode(promocode)
    }

    function setMultilinkURL(index, url) {
        setMultilinkPropertiesModified(true)
        setUrls(prev => prev.map((currentUrl, urlIndex) => index === urlIndex ? url : currentUrl))
    }

    function setUrlInputValidationState(newStateIndex, urlInputValidationState) {
        setUrlsInputValidationState(prev => prev.map((currentInputValidationState, index) => index === newStateIndex ? urlInputValidationState : currentInputValidationState))
    }

    function setUrlSelectionType(index, newType) {
        setUrlSelectionTypes(prev => prev.map((currentUrlType, urlIndex) => index === urlIndex ? newType : currentUrlType))
    }

    function setMultilinkLinkSelectedProducts(index, products) {
        setSelectedProducts(prev => prev.map((currentProducts, linkIndex) => linkIndex == index ? products : currentProducts))
    }


    function setMultilinkLinkSelectedCollections(index, collections) {
        setSelectedCollections(prev => prev.map((currentCollections, linkIndex) => linkIndex == index ? collections : currentCollections))
    }


    function setMultilinkDeviceOptions(index, deviceOption) {
        setMultilinkPropertiesModified(true)
        setDeviceOptions(prev => prev.map((currentDeviceOption, linkIndex) => linkIndex == index ? deviceOption : currentDeviceOption))
    }


    function setMultilinkSplittingValue(index, value) {
        setMultilinkPropertiesModified(true)
        setSplittingValues(prev => {
            let newValues = prev.map((currentValue, linkIndex) => linkIndex == index ? value : currentValue)
            const valuesSum = newValues.reduce((accumulator, currentValue) => accumulator + currentValue ,0)
            if (valuesSum !== 100) {
                let delta = 100 - valuesSum
                newValues = newValues?.map((newValue, valueIndex) => {
                    if (valueIndex != index) {
                        let oldValue = newValue
                        newValue = Math.max(0, Math.min(100, newValue + delta))
                        delta = delta - newValue + oldValue
                    }
                    return newValue
                })
            }
            return newValues
        })
    }

    function setMultilinkUTMSource(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setMultilinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMSource: UTMValue
            }))
        }
    }

    function setMultilinkUTMMedium(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setMultilinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMMedium: UTMValue
            }))
        }
    }

    function setMultilinkUTMCampaign(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setMultilinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMCampaign: UTMValue
            }))
        }
    }

    function setMultilinkUTMTerm(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setMultilinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMTerm: UTMValue
            }))
        }
    }

    function setMultilinkUTMContent(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setMultilinkPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMContent: UTMValue
            }))
        }
    }

    function prepareMultilinkLinkUrl(url) {
        if (!url) {
            url = `${t('EDIT_LINK_SECTION_SHOPPING_URL_INPUT_PREFIX')}${userDetails?.domain}`
            return url
        }
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            return 'https://' + url
        }

        return url
    }

    async function save() {
        setIsButtonProcessing(true)
        const promocodeToSave = {
            code: promocode
        }
        // const urlToSave = prepareMultilinkUrl(url)
        const linksOptions = urls?.map((url, index) => {
            const selectedProductsOptions = selectedProducts[index]?.reduce((acc, selectedProduct) => {
                if (Object.keys(selectedProduct?.selectedOptions).length > 0)
                    return { ...acc, [selectedProduct.id]: selectedProduct?.selectedOptions}
                return acc
            }, {})
            return {
                value: splittingValues[index],
                linkId: multilinkData?.linksOptions[index]?.linkId,
                linkData: {
                    images: getLinkImage(index) ? [getLinkImage(index)] : [],
                    inventoryIds: selectedProducts[index]?.map(product => product?.id),
                    collectionIds: selectedCollections[index]?.map(collection => collection?.id),
                    shoppingRedirectUrl: prepareMultilinkLinkUrl(url),
                    linkType: getLinkType(index),
                    selectedOptions: selectedProductsOptions,
                },
                deviceOption: deviceOptions[index]
            }
        })

        if (mode === VIEW_MODES.CREATE) {
            CreateMultilinkDetailsAPI(userDetails.businessId, title, "PERCENTAGE", promocodeToSave, utmParams, linksOptions)
                .then(newMultilink => {
                    setObjectToSave(extractMultilinkPreviewData(newMultilink))
                    setMultilinkPropertiesModified(false)
                    history.goBackStepsNoPerform()
                    setTimeout(() => {
                        history.goBack()
                    }, SEPERATE_GO_BACK_FOR_SAFARY_DELAY)
                    setTimeout(() => {
                        if (window.location.pathname !== MULTILINKS_PAGE_URL) {
                            history.replace(MULTILINKS_PAGE_URL)
                        } else {
                            setFinishedCreatingObject(true)
                            setTimeout(() => {
                                setFinishedCreatingObject(false)
                            }, 200)
                        }
                    }, CREATE_LINK_REDIRECT_TO_PAGE_DELAY)
                }).catch((error) => {
                    logger.error(`Error - edit multilink failure - ${error}`)
                    showSaveDetailsErrorMessage()
                    setIsButtonProcessing(false)
                })
        } else {
            UpdateMultilinkDetailsAPI(userDetails.businessId, data?.multilinkId, title, "PERCENTAGE", promocodeToSave, utmParams, linksOptions)
                .then(updatedMultilink => {
                    setObjectToSave(extractMultilinkPreviewData(updatedMultilink))
                    setIsButtonProcessing(false)
                    showApprovalMessage(t('EDIT_MULTILINK_SECTION_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
                    setMultilinkPropertiesModified(false)
                    history.goBackStepsNoPerform()
                    setFinishedUpdatingObject(true)
                    setTimeout(() => {
                        setFinishedUpdatingObject(false)
                    }, 200)
                }).catch((error) => {
                    logger.error(`Error - edit multilink failure - ${error}`)
                    showSaveDetailsErrorMessage()
                    setIsButtonProcessing(false)
                })
        }
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showSaveDetailsErrorMessage() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_TITLE'),
            leftButtonText: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonText: null,
            rightButtonClickHandler: () => { },
            isRightButtonWithLoader: false
        }))
    }

    function addDiscardChangesDialogBackHandler() {
        setTimeout(() => {
            history.addBackHandler(() => {
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_TITLE'),
                    message: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_MESSAGE'),
                    leftButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
                    leftButtonClickHandler: addDiscardChangesDialogBackHandler,
                    rightButtonClickHandler: () => {
                        endEditing()
                        history.goBack()
                    },
                    isRightButtonWithLoader: false
                }))
            })
        }, 300)
    }

    function goBack() {
        history.goBack()
    }

    function renderTopbarSaveButton() {
        switch (mode) {
            case VIEW_MODES.CREATE: return t('MULTILINKS_VIEW_TOPBAR_CREATE_BUTTON_TEXT')
            case VIEW_MODES.EDIT: return t('MULTILINKS_VIEW_TOPBAR_SAVE_BUTTON_TEXT')
            default: return ''
        }
    }

    function resetUTMParams() {
        setUtmParams({
            UTMSource: '',
            UTMMedium: '',
            UTMCampaign: '',
            UTMTerm: '',
            UTMContent: ''
        })
        setMultilinkPropertiesModified(true)
    }

    function setShouldAddUTMParams(value) {
        if (!value) {
            resetUTMParams()
        }

        setShouldAddUTMs(value)
    }

    function isProductUrlTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT
    }

    function isCollectionUrlTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.COLLECTION
    }

    function isCustomUrlTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM
    }

    function isInstantStoreTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.LOOK
    }

    function getLinkType(index) {
        if (isProductUrlTypeSelected(index) || isCollectionUrlTypeSelected(index) || isCustomUrlTypeSelected(index)) {
            return MULTILINK_SUBLINK_TYPES.SHORT
        } else {
            return MULTILINK_SUBLINK_TYPES.LOOK
        }
    }

    function renderContent() {
        return <EditMultilinkSection
            mode={mode}
            title={title}
            setTitle={setMultilinkTitle}
            urls={urls}
            setURL={setMultilinkURL}
            promocode={promocode}
            setPromocode={setMultilinkPromocode}
            shouldAddUTMs={shouldAddUTMs}
            setShouldAddUTMs={setShouldAddUTMParams}
            utmParams={utmParams}
            setUTMSource={setMultilinkUTMSource}
            setUTMMedium={setMultilinkUTMMedium}
            setUTMCampaign={setMultilinkUTMCampaign}
            setUTMTerm={setMultilinkUTMTerm}
            setUTMContent={setMultilinkUTMContent}
            urlsInputValidationState={urlsInputValidationState}
            setUrlInputValidationState={setUrlInputValidationState}
            urlSelectionTypes={urlSelectionTypes}
            setUrlSelectionType={setUrlSelectionType}
            selectedProducts={selectedProducts}
            setSelectedProducts={setMultilinkLinkSelectedProducts}
            selectedCollections={selectedCollections}
            setSelectedCollections={setMultilinkLinkSelectedCollections}
            multilinkPropertiesModified={multilinkPropertiesModified}
            splittingValues={splittingValues}
            setSplittingValue={setMultilinkSplittingValue}
            deviceOptions={deviceOptions}
            setDeviceOptions={setMultilinkDeviceOptions}
        />
    }

    return (
        <div className={isMobile ? 'mobile-multilink-view-container' : 'multilink-view-container'}>
            <ContentDialogTopbar
                title={getTitle()}
                onBackButtonClick={goBack}
                rightElement={
                    <LoaderButton
                        className='page-view-topbar-save-button'
                        isDisabled={!isAbleToSaveMultilink || isButtonProcessing}
                        buttonText={renderTopbarSaveButton()}
                        isLoading={isButtonProcessing}
                        loaderCircleColor='#0095f6'
                        onClick={save}
                    />
                }
            />
            <div className={isMobile ? "mobile-multilink-view-content" : "multilink-view-content"} style={{ height: isMobile ? `100dvh` : `calc(var(--content-dialog-height) - var(--content-dialog-topbar-height))` }}>
                {isLoadingMultilink && <ContentDialogLoader />}
                {renderContent()}
            </div>
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={true}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                onLeftButtonClick={dialogState.leftButtonClickHandler}
            />
        </div>
    )
}