import React from 'react'

export default function InstantStoresImage(props) {
    return (
            
            <svg 
                {...props}
                height="512" 
                viewBox="0 0 24 24" 
                width="512"
            >
                <path d="m15 8h-6c-.2 0-.5.2-.5.4l-1 7c0 .1 0 .3.1.4s.3.2.4.2h8c.1 0 .3-.1.4-.2s.1-.3.1-.4l-1-7c0-.2-.3-.4-.5-.4zm-6.4 7 .9-6h5.1l.9 6z"/><path d="m16.5 2h-9c-1.4 0-2.5 1.1-2.5 2.5v15c0 1.4 1.1 2.5 2.5 2.5h9c1.4 0 2.5-1.1 2.5-2.5v-15c0-1.4-1.1-2.5-2.5-2.5zm-1.5 1c-.3.3-.7.5-1.1.5h-3.9c-.4 0-.8-.2-1-.5zm-9 1.5c0-.8.7-1.5 1.5-1.5h.2l.3.4c.5.7 1.2 1.1 2.1 1.1h3.9c.8 0 1.6-.4 2.1-1.1l.2-.4h.2c.8 0 1.5.7 1.5 1.5v15h-12zm10.5 16.5h-9c-.4 0-.8-.2-1.1-.5h11.2c-.3.3-.7.5-1.1.5z"/><path d="m13.5 9.5c-.3 0-.5.2-.5.5v1c0 .6-.4 1-1 1s-1-.4-1-1v-1c0-.3-.2-.5-.5-.5s-.5.2-.5.5v1c0 1.1.9 2 2 2s2-.9 2-2v-1c0-.3-.2-.5-.5-.5z"/>
            </svg>
    )
}