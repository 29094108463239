export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const COMMIT_LOGS_API_URL = '/api/logs/commit'
export const AUTH_API_URL = '/auth/verify'
export const REGISTER_CONNECT_STORE_API_ROUTE = '/auth/register/connect'
export const LOGIN_CONNECT_STORE_API_ROUTE = '/auth/login/connect'
export const LOGIN_API_URL = '/auth/login'
export const REGISTER_API_URL = '/auth/register'
export const PASSWORD_RESET_REQUEST_API_ROUTE = '/password/reset/request'
export const PASSWORD_RESET_REQUEST_API_URL = '/auth' + PASSWORD_RESET_REQUEST_API_ROUTE
export const PASSWORD_RESET_API_URL = '/auth/password/reset'
export const USER_RESET_API_URL = '/auth/user/password/reset/request'
export const UPDATE_USER_INFO_API_URL = '/auth/update/info'
export const LINKS_API_ROUTE = '/admin/v1/links'
export const MULTILINKS_API_ROUTE = '/admin/v1/links/multi'
export const EDIT_LINK_API_URL_SUFFIX = 'edit'
export const DELETE_LINK_API_URL_SUFFIX = 'close'
export const CREATE_LINK_API_URL_SUFFIX = 'create'
export const UPLOAD_FILE_API_URL = '/admin/v1/file/upload'
export const PRODUCT_SEARCH_API_URL = '/admin/v1/products/search'
export const PRODUCT_OPTIONS_IMAGE_API_URL = '/admin/v1/products/options/image'
export const PRODUCT_TOP_PERFORMING_API_URL = '/admin/v1/products/trends/top-performing'
export const PRODUCT_TOP_COMBINATIONS_API_URL = '/admin/v1/products/trends/top-combinations'
export const PRODUCT_COMPLEMENTARY_API_URL = '/admin/v1/products/trends/complementary'
export const AUTH_STORE_CONNECT_API_URL_PREFIX = '/admin/v1/store'
export const AUTH_STORE_CONNECT_API_URL = '/auth/connect'
export const STORE_CONNECT_API_URL = '/connect'
export const STORE_DISCONNECT_API_URL = '/disconnect'
export const BUSINESS_SETTINGS_API_URL = '/admin/v1/businesses/settings'
export const UPGRADE_PRICING_PLAN_API_URL = AUTH_STORE_CONNECT_API_URL_PREFIX + '/plan/upgrade/request'
export const CANCEL_PRICING_PLAN_API_URL = AUTH_STORE_CONNECT_API_URL_PREFIX + '/plan/cancel'
export const BUSINESS_ANALYTICS_API_URL_PREFIX = LINKS_API_ROUTE + '/analytics/business'
export const LINK_ANALYTICS_API_URL_PREFIX = LINKS_API_ROUTE + '/analytics'
export const OVERVIEW_API_URL_SUFFIX = '/overview'
export const VALIDATE_USERNAME_API_URL = '/auth/validate/username'
export const VALIDATE_EMAIL_API_URL = '/auth/validate/email'
export const STORE_SETTINGS_API_URL_PREFIX = AUTH_STORE_CONNECT_API_URL_PREFIX
export const STORE_SETTINGS_API_URL_SUFFIX = '/settings'
export const STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX = '/appearance'
export const STORE_ORDERS_API_URL_SUFFIX = '/orders'
export const STORE_ORDER_DETAILS_API_URL_SUFFIX = '/details'
export const APPEARANCE_SETTINGS_OPTIONS_API_URL_SUFFIX = '/options'
export const STORE_SETTINGS_PRODUCT_SYNC_API_URL_SUFFIX = '/sync'
export const STORE_SETTINGS_THEME_UPDATE_API_URL_SUFFIX = '/theme'
export const ARCHIVE_LINKS_API_URL_PREFIX = LINKS_API_ROUTE
export const ARCHIVE_LINKS_API_URL_SUFFIX = '/archive'
export const ACCOUNT_VERIFICATION_API_URL = '/auth/account/verify'
export const REGISTRATION_REQUEST_API_URL = '/auth/register/request'
export const INSIGHTS_REQUEST_API_URL = '/admin/v1/insights'

export const COLLECTIONS_API_BASE_URL = '/admin/v1/collections'
export const INFLUENCERS_API_BASE_URL = '/admin/v1/influencers'
export const SHOPBOARD_API_BASE_URL = '/admin/v1/shopboard'
export const ANALYTICS_API_SUFFIX = '/analytics'
export const VISIBLE_API_SUFFIX = '/visible'
export const TAG_PRODUCTS_API_SUFFIX = '/tag_products'
export const POSTS_API_SUFFIX = '/posts'
export const CONNECT_API_SUFFIX = '/connect'
export const DISCONNECT_API_SUFFIX = '/disconnect'
export const INFLUENCERS_ACTIVATE_INFLUENCER_API_SUFFIX = '/activate'
export const INFLUENCERS_CREATE_INFLUENCER_API_SUFFIX = '/create'
export const INFLUENCERS_EDIT_INFLUENCER_API_SUFFIX = '/edit'
export const INFLUENCERS_LOGIN_API_LOGIN_ROUTE = '/login'
export const INFLUENCERS_VALIDATE_INFLUENCER_USERNAME_API_SUFFIX = '/validate/username'
export const INFLUENCERS_PASSWORD_RESET_API_ROUTE = '/password/reset'
export const AUTH_API_ROUTE = '/auth'

export const SEARCH_ROUTE = '/search'
export const STORE_API_BASE_URL = '/admin/v1/store'