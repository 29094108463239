import {
	ACCOUNT_PAGE_MAX_WIDTH,
	ANALYTICS_PAGE_MAX_WIDTH,
	CREATE_INFLUENCER_PAGE_MAX_WIDTH,
	CREATE_NEW_PASSWORD_PAGE_MAX_WIDTH,
	LINK_EDIT_PREVIEW_PAGE_MAX_WIDTH,
	LINK_PAGES_MAX_WIDTH,
	PADDED_PAGES,
	PAGE_DEFAULT_MAX_WIDTH,
	PREVIEW_SHORT_PAGE_MAX_WIDTH,
	PRICING_PLANS_PAGE_MAX_WIDTH,
	SHOPIFY_HELP_PAGE_MAX_WIDTH,
	TRENDS_PAGE_MAX_WIDTH,
	UNLIMITED_WIDTH_PAGES
} from "constants/general.constants"
import {
	ACCOUNT_PAGE_URL,
	ADMIN_PAGES_URL_PREFIX,
	ANALYTICS_PAGE_URL,
	CREATE_NEW_PASSWORD_PAGE_URL,
	EDIT_URL_SUFFIX,
	INFLUENCERS_PAGE_URL,
	INFLUENCERS_PAGE_URL_PREFIX,
	LINKS_PAGE_URL,
	LINKS_URL_PREFIX,
	PREVIEW_INFLUECNER_PAGE_SUFFIX,
	PREVIEW_LINK_PAGE_SUFFIX,
	PRICING_PLANS_PAGE_URL,
	SHOPIFY_HELP_PAGE_URL,
	SHORTS_PAGE_URL,
	SHORTS_URL_PREFIX,
	APPEARANCE_PAGE_URL,
	HOME_PAGE_URL,
	MAGIC_STORE_PAGE_URL,
	SHOPBOARD_PAGE_URL,
	MULTILINKS_PAGE_URL,
	TRENDS_PAGE_URL,
} from 'constants/routes.constants'
import React, { useEffect } from "react"
import './GeneralLayout.css'
import { useGeneralDataContext } from "contexts/User"
import { isMobile } from "react-device-detect"

export default function GeneralLayout({ children }) {
	const { setGeneralData } = useGeneralDataContext()

	useEffect(() => {
		window.scrollTo(0, 0)
		setGeneralData(prev => ({
			...prev,
			pageMaxWidthPx: getMaxWidth()
		}))
	}, [])

	const baseLayoutStyles = {
		...(isLimitedPage() ? { maxWidth: `${getMaxWidth()}px` } : {}),
		...getPaddingProperty()
	}

	function getPaddingProperty() {
		if (isMobile) {
			if (PADDED_PAGES.includes(window.location.pathname)) {
				return { padding: '0 20px 20px 20px' }
			} else {
				return { padding: '0' }
			}
		} else {
			if (window.location.pathname === MAGIC_STORE_PAGE_URL) {
				return { padding: 0 }
			}
			if ([LINKS_PAGE_URL, SHORTS_PAGE_URL, MULTILINKS_PAGE_URL, SHOPBOARD_PAGE_URL, TRENDS_PAGE_URL].includes(window.location.pathname)) {
				return { padding: '0px 20px 0px 20px' }
			}
			return { padding: '40px 20px 20px 20px' }
		}
	}

	function isLimitedPage() {
		return !UNLIMITED_WIDTH_PAGES.includes(window.location.pathname)
	}

	function getMaxWidth() {
		if ((window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX))
			&& (window.location.pathname.endsWith(PREVIEW_LINK_PAGE_SUFFIX) || window.location.pathname.endsWith(EDIT_URL_SUFFIX))) {
			return LINK_EDIT_PREVIEW_PAGE_MAX_WIDTH
		}

		if ((window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + INFLUENCERS_PAGE_URL_PREFIX) && window.location.pathname.endsWith(PREVIEW_INFLUECNER_PAGE_SUFFIX))) {
			return LINK_PAGES_MAX_WIDTH
		}

		if ((window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + INFLUENCERS_PAGE_URL_PREFIX) && window.location.pathname.endsWith(EDIT_URL_SUFFIX))) {
			return CREATE_INFLUENCER_PAGE_MAX_WIDTH
		}

		if (window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX) && window.location.pathname.endsWith(PREVIEW_LINK_PAGE_SUFFIX)) {
			return PREVIEW_SHORT_PAGE_MAX_WIDTH
		}

		if (window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX) && window.location.pathname.endsWith(EDIT_URL_SUFFIX)) {
			return CREATE_INFLUENCER_PAGE_MAX_WIDTH
		}

		switch (window.location.pathname) {
			case ACCOUNT_PAGE_URL:
			case APPEARANCE_PAGE_URL:
				return ACCOUNT_PAGE_MAX_WIDTH

			case LINKS_PAGE_URL:
			case MULTILINKS_PAGE_URL:
			case INFLUENCERS_PAGE_URL:
			case SHORTS_PAGE_URL:
			case HOME_PAGE_URL:
			case SHOPBOARD_PAGE_URL:
				return LINK_PAGES_MAX_WIDTH
				
			case TRENDS_PAGE_URL:
				return TRENDS_PAGE_MAX_WIDTH
				
			case PRICING_PLANS_PAGE_URL:
				return PRICING_PLANS_PAGE_MAX_WIDTH

			case SHOPIFY_HELP_PAGE_URL:
				return SHOPIFY_HELP_PAGE_MAX_WIDTH

			case CREATE_NEW_PASSWORD_PAGE_URL:
				return CREATE_NEW_PASSWORD_PAGE_MAX_WIDTH

			case ANALYTICS_PAGE_URL:
				return ANALYTICS_PAGE_MAX_WIDTH

			default: return PAGE_DEFAULT_MAX_WIDTH
		}
	}

	return (
		<div className="general-layout-container">
			<div className="general-layout-content-container" style={baseLayoutStyles}>
				{children}
			</div>
		</div>
	)
}