import React, { useState } from 'react'
import './AccountSection.css'
import Card from 'components/Card/Card'
import { isMobile } from 'react-device-detect'
import PaydinSwitch from 'components/PaydinSwitch/PaydinSwitch'

/**
 * Represents an account page section.
 * @param {DOMElement} buttons - The node element for the buttons section 
 * @param {string} title - The title for this section 
 * @param {string} id - The id for this section 
 * @param {string} sectionContentClassName - A class name for this section's content 
 * @param {boolean} hasTitle - Determins whether this section has a title or not
 * @param {boolean} hasToggleSwitch - Determins whether this section has a switch for this section or not
 * @param {boolean} shouldControlContent - Determins whether this section's switch controls the content's appearance or not. If false, then isContentShown is ignored
 * @param {boolean} isContentShown - Determins whether this section's content is shown or not
 * @param {function} setIsContentShown - A function for setting the section's content's appearance
 * @param {Node} rightTopCornerContent - An optional element for display in the right top corner of this section
 */
export default function AccountSection({
    children,
    title = '',
    id = '',
    sectionContentClassName = '',
    hasToggleSwitch = false,
    shouldControlContent = true,
    isContentShown = true,
    setIsContentShown = () => { },
    rightTopCornerContent = <></>
}) {
    function renderTitleRow() {
        return <div className="account-page-section-title-container">
            {title && <div className="account-page-section-title">{title}</div>}
            <div className="account-page-section-right-top-corner-content">
                {rightTopCornerContent}
                {renderSwitch()}
            </div>
        </div>
    }

    function renderSwitch() {
        return hasToggleSwitch && <PaydinSwitch
            checked={isContentShown}
            setChecked={setIsContentShown}
        />
    }

    return (
        isMobile ? <div className='mobile-account-page-section' id={id}>
            {renderTitleRow()}
            <div className={`account-page-section-content ${sectionContentClassName}`}>
                {children}
            </div>
        </div> : <Card>
            <div className='account-page-section' id={id}>
                {renderTitleRow()}
                {
                    !shouldControlContent ? <div className={`account-page-section-content ${sectionContentClassName}`}>
                        {children}
                    </div> : isContentShown && <div className={`account-page-section-content ${sectionContentClassName}`}>
                        {children}
                    </div>
                }
            </div>
        </Card>
    )
}