import React, { useState } from 'react'
import './LinkOrdersTableRowView.css'
import { isMobile } from 'react-device-detect'
import { TableCell, TableRow, tableCellClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getFormattedDateTime } from 'utils/dateUtils'
import { getCurrencySymbol } from 'services/currency'
import { formatNumber } from 'utils/utils'
import { NUMBER_TYPES, ORDERS_TABLE_PRODUCT_LIST_GAP, ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX, ORDERS_TABLE_ROW_HEIGHT, ORDERS_TABLE_STATUS_OPTIONS } from 'constants/general.constants'
import { RiShareBoxLine } from 'react-icons/ri'
import { styled } from '@mui/system'
import ColorfulTag from 'components/ColorfulTag/ColorfulTag'
import { getLinkOrder } from 'api/links/links'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import Loader from 'components/Loader/Loader'
import { IoIosArrowDown } from 'react-icons/io'
import { extractOrderProduct } from 'utils/dataUtils'
import OrderProduct from 'components/OrderProduct/OrderProduct'

/**
 * Represents a row view for the orders table
 * @param {object} rowData - The data object of a specific row
 */
export default function LinkOrdersTableRowView({
    rowData
}) {
    const { t } = useTranslation()

    const [isExpanded, setIsExpanded] = useState(false)
    const [isLoadingMoreDetails, setIsLoadingMoreDetails] = useState(true)
    const [orderProducts, setOrderProducts] = useState([])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide all borders
        '&:not(:last-child) td, & th': {
            border: 0,
        }
    }))

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            textAlign: 'center',
            padding: '0'
        },
    }))

    const productLineHeight = (ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX * rowData?.totalProductsCount) + (ORDERS_TABLE_PRODUCT_LIST_GAP * (rowData?.totalProductsCount - 1)) + 20
    const product3LineHeight = (ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX * 3) + (ORDERS_TABLE_PRODUCT_LIST_GAP * (rowData?.totalProductsCount - 1)) + 20

    const OpenInStoreLink = ({ redirectUrl }) => {
        return redirectUrl ? <a href={redirectUrl} target="_blank" className={isMobile ? 'mobile-orders-table-row-view-actions' : 'orders-table-row-view orders-table-row-view-actions'}>
            {!isMobile && <div className={isMobile ? "mobile-orders-table-row-view-view-in-shopify-action-text" : "orders-table-row-view-view-in-shopify-action-text"}>{redirectUrl ? t('ORDERS_TABLE_ROW_ACTION_TEXT') : ''}</div>}
            <RiShareBoxLine className={isMobile ? 'mobile-orders-table-row-view-view-in-shopify-action-image' : 'orders-table-row-view-view-in-shopify-action-image'} />
        </a> : <div className={isMobile ? 'mobile-orders-table-row-view-actions' : 'orders-table-row-view orders-table-row-view-actions'}>
            {!isMobile && <div className={isMobile ? "mobile-orders-table-row-view-view-in-shopify-action-text" : "orders-table-row-view-view-in-shopify-action-text"}>{redirectUrl ? t('ORDERS_TABLE_ROW_ACTION_TEXT') : ''}</div>}
            <div className={isMobile ? 'mobile-orders-table-row-view-view-in-shopify-action-image' : 'orders-table-row-view-view-in-shopify-action-image'} />
        </div>
    }

    const actionsCellStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '19px'
    }

    const orderProductsListStyle = {
        ...(rowData?.totalProductsCount > 3 ? { 
            height: `${product3LineHeight}px`,
            overflowY: 'scroll'
        } : {
            height: `${productLineHeight}px`,
        }),
    }

    const orderProductsListLoaderStyle = {
        height: rowData?.totalProductsCount > 3 ? `${product3LineHeight}px` : `${productLineHeight}px`
    }

    function onExpandOrder() {
        setIsExpanded(prev => !prev)
        if (orderProducts.length === 0) {
            getLinkOrder(rowData?.linkId, rowData?.id)
                .then(orderProducts => {
                    setOrderProducts(orderProducts?.map(orderProduct => extractOrderProduct(orderProduct)))
                })
                .catch(console.log)
                .finally(() => {
                    setIsLoadingMoreDetails(false)
                })
        }
    }

    function getStatusBackgroundColor(status) {
        switch (status) {
            case ORDERS_TABLE_STATUS_OPTIONS.PAID: return '#00af50'
            case ORDERS_TABLE_STATUS_OPTIONS.REFUNDED: return '#f4b184'
            // PARTIALLY REFUNDED
            default: return '#ffd95e'
        }
    }

    function getStatusTextColor(status) {
        switch (status) {
            case ORDERS_TABLE_STATUS_OPTIONS.PAID: return 'white'
            case ORDERS_TABLE_STATUS_OPTIONS.REFUNDED: return '#432704'
            // PARTIALLY REFUNDED
            default: return '#987c1a'
        }
    }

    function renderRowContent() {
        return isMobile ? <StyledTableCell padding="normal" align={'left'}>
            <div className='mobile-orders-table-row-view'>
                {rowData?.allowOrderDetails && <IoIosArrowDown className={`orders-table-row-view-expand-button-image ${isExpanded ? 'expanded' : ''}`} onClick={onExpandOrder} />}
                <div className="mobile-orders-table-row-view-content">
                    <div className="mobile-orders-table-row-view-details">
                        <div className="mobile-orders-table-row-view-created-at">{getFormattedDateTime(new Date(rowData?.createdAt))}</div>
                        <div className="mobile-orders-table-row-view-total-price-container">
                            <div className="mobile-orders-table-row-view-total-price">{`${getCurrencySymbol(rowData?.currency)}${formatNumber(rowData?.totalPrice, NUMBER_TYPES.PRICE)}`}</div>
                            {rowData?.actions && <OpenInStoreLink redirectUrl={rowData?.actions} />}
                        </div>
                    </div>
                    <div className="mobile-orders-table-row-view-products-count-container">
                        <div className="mobile-orders-table-row-view-total-products-count">{t('ORDERS_TABLE_ROW_TOTAL_PRODUCTS_COUNT', { count: rowData?.totalProductsCount })}</div>
                        <ColorfulTag
                            text={rowData?.status}
                            textColor={getStatusTextColor(rowData?.status)}
                            backgroundColor={getStatusBackgroundColor(rowData?.status)}
                            isSmall={true}
                            textSize='10px'
                        />
                    </div>
                </div>
            </div>
        </StyledTableCell> : <>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="orders-table-row-view orders-table-row-view-date-container">
                    {rowData?.allowOrderDetails && <IoIosArrowDown className={`orders-table-row-view-expand-button-image ${isExpanded ? 'expanded' : ''}`} onClick={onExpandOrder} />}
                    <div className="orders-table-row-view-date">{getFormattedDateTime(new Date(rowData?.createdAt))}</div>
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='orders-table-row-view orders-table-row-view-items'>{t('ORDERS_TABLE_ROW_TOTAL_PRODUCTS_COUNT', { count: rowData?.totalProductsCount })}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='orders-table-row-view orders-table-row-view-total'>{`${getCurrencySymbol(rowData?.currency)}${formatNumber(rowData?.totalPrice, NUMBER_TYPES.PRICE)}`}</div>
            </StyledTableCell>
            <StyledTableCell padding="none" align='right'>
                <div className="orders-table-row-view orders-table-row-view-status-container">
                    <div className='orders-table-row-view-status' style={{ backgroundColor: getStatusBackgroundColor(rowData?.status), color: getStatusTextColor(rowData?.status) }}>{rowData?.status}</div>
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right' sx={actionsCellStyle}>
                <OpenInStoreLink redirectUrl={rowData?.actions} />
            </StyledTableCell>
        </>
    }

    return (
        <>
            {
                isMobile ? <StyledTableRow style={{ height: `${ORDERS_TABLE_ROW_HEIGHT}px` }}>
                    {renderRowContent()}
                </StyledTableRow> : <>
                    <StyledTableRow style={{ height: `${ORDERS_TABLE_ROW_HEIGHT}px` }}>
                        {renderRowContent()}
                    </StyledTableRow>
                </>
            }
            <TableRow sx={{ '& > *': { borderBottom: !isMobile && 'unset' } }}>
                <TableCell style={{ padding: 0 }} colSpan={isMobile ? 1 : 6}>
                    <CollapsingElement
                        expanded={isExpanded}
                    >
                        {
                            isLoadingMoreDetails ? <div className="orders-table-row-view-more-details-loader-container" style={orderProductsListLoaderStyle}>
                                <Loader styles={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    inset: 0,
                                    margin: 'auto'
                                }} />
                            </div> : <div className={isMobile ? "mobile-orders-table-row-view-more-details" : "orders-table-row-view-more-details"} style={orderProductsListStyle}>
                                {
                                    orderProducts?.map(orderProduct => <OrderProduct
                                        key={orderProduct?.title + '__' + orderProduct?.variantTitle}
                                        title={orderProduct?.title}
                                        imageUrl={orderProduct?.imageUrl}
                                        variantTitle={orderProduct?.variantTitle}
                                        price={orderProduct?.price}
                                        quantity={orderProduct?.quantity}
                                        currency={rowData?.currency}
                                    />)
                                }
                            </div>
                        }
                    </CollapsingElement>
                </TableCell>
            </TableRow>
        </>
    )
}