import React, { useEffect, useRef, useState } from 'react'
import './LinksPage.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import {
    HOME_PAGE_URL
} from 'constants/routes.constants'
import {
    useFetchLinksContext,
    useGeneralDataContext,
    useIsUserLoadedContext,
    useUserDetailsContext,
} from 'contexts/User'
import { useTranslation } from 'react-i18next'
import {
    DEFAULT_CURRENCY_CODE,
    EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS,
    LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT,
    LINKS_PAGE_LINKS_FILTER_COUNT,
    LINK_ITEM_HEIGHT_PX,
    LINK_PAGES_MAX_WIDTH,
    LINK_TYPES,
    PAGE_DEFAULT_MAX_WIDTH,
    SHOPEAKS_SMALL_LOGO_URL
} from 'constants/general.constants'
import ShopifyConnect from 'components/ShopifyConnect/ShopifyConnect'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import LinkItem from 'components/LinkItem/LinkItem'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'
import { GetBusinessAnalytics } from 'api/links/links'
import AnalyticsStrip from 'components/AnalyticsStrip/AnalyticsStrip'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'
import Loader from 'components/Loader/Loader'
import { getCurrencySymbol } from 'services/currency'
import { extractExtendedInfluencerAnalytics, extractLinkData } from 'utils/dataUtils'
import AddLInkFlow from 'components/AddLInkFlow/AddLInkFlow'

/**
 * Represents the user's links page.
 */
export default function LinksPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        setUserDetails,
        setRecentLinks,
        isFreeUserAndTrialEnded
    } = useUserDetailsContext()
    const {
        finishedUpdatingObject,
        finishedCreatingObject,
        objectToSave,
        openFreeTrialDialog
    } = useGeneralDataContext()
    const { fetchLinks } = useFetchLinksContext()
    const isUserLoaded = useIsUserLoadedContext()

    const [isLoadingLinks, setIsLoadingLinks] = useState(true)
    const [isLoadingMoreLinks, setIsLoadingMoreLinks] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [skip, setSkip] = useState(0)
    const [defaultLinks, setDefaultLinks] = useState([])
    const [linkSearchResult, setLinkSearchResult] = useState([])
    const [hasMoreLinks, setHasMoreLinks] = useState(true)
    const [shouldShowNoLinks, setShouldShowNoLinks] = useState(false)
    const [lifetimeAnalyticsState, setLifetimeAnalyticsState] = useState({
        isLoading: true,
        clicks: 0,
        sales: 0,
        checkouts: 0,
        revenue: 0,
        totalTax: 0
    })
    const [isFirstRender, setIsFirstRender] = useState(true) // used to control the appearance of the loader only in the first render
    const [addFlowTrigger, setAddFlowTrigger] = useState(false)

    const typingTimeoutRef = useRef(null)
    const topbarRef = useRef(null)
    const linksListRef = useRef(null)
    const analyticsStripRef = useRef(null)

    useEffect(() => {
        if (hasShopifyStore()) {
            if (!isMobile) {
                topbarRef.current.style.width = `${linksListRef?.current?.clientWidth}px`
            }

            if (isUserLoaded) {
                // when the splash is no longer shown, load the links again to refresh the links collection
                fetchFirstLinks()
            }

            if (userDetails?.analytics?.linksLifetimeAnalytics) {
                setLifetimeAnalyticsState({
                    isLoading: false,
                    clicks: userDetails?.analytics?.linksLifetimeAnalytics?.clicks,
                    sales: userDetails?.analytics?.linksLifetimeAnalytics?.sales,
                    checkouts: userDetails?.analytics?.linksLifetimeAnalytics?.checkouts,
                    revenue: userDetails?.analytics?.linksLifetimeAnalytics?.revenue,
                    totalTax: userDetails?.analytics?.linksLifetimeAnalytics?.totalTax,
                    data: userDetails?.analytics?.linksLifetimeAnalytics?.data,
                    currency: getCurrencySymbol(userDetails?.analytics?.currency)
                })
            } else {
                GetBusinessAnalytics(userDetails?.businessId)
                    .then(response => {
                        const linksClicks = response?.looks_analytics?.visits ?? 0
                        const linksSales = response?.looks_analytics?.checkouts ?? 0
                        const linksCheckouts = response?.looks_analytics?.requested_checkouts ?? 0
                        const linksTotalTax = response?.looks_analytics?.total_tax ?? 0
                        const linksRevenue = response?.looks_analytics?.revenue ?? 0
                        const linksData = response?.looks_analytics?.data ?? []

                        const shortsClicks = response?.shorts_analytics?.visits ?? 0
                        const shortsSales = response?.shorts_analytics?.checkouts ?? 0
                        const shortsCheckouts = response?.shorts_analytics?.requested_checkouts ?? 0
                        const shortsRevenue = response?.shorts_analytics?.revenue ?? 0
                        const shortsTotalTax = response?.shorts_analytics?.total_tax ?? 0
                        const shortsData = response?.shorts_analytics?.data ?? []

                        const currency = response?.currency ?? DEFAULT_CURRENCY_CODE
                        setLifetimeAnalyticsState({
                            isLoading: false,
                            clicks: linksClicks,
                            sales: linksSales,
                            checkouts: linksCheckouts,
                            revenue: linksRevenue,
                            totalTax: linksTotalTax,
                            currency: getCurrencySymbol(currency)
                        })
                        setUserDetails(prev => ({
                            ...prev,
                            analytics: {
                                ...prev.analytics,
                                currency: currency,
                                linksLifetimeAnalytics: {
                                    clicks: linksClicks,
                                    sales: linksSales,
                                    checkouts: linksCheckouts,
                                    revenue: linksRevenue,
                                    totalTax: linksTotalTax,
                                    data: linksData
                                },
                                shortsLifetimeAnalytics: {
                                    clicks: shortsClicks,
                                    sales: shortsSales,
                                    checkouts: shortsCheckouts,
                                    revenue: shortsRevenue,
                                    totalTax: shortsTotalTax,
                                    data: shortsData
                                },
                                influencerInsights: extractExtendedInfluencerAnalytics(response?.summary_insights ?? {})
                            }
                        }))
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        } else {
            history.replace(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        setShouldShowNoLinks(false)
        setLinks([], true, true)
        setIsLoadingLinks(true)
        setHasMoreLinks(true)
        clearTypingTimeout(typingTimeoutRef.current)
        if (searchQuery) {
            typingTimeoutRef.current = setTimeout(() => {
                fetchLinks(searchQuery, 0, false, LINK_TYPES.LOOK, linksData => {
                    setShouldShowNoLinks(true)
                    setLinks(linksData?.links, true, true)
                    setHasMoreLinks(linksData?.links.length === LINKS_PAGE_LINKS_FILTER_COUNT)
                    setIsLoadingLinks(false)
                })
            }, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS)
        } else {
            setLinkSearchResult(defaultLinks)
            if (!isFirstRender) { // On the first render, the next line will be executed unless we check for first render, because in this case 'searchQuery' is falsy
                setIsLoadingLinks(false)
            }
        }
        setIsFirstRender(false)

        return () => {
            clearTypingTimeout(typingTimeoutRef.current)
        }
    }, [searchQuery])

    useEffect(() => {
        if (finishedUpdatingObject) {
            updateLink(objectToSave)
        } else if (finishedCreatingObject) {
            addNewLink(objectToSave)
        }
    }, [finishedUpdatingObject, finishedCreatingObject])

    function fetchFirstLinks() {
        setIsLoadingLinks(true)
        fetchLinks(searchQuery, 0, true, LINK_TYPES.LOOK, linksData => {
            setShouldShowNoLinks(true)
            setDefaultLinkList(linksData?.links)
            setLinks(linksData?.links, false, true)
            setHasMoreLinks(linksData?.links.length === LINKS_PAGE_LINKS_FILTER_COUNT)
            setIsLoadingLinks(false)
        })
    }

    function setDefaultLinkList(links) {
        if (!links) {
            return
        }

        setDefaultLinks([...(links.map(link => extractLinkData(link)))])
    }

    function setLinks(links, shouldResetSkip = false, shouldOverrideLinks = false) {
        if (!links) {
            return
        }

        if (shouldOverrideLinks) {
            setLinkSearchResult([...(links.map(link => extractLinkData(link)))])
        } else {
            setLinkSearchResult(prev => ([
                ...prev,
                ...(links.map(link => extractLinkData(link)))
            ]))
        }
        if (shouldResetSkip)
            setSkip(0)
        else
            setSkip(prev => prev + links.length)
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function clearSearchQuery() {
        setSearchQuery('')
    }

    function handleChange(e) {
        setSearchQuery(e.target.value)
    }

    function onAddLinkButtonClick() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            setAddFlowTrigger(true)
        }
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    function hasNoLinks() {
        return !defaultLinks || defaultLinks.length === 0
    }

    function hasNoLinksResults() {
        return !linkSearchResult || linkSearchResult.length === 0
    }

    function renderLinksContent() {
        if (hasNoLinks()) {
            return <>
                {
                    shouldShowNoLinks && <NoItemsFound
                        image={SHOPEAKS_SMALL_LOGO_URL}
                        title={t('LINKS_PAGE_NO_LINKS_TITLE')}
                        buttonText={t('LINKS_PAGE_NO_LINKS_CREATE_LINK_BUTTON_TEXT')}
                        showButton={userDetails?.isAdmin}
                        addItemButtonClickHandler={onAddLinkButtonClick}
                    />
                }
            </>
        }

        if (searchQuery && hasNoLinksResults()) {
            return <>
                {
                    shouldShowNoLinks && <div className={isMobile ? 'mobile-links-page-no-results-found-container' : 'links-page-no-results-found-container'}>
                        <div className="links-page-no-results-found-content">
                            <div className="links-page-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_NO_RESULTS_FOUND_TEXT')}</div>
                        </div>
                    </div>
                }
            </>
        }

        return <div className={isMobile ? 'mobile-links-page-content-links-list' : 'links-page-content-links-list'}>
            {
                linkSearchResult?.map(link => <LinkItem
                    key={link?.linkId}
                    linkId={link?.linkId}
                    image={link?.images && link.images?.length > 0 ? link?.images[0] : ''}
                    defaultImage={link?.defaultImage}
                    views={link?.views}
                    createdAt={link?.createdAt}
                    influencerUsername={link?.influencerUsername}
                    influencerImage={link?.influencerImage}
                    title={link?.description}
                    onDeleteLink={deleteLink}
                />)
            }
        </div>
    }

    function deleteLink(linkId) {
        setLinkSearchResult(prev => prev.filter(link => link?.linkId !== linkId))
        setDefaultLinks(prev => prev.filter(link => link?.linkId !== linkId))
        setIsLoadingLinks(false)
        setRecentLinks([])
    }

    function updateLink(updatedLink) {
        setLinkSearchResult(prev => prev.map(link => link?.linkId === updatedLink.linkId ? updatedLink : link))
        setDefaultLinks(prev => prev.map(link => link?.linkId === updatedLink.linkId ? updatedLink : link))
    }

    function addNewLink(newLink) {
        setLinkSearchResult(prev => [newLink, ...prev])
        setDefaultLinks(prev => [newLink, ...prev])
    }

    function handleLinksScroll(e) {
        const scrollPosition = e.target.scrollTop
        const thresholdScrollHeight = linksListRef?.current?.clientHeight - (LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT * LINK_ITEM_HEIGHT_PX) + (topbarRef?.current?.clientHeight + analyticsStripRef?.current?.clientHeight) - (isMobile ? 0.4 * window.innerHeight : 0)
        if (!isLoadingMoreLinks && hasMoreLinks && scrollPosition + e.target.clientHeight >= thresholdScrollHeight) {
            setIsLoadingMoreLinks(true)
            fetchLinks(searchQuery, linkSearchResult?.length, false, LINK_TYPES.LOOK, linksData => {
                setLinks(linksData?.links, false, false)
                setHasMoreLinks(linksData?.links.length === LINKS_PAGE_LINKS_FILTER_COUNT)
                setIsLoadingMoreLinks(false)
            })
        }
    }

    function renderLoaderRow() {
        return <div className={isMobile ? 'mobile-links-page-loader-row' : 'links-page-loader-row'}>
            <Loader styles={{
                width: isMobile ? '25px' : '30px',
                height: isMobile ? '25px' : '30px'
            }} />
        </div>
    }

    function renderAnalyticsStrip() {
        return <AnalyticsStrip
            analytics={lifetimeAnalyticsState}
            currency={lifetimeAnalyticsState?.currency}
            isLoadingAnalytics={lifetimeAnalyticsState.isLoading}
            containerRef={analyticsStripRef}
        />
    }

    return (
        <>
            <BrowserView>
                <div onScroll={handleLinksScroll} className='links-page-container' style={{ height: hasShopifyStore() ? null : '100vh', maxWidth: hasShopifyStore() ? `${LINK_PAGES_MAX_WIDTH}px` : `${PAGE_DEFAULT_MAX_WIDTH}px`, margin: !hasShopifyStore() && '0 auto' }}>
                    {
                        hasShopifyStore() ? (
                            <>
                                {isLoadingLinks && <DashboardLoader />}
                                <div ref={topbarRef} className="links-page-topbar">
                                    <div className="links-page-topbar-store-name-container">
                                        {
                                            userDetails?.businessLogo ? <ImageBox
                                                className='links-page-topbar-store-image'
                                                image={userDetails?.businessLogo}
                                            /> : <div className="links-page-topbar-store-name">{userDetails?.businessName ?? ""}</div>
                                        }
                                    </div>
                                    <div className="links-page-topbar-buttons-container">
                                        {
                                            !hasNoLinks() && <>
                                                <div className="links-page-topbar-search-box" style={{ width: isMobile ? '100%' : '300px' }}>
                                                    <input className='links-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                                    {
                                                        searchQuery.length === 0 ?
                                                            <CiSearch className='links-page-topbar-search-box-image' /> :
                                                            <IoCloseOutline className='edit-link-section-add-products-dialog-topbar-search-box-image close' onClick={clearSearchQuery} />
                                                    }
                                                </div>
                                            </>
                                        }
                                        {(userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) && <div className="links-page-topbar-create-new-link-button links-page-topbar-button" onClick={onAddLinkButtonClick}>{t('LINKS_PAGE_CREATE_NEW_LINK_BUTTON_TEXT')}</div>}
                                    </div>
                                </div>
                                {renderAnalyticsStrip()}
                                <div ref={linksListRef} className={`links-page-content ${hasNoLinks() ? 'no-links-content' : ''}`}>
                                    {renderLinksContent()}
                                    {isLoadingMoreLinks && renderLoaderRow()}
                                </div>
                            </>
                        ) : <ShopifyConnect hasShopifyStore={false} />
                    }
                </div>
            </BrowserView>

            <MobileView>
                {
                    hasShopifyStore() ? <>
                        {isLoadingLinks && <DashboardLoader />}
                        <div onScroll={handleLinksScroll} className='mobile-links-page-container'>
                            {
                                !hasNoLinks() && <>
                                    <div ref={topbarRef} className='mobile-links-page-topbar-search-box-container'>
                                        <div className="mobile-links-page-topbar-search-box">
                                            <input className='links-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                            {
                                                searchQuery.length === 0 ?
                                                    <CiSearch className='links-page-topbar-search-box-image' /> :
                                                    <IoCloseOutline className='links-page-topbar-search-box-image close' onClick={clearSearchQuery} />
                                            }
                                        </div>
                                    </div>
                                    <div className="separator-line"></div>
                                </>
                            }
                            {renderAnalyticsStrip()}
                            <div ref={linksListRef} className={`mobile-links-page-content ${hasNoLinks() ? 'mobile-no-links-content' : ''}`}>
                                {renderLinksContent()}
                                {isLoadingMoreLinks && renderLoaderRow()}
                            </div>
                        </div>
                    </> : <ShopifyConnect hasShopifyStore={false} />
                }
            </MobileView>
            <AddLInkFlow
                trigger={addFlowTrigger}
                setTrigger={setAddFlowTrigger}
            />
        </>
    )
}