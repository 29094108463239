import React from 'react'
import './UserMenuListItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { useUserDetailsContext } from 'contexts/User'
import { PRICING_PLAN_DATA } from 'constants/general.constants'

export default function UserMenuListItem({
    title = '',
    image = <></>,
    subscriptionPlan = '',
    buttonText = '',
    onButtonClick = () => { }
}) {
    const {
        userDetails
    } = useUserDetailsContext()

    function getTitle() {
        const subscriptedPricingPlanName = Object.keys(PRICING_PLAN_DATA).find(pricingPlanName => PRICING_PLAN_DATA[pricingPlanName].name === subscriptionPlan.toLowerCase())
        const subscriptedPricingPlan = PRICING_PLAN_DATA[subscriptedPricingPlanName]

        return subscriptedPricingPlan.presentedName.toUpperCase()
    }

    return (
        <div className='user-menu-list-item'>
            <div className={`user-menu-list-item-data ${!userDetails?.isAdmin ? 'stretch' : ''}`}>
                {image}
                <div className="user-menu-list-item-titles-container">
                    {
                        title && <MultiLineText
                            text={title}
                            className='user-menu-list-item-title'
                            lines={1}
                            oneWord={true}
                        />
                    }
                    {userDetails?.isAdmin && <div className="user-menu-list-item-subscription-plan">{getTitle()}</div>}
                </div>
            </div>
            {
                userDetails?.isAdmin && <div className="user-menu-list-item-button-container">
                    <div className="user-menu-list-item-button" onClick={onButtonClick}>{buttonText}</div>
                </div>
            }
        </div>
    )
}