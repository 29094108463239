import React, { useEffect, useRef, useState } from 'react'
import './EditShortSection.css'
import AddInfluencerSelect from 'components/AddInfluencerSelect/AddInfluencerSelect'
import { ALERT_MESSAGE_TYPES, EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT, EDIT_SHORT_PROMOCODE_INPUT_MAX_LENGTH, EDIT_SHORT_SECTION_URL_TEST_DELAY_MILLISECONDS, INPUT_VALIDATION_INDICATIONS, PRICING_PLAN_DATA, SHORT_URL_SELECTION_TYPE, VIEW_MODES } from 'constants/general.constants'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { useTranslation } from 'react-i18next'
import UTMForm from 'components/UTMForm/UTMForm'
import { isMobile } from 'react-device-detect'
import { useUserDetailsContext } from 'contexts/User'
import { URL_REGEX } from 'constants/regex.constants'
import { IoShirtOutline } from 'react-icons/io5'
import UrlSelectionType from 'components/UrlSelectionType/UrlSelectionType'
import { HiOutlineLink } from 'react-icons/hi'
import ShortUrlSelectionCollectionTypeImage from 'embeddedImages/ShortUrlSelectionCollectionTypeImage'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import AddCollectionsDialog from 'dialogs/AddCollectionsDialog/AddCollectionsDialog'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'

/**
 * Represents the section for editing/creating a short.
 * @param {string} mode - The mode of this section, it determins whether it is creating or editing a short, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 * @param {array} influencers - The list of influencers to allow influencer select
 * @param {string} title - The title of this short
 * @param {function} setTitle - A function for setting the title of this short
 * @param {string} url - The currently displayed url
 * @param {function} setURL - A function for setting the currently displayed url
 * @param {string} originalInfluencerUsername - The currently saved influencer's username
 * @param {string} selectedInfluencerUsername - The currently selected influencer's username
 * @param {function} setSelectedInfluencerUsername - A function for setting the currently seelcted influencer's username
 * @param {string} promocode - The promocode state
 * @param {function} setPromocode - A function for setting the promocode state
 * @param {boolean} shouldAddUTMs - Determins whether the {@link UTMForm} component is visible or not, also determins whether to ignore the UTM fields or not
 * @param {function} setShouldAddUTMs - A function for setting the 'shouldAddUTMs' state
 * @param {string} UTMSource - The UTM source value state
 * @param {function} setUTMSource - A function for setting the UTM source value state
 * @param {string} UTMMedium - The UTM medium value state
 * @param {function} setUTMMedium - A function for setting the UTM medium value state
 * @param {string} UTMCampaign - The UTM campaign value state
 * @param {function} setUTMCampaign - A function for setting the UTM campaign value state
 * @param {string} UTMTerm - The UTM term value state
 * @param {function} setUTMTerm - A function for setting the UTM term value state
 * @param {string} UTMContent - The UTM content value state
 * @param {function} setUTMContent - A function for setting the UTM content value state
 * @param {string} urlInputValidationState - The validation status of the url
 * @param {function} setUrlInputValidationState - A function for setting the validation status of the url
 * @param {boolean} hasSavedInfluencer - Determins whether an influencer was already saved or not
 * @param {function} setIsInfluencerChanged - A function to perform whenever an influencer has changed
 * @param {string} urlSelectionType - The type of url selection for this short
 * @param {function} setUrlSelectionType - A function for setting the type of url selection for this short
 * @param {array} selectedProducts - The list of selected products for this short
 * @param {function} setSelectedProducts - A function for setting the list of selected products for this short
 * @param {array} selectedCollections - The list of selected collections for this short
 * @param {function} setSelectedCollections - A function for setting the list of selected collections for this short
 */
export default function EditShortSection({
    mode,
    influencers = [],
    title = '',
    setTitle = () => { },
    url = '',
    setURL = () => { },
    originalInfluencerUsername = '',
    selectedInfluencerUsername = '',
    setSelectedInfluencerUsername = () => { },
    promocode = '',
    setPromocode = () => { },
    shouldAddUTMs = false,
    setShouldAddUTMs = () => { },
    utmParams = {},
    setUTMSource = () => { },
    setUTMMedium = () => { },
    setUTMCampaign = () => { },
    setUTMTerm = () => { },
    setUTMContent = () => { },
    urlInputValidationState = {},
    setUrlInputValidationState = () => { },
    hasSavedInfluencer = false,
    setIsInfluencerChanged = () => { },
    urlSelectionType = SHORT_URL_SELECTION_TYPE.CUSTOM,
    setUrlSelectionType = () => { },
    selectedProducts = [],
    setSelectedProducts = () => { },
    selectedCollections = [],
    setSelectedCollections = () => { },
    shortPropertiesModified = false,
}) {
    const { t } = useTranslation()
    const { 
        userDetails, 
        // isFreeUser, 
        isProFeatureAnabled,
        endEditing
    } = useUserDetailsContext()
    const history = useHistoryContext()

    const [multiSelectDialogsState, setMultiSelectDialogsState] = useState({
        productsDialogShown: false,
        collectionsDialogShown: false
    })
    const [urlSelectionOptionDimension, setUrlSelectionOptionDimension] = useState(0)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    const urlTypingTimeoutRef = useRef(null)
    const urlSelectionOptionsRef = useRef(null)

    function onSelectInfluencer(influencerUsername) {
        const selectedInfluencer = influencers?.find(influencer => influencer?.username === influencerUsername)
        setPromocode(selectedInfluencer?.promocode)
        setSelectedInfluencerUsername(influencerUsername)
        setIsInfluencerChanged(true)
    }

    function removeTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    useEffect(() => {
        if (urlSelectionOptionsRef?.current) {
            setUrlSelectionOptionDimension(`min(calc((${urlSelectionOptionsRef?.current?.clientWidth}px - 30px) / 3), 150px)`)
        }
    }, [])

    useEffect(() => {
        if (isCustomUrlTypeSelected()) {
            removeTimeout(urlTypingTimeoutRef?.current)
            if (!url) {
                setUrlInputValidationState({
                    status: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
                    isValidating: false
                })
            } else {
                setUrlInputValidationState(prev => ({
                    ...prev,
                    isValidating: true
                }))
                urlTypingTimeoutRef.current = setTimeout(() => {
                    const urlToTest = url.startsWith('https://') || url.startsWith('http://') ? url : `https://${url}`
                    const hasBusinessDomain = urlToTest.startsWith('https://' + userDetails?.domain) || urlToTest.startsWith('http://' + userDetails?.domain)
                    if (hasBusinessDomain && urlToTest.match(URL_REGEX) != null) {
                        setUrlInputValidationState({
                            status: INPUT_VALIDATION_INDICATIONS.VALID,
                            isValidating: false
                        })
                    } else {
                        setUrlInputValidationState({
                            status: INPUT_VALIDATION_INDICATIONS.INVALID,
                            isValidating: false
                        })
                    }
                }, EDIT_SHORT_SECTION_URL_TEST_DELAY_MILLISECONDS)
            }
        }
    }, [url])

    function openAddProductsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            productsDialogShown: true
        }))
    }

    function closeAddProductDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            productsDialogShown: false
        }))
    }

    function openAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: true
        }))
    }

    function closeAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: false
        }))
    }

    function selectCustomUrl() {
        setSelectedProducts([])
        setSelectedCollections([])
        setURL('')
        setTitle('')
        setUrlSelectionType(SHORT_URL_SELECTION_TYPE.CUSTOM)
    }

    function setUserSelectedProducts(selectedProducts) {
        if (selectedProducts?.length > 0) {
            setSelectedCollections([])
            setUrlSelectionType(SHORT_URL_SELECTION_TYPE.PRODUCT)
            setURL(selectedProducts[0]?.url)
            setTitle(selectedProducts[0]?.title)
        }
        setSelectedProducts(selectedProducts)
    }

    function setUserSelectedCollections(selectedCollections) {
        if (selectedCollections?.length > 0) {
            setSelectedProducts([])
            setUrlSelectionType(SHORT_URL_SELECTION_TYPE.COLLECTION)
            setURL(selectedCollections[0]?.url)
            setTitle(selectedCollections[0]?.title)
        }
        setSelectedCollections(selectedCollections)
    }

    function isProductUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.PRODUCT
    }

    function isCollectionUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.COLLECTION
    }

    function isCustomUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.CUSTOM
    }

    return (
        <div className={isMobile ? 'mobile-edit-short-section-container' : 'edit-short-section-container'}>
            <div ref={urlSelectionOptionsRef} className="edit-short-section-url-selection-types">
                <UrlSelectionType
                    title={isProductUrlTypeSelected() ? selectedProducts[0]?.title : t('EDIT_SHORT_SECTION_URL_SELECTION_PRODUCT_TYPE_TITLE')}
                    image={isProductUrlTypeSelected() ? selectedProducts[0]?.imageUrl : IoShirtOutline}
                    isSelected={isProductUrlTypeSelected()}
                    imageClassName='edit-short-section-url-selection-type-image'
                    onClick={openAddProductsDialog}
                    dimens={urlSelectionOptionDimension}
                    hasChangedData={isProductUrlTypeSelected()}
                />
                <UrlSelectionType
                    title={isCollectionUrlTypeSelected() ? selectedCollections[0]?.title : t('EDIT_SHORT_SECTION_URL_SELECTION_COLLECTION_TYPE_TITLE')}
                    image={isCollectionUrlTypeSelected() ? selectedCollections[0]?.images?.medium?.src : ShortUrlSelectionCollectionTypeImage}
                    isSelected={isCollectionUrlTypeSelected()}
                    imageClassName='edit-short-section-url-selection-type-image edit-short-section-url-selection-collection-type-image'
                    onClick={openAddCollectionsDialog}
                    dimens={urlSelectionOptionDimension}
                    hasChangedData={isCollectionUrlTypeSelected()}
                />
                <UrlSelectionType
                    title={t('EDIT_SHORT_SECTION_URL_SELECTION_CUSTOM_TYPE_TITLE')}
                    image={HiOutlineLink}
                    isSelected={urlSelectionType === SHORT_URL_SELECTION_TYPE.CUSTOM}
                    imageClassName='edit-short-section-url-selection-type-image'
                    onClick={selectCustomUrl}
                    dimens={urlSelectionOptionDimension}
                />
            </div>
            <CollapsingElement expanded={urlSelectionType === SHORT_URL_SELECTION_TYPE.CUSTOM}>
                <div className={isMobile ? "mobile-edit-short-section-url-container" : "edit-short-section-url-container"}>
                    <EditFieldSectionInput
                        value={url}
                        setValue={setURL}
                        title={t('EDIT_SHORT_URL_INPUT_TITLE')}
                        placeholder={t('EDIT_SHORT_URL_INPUT_PLACEHOLDER', { domain: userDetails?.domain })}
                        hasValidation={true}
                        validationStatus={urlInputValidationState.status}
                        validationErrorMessage={t('EDIT_SHORT_URL_ERROR_MESSAGE', { domain: `https://${userDetails?.domain}` })}
                        isValidating={urlInputValidationState.isValidating}
                        titleStyles={{
                            fontSize: isMobile ? '15px' : '17px',
                            color: '#7f859e'
                        }}
                        inputStyles={{
                            width: '100%',
                            outline: 'none',
                            border: '1px solid black',
                            borderRadius: '25px',
                        }}
                    />
                </div>
            </CollapsingElement>
            <div className={isMobile ? "mobile-edit-short-section-title-container" : "edit-short-section-title-container"}>
                <EditFieldSectionInput
                    value={title}
                    setValue={setTitle}
                    isFramed={true}
                    maxLength={EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT}
                    title={t('EDIT_SHORT_TITLE_INPUT_TITLE')}
                    isOptional={true}
                />
            </div>
            {(userDetails?.isAdmin && influencers && influencers.length > 0) && <div className={isMobile ? "mobile-edit-short-section-influencer-select-container" : "edit-short-section-influencer-select-container"}>
                <AddInfluencerSelect
                    isDisabled={originalInfluencerUsername !== '' || hasSavedInfluencer}
                    optionState={selectedInfluencerUsername}
                    onOptionClick={onSelectInfluencer}
                    options={influencers}
                    fullWidth={true}
                    isOptional={true}
                />
            </div>}
            <div className={isMobile ? "mobile-edit-short-section-promocode-container" : "edit-short-section-promocode-container"}>
                <EditFieldSectionInput
                    title={t('EDIT_SHORT_PROMOCODE_INPUT_TITLE')}
                    value={promocode}
                    setValue={setPromocode}
                    placeholder={t('EDIT_SHORT_PROMOCODE_INPUT_PLACEHOLDER')}
                    maxLength={EDIT_SHORT_PROMOCODE_INPUT_MAX_LENGTH}
                    isOptional={true}
                    id="promocode"
                />
            </div>
            <div className={isMobile ? "mobile-edit-short-section-utm-container" : "edit-short-section-utm-container"}>
                {
                    userDetails?.isAdmin && <UTMForm
                        inlinePadding='20px'
                        shouldAddUTMs={shouldAddUTMs}
                        setShouldAddUTMs={setShouldAddUTMs}
                        UTMSource={utmParams.UTMSource}
                        setUTMSource={setUTMSource}
                        UTMMedium={utmParams.UTMMedium}
                        setUTMMedium={setUTMMedium}
                        UTMCampaign={utmParams.UTMCampaign}
                        setUTMCampaign={setUTMCampaign}
                        UTMTerm={utmParams.UTMTerm}
                        setUTMTerm={setUTMTerm}
                        UTMContent={utmParams.UTMContent}
                        setUTMContent={setUTMContent}
                    />
                }
            </div>
            <AddProductsDialog
                isDialogOpen={multiSelectDialogsState.productsDialogShown}
                handleDialogClose={closeAddProductDialog}
                selectedProducts={selectedProducts}
                setSelectedProducts={setUserSelectedProducts}
                selectMaxLimit={1}
                selectMinLimit={1}
                searchResultFilter={products => products.filter(product => product?.url !== '')}
            />
            <AddCollectionsDialog
                isDialogOpen={multiSelectDialogsState.collectionsDialogShown}
                handleDialogClose={closeAddCollectionsDialog}
                selectedCollections={selectedCollections}
                setSelectedCollections={setUserSelectedCollections}
                selectMaxLimit={1}
                selectMinLimit={1}
                searchResultFilter={collections => collections.filter(collection => collection?.url !== '')}
            />
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={false}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={false}
                onRightButtonClick={dialogState.rightButtonClickHandler}
            />
        </div>
    )
}