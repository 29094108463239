import React from 'react'
import './DrawerStyledButtonList.css'

export default function DrawerStyledButtonList({
    buttons = []
}) {
    return (
        <div className="drawer-styled-button-list">
            {
                buttons.map(button => <div key={button?.text} className="drawer-styled-button-list-item-container" onClick={button?.onClick} style={button?.color && { color: button?.color }}>
                    <div className={`drawer-styled-button-list-item ${button?.className}`}>
                        {button?.image}
                        <div className="drawer-styled-button-list-item-text">{button?.text}</div>
                    </div>
                </div>)
            }
        </div>
    )
}