import React from 'react'
import './MobileLinkPreviewSimulator.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import { useUserDetailsContext } from 'contexts/User'
import MobileSimulatorTopBarMenuImage from 'embeddedImages/MobileSimulatorTopBarMenuImage'
import MobileSimulatorProduct from 'components/MobileSimulatorProduct/MobileSimulatorProduct'

/**
 * Represents a simulator for preview link on a mobile device.
 * @param {string} mainImageUrl - The image url of the large main image on the simulator display
 * @param {array} products - The products' list which their images will be shown under the main image
 * @param {boolean} hasBorder - Determins whether the simulator has a bold border or not 
 * @param {boolean} isFullScreen - Determins whether the simulator will be displayed as fullscreen or not 
 */
export default function MobileLinkPreviewSimulator({
    products,
    hasBorder = true,
    isFullScreen = false
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()

    return (
        <div className='mobile-link-preview-simulator-container'
            style={{
                border: hasBorder ? '12px solid black' : 'none',
                maxHeight: isFullScreen ? 'unset' : '560px'
            }}
        >
            <div className="mobile-link-preview-simulator-content">
                <div className="mobile-link-preview-simulator-upper-container">
                    <div className="mobile-link-preview-simulator-topbar">
                        <MobileSimulatorTopBarMenuImage className="mobile-link-preview-simulator-topbar-menu-image mobile-link-preview-simulator-topbar-image" />
                        {
                            userDetails?.businessLogo ? <ImageBox
                                className="mobile-link-preview-simulator-mobile-simulator-topbar-image"
                                image={userDetails?.businessLogo}
                            /> : <div className="mobile-link-preview-simulator-topbar-store-name">{userDetails?.businessName}</div>
                        }
                        <div style={{ width: '20px' }}></div>
                    </div>
                    <div className="mobile-link-preview-simulator-products-list">
                        {
                            products && products?.map(product => <MobileSimulatorProduct
                                key={product?.title + '__' + product?.imageUrl}
                                title={product?.title}
                                imageUrl={product?.imageUrl}
                            />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}