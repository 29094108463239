import { DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX, DASHBOARD_SIDEBAR_WIDTH_PX } from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/User'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import React from 'react'
import { isMobile } from 'react-device-detect'

/**
 * Represents a loader of the dashboard pages.
 * It covers the whole screen except the dashboard topbar.
 */
export default function DashboardLoader() {
    const {
        userDetails
    } = useUserDetailsContext()
    
    return (
        <LoaderPage
            styles={{ 
                backgroundColor: '#ffffffaa', 
                width: `calc(100vw - ${userDetails?.sidebarExpanded ? DASHBOARD_SIDEBAR_WIDTH_PX : DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX})`,
                height: `calc(100dvh - ${isMobile ? 'var(--mobile-dashboard-topbar-height))' : '0'}`, 
                marginTop: `${isMobile ? 'var(--mobile-dashboard-topbar-height)' : '0'}` 
            }} 
            marginBottom={0} 
            isFullScreen={true} 
        />
    )
}