import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { PAYDIN_RADIO_BUTTON_GROUP_ALIGNMENTS } from 'constants/general.constants'
import React from 'react'

/**
 * Represents a generic radio button group.
 * @param {string} selectedValue - The currently selected radio button's value
 * @param {function} onSelectedValueChange - A function for setting currently selected radio button's value
 * @param {array} radioButtons - The list of radio buttons for display
 * @param {string} alignemnet - A description of the radio buttons alignment
 */
export default function PaydinRadioButtonGroup({
    selectedValue,
    onSelectedValueChange = () => { },
    radioButtons = [],
    alignemnet = PAYDIN_RADIO_BUTTON_GROUP_ALIGNMENTS.COLUMN
}) {
    
    function handleChange(e) {
        onSelectedValueChange(e.target.value)
    }

    return (
        <RadioGroup
            row={alignemnet === PAYDIN_RADIO_BUTTON_GROUP_ALIGNMENTS.ROW}
            value={selectedValue}
            onChange={handleChange}
        >
            {
                radioButtons?.map(radio => <FormControlLabel
                    key={radio?.value}
                    value={radio?.value}
                    control={<Radio color="primary" />}
                    label={radio?.label}
                    labelPlacement={radio?.placement ?? 'end'}
                    disabled={radio?.disabled ?? false}
                />)
            }
        </RadioGroup>
    )
}