import React, { useEffect, useState } from "react"
import './DashboardLayout.css'
import DashboardTopBar from "components/DashboardTopBar/DashboardTopBar"
import AuthLayout from "layouts/RequiredAuthLayout"
import { isMobile } from "react-device-detect"
import { DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR, DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX, DASHBOARD_SIDEBAR_WIDTH_PX, NO_BOTTOM_PADDING_PAGES } from "constants/general.constants"
import { ADMIN_PAGES_URL_PREFIX, INFLUENCERS_URL_PREFIX, LINKS_URL_PREFIX, SHOPBOARD_PAGE_URL, SHORTS_URL_PREFIX } from "constants/routes.constants"
import DashboardSidebar from "components/DashboardSidebar/DashboardSidebar"
import { useUserDetailsContext } from "contexts/User"

/**
 * Represents a layout for all main dashboard screens.
 */
export default function DashboardLayout({ children }) {

    const {
        setSidebarExpanded
    } = useUserDetailsContext()

    const [open, setOpen] = useState(window.innerWidth >= 1200)

    useEffect(() => {
        const rootElement = document.querySelector('#root')

        const resizeObserver = new ResizeObserver(checkWindowSize)
        resizeObserver.observe(rootElement)

        return () => resizeObserver.disconnect()
    }, [])

    useEffect(() => {
        setSidebarExpanded(open)
    }, [open])

    function checkWindowSize() {
        setOpen(window.innerWidth >= 1200)
    }

    function isBottomPaddingPage() {
        if (window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX) && window.location.pathname.endsWith('/edit')) { // currently on edit short page
            return false
        }

        if (window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX) && window.location.pathname.endsWith('/edit')) { // currently on edit link page
            return false
        }

        if (window.location.pathname.startsWith(INFLUENCERS_URL_PREFIX) && window.location.pathname.endsWith('/preview')) { // currently on preview influencer page
            return false
        }

        if (window.location.pathname.startsWith(ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX) && window.location.pathname.endsWith('/preview')) { // currently on preview short page
            return !isMobile
        }

        return !NO_BOTTOM_PADDING_PAGES.includes(window.location.pathname)
    }

    function isDashboardTopbarShown() {
        return isMobile && DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR.includes(window.location.pathname)
    }

    function getBackgroundColor() {
        if (window.location.pathname === SHOPBOARD_PAGE_URL) {
            return '#f6f6f6'
        }
    }

    return (
        <div className={isMobile ? 'mobile-dashboard-layout-container' : 'dashboard-layout-container'} style={{ width: isMobile ? '100%' : `calc(100% - ${open ? DASHBOARD_SIDEBAR_WIDTH_PX : DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX}px)`, paddingTop: isDashboardTopbarShown() ? (isMobile ? `var(--mobile-dashboard-topbar-height)` : `var(--dashboard-topbar-height)`) : '0px', backgroundColor: !isMobile && getBackgroundColor() }}>
            {
                isMobile ? (
                    DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR.includes(window.location.pathname) && <DashboardTopBar />
                ) : <DashboardSidebar open={open} />
            }
            <AuthLayout>
                <div className={isMobile ? "mobile-dashboard-layout-content" : "dashboard-layout-content"} style={{ paddingBottom: isBottomPaddingPage() ? '20dvh' : 0 }}>
                    {children}
                </div>
            </AuthLayout>
        </div>
    )
}