import { Collapse } from '@mui/material'
import React from 'react'
import './CollapsingElement.css'
/**
 * Represents a collapsing or expanding element.
 * @param {boolean} expanded - If true, the container is expanded, otherwise the container is collapsed
 */
export default function CollapsingElement({
    children,
    expanded,
    collapsedSize='0px',
    unmountOnExit=true
}) {

    return (
        <Collapse in={expanded} timeout="auto" collapsedSize={collapsedSize} unmountOnExit={unmountOnExit}>
            {children}
        </Collapse>
    )
}