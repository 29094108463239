import React from 'react'
import './CircularProgressBar.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents a circular progress bar
 * @param {number} value - The value to diaplay
 * @param {string} width - The width of the progress bar
 * @param {string} height - The height of the progress bar
 * @param {string} archColor - The color of the filling arch
 * @param {number} min - The lower bound of the progress range
 * @param {number} max - The upper bound of the progress range
 */
export default function CircularProgressBar({
    value,
    width,
    height,
    archColor = 'black',
    min = 0,
    max = 100,
}) {
    const percentage = Math.floor(((value - min) / (max - min)) * 100)

    return (
        <div className="circular-progress-bar" role="progressbar" style={{
            background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${archColor} ${percentage}%, #e8e8e8 0)`,
            width,
            height
        }}>
            <div className="circular-progress-bar-text" style={{ fontSize: isMobile ? '15px' : '18px' }}>{value}</div>
        </div>
    )
}