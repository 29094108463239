import React from 'react'
import './Splash.css'
import LoaderPage from '../LoaderPage/LoaderPage'

/**
 * Represents the splash screen (first screen of the app).
 */
export default function Splash() {
    return (
        <div className="splash-container">
            <LoaderPage isSplash={true} animatedString="Shopeaks" isFullScreen={true} />
        </div>
    )
}