import React from 'react'
import './DateIntervalOption.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents a predefined date interval option that can be translated to a date range.
 * Used in @see DatePicker component.
 * @param {object} item - The data object of this item
 * @param {function} onItemClick - A function to perform whenever this item is clicked
 */
export default function DateIntervalOption({
    item,
    onItemClick = () => { }
}) {
    return (
        <div
            className={`${isMobile ? 'mobile-date-interval-option' : 'date-interval-option'} ${item?.isSelected ? 'selected' : ''}`}
            onClick={() => onItemClick(item?.name)}
        >
            {item?.name}
        </div>
    )
}