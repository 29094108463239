import React from "react"
import { useUserDetailsContext } from 'contexts/User'
import { Redirect } from "react-router-dom"
import { EXTERNAL_AUTH_PAGE_URL, LOGIN_PAGE_URL } from 'constants/routes.constants'
import GeneralLayout from "./GeneralLayout/GeneralLayout";

const AuthLayout = ({ children }) => {
	const {
		userDetails
	} = useUserDetailsContext()

	if (!userDetails?.isAuthenticated) {
		return (
			<Redirect to={{ pathname: LOGIN_PAGE_URL, search: window.location.pathname === EXTERNAL_AUTH_PAGE_URL ? window.location.search : "" }} />
		)
	}
	
	return (
		<GeneralLayout>
			{children}
		</GeneralLayout>
	)
};

export default AuthLayout