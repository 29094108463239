import React from 'react'
import './PrivaciesFooterText.css'
import { Trans } from 'react-i18next'
import { LOGIN_PAGE_URL, PRIVACY_POLICY_PAGE_URL, TERMS_PAGE_URL } from 'constants/routes.constants'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

/**
 * Represents a privacy policy instruction text.
 */
export default function PrivaciesFooterText() {

    function getPoliciesFooterTextI18NextKey() {
        if (window.location.pathname === LOGIN_PAGE_URL)
            return "LOGIN_PAGE_POLICIES_FOOTER_TEXT"
        return "SIGN_UP_PAGE_POLICIES_FOOTER_TEXT"
    }

    return (
        <div className={isMobile ? "mobile-policies-footer-text" : "policies-footer-text"}>
            <div className={isMobile ? 'mobile-policies-footer-text-space-block' : 'policies-footer-text-space-block'}></div>
            <Trans
                i18nKey={getPoliciesFooterTextI18NextKey()}
                components={{ 
                    p: <Link to={PRIVACY_POLICY_PAGE_URL} className='policies-footer-text-link' />,
                    t: <Link to={TERMS_PAGE_URL} className='policies-footer-text-link' /> 
                }}
            />
        </div>
    )
}