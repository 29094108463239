import React, { useState } from 'react'
import './ImageCropDialog.css'
import PaydinImageCropper from 'components/PaydinImageCropper/PaydinImageCropper'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_MAX_HEIGHT, PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_WIDTH, PAYDIN_IMAGE_CROP_DIALOG_MAX_HEIGHT, PAYDIN_IMAGE_CROP_DIALOG_WIDTH } from 'constants/general.constants'
import { BsArrowLeft } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import getCroppedImage from 'services/imageUtils'
import { useHistoryContext } from 'contexts/History'

/**
 * Represents a dialog that displays the {@link PaydinImageCropper}.
 * @param {boolean} isDialogOpen - Determins whether the dialog is open or not 
 * @param {function} handleDialogClose - A function for closing the dialog
 * @param {string} image - The image to crop
 * @param {object} initialCrop - The initial value of the image crop
 * @param {number} initialZoom - The initial value of the image zoom
 * @param {number} initialAspectRatio - The initial value of the image aspect ratio
 * @param {array} aspectRatiosArray - The aspect ratio options list
 * @param {boolean} isLogoCropper - Determins whether the cropper is used for logo image or for general image
 * @param {function} onCropDone - A function for applying the image cropping
 */
export default function ImageCropDialog({
    isDialogOpen,
    handleDialogClose,
    image,
    initialCrop = { x: 0, y: 0 },
    initialZoom = 1,
    initialAspectRatio = 1 / 1,
    aspectRatiosArray,
    isLogoCropper = false,
    isInfluencerImageCropper = false,
    isRoundCrop = false,
    onCropDone = () => { },
    rightButtonText = null
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [crop, setCrop] = useState(initialCrop)
    const [zoom, setZoom] = useState(initialZoom)
    const [aspectRatio, setAspectRatio] = useState(initialAspectRatio)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    async function applyCrop() {
        const croppedImage = await getCroppedImage(image, croppedAreaPixels)
        onCropDone(croppedImage, crop, zoom, aspectRatio)
        closeDialog()
    }

    function closeDialog() {
        if (handleDialogClose) {
            handleDialogClose()
        }
        
        history.goBack()
    }

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            maxHeight={isInfluencerImageCropper ? PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_MAX_HEIGHT : PAYDIN_IMAGE_CROP_DIALOG_MAX_HEIGHT}
            backdropColor='rgba(255, 255, 255, 0.85)'
            borderRadiusPx={10}
            paddingPx={0}
            width={isInfluencerImageCropper ? PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_WIDTH : PAYDIN_IMAGE_CROP_DIALOG_WIDTH}
            styles={{ overflow: 'hidden' }}
            dialogLayout={<>
                <div className='image-crop-dialog-container'>
                    <div className="image-crop-dialog-topbar">
                        <BsArrowLeft className='image-crop-dialog-topbar-back-button' onClick={closeDialog} />
                        <div className="image-crop-dialog-topbar-title">{t('IMAGE_CROP_DIALOG_TOPBAR_TITLE')}</div>
                        <div className="image-crop-dialog-topbar-done-button" onClick={applyCrop}>{rightButtonText ?? t('IMAGE_CROP_DIALOG_TOPBAR_DONE_BUTTON_TEXT')}</div>
                    </div>
                    <PaydinImageCropper
                        image={image}
                        isRoundCrop={isRoundCrop}
                        crop={crop}
                        setCrop={setCrop}
                        zoom={zoom}
                        setZoom={setZoom}
                        aspectRatio={aspectRatio}
                        setAspectRatio={setAspectRatio}
                        setCroppedAreaPixels={setCroppedAreaPixels}
                        aspectRatiosArray={aspectRatiosArray}
                        isLogoCropper={isLogoCropper}
                        isInfluencerImageCropper={isInfluencerImageCropper}
                    />
                </div>
            </>}
        />
    )
}