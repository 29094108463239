import React, { useEffect, useState } from 'react'
import './ColorSelect.css'
import { BsCheck } from 'react-icons/bs'
import { COLOR_SELECT_DEFAULT_DIMENSIONS } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import { useGeneralDataContext } from "contexts/User"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material'

/**
 * Represents a color option selector.
 * @param {string} selectedColor - The state of the selected color
 * @param {function} setSelectedColor - A function for setting the state of the selected color
 * @param {object} colorCounter - The color option values quantities object
 */
export default function ColorSelect({
    optionName,
    selectedColor,
    setSelectedColor = () => { },
    colorCounter = {},
    colorSelectDisplayedColorsCount = 0
}) {
    const { t } = useTranslation()
    const { 
        getOptionValue,
        productsOptionsMap
    } = useGeneralDataContext()

    const [colors, setColors] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let tempColors = Object.keys(colorCounter)

        if (tempColors?.length > colorSelectDisplayedColorsCount) {
            if (selectedColor && colorSelectDisplayedColorsCount > 0 && tempColors.includes(selectedColor) && !tempColors?.slice(0, colorSelectDisplayedColorsCount - 1).includes(selectedColor)) {
                setColors([...tempColors?.slice(0, colorSelectDisplayedColorsCount - 2), selectedColor])
            } else {
                setColors(tempColors?.slice(0, colorSelectDisplayedColorsCount - 1))
            }
        } else {
            setColors(tempColors?.slice(0, colorSelectDisplayedColorsCount))
        }

    }, [colorCounter, selectedColor, productsOptionsMap])

    function onColorClick(color) {
        if (colorCounter[color] > 0) {
            if (selectedColor === color) {
                setSelectedColor("")
            } else {
                setSelectedColor(color)
                setAnchorEl(null)
            }
        }
    }

    function isColorSelected(color) {
        return selectedColor === color
    }

    function renderColorCircle(color, onClick = () => {}) {
        return <div key={color} className='color-select-item-frame' style={{height: `${COLOR_SELECT_DEFAULT_DIMENSIONS + 8}px`}} onClick={() => onClick(color)}>
            <div
                className={`color-select-item ${isColorSelected(color) ? 'selected' : ''} ${colorCounter[color] === 0 ? 'out-of-stock' : ''}`}
                style={{
                    backgroundColor: getOptionValue(optionName, color),
                    boxShadow: `0 0 0 2px white, 0 0 0 4px ${isColorSelected(color) ? 'black' : '#cccccc'}`,
                    width: `${COLOR_SELECT_DEFAULT_DIMENSIONS}px`,
                    height: `${COLOR_SELECT_DEFAULT_DIMENSIONS}px`
                }}
            >
                {isColorSelected(color) && <BsCheck className='color-select-item-selected-v-mark' style={{
                    width: `${COLOR_SELECT_DEFAULT_DIMENSIONS - 5}px`,
                    height: `${COLOR_SELECT_DEFAULT_DIMENSIONS - 5}px`
                }} />}
            </div>
        </div>
    }

    return (
        <div className='color-select-container'>
            {
                (Object.keys(colorCounter)?.length >= 2 && Object.keys(colorCounter)?.length <= colorSelectDisplayedColorsCount) ?
                    colors?.map(color => renderColorCircle(color, onColorClick)) : <>
                        {colors?.map(color => renderColorCircle(color, onColorClick))}
                        <div className="color-select-item-more-colors" onClick={handleMoreClick}>{t('COLOR_SELECT_MORE_COLORS_BUTTON_TEXT')}</div>
                    </>
            }
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMoreClose}
                    MenuListProps={{
                      'dense': true,
                    }}
                    slotProps={{
                        paper: {
                            style: {
                                maxHeight: '190px',
                                width: 'fit-content',
                                minWidth: '200px',
                                maxWidth: '400px'
                            },
                        },
                    }}
                >
                    {
                        Object.keys(colorCounter).map(color => <MenuItem key={color} onClick={() => onColorClick(color)}>
                            <div className='color-select-menu-item'>
                                {renderColorCircle(color)}
                                <Typography variant="inherit" noWrap>{color}</Typography>
                            </div>
                        </MenuItem>)
                    }
                </Menu>
        </div>
    )
}