import React, { useEffect, useRef, useState } from 'react'
import './MultilinksPage.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useHistoryContext } from 'contexts/History'
import {
    HOME_PAGE_URL
} from 'constants/routes.constants'
import {
    useFetchLinksContext,
    useGeneralDataContext,
    useIsUserLoadedContext,
    useUserDetailsContext,
} from 'contexts/User'
import { useTranslation } from 'react-i18next'
import {
    EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS,
    LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT,
    LINK_ITEM_HEIGHT_PX,
    MULTILINK_PAGES_MAX_WIDTH,
    MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT,
    PAGE_DEFAULT_MAX_WIDTH,
    SHOPEAKS_SMALL_LOGO_URL,
    VIEW_MODES,
    CONTENT_TYPES
} from 'constants/general.constants'
import ShopifyConnect from 'components/ShopifyConnect/ShopifyConnect'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'
import Loader from 'components/Loader/Loader'
import { extractMultilinkPreviewData } from 'utils/dataUtils'
import MultilinkItem from 'components/MultilinkItem/MultilinkItem'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import MultilinksImage2 from 'embeddedImages/MultilinksImage2'

/**
 * Represents the user's multilinks page.
 */
export default function MultilinksPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        isFreeUserAndTrialEnded
    } = useUserDetailsContext()
    const {
        finishedUpdatingObject,
        finishedCreatingObject,
        objectToSave,
        openFreeTrialDialog
    } = useGeneralDataContext()
    const { fetchMultilinks } = useFetchLinksContext()
    const isUserLoaded = useIsUserLoadedContext()

    const [isLoadingMultilinks, setIsLoadingMultilinks] = useState(true)
    const [isLoadingMoreMultilinks, setIsLoadingMoreMultilinks] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [skip, setSkip] = useState(0)
    const [defaultMultilinks, setDefaultMultilinks] = useState([])
    const [multilinkSearchResult, setMultilinkSearchResult] = useState([])
    const [hasMoreMultilinks, setHasMoreMultilinks] = useState(true)
    const [shouldShowNoMultilinks, setShouldShowNoMultilinks] = useState(false)
    const [isFirstRender, setIsFirstRender] = useState(true) // used to control the appearance of the loader only in the first render
    const [isMultilinkContentDialogOpen, setIsMultilinkContentDialogOpen] = useState(false)

    const typingTimeoutRef = useRef(null)
    const topbarRef = useRef(null)
    const multilinksListRef = useRef(null)

    useEffect(() => {
        if (hasShopifyStore()) {
            if (!isMobile) {
                topbarRef.current.style.width = `${multilinksListRef?.current?.clientWidth}px`
            }

            if (isUserLoaded) {
                // when the splash is no longer shown, load the links again to refresh the links collection
                fetchFirstMultilinks()
            }
        } else {
            history.replace(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        setShouldShowNoMultilinks(false)
        setMultilinks([], true, true)
        setIsLoadingMultilinks(true)
        setHasMoreMultilinks(true)
        clearTypingTimeout(typingTimeoutRef.current)
        if (searchQuery) {
            typingTimeoutRef.current = setTimeout(() => {
                fetchMultilinks(searchQuery, 0, multilinksData => {
                    setShouldShowNoMultilinks(true)
                    setMultilinks(multilinksData?.multilinks, true, true)
                    setHasMoreMultilinks(multilinksData?.multilinks.length === MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT)
                    setIsLoadingMultilinks(false)
                })
            }, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS)
        } else {
            setMultilinkSearchResult(defaultMultilinks)
            if (!isFirstRender) { // On the first render, the next line will be executed unless we check for first render, because in this case 'searchQuery' is falsy
                setIsLoadingMultilinks(false)
            }
        }
        setIsFirstRender(false)

        return () => {
            clearTypingTimeout(typingTimeoutRef.current)
        }
    }, [searchQuery])

    useEffect(() => {
        if (finishedUpdatingObject) {
            updateMultilink(objectToSave)
        } else if (finishedCreatingObject) {
            addNewMultilink(objectToSave)
        }
    }, [finishedUpdatingObject, finishedCreatingObject])

    function fetchFirstMultilinks() {
        setIsLoadingMultilinks(true)
        fetchMultilinks(searchQuery, 0, multilinksData => {
            setShouldShowNoMultilinks(true)
            setDefaultMultilinkList(multilinksData?.multilinks)
            setMultilinks(multilinksData?.multilinks, false, true)
            setHasMoreMultilinks(multilinksData?.multilinks.length === MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT)
            setIsLoadingMultilinks(false)
        })
    }

    function setDefaultMultilinkList(multilinks) {
        if (!multilinks) {
            return
        }

        setDefaultMultilinks([...(multilinks.map(link => extractMultilinkPreviewData(link)))])
    }

    function setMultilinks(multilinks, shouldResetSkip = false, shouldOverrideMultilinks = false) {
        if (!multilinks) {
            return
        }

        if (shouldOverrideMultilinks) {
            setMultilinkSearchResult([...(multilinks.map(multilink => extractMultilinkPreviewData(multilink)))])
        } else {
            setMultilinkSearchResult(prev => ([
                ...prev,
                ...(multilinks.map(multilink => extractMultilinkPreviewData(multilink)))
            ]))
        }
        if (shouldResetSkip)
            setSkip(0)
        else
            setSkip(prev => prev + multilinks.length)
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function clearSearchQuery() {
        setSearchQuery('')
    }

    function handleChange(e) {
        setSearchQuery(e.target.value)
    }

    function onAddMultilinkButtonClick() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            setIsMultilinkContentDialogOpen(true)
        }
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    function hasNoMultilinks() {
        return !defaultMultilinks || defaultMultilinks.length === 0
    }

    function hasNoMultilinksResults() {
        return !multilinkSearchResult || multilinkSearchResult.length === 0
    }

    function renderMultilinksContent() {
        if (hasNoMultilinks()) {
            return <>
                {
                    shouldShowNoMultilinks && <NoItemsFound
                        isEmbeddedIcon={true}
                        image={<MultilinksImage2 className='multilinks-page-no-multilinks-image' />}
                        title={t('MULTILINKS_PAGE_NO_LINKS_TITLE')}
                        buttonText={t('MULTILINKS_PAGE_NO_MULTILINKS_CREATE_LINK_BUTTON_TEXT')}
                        showButton={userDetails?.isAdmin}
                        addItemButtonClickHandler={onAddMultilinkButtonClick}
                    />
                }
            </>
        }

        if (searchQuery && hasNoMultilinksResults()) {
            return <>
                {
                    shouldShowNoMultilinks && <div className={isMobile ? 'mobile-multilinks-page-no-results-found-container' : 'multilinks-page-no-results-found-container'}>
                        <div className="multilinks-page-no-results-found-content">
                            <div className="multilinks-page-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_NO_RESULTS_FOUND_TEXT')}</div>
                        </div>
                    </div>
                }
            </>
        }

        return <div className={isMobile ? 'mobile-multilinks-page-content-multilinks-list' : 'multilinks-page-content-multilinks-list'}>
            {
                multilinkSearchResult?.map(multilink => <MultilinkItem
                    key={multilink?.multilinkId}
                    multilinkId={multilink?.multilinkId}
                    createdAt={multilink?.createdAt}
                    currency={multilink?.currency}
                    title={multilink?.title}
                    splitterType={multilink?.splitterType}
                    linksOptions={multilink.linksOptions}
                    onDeleteMultilink={deleteMultilink}
                />)
            }
        </div>
    }

    function deleteMultilink(multilinkId) {
        setMultilinkSearchResult(prev => prev.filter(multilink => {
            return multilink?.multilinkId !== multilinkId
        }))
        setDefaultMultilinks(prev => prev.filter(multilink => multilink?.multilinkId !== multilinkId))
        setIsLoadingMultilinks(false)
    }

    function updateMultilink(updatedMultilink) {
        setMultilinkSearchResult(prev => prev.map(multilink => multilink?.multilinkId === updatedMultilink.multilinkId ? updatedMultilink : multilink))
        setDefaultMultilinks(prev => prev.map(multilink => multilink?.multilinkId === updatedMultilink.multilinkId ? updatedMultilink : multilink))
    }

    function addNewMultilink(newMultilink) {
        setMultilinkSearchResult(prev => [newMultilink, ...prev])
        setDefaultMultilinks(prev => [newMultilink, ...prev])
    }

    function handleMultilinksScroll(e) {
        const scrollPosition = e.target.scrollTop
        const thresholdScrollHeight = multilinksListRef?.current?.clientHeight - (LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT * LINK_ITEM_HEIGHT_PX) + (topbarRef?.current?.clientHeight) - (isMobile ? 0.4 * window.innerHeight : 0)
        if (!isLoadingMoreMultilinks && hasMoreMultilinks && scrollPosition + e.target.clientHeight >= thresholdScrollHeight) {
            setIsLoadingMoreMultilinks(true)
            fetchMultilinks(searchQuery, multilinkSearchResult?.length, multilinksData => {
                setMultilinks(multilinksData?.multilinks, false, false)
                setHasMoreMultilinks(multilinksData?.multilinks.length === MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT)
                setIsLoadingMoreMultilinks(false)
            })
        }
    }

    function renderLoaderRow() {
        return <div className={isMobile ? 'mobile-multilinks-page-loader-row' : 'multilinks-page-loader-row'}>
            <Loader styles={{
                width: isMobile ? '25px' : '30px',
                height: isMobile ? '25px' : '30px'
            }} />
        </div>
    }

    function closeContentDialog() {
        setIsMultilinkContentDialogOpen(false)
    }

    return (
        <>
            <BrowserView>
                <div onScroll={handleMultilinksScroll} className='multilinks-page-container' style={{ height: hasShopifyStore() ? null : '100vh', maxWidth: hasShopifyStore() ? `${MULTILINK_PAGES_MAX_WIDTH}px` : `${PAGE_DEFAULT_MAX_WIDTH}px`, margin: !hasShopifyStore() && '0 auto' }}>
                    {
                        hasShopifyStore() ? (
                            <>
                                {isLoadingMultilinks && <DashboardLoader />}
                                <div ref={topbarRef} className="multilinks-page-topbar">
                                    <div className="multilinks-page-topbar-store-name-container">
                                        {
                                            userDetails?.businessLogo ? <ImageBox
                                                className='multilinks-page-topbar-store-image'
                                                image={userDetails?.businessLogo}
                                            /> : <div className="multilinks-page-topbar-store-name">{userDetails?.businessName ?? ""}</div>
                                        }
                                    </div>
                                    <div className="multilinks-page-topbar-buttons-container">
                                        {
                                            !hasNoMultilinks() && <>
                                                <div className="multilinks-page-topbar-search-box" style={{ width: isMobile ? '100%' : '300px' }}>
                                                    <input className='multilinks-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                                    {
                                                        searchQuery.length === 0 ?
                                                            <CiSearch className='multilinks-page-topbar-search-box-image' /> :
                                                            <IoCloseOutline className='edit-multilink-section-add-products-dialog-topbar-search-box-image close' onClick={clearSearchQuery} />
                                                    }
                                                </div>
                                            </>
                                        }
                                        {userDetails?.isAdmin && <div className="multilinks-page-topbar-create-new-multilink-button multilinks-page-topbar-button" onClick={onAddMultilinkButtonClick}>{t('MULTILINKS_PAGE_CREATE_NEW_MULTILINK_BUTTON_TEXT')}</div>}
                                    </div>
                                </div>
                                <div ref={multilinksListRef} className={`multilinks-page-content ${hasNoMultilinks() ? 'no-multilinks-content' : ''}`}>
                                    {renderMultilinksContent()}
                                    {isLoadingMoreMultilinks && renderLoaderRow()}
                                </div>
                            </>
                        ) : <ShopifyConnect hasShopifyStore={false} />
                    }
                </div>
            </BrowserView>

            <MobileView>
                {
                    hasShopifyStore() ? <>
                        {isLoadingMultilinks && <DashboardLoader />}
                        <div onScroll={handleMultilinksScroll} className='mobile-multilinks-page-container'>
                            {
                                !hasNoMultilinks() && <>
                                    <div ref={topbarRef} className='mobile-multilinks-page-topbar-search-box-container'>
                                        <div className="mobile-multilinks-page-topbar-search-box">
                                            <input className='multilinks-page-topbar-search-box-input' value={searchQuery} type='text' onChange={handleChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                            {
                                                searchQuery.length === 0 ?
                                                    <CiSearch className='multilinks-page-topbar-search-box-image' /> :
                                                    <IoCloseOutline className='multilinks-page-topbar-search-box-image close' onClick={clearSearchQuery} />
                                            }
                                        </div>
                                    </div>
                                    <div className="separator-line"></div>
                                </>
                            }
                            <div ref={multilinksListRef} className={`mobile-multilinks-page-content ${hasNoMultilinks() ? 'mobile-no-multilinks-content' : ''}`}>
                                {renderMultilinksContent()}
                                {isLoadingMoreMultilinks && renderLoaderRow()}
                            </div>
                        </div>
                    </> : <ShopifyConnect hasShopifyStore={false} />
                }
            </MobileView>
            <ContentDialog
                isDialogOpen={isMultilinkContentDialogOpen}
                handleDialogClose={closeContentDialog}
                mode={VIEW_MODES.CREATE}
                contentType={CONTENT_TYPES.MULTILINK}
            />
        </>
    )
}