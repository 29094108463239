import { Box, TableCell, TableSortLabel, tableCellClasses } from '@mui/material'
import React from 'react'
import './ProductsTableHeaderView.css'
import { visuallyHidden } from '@mui/utils'
import { styled } from '@mui/system'
import { PRODUCT_TABLE_HEADERS_IDS } from 'constants/general.constants'
import { BsBoxSeam } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

/**
 * Represents a header view for the products table
 * @param {string} order - The way of ordering the column data, e.g., asc or desc
 * @param {string} orderBy - The column to order the data by
 * @param {string} headerBackgroundColor - The background color of the header view
 * @param {string} headerTextColor - The text color of the header view
 * @param {string} createSortHandler - A function for creating the sort function for the data ordering
 */
export default function ProductsTableHeaderView({
    order,
    orderBy,
    headerBackgroundColor = '',
    headerTextColor = '',
    createSortHandler = () => { },
    removedColumnIds = []
}) {
    const { t } = useTranslation()

    const headerContentMap = {
        [PRODUCT_TABLE_HEADERS_IDS.TITLE]: <div className='product-table-header-view product-table-header-view-product'>
            <BsBoxSeam className="product-table-header-view-product-image" />
            <div className="product-table-header-view-product-text">{t('PRODUCTS_TABLE_HEADER_PRODUCT_NAME')}</div>
        </div>,
        [PRODUCT_TABLE_HEADERS_IDS.VIEW]: <div className='table-header-view product-table-header-view'>{t('PRODUCTS_TABLE_HEADER_PRODUCT_VIEW_COUNT')}</div>,
        [PRODUCT_TABLE_HEADERS_IDS.ADD]: <div className='table-header-view product-table-header-view'>{t('PRODUCTS_TABLE_HEADER_PRODUCT_ADD_COUNT')}</div>,
        [PRODUCT_TABLE_HEADERS_IDS.BUY]: <div className='table-header-view product-table-header-view'>{t('PRODUCTS_TABLE_HEADER_PRODUCT_BUY_COUNT')}</div>,
        [PRODUCT_TABLE_HEADERS_IDS.RANK]: <div className='table-header-view product-table-header-view'>{t('PRODUCTS_TABLE_HEADER_PRODUCT_RANKING')}</div>
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: headerBackgroundColor,
            color: headerTextColor,
            textAlign: 'start'
        },
    }))

    const ProductsTableHeaderCell = ({ id, label, align }) => <StyledTableCell
        key={id}
        align={align}
        padding={'normal'}
        sortDirection={orderBy === id ? order : false}
    >
        <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={createSortHandler(id)}
        >
            {label}
            {orderBy === id ? (
                <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
            ) : null}
        </TableSortLabel>
    </StyledTableCell>

    return (
        isMobile ? <ProductsTableHeaderCell id={PRODUCT_TABLE_HEADERS_IDS.TITLE} align={'left'} label={
            <div className='product-table-header-view product-table-header-view-product'>
                <BsBoxSeam className="product-table-header-view-product-image" />
                <div className="product-table-header-view-product-text">{t('PRODUCTS_TABLE_HEADER_PRODUCT_NAME')}</div>
            </div>
        } /> : <>
            {
                removedColumnIds?.length > 0 ? Object.keys(headerContentMap).filter(columnId => !removedColumnIds.includes(columnId)).map(columnId => <ProductsTableHeaderCell key={columnId} id={columnId} align={'left'} label={
                    headerContentMap[columnId]
                } />) : <>
                    <ProductsTableHeaderCell id={PRODUCT_TABLE_HEADERS_IDS.TITLE} align={'left'} label={
                        headerContentMap[PRODUCT_TABLE_HEADERS_IDS.TITLE]
                    } />
                    <ProductsTableHeaderCell id={PRODUCT_TABLE_HEADERS_IDS.VIEW} align={'left'} label={
                        headerContentMap[PRODUCT_TABLE_HEADERS_IDS.VIEW]
                    } />
                    <ProductsTableHeaderCell id={PRODUCT_TABLE_HEADERS_IDS.ADD} align={'left'} label={
                        headerContentMap[PRODUCT_TABLE_HEADERS_IDS.ADD]
                    } />
                    <ProductsTableHeaderCell id={PRODUCT_TABLE_HEADERS_IDS.BUY} align={'left'} label={
                        headerContentMap[PRODUCT_TABLE_HEADERS_IDS.BUY]
                    } />
                    <ProductsTableHeaderCell id={PRODUCT_TABLE_HEADERS_IDS.RANK} align={'left'} label={
                        headerContentMap[PRODUCT_TABLE_HEADERS_IDS.RANK]
                    } />
                </>
            }
        </>
    )
}
