import React from 'react'
import { useTranslation } from 'react-i18next'
import './MobileSimulatorProduct.css'
import ImageBox from 'components/ImageBox/ImageBox'

/**
 * Represents a cart item row inside the cart display in the home screen.
 * @param {string} imageUrl - The image url of the item
 * @param {string} title - The title of the item
 */
export default function MobileSimulatorProduct({
    imageUrl,
    title
}) {
    const { t } = useTranslation()

    return (
        <div dir={t('direction.dir')} className='mobile-simulator-product-container'>
            <div className='mobile-simulator-product-content'>
                <ImageBox
                    className='mobile-simulator-product-image'
                    image={imageUrl}
                    showImageLoader={true}
                    showGreyLoaderBackground={true}
                    isImageFitCover={true}
                    showImageBorderRadius={true}
                    borderRadius={'6px'}
                    border='1px solid #ececec'
                />
                <div className="mobile-simulator-product-details-container">
                    <div className="mobile-simulator-product-details-upper-section">
                        <div className="mobile-simulator-product-title-price-container">
                            <div className="mobile-simulator-product-title">{title}</div>
                        </div>
                    </div>
                    <div className="mobile-simulator-product-details-lower-section">
                        <div className={`mobile-simulator-product-add-to-cart-more-info-container`}>
                            <div className='mobile-simulator-product-add-to-cart-button'>{t('MOBILE_SIMULATOR_PRODUCT_ADD_TO_CART_BUTTON_TEXT')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}