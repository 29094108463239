import React from 'react'
import './DashboardDataSingleValue.css'
import DashboardDataContainer from 'components/DashboardDataContainer/DashboardDataContainer'
import { isMobile } from 'react-device-detect'

/**
 * Represents a single-value dashboard data view. Uses {@link DashboardDataContainer}.
 * @param {string/number} width - The width of this container
 * @param {string/number} height - The height of this container
 * @param {string} title - The title of this view
 * @param {string} value - The value of this view
 */
export default function DashboardDataSingleValue({
    width,
    height,
    title = '',
    value = '',
}) {
    return (
        <DashboardDataContainer
            title={title}
            width={width}
            height={height}
        >
            <div className={isMobile ? "mobile-dashboard-analytics-single-value-text" : "dashboard-analytics-single-value-text"}>{value}</div>
        </DashboardDataContainer>
    )
}