import React, { useState } from 'react'
import './TimeSelect.css'
import { TbCalendarEvent } from 'react-icons/tb'
import { isMobile } from 'react-device-detect'
import { ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES, DATE_PICKER_DESKTOP_BORDER_COLOR, DATE_PICKER_DESKTOP_BORDER_RADIUS_PX, DATE_PICKER_DESKTOP_WIDTH_PX, PAYDIN_MENU_OPENING_DIRECTIONS } from 'constants/general.constants'
import { getFormattedDateRange, getFormattedShortDateRange } from 'utils/dateUtils'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import DatePicker from 'components/DatePicker/DatePicker'
import { useTranslation } from 'react-i18next'
import { useGeneralDataContext } from 'contexts/User'

/**
 * Represents a select element for selecting time periods for business analytics.
 * @param {date} selectedStartDate - The currently selected start date
 * @param {date} selectedEndDate - The currently selected end date
 * @param {function} setStartEndDates - A function for setting the start and end dates
 * @param {function} onTimeIntervalSelect - A function to perform whenever a date interval is selected
 * @param {function} setShouldApplyDateRange - A function for setting the "shouldApplyUpdateDateRange" state which determins whether the user has finished selecting a date range
 * @param {array} timeIntervals - The select's time intervals list
 */
export default function TimeSelect({
    selectedStartDate = null,
    selectedEndDate = null,
    setStartEndDates = () => { },
    selectedTimeInterval = ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH,
    onTimeIntervalSelect = () => { },
    setShouldApplyDateRange = () => { },
    timeIntervals = [],
    displayShortDates = false
}) {
    const { t } = useTranslation()
    const {
        openDrawer,
        closeDrawer
    } = useGeneralDataContext()

    const [menuState, setMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: closeDatePicker
    })
    function getFormattedShortTimeRange() {
        return isLifetimeRangeSelected() ? ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME : getFormattedShortDateRange(selectedStartDate, selectedEndDate)
    }

    function getTimeRangeDescription() {
        if (Object.values(ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES).includes(selectedTimeInterval)) {
            if (isMobile || selectedTimeInterval === ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME) {
                return selectedTimeInterval
            }
            return selectedTimeInterval + ': ' + getFormattedDateRange(selectedStartDate, selectedEndDate)
        } else {
            if (isMobile) {
                return t('DATE_PICKER_CUSTOM_DATE_RANGE_SELECTED_INTERVAL_DISPLAY_NAME')
            }
            return getFormattedDateRange(selectedStartDate, selectedEndDate)
        }
    }
    
    function closeDatePicker() {
        if (isMobile) {
            closeDrawer()
        } else {
            setMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        }
    }

    function openDatePicker(e) {
        if (isMobile) {
            openDrawer(false, renderDatePicker(), '95dvh')
        } else {
            setMenuState({
                isOpen: true,
                anchorElement: e.currentTarget,
                onClose: () => setMenuState(prev => ({
                    ...prev,
                    isOpen: false
                }))
            })
        }
    }
    
    function handleDatePickerUpdate(newStartDate, newEndDate, tempDateInterval) {
        onTimeIntervalSelect(tempDateInterval)
        setStartEndDates(newStartDate, newEndDate)
        setShouldApplyDateRange(true)
        closeDatePicker()
    }
    
    function handleDatePickerCancel() {
        closeDatePicker()
    }

    function renderDatePicker() {
        return <DatePicker
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            dateIntervals={timeIntervals}
            onCancelClick={handleDatePickerCancel}
            onUpdateClick={handleDatePickerUpdate}
            selectedDateInterval={selectedTimeInterval}
        />
    }

    function isLifetimeRangeSelected() {
        return selectedTimeInterval === ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME
    }

    return (
        <div className={isMobile ? 'mobile-time-select-container' : 'time-select-container'}>
            <div className={isMobile ? "mobile-time-select" : "time-select"} onClick={openDatePicker}>
                <div className='time-select-display'>{displayShortDates ? getFormattedShortTimeRange() : getTimeRangeDescription()}</div>
                <TbCalendarEvent className='time-select-image' />
            </div>
            {isMobile && <div className="mobile-time-select-time-desc">{getFormattedShortTimeRange()}</div>}
            {
                !isMobile && <PaydinMenu
                    anchorElement={menuState.anchorElement}
                    isOpen={menuState.isOpen}
                    onClose={menuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                    widthPx={DATE_PICKER_DESKTOP_WIDTH_PX}
                    borderRadius={DATE_PICKER_DESKTOP_BORDER_RADIUS_PX}
                    borderColor={DATE_PICKER_DESKTOP_BORDER_COLOR}
                >
                    {renderDatePicker()}
                </PaydinMenu>
            }
        </div>
    )
}