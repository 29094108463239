import React from 'react'
import './ShopifyHelpPage.css'
import { MdOutlineArrowBackIos } from 'react-icons/md'
import { useHistoryContext } from 'contexts/History'

export default function ShopifyHelpPage() {
    const history = useHistoryContext()

    function goBack() {
        history.goBack()
    }

    return (
        <div className='shopify-help-page-container'>
            <div className="shopify-help-page-back-button-container">
                <MdOutlineArrowBackIos className='shopify-help-page-back-button' onClick={goBack} />
            </div>
            <div className="shopify-help-page-title">How to connect your Shopify store to your Shopeaks account</div>
            <div className='shopify-help-page-section'>
                <ol>
                    <li>
                        <div className="shopify-help-page-section-title">Install the Shopeaks sales channel app on your shopify store from the <a target="_blank" href="https://apps.shopify.com/shopeaks">Shopify Appstore</a>.</div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">Subscribe to one of the plans and start your 30 days free trial.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paylink.cloudfront.payli.in/application_resources/shopify-screenshots/Subscribe_page.png"} alt={"helper1"} />
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">We will not charge you anything during your free trial.</div>
                        <div className="shopify-help-page-section-title">You will not be asked to put you payment methods details. All charges are made through Shopify and you can unsubscribe at any time. </div>
                        <div className="shopify-help-page-section-title">The tab will close after succesfully approving your subscription.</div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">The tab will close after succesfully connecting your shopify store to your Shopeaks account</div>
                        <div className="shopify-help-page-section-title">On the shopify admin panel you can now see the account status.</div>
                        <div className="shopify-help-page-section-title">In the background, Shopeaks is syncing your products to your account.</div>
                        <div className="shopify-help-page-section-image-container">
                            <img className="shopify-help-page-section-image" src={"https://cdn.paylink.cloudfront.payli.in/application_resources/shopify-screenshots/shopify_admin.png"} alt={"helper1"} />
                        </div>
                    </li>
                    <li>
                        <div className="shopify-help-page-section-title">In the admin page in Shopeaks you will now be able to create shoppable links and connect all your products to each link per your choise.</div>
                        <div className="shopify-help-page-section-title">Start your Shopeaks journey!</div>
                    </li>
                </ol>
                <div className="shopify-help-page-section-title">Contact us on <a href="mailto: support@shopeaks.com">support@shopeaks.com</a> for any further help needed.</div>
            </div>
        </div>
    )
}