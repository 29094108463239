import React, { useEffect, useRef, useState } from 'react'
import './EditInfluencerSection.css'
import ImageBox from 'components/ImageBox/ImageBox'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import { EDIT_INFLUENCER_SECTION_VALIDATION_TIMEOUT_MILLISECONDS, INFLUENCER_PROMOCODE_LENGTH_LIMIT, INPUT_VALIDATION_INDICATIONS, PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS, RESIZE_IMAGE_MAX_HEIGHT, RESIZE_IMAGE_MAX_WIDTH, VIEW_MODES } from 'constants/general.constants'
import { resizeImage } from 'services/imageUtils'
import { validateInfluencerUsername } from 'api/influencers/influencers'
import { EMAIL_PATTERN_REGEX, INFLUENCER_USERNAME_REGEX, NUMBERS_WITH_SYMBOLS_REGEX, PHONE_NUMBER_REGEX } from 'constants/regex.constants'
import { containsWhitespace, formatUsername, isNumberInRange } from 'utils/utils'
import PaydinSwitch from 'components/PaydinSwitch/PaydinSwitch'
import ExpandHideButton from 'components/ExpandHideButton/ExpandHideButton'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'

/**
 * Represents the section for editing/creating an influencer.
 * @param {string} mode - The mode of this section, it determins whether it is creating or editing an influencer, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 * @param {boolean} isButtonDisabled - Determins whether the button is disabled or not
 * @param {boolean} isButtonProcessing - Determins whether the button's loader is shown or not
 * @param {string} image - The influencer's image
 * @param {function} setImage - A function for setting the influencer's image
 * @param {string} username - The influencer's username
 * @param {function} setUsername - A function for setting the influencer's username
 * @param {string} email - The influencer's email
 * @param {function} setEmail - A function for setting the influencer's email
 * @param {string} promocode - The influencer's promocode
 * @param {function} setPromocode - A function for setting the influencer's promocode
 * @param {string} commission - The influencer's commission
 * @param {function} setCommission - A function for setting the influencer's commission
 * @param {string} commissionHelperText - A helper text for the commission input
 * @param {function} setCommissionHelperText - A function for setting the helper text for the commission input
 * @param {string} commissionValidationStatus - The validation status for the commission input
 * @param {function} setCommissionValidationStatus - A function for setting the validation status for the commission input
 * @param {string} phoneNumber - The influencer's phone number
 * @param {function} setPhoneNumber - A function for setting the influencer's phone number
 * @param {string} phoneNumberValidationStatus - The validation status of the phone number input
 * @param {function} setPhoneNumberValidationStatus - A function for setting the validation status of the phone number input
 * @param {string} phoneNumberHelperText - A helper text for the phone number input
 * @param {function} setPhoneNumberHelperText - A function for setting the helper text for the phone number input
 * @param {function} isActiveInfluencer - If true, it means that the influencer can edit and create links, otherwise he can't
 * @param {function} setIsActiveInfluencer - A function for setting the influencer's activity
 * @param {function} setIsUsernameValid - A function for setting the validity of the influencer's username
 * @param {function} setIsEmailValid - A function for setting the validity of the influencer's email
 * @param {object} influencerPermissions - The analytics permissions object of the influencer
 * @param {function} setInfluencerClicksPermission - A function for setting the influencer's clicks permission
 * @param {function} setInfluencerSalesPermission - A function for setting the influencer's sales permission
 * @param {function} setInfluencerRevenuePermission - A function for setting the influencer's revenue permission
 * @param {function} setInfluencerIncludeVATPermission - A function for setting the influencer's VAT permission
 */
export default function EditInfluencerSection({
    mode,
    image,
    setImage,
    username,
    setUsername,
    email,
    originalEmail,
    setEmail,
    promocode,
    setPromocode,
    commission,
    setCommission,
    commissionHelperText,
    setCommissionHelperText,
    commissionValidationStatus,
    setCommissionValidationStatus,
    phoneNumber,
    setPhoneNumber,
    phoneNumberValidationStatus,
    setPhoneNumberValidationStatus,
    phoneNumberHelperText,
    setPhoneNumberHelperText,
    isActiveInfluencer,
    setIsActiveInfluencer,
    setIsUsernameValid = () => { },
    setIsEmailValid = () => { },
    influencerPermissions = {},
    setInfluencerCommissionPermission = () => { },
    setInfluencerClicksPermission = () => { },
    setInfluencerSalesPermission = () => { },
    setInfluencerRevenuePermission = () => { },
    setInfluencerIncludeVATPermission = () => { },
    setInfluencerAovEnabledPermission = () => { },
    setInfluencerCrEnabledPermission = () => { },
    commissionRules = {},
    setReduceRefundsFromCommission = () => { },
    setReduceVatFromCommission = () => { },
    shouldTestEmailValidationStatus = true,
}) {
    const { t } = useTranslation()
    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState(false)
    const [validationIndicatorState, setValidationIndicatorState] = useState({
        username: {
            validationStatus: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
            isValidating: false
        },
        email: {
            validationStatus: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
            isValidating: false
        }
    })
    const [validationErrorMessagesState, setValidationErrorMessagesState] = useState({
        username: '',
        email: ''
    })
    const [shouldMarkImageRequired, setShouldMarkImageRequired] = useState(false)
    const [isAnalyticsPermissionsShown, setIsAnalyticsPermissionsShown] = useState(true)
    const [wasEmailFilledBefore, setWasEmailFilledBefore] = useState(false)

    const fileInputRef = useRef(null)
    const usernameValidationTimeoutRef = useRef(null)
    const emailValidationTimeoutRef = useRef(null)

    useEffect(() => {
        if (mode === VIEW_MODES.CREATE) {
            if (usernameValidationTimeoutRef?.current)
                clearTimeout(usernameValidationTimeoutRef?.current)

            if (username) {
                usernameValidationTimeoutRef.current = setTimeout(() => {
                    if (username) {
                        if (isUsernameFormatValid(username)) {
                            validateUsername(username)
                        } else {
                            setUsernameValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
                            setUsernameValidatingState(false)
                            setValidationErrorMessagesState(prev => ({
                                ...prev,
                                username: t('EDIT_INFLUENCER_SECTION_INVALID_USERNAME_FORMAT_HELPER_TEXT')
                            }))
                        }
                    } else {
                        setUsernameValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
                        setUsernameValidatingState(false)
                    }
                }, EDIT_INFLUENCER_SECTION_VALIDATION_TIMEOUT_MILLISECONDS)
            } else {
                setUsernameValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
                setUsernameValidatingState(false)
                setIsUsernameValid(false)
            }
        }
    }, [username])

    useEffect(() => {
        if (emailValidationTimeoutRef?.current)
            clearTimeout(emailValidationTimeoutRef?.current)

        if (email === originalEmail) {
            setEmailValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
            setIsEmailValid(true)
            return
        }

        if (email) {
            setWasEmailFilledBefore(true)
            emailValidationTimeoutRef.current = setTimeout(() => {
                if (email) {
                    if (isEmailFormatValid(email)) {
                        setEmailValidationStatus(INPUT_VALIDATION_INDICATIONS.VALID)
                        setEmailValidatingState(false)
                        setIsEmailValid(true)
                    } else {
                        setEmailValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
                        setIsEmailValid(false)
                        setValidationErrorMessagesState(prev => ({
                            ...prev,
                            email: t('EDIT_INFLUENCER_SECTION_INVALID_EAMIL_FORMAT_HELPER_TEXT')
                        }))
                    }
                } else {
                    setEmailValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
                    setEmailValidatingState(false)
                }
            }, EDIT_INFLUENCER_SECTION_VALIDATION_TIMEOUT_MILLISECONDS)
        } else {
            if (mode === VIEW_MODES.EDIT || wasEmailFilledBefore) {
                setEmailValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
                setValidationErrorMessagesState(prev => ({
                    ...prev,
                    email: t('EDIT_INFLUENCER_SECTION_INVALID_EAMIL_FORMAT_HELPER_TEXT')
                }))
            }
        }
    }, [email])

    function isUsernameFormatValid(username) {
        return !containsWhitespace(username)
    }

    function isEmailFormatValid(email) {
        return EMAIL_PATTERN_REGEX.test(email)
    }

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    function addMedia() {
        fileInputRef.current.value = null
        fileInputRef.current.click()
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        setProfileImage(croppedImage)
        setIsImageCropDialogOpen(false)
    }

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function setUsernameValidatingState(state) {
        setValidationIndicatorState(prev => ({
            ...prev,
            username: {
                ...prev.username,
                isValidating: state
            }
        }))
    }

    function setEmailValidatingState(state) {
        setValidationIndicatorState(prev => ({
            ...prev,
            email: {
                ...prev.email,
                isValidating: state
            }
        }))
    }

    function setUsernameValidationStatus(validationStatus) {
        setValidationIndicatorState(prev => ({
            ...prev,
            username: {
                ...prev.username,
                validationStatus
            }
        }))
    }

    function setEmailValidationStatus(validationStatus) {
        setValidationIndicatorState(prev => ({
            ...prev,
            email: {
                ...prev.email,
                validationStatus
            }
        }))
    }

    function validateUsername(username) {
        setUsernameValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
        setUsernameValidatingState(true)
        validateInfluencerUsername(username)
            .then(() => {
                if (username) {
                    setUsernameValidationStatus(INPUT_VALIDATION_INDICATIONS.VALID)
                    setUsernameValidatingState(false)
                    setIsUsernameValid(true)
                }
            })
            .catch(error => {
                if (username) {
                    setUsernameValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
                    setUsernameValidatingState(false)
                    setIsUsernameValid(false)
                    setValidationErrorMessagesState(prev => ({
                        ...prev,
                        username: t('EDIT_INFLUENCER_SECTION_INVALID_USERNAME_HELPER_TEXT')
                    }))
                }
            })
    }

    function renderAddMediaFrame(className = '') {
        return <div className={`${isMobile ? 'mobile-edit-influencer-section-add-media-button' : 'edit-influencer-section-add-media-button'} ${className}`}>
            <div className="edit-influencer-section-add-media-button-inner-container">
                <div className={isMobile ? "mobile-edit-influencer-section-add-media-button-plus" : "edit-influencer-section-add-media-button-plus"}>+</div>
                <div className="edit-influencer-section-add-media-button-text">{t('EDIT_LINK_SECTION_ADD_MEDIA_BUTTON_TEXT')}</div>
            </div>
        </div>
    }

    function setProfileImage(image) {
        setShouldMarkImageRequired(false)
        setImage(image)
    }

    function setInfluencerUsername(value) {
        setShouldMarkImageRequired(!image ? true : false)
        if (INFLUENCER_USERNAME_REGEX.test(value)) {
            setUsername(formatUsername(value))
        }
    }

    function setInfluencerEmail(value) {
        setShouldMarkImageRequired(!image ? true : false)
        setEmail(value)
    }

    function setInfluencerPromocode(value) {
        setShouldMarkImageRequired(!image ? true : false)
        setPromocode(value)
    }

    function isCommissionValid(commission) {
        if (commission.includes('.')) {
            const fractionalNumberPart = commission?.split('.')[1]
            return NUMBERS_WITH_SYMBOLS_REGEX.test(commission) && fractionalNumberPart.length <= 2
        } else {
            return NUMBERS_WITH_SYMBOLS_REGEX.test(commission)
        }
    }

    function setInfluencerCommission(value) {
        setShouldMarkImageRequired(!image ? true : false)
        if (isCommissionValid(value)) {
            setCommission(value?.length > 1 && value?.startsWith('0') ? value?.slice(1) : value)
        }

        if (value === '' || isNumberInRange(Number(value), 0, 100)) {
            setCommissionValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
            setCommissionHelperText('')
        } else {
            setCommissionValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
            setCommissionHelperText(t('EDIT_INFLUENCER_SECTION_INVALID_COMMISSION_ERROR_TEXT'))
        }
    }

    function setInfluencerPhoneNumber(value) {
        setShouldMarkImageRequired(!image ? true : false)
        setPhoneNumber(value)

        if (value === '' || PHONE_NUMBER_REGEX.test(value)) {
            setPhoneNumberValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
            setPhoneNumberHelperText('')
        } else {
            setPhoneNumberValidationStatus(INPUT_VALIDATION_INDICATIONS.INVALID)
            setPhoneNumberHelperText(t('EDIT_INFLUENCER_SECTION_INVALID_PHONE_NUMBER_ERROR_TEXT'))
        }
    }

    function setInfluencerActivity(isActive) {
        setShouldMarkImageRequired(!image ? true : false)
        setIsActiveInfluencer(isActive)
    }

    function toggleAnalyticsPermissionsDisplay() {
        setIsAnalyticsPermissionsShown(prev => !prev)
    }

    return (
        <div className={isMobile ? 'mobile-edit-influencer-section' : 'edit-influencer-section'}>
            <div className={isMobile ? "mobile-edit-influencer-section-image-frame" : "edit-influencer-section-image-frame"}>
                <div className="edit-influencer-section-image-frame-title">{t('EDIT_INFLUENCER_SECTION_PROFILE_IMAGE_TITLE')}</div>
                <div className={`edit-influencer-section-image-container ${shouldMarkImageRequired ? 'required' : ''}`} onClick={addMedia}>
                    {
                        image ? <>
                            <ImageBox
                                image={image}
                                className={isMobile ? 'mobile-edit-influencer-section-image' : 'edit-influencer-section-image'}
                                roundImage={true}
                                isImageFitCover={true}
                                showImageLoader={true}
                                showBorder={true}
                            />
                            {renderAddMediaFrame('has-image')}
                        </> : <>
                            {renderAddMediaFrame()}
                        </>
                    }
                </div>
            </div>
            {shouldMarkImageRequired && <div className='edit-influencer-section-image-container-error-message'>{t('EDIT_INFLUENCER_SECTION_PROFILE_IMAGE_ERROR_MESSAGE')}</div>}
            <form className="edit-influencer-section-form">
                <EditFieldSectionInput
                    title={t('EDIT_INFLUENCER_SECTION_USERNAME_INPUT_TITLE')}
                    value={username}
                    setValue={setInfluencerUsername}
                    isDisabled={mode === VIEW_MODES.EDIT}
                    hasValidation={mode === VIEW_MODES.CREATE}
                    isValidating={validationIndicatorState.username.isValidating}
                    validationStatus={validationIndicatorState.username.validationStatus}
                    validationErrorMessage={validationErrorMessagesState.username}
                    id="username"
                />
                <EditFieldSectionInput
                    title={t('EDIT_INFLUENCER_SECTION_EMAIL_INPUT_TITLE')}
                    value={email}
                    setValue={setInfluencerEmail}
                    hasValidation={true}
                    shouldTestValidationStatus={shouldTestEmailValidationStatus}
                    shouldResetValidation={!(mode === VIEW_MODES.EDIT || wasEmailFilledBefore)}
                    isValidating={validationIndicatorState.email.isValidating}
                    validationStatus={validationIndicatorState.email.validationStatus}
                    validationErrorMessage={validationErrorMessagesState.email}
                    id="email"
                />
                <EditFieldSectionInput
                    title={t('EDIT_INFLUENCER_SECTION_PHONE_NUMBER_INPUT_TITLE')}
                    value={phoneNumber}
                    setValue={setInfluencerPhoneNumber}
                    isOptional={true}
                    hasValidation={true}
                    validationStatus={phoneNumberValidationStatus}
                    validationErrorMessage={phoneNumberHelperText}
                    id="phone-number"
                />
                <PaydinSwitch
                    checked={isActiveInfluencer}
                    setChecked={setInfluencerActivity}
                    label={t('EDIT_LINK_SECTION_INFLUENCER_ACTIVITY_SWITCH_LABEL')}
                    className='edit-influencer-switch'
                    labelClassName='edit-influencer-switch-label'
                />
                <EditFieldSectionInput
                    title={t('EDIT_INFLUENCER_SECTION_PROMOCODE_INPUT_TITLE')}
                    value={promocode}
                    setValue={setInfluencerPromocode}
                    isOptional={true}
                    maxLength={INFLUENCER_PROMOCODE_LENGTH_LIMIT}
                    id="promocode"
                />
                <EditFieldSectionInput
                    title={t('EDIT_INFLUENCER_SECTION_COMMISSION_INPUT_TITLE')}
                    value={commission}
                    setValue={setInfluencerCommission}
                    isOptional={true}
                    inputType='number'
                    hasValidation={true}
                    validationStatus={commissionValidationStatus}
                    validationErrorMessage={commissionHelperText}
                    prefixStart={false}
                    prefix='%'
                    showValidationIcon={false}
                    id="commission"
                />
                <div className="edit-influencer-section-influencer-commission-calculation-sections-container">
                    <div className="edit-influencer-section-influencer-commission-calculation-section">
                        <PaydinSwitch
                            checked={commissionRules?.reduceRefundsFromCommission}
                            setChecked={setReduceRefundsFromCommission}
                            label={t('EDIT_LINK_SECTION_INFLUENCER_COMMISSION_REDUCE_REFUNDS_LABEL')}
                            labelClassName='edit-influencer-switch-label'
                        />
                    </div>
                    <div className="edit-influencer-section-influencer-commission-calculation-section">
                        <PaydinSwitch
                            checked={commissionRules?.reduceVatFromCommission}
                            setChecked={setReduceVatFromCommission}
                            label={t('EDIT_LINK_SECTION_INFLUENCER_COMMISSION_REDUCE_VAT_LABEL')}
                            labelClassName='edit-influencer-switch-label'
                        />
                    </div>
                </div>
                <div className="edit-influencer-section-influencer-permissions">
                    <div className="edit-influencer-section-influencer-permissions-title-container">
                        <div className="edit-influencer-section-influencer-permissions-title">{t('EDIT_LINK_SECTION_LINK_PERMISSIONS_TITLE') + ' ' + t('OPTIONAL_INPUT_TITLE_SUFFIX')}</div>
                        <ExpandHideButton
                            isExpanded={isAnalyticsPermissionsShown}
                            onClick={toggleAnalyticsPermissionsDisplay}
                        />
                    </div>
                    <CollapsingElement
                        expanded={isAnalyticsPermissionsShown}
                    >
                        <div className="edit-influencer-section-influencer-permissions-sections-container">
                            <div className="edit-influencer-section-influencer-permissions-section">
                                <PaydinSwitch
                                    checked={influencerPermissions?.commissionEnabled}
                                    setChecked={setInfluencerCommissionPermission}
                                    label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_COMMISSION_LABEL')}
                                    labelClassName='edit-influencer-switch-label'
                                />
                            </div>
                            <div className="edit-influencer-section-influencer-permissions-section">
                                <PaydinSwitch
                                    checked={influencerPermissions?.clicksEnabled}
                                    setChecked={setInfluencerClicksPermission}
                                    label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_CLICKS_LABEL')}
                                    labelClassName='edit-influencer-switch-label'
                                />
                            </div>
                            <div className="edit-influencer-section-influencer-permissions-section">
                                <PaydinSwitch
                                    checked={influencerPermissions?.salesEnabled}
                                    setChecked={setInfluencerSalesPermission}
                                    label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_SALES_LABEL')}
                                    labelClassName='edit-influencer-switch-label'
                                />
                            </div>

                            <CollapsingElement expanded={influencerPermissions?.salesEnabled}>
                                <div className="edit-influencer-section-influencer-permissions-section sub-permission">
                                    <PaydinSwitch
                                        checked={influencerPermissions?.crEnabled}
                                        setChecked={setInfluencerCrEnabledPermission}
                                        label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_CR_ENABLED_LABEL')}
                                        labelClassName='edit-influencer-switch-label'
                                        checkedBackgroundColor='#658dc4'
                                    />
                                </div>
                            </CollapsingElement>
                            <div className="edit-influencer-section-influencer-permissions-section">
                                <PaydinSwitch
                                    checked={influencerPermissions?.revenueEnabled}
                                    setChecked={setInfluencerRevenuePermission}
                                    label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_REVENUE_LABEL')}
                                    labelClassName='edit-influencer-switch-label'
                                />
                            </div>
                            <CollapsingElement expanded={influencerPermissions?.revenueEnabled}>
                                <div className="edit-influencer-section-influencer-permissions-sections-container">
                                    <div className="edit-influencer-section-influencer-permissions-section sub-permission">
                                        <PaydinSwitch
                                            checked={!influencerPermissions?.includeVat}
                                            setChecked={reduceVat => setInfluencerIncludeVATPermission(!reduceVat)} //Replace to not to make more intuitive
                                            label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_REDUCE_VAT_LABEL')}
                                            labelClassName='edit-influencer-switch-label'
                                            checkedBackgroundColor='#658dc4'
                                        />
                                    </div>
                                    <div className="edit-influencer-section-influencer-permissions-section sub-permission">
                                        <PaydinSwitch
                                            checked={influencerPermissions?.aovEnabled}
                                            setChecked={setInfluencerAovEnabledPermission}
                                            label={t('EDIT_LINK_SECTION_LINK_PERMISSIONS_AOV_ENABLED_LABEL')}
                                            labelClassName='edit-influencer-switch-label'
                                            checkedBackgroundColor='#658dc4'
                                        />
                                    </div>
                                </div>
                            </CollapsingElement>
                        </div>
                    </CollapsingElement>
                </div>
            </form>
            <ImageCropDialog
                isDialogOpen={isImageCropDialogOpen}
                handleDialogClose={closeImageCropDialog}
                image={cropperTempImage}
                aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                onCropDone={onApplyCrop}
                isRoundCrop={true}
                isInfluencerImageCropper={true}
            />
            <input ref={fileInputRef} type="file" accept="image/*" onChange={onImageChange} style={{ display: 'none' }} />
        </div>
    )
}