import Loader from 'components/Loader/Loader'
import React from 'react'

/**
 * Represents a loader for a value while it is being loading.
 */
export default function InfluencerAnalyticsLoader() {
    return (
        <Loader
            styles={{
                width: '15px',
                height: '15px'
            }}
        />
    )
}