import React, { useEffect, useRef, useState } from 'react'
import './ProgressDialog.css'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { Trans, useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { IoMdClose } from 'react-icons/io'
import { useHistoryContext } from 'contexts/History'

export default function ProgressDialog({
    isDialogOpen,
    handleDialogClose,
    setHasDoneProcessing = () => { }
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [progressValue, setProgressValue] = useState(0)

    const intervalRef = useRef(null)

    useEffect(() => {
        if (isDialogOpen) {
            intervalRef.current = setInterval(() => {
                if (progressValue < 100) {
                    setProgressValue(prev => prev + 5)
                } else {
                    setProgressValue(0)
                }
            }, 50)
        }
    }, [isDialogOpen])

    useEffect(() => {
        if (progressValue === 100) {
            setTimeout(() => {
                setHasDoneProcessing(true)
                setTimeout(() => {
                    stop()
                }, 500)
            }, 1000)
        }
    }, [progressValue])

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: 'linear-gradient(to right, var(--magic-purple-color), #84BFDE, #94f2f7)',
        },
    }))

    function stop() {
        if (intervalRef?.current) {
            clearInterval(intervalRef.current)
        }
        setProgressValue(0)
        setHasDoneProcessing(false)
    }
    
    function close() {
        stop()
        history.goBack()
    }

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            maxHeight={500}
            backdropColor='rgba(255, 255, 255, 0.85)'
            borderRadiusPx={10}
            paddingPx={0}
            width={isMobile ? '100dvh' : 1000}
            isCloseOnTouchOutside={false}
            dialogLayout={<>
                <div className='progress-dialog-container'>
                    <IoMdClose className='progress-dialog-close-button' onClick={close} />
                    <div className="progress-dialog-progress-container">
                        <div className="progress-dialog-progress-title">{t('PROGRESS_DIALOG_PROGRESS_TITLE')}</div>
                        <BorderLinearProgress variant='determinate' value={progressValue} />
                        <div className="progress-dialog-progress-text">
                            <Trans
                                i18nKey={'PROGRESS_DIALOG_PROGRESS_TEXT'}
                                components={{
                                    b: <b />
                                }}
                            />
                        </div>

                    </div>
                </div>
            </>}
        />
    )
}