import React, { useState } from 'react'
import './PaydinSlider.css'
import { Slider } from '@mui/material'

/**
 * Represents a custom slider based on the material UI Slider.
 * see docs: https://mui.com/material-ui/react-slider/
 * @param {number} initialValue - The initial value of the slider 
 * @param {function} onValueChange - The initial value of the slider 
 */
export default function PaydinSlider({
    initialValue,
    onValueChange,
    minValue=1,
    maxValue=3
}) {
    const [zoom, setZoom] = useState(initialValue ? initialValue : 1)

    function onZoom(zoom) {
        onValueChange(zoom)
        setZoom(zoom)
    }

    return (
        <div className='paydin-slider-container'>
            <Slider 
                value={zoom} 
                onChange={e => onZoom(e.target.value)} 
                min={minValue} 
                max={maxValue}
                step={0.02}
            />
        </div>
    )
}