import React, { useEffect, useState } from 'react'
import './ForgotPasswordPage.css'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { ResetPasswordRequestAPI } from 'api/auth/auth'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { resetInfluencerPasswordRequest } from 'api/influencers/influencers'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { formatUsername } from 'utils/utils'

/**
 * Represents the forgot password page.
 * Accepts the 'isInfluencer' to determine whether this page requests a password reset for a merchant or influencer.
 * @param {boolean} isInfluencer - Determins whether this page requests a password reset for a merchant or influencer
 */
export default function ForgotPasswordPage({
    isInfluencer
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [isAbleToResetPassword, setIsAbleToResetPassword] = useState(false)
    const [inputState, setInputState] = useState({
        // If 'isInfluencer' is true then 'merchantUsernameOrEmail' can be only a username, otherwise, 'merchantUsernameOrEmail' can be either a username or email
        merchantUsernameOrEmail: '',
        influencerUsernameOrEmail: ''
    })
    const [isResetPasswordInProgress, setIsResetPasswordInProgress] = useState(false)
    const [dialogProperties, setDialogProperties] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: ''
    })

    useEffect(() => {
        setIsAbleToResetPassword(
            inputState.merchantUsernameOrEmail !== '' &&
            (isInfluencer ? inputState.influencerUsernameOrEmail !== '' : true)
        )
    }, [inputState])

    function resetAllFields() {
        setInputState({
            merchantUsernameOrEmail: '',
            influencerUsernameOrEmail: ''
        })
    }

    function resetPassword() {
        setIsResetPasswordInProgress(true)
        if (isInfluencer) {
            resetInfluencerPasswordRequest(inputState.merchantUsernameOrEmail, inputState.influencerUsernameOrEmail)
                .then(() => {
                    resetAllFields()
                    showPasswordResetEmailSentDialog()
                    setIsResetPasswordInProgress(false)
                })
                .catch(error => {
                    setIsResetPasswordInProgress(false)
                    console.log(error)
                    showPasswordResetErrorDialog()
                })
        } else {
            ResetPasswordRequestAPI(inputState.merchantUsernameOrEmail)
                .then(() => {
                    resetAllFields()
                    showPasswordResetEmailSentDialog()
                    setIsResetPasswordInProgress(false)
                }).catch(error => {
                    setIsResetPasswordInProgress(false)
                    console.log(error)
                    showPasswordResetErrorDialog()
                })
        }
    }

    function showPasswordResetEmailSentDialog() {
        setDialogProperties(prev => ({
            ...prev,
            isOpen: true,
            title: t('RESET_PASSWORD_EMAIL_SENT_DIALOG_TITLE'),
            message: '',
            leftButtonText: t('FORGOT_PASSWORD_PAGE_RESET_PASSWORD_EMAIL_SENT_DIALOG_LEFT_BUTTON_TEXT')
        }))
        history.addBackHandler(closeDialog)
    }

    function showPasswordResetErrorDialog() {
        setDialogProperties(prev => ({
            ...prev,
            isOpen: true,
            title: t('GENERAL_ERROR_DIALOG_TITLE'),
            message: t('GENERAL_ERROR_DIALOG_MESSAGE'),
            leftButtonText: t('GENERAL_ERROR_DIALOG_LEFT_BUTTON_TEXT')
        }))
    }

    function handleMerchantUsernameOrEmail(value) {
        setInputState(prev => ({
            ...prev,
            merchantUsernameOrEmail: formatUsername(value)
        }))
    }

    function handleInfluecnerUsernameOrEmail(value) {
        setInputState(prev => ({
            ...prev,
            influencerUsernameOrEmail: formatUsername(value)
        }))
    }

    function backToLogin() {
        history.goBack()
    }

    function closeDialog() {
        setDialogProperties(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <form className='forgot-password-page-container'>
            <div className="forgot-password-page-content">
                <div className="forgot-password-page-title-section">
                    <div className="forgot-password-page-title auth-page-title">{t('FORGOT_PASSWORD_PAGE_TITLE')}</div>
                </div>
                <div className="forgot-password-page-input-button-section">
                    <EditFieldSectionInput
                        title={isInfluencer ? t('FORGOT_PASSWORD_PAGE_MERCHANT_USERNAME_INPUT_TITLE') : t('FORGOT_PASSWORD_PAGE_USERNAME_INPUT_TITLE')}
                        value={inputState.merchantUsernameOrEmail}
                        setValue={handleMerchantUsernameOrEmail}
                        id="merchant-username"
                    />
                    {
                        isInfluencer && <EditFieldSectionInput
                            title={t('FORGOT_PASSWORD_PAGE_INFLUENCER_USERNAME_INPUT_TITLE')}
                            value={inputState.influencerUsernameOrEmail}
                            setValue={handleInfluecnerUsernameOrEmail}
                            id="influecner-username-or-email"
                        />
                    }
                    <LoaderButton
                        className='forgot-password-reset-password-button'
                        isLoading={isResetPasswordInProgress}
                        buttonText={t('FORGOT_PASSWORD_PAGE_RESET_PASSWORD_BUTTON_TEXT')}
                        isDisabled={!isAbleToResetPassword}
                        renderAsButton={true}
                        onClick={resetPassword} />
                </div>
                <div className="forgot-password-page-back-to-login-button" onClick={backToLogin}>{t('FORGOT_PASSWORD_PAGE_BACK_TO_LOGIN_BUTTON_TEXT')}</div>
            </div>
            <PaydinDialog
                title={dialogProperties.title}
                message={dialogProperties.message}
                isDialogOpen={dialogProperties.isOpen}
                handleDialogClose={closeDialog}
                leftButtonText={dialogProperties.leftButtonText}
                rightButtonText={dialogProperties.rightButtonText}
                leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={false}
            />
        </form>
    )
}