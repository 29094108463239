import React from 'react'
import { FaPodcast } from 'react-icons/fa'

/**
 * Represents the image for indicating that no influencers found.
 * @param {string} className - The class name for this image
 */
export default function NoInfluencersFoundImage(props) {
  return (
    <FaPodcast {...props} />
  )
}