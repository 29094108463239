import React from 'react'
import MultiSelectDialog from 'dialogs/MultiSelectDialog/MultiSelectDialog'
import { useTranslation } from 'react-i18next'
import AddCollectionsDialogCollection from 'components/AddCollectionsDialogCollection/AddCollectionsDialogCollection'
import { getCollections } from 'api/collections/collections'
import { extractCollectionData } from 'utils/dataUtils'
import { 
    ADD_COLLECTIONS_DIALOG_DESKTOP_COLUMN_COUNT, 
    ADD_COLLECTIONS_DIALOG_MOBILE_COLUMN_COUNT 
} from 'constants/general.constants'
import { isMobile } from 'react-device-detect'

/**
 * Represents the dialog for adding collections to the link.
 * @param {boolean} isDialogOpen - Determins whether this dialog is open or not
 * @param {function} handleDialogClose - A function for closing this dialog
 * @param {array} selectedCollections - The list of collections the user selected
 * @param {function} setSelectedCollections - A function that sets the list of selected collections
 * @param {number} selectMaxLimit - The upper bound of items the user can select from the list. If equals to 0, then no limit applied
 * @param {number} selectMinLimit - The lower bound of items the user can select from the list. If equals to 0, then no limit applied
 * @param {function} searchResultFilter - A function for filtering the search results
 */
export default function AddCollectionsDialog({
    isDialogOpen,
    handleDialogClose,
    selectedCollections,
    setSelectedCollections,
    selectMaxLimit = 0,
    selectMinLimit = 0,
    searchResultFilter = items => items
}) {
    const { t } = useTranslation()

    function fetchCollections(q, count, skip) {
        return getCollections(q, count, skip)
    }

    return (
        <MultiSelectDialog
            selectedItems={selectedCollections}
            setSelectedItems={setSelectedCollections}
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            title={t('ADD_COLLECTIONS_DIALOG_TITLE')}
            itemName={t('COLLECTION_ITEM_NAME', { count: selectMaxLimit})}
            viewItemComponent={AddCollectionsDialogCollection}
            selectMaxLimit={selectMaxLimit}
            selectMinLimit={selectMinLimit}
            columnsCount={isMobile ? ADD_COLLECTIONS_DIALOG_MOBILE_COLUMN_COUNT : ADD_COLLECTIONS_DIALOG_DESKTOP_COLUMN_COUNT}
            dataGenerator={fetchCollections}
            dataExtractor={extractCollectionData}
            searchResultFilter={searchResultFilter}
        />
    )
}