import React from 'react'
import './PaydinChartControlSection.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents a view for toggling the display for a single chart and contains data for that chart: title, color and total value
 * @param {string} title - The title for this view
 * @param {string} dotColor - The color for this view's dot (next to the title)
 * @param {string} value - The value for this view
 * @param {boolean} selected - Determins whether this view is selected or not
 * @param {function} onClick - A function to perform when a view is clicked
 */
export default function PaydinChartControlSection({
    title,
    dotColor,
    value,
    selected = false,
    onClick = () => { }
}) {
    return (
        <div className={`${isMobile ? 'mobile-paydin-chart-control-section' : 'paydin-chart-control-section'} ${selected ? 'selected' : ''}`} onClick={onClick}>
            <div className="paydin-chart-control-section-title-container">
                <div className="paydin-chart-control-section-dot" style={{ backgroundColor: selected ? dotColor : '#a5a5a5' }}></div>
                <div className={`${isMobile ? 'mobile-paydin-chart-control-section-title' : 'paydin-chart-control-section-title'} ${selected ? 'selected' : ''}`}>{title}</div>
            </div>
            <div className={`${isMobile ? 'mobile-paydin-chart-control-section-value' : 'paydin-chart-control-section-value'} ${selected ? 'selected' : ''}`}>{value}</div>
        </div>
    )
}