import React from 'react'
import './InfluencerStrip.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

/**
 * Represents a strip of display for an influencer.
 * Used in link/short preview pages.
 * @param {string} image - The image url of the influencer
 * @param {string} username - The username of the influencer
 */
export default function InfluencerStrip({
    image,
    username,
    promocode
}) {
    const { t } = useTranslation()

    return (
        <div className={isMobile ? "mobile-influencer-strip-container" : "influencer-strip-container"}>
            <div className="influencer-strip-title">{t('INFLUENCER_STRIP_TITLE')}</div>
            <div className={isMobile ? "mobile-influencer-strip-content" : "influencer-strip-content"}>
                {image && <ImageBox
                    image={image}
                    className='influencer-strip-image-container'
                    imageClassName='influencer-strip-image'
                    roundImage={true}
                />}
                <div className="influencer-strip-username">{username}{promocode ? ` (${promocode})` : ''}</div>
            </div>
        </div>
    )
}