import React from 'react'

export default function ShoppingBagImage3(props) {
    return (
        <svg {...props} id="dfew" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512">
            <g>
                <path d="m387.525 128h-41.925v-38.4c0-49.4-40.2-89.6-89.6-89.6s-89.6 40.2-89.6 89.6v38.4h-41.925c-33.425 0-61.45 26.1-63.838 59.438l-18.287 256c-1.262 17.662 4.925 35.212 17 48.162 12.063 12.962 29.125 20.4 46.838 20.4h299.625c17.712 0 34.775-7.438 46.838-20.4 12.075-12.95 18.262-30.5 17-48.162l-18.287-256c-2.389-33.338-30.414-59.438-63.839-59.438zm-195.525-38.4c0-35.287 28.713-64 64-64s64 28.713 64 64v38.4h-128zm241.925 384.562c-7.35 7.888-17.325 12.238-28.113 12.238h-299.625c-10.787 0-20.763-4.35-28.113-12.238-7.338-7.888-10.95-18.15-10.187-28.9l18.287-256c1.425-20 18.25-35.662 38.3-35.662h263.05c20.05 0 36.875 15.662 38.3 35.663l18.287 256c.764 10.749-2.849 21.012-10.186 28.899z" fill="rgb(0,0,0)" />
            </g>
        </svg>
    )
}
