import React from 'react'
import './AlreadyHaveAccount.css'
import { useHistoryContext } from 'contexts/History'
import { LOGIN_PAGE_URL } from 'constants/routes.constants'
import { useTranslation } from 'react-i18next'

/**
 * Represents the line "Already have an account? Log in" inside authentication pages.
 */
export default function AlreadyHaveAccount({
    navigationUrl = LOGIN_PAGE_URL
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    function navigate() {
        history.push(navigationUrl)
    }

    return (
        <div className="already-have-account-container">
            <div className="already-have-account-label">{t('ALREADY_HAVE_ACCOUNT_LABEL_TEXT')}</div>
            <div className="already-have-account-login-button" onClick={navigate}>{t('ALREADY_HAVE_ACCOUNT_LOGIN_BUTTON_TEXT')}</div>
        </div>
    )
}