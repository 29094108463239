import React from 'react'
import './PasswordInputRule.css'
import { GrFormClose } from 'react-icons/gr'
import { BiCheck } from 'react-icons/bi'

/**
 * Represents a rule for {@link PasswordInput} component.
 * Displays the rule text and an image indicating its validation status.
 * @param {string} text - The text of this rule
 * @param {boolean} fulfilled - Determins whether this rule is fulfilled or not
 */
export default function PasswordInputRule({
    text,
    fulfilled
}) {
    return (
        <div className={`password-input-rule-container ${fulfilled ? 'fulfilled' : ''}`}>
            {
                fulfilled ? 
                    <BiCheck className='password-input-rule-validation-image-fulfilled password-input-rule-image' /> : 
                    <GrFormClose className='password-input-rule-validation-image password-input-rule-image' />
            }
            <div className="password-input-rule-text">{text}</div>
        </div>
    )
}