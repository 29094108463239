import React from 'react'
import './ShopboardSocialMediaPostProduct.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'

export default function ShopboardSocialMediaPostProduct({
    item
}) {
    return (
        <div className='shopboard-social-media-post-product'>
            <ImageBox
                image={item?.imageUrl}
                className={isMobile ? 'mobile-shopboard-social-media-post-product-image' : 'shopboard-social-media-post-product-image'}
                isImageFitCover={true}
            />
        </div>
    )
}