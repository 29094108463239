import React from 'react'
import './BackButton.css'
import { isMobile } from 'react-device-detect'
import { MdArrowBack } from 'react-icons/md'
import { useHistoryContext } from 'contexts/History'

/**
 * Represents the back button for the dashboard pages.
 * @param {boolean} disableHistoryPopOnClick - If true, clicking on the back button will not trigger history pop, otherwise, it will
 * @param {function} onClick - A function for perforing addition logic when clicking on the back button
 */
export default function BackButton({
    disableHistoryPopOnClick = false,
    onClick = () => { }
}) {
    const history = useHistoryContext()

    function backButtonClick() {
        onClick()
        if (!disableHistoryPopOnClick)
            history.goBack()
    }

    return (
        <div className="back-button-container" onClick={backButtonClick}>
            <MdArrowBack className={isMobile ? "mobile-back-button-image" : "back-button-image"} />
        </div>
    )
}