import React, { useEffect, useState } from 'react'
import './SaveChangesStrip.css'
import { PiWarning } from 'react-icons/pi'
import { useTranslation } from 'react-i18next'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { isMobile } from 'react-device-detect'
import ImageBox from 'components/ImageBox/ImageBox'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { useHistoryContext } from 'contexts/History'
import { useUserDetailsContext } from 'contexts/User'
import { FREE_TRIAL_STRIP_HEIGHT, SHOPEAKS_LOGO_WHITE_URL } from 'constants/general.constants'

export default function SaveChangesStrip({
    isStripShown = false,
    isSaving = false,
    onDiscardClick = () => { },
    onSaveClick = () => { }
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        beginEditing,
        endEditing,
        isFreeUser,
    } = useUserDetailsContext()

    const [shouldAddLeavePageCallbackToHistory, setShouldAddLeavePageCallbackToHistory] = useState(true)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { },
        onDialogClosedLeftButton: () => { },
        onDialogClosedRightButton: () => { }
    })

    useEffect(() => {
        if (isStripShown) {
            beginEditing()
        } else {
            endEditing()
        }

        if (isStripShown && shouldAddLeavePageCallbackToHistory) {
            addLeavePageCallbackToHistory()
            setShouldAddLeavePageCallbackToHistory(false)
        } else if (!isStripShown && !shouldAddLeavePageCallbackToHistory) {
            onStripHidden()
        }
    }, [isStripShown])

    function onSave() {
        onSaveClick()
        onStripHidden()
    }

    function addLeavePageCallbackToHistory() {
        history.addBackHandler(showLeavePageDialog)
    }

    function discardChanges() {
        setShouldAddLeavePageCallbackToHistory(true)
        onDiscardClick()
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function onStripHidden() {
        setShouldAddLeavePageCallbackToHistory(true)
        history.replaceBackHandler()
        history.goBack()
    }

    function showDiscardChangesDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('SAVE_CHNAGES_STRIP_DISCARD_CHANGES_DIALOG_TITLE'),
            message: t('SAVE_CHNAGES_STRIP_DISCARD_CHANGES_DIALOG_MESSAGE'),
            leftButtonText: t('SAVE_CHNAGES_STRIP_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('SAVE_CHNAGES_STRIP_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: discardChanges,
            onDialogClosedRightButton: onStripHidden,
            onDialogClosedLeftButton: () => { }
        }))
    }

    function showLeavePageDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('SAVE_CHNAGES_STRIP_LEAVE_PAGE_DIALOG_TITLE'),
            message: t('SAVE_CHNAGES_STRIP_LEAVE_PAGE_DIALOG_MESSAGE'),
            leftButtonText: t('SAVE_CHNAGES_STRIP_LEAVE_PAGE_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('SAVE_CHNAGES_STRIP_LEAVE_PAGE_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: () => history.goBack(),
            onDialogClosedLeftButton: addLeavePageCallbackToHistory,
            onDialogClosedRightButton: () => { }
        }))
    }

    return (
        <>
            {
                !isMobile && <div className={`save-changes-strip ${isStripShown ? 'shown' : ''}`} style={(isStripShown && isFreeUser()) ? {translate: `0 ${FREE_TRIAL_STRIP_HEIGHT}px`} : null}>
                    <ImageBox
                        className='save-changes-strip-logo-image'
                        image={SHOPEAKS_LOGO_WHITE_URL}
                    />
                    <div className="save-changes-strip-content">
                        <div className="save-changes-strip-titles-section">
                            <PiWarning className='save-changes-strip-title-image' />
                            <div className="save-changes-strip-title">{t('SAVE_CHNAGES_STRIP_TITLE')}</div>
                        </div>
                        <div className="save-changes-strip-buttons-section">
                            <LoaderButton
                                className="save-changes-strip-discard-button"
                                onClick={showDiscardChangesDialog}
                                buttonText={t('SAVE_CHNAGES_STRIP_DISCARD_BUTTON_TEXT')}
                            />
                            <LoaderButton
                                className="save-changes-strip-save-button"
                                isLoading={isSaving}
                                isDisabled={isSaving}
                                onClick={onSave}
                                buttonText={t('SAVE_CHNAGES_STRIP_SAVE_BUTTON_TEXT')}
                            />
                        </div>
                    </div>
                </div>
            }
            <PaydinDialog
                title={dialogState.title}
                message={dialogState.message}
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                onLeftButtonClick={dialogState.leftButtonClickHandler}
                onDialogClosedLeftButton={dialogState.onDialogClosedLeftButton}
                onDialogClosedRightButton={dialogState.onDialogClosedRightButton}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={false}
            />
        </>
    )
}