import PaydinTable from 'components/PaydinTable/PaydinTable'
import { CONTENT_TYPES, MOBILE_MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX, MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS, MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX, MULTILINK_SUBLINK_SELECTION_TYPE, MULTILINK_SUBLINK_TYPES, VIEW_MODES } from 'constants/general.constants'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import './MultilinkExperiencesTable.css'
import { useTranslation } from 'react-i18next'
import MultilinkExperiencesTableRowView from 'components/MultilinkExperiencesTableRowView/MultilinkExperiencesTableRowView'
import MultilinkExperiencesTableHeaderView from 'components/MultilinkExperiencesTableHeaderView/MultilinkExperiencesTableHeaderView'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import { MdOutlineLinkOff } from "react-icons/md";

/**
 * Represents an orders table for a specific link.
 * @param {string} linkId - The id of the link 
 * @param {number} salesCount - The total number of sale made with the link 
 * @param {array} removedColumnIds - An array of column IDs that are required to remove from display
 */
export default function MultilinkExperiencesTable({
    linksOptions=[],
    currency="USD",
}) {
    const { t } = useTranslation()
    const [linkContentDialogState, setLinkContentDialogState] = useState({
        isOpen: false,
        data: {},
        linkType: "",
    })

    function getExperiencesData(count, skip, onSuccess = () => { }, onFailure = () => { }) {
        onSuccess(linksOptions.map((linkOption, index) => {
            return {
                linkId: linkOption?.linkId,
                openLinkPreview: openLinkContentDialog,
                currency,
                url: linkOption?.linkData?.shoppingRedirectUrl,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.NAME]: `Experience ${String.fromCharCode(index + 65)}`,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TYPE]: getUrlSelectionType(linkOption),
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.DEVICE]: linkOption?.deviceOption,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TRAFFIC]: linkOption?.value,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CLICKS]: linkOption?.linkData?.views ?? 0,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.SALES]: linkOption?.linkData?.checkouts ?? 0,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.REVENUE]: linkOption?.linkData?.revenue ?? 0,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CR]: linkOption?.linkData?.views > 0 ? (linkOption?.linkData?.checkouts / linkOption?.linkData?.views) * 100 : 0,
                [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CART_AVERAGE]: linkOption?.linkData?.checkouts > 0 ? linkOption?.linkData?.revenue / linkOption?.linkData?.checkouts : 0,
            }
        }))
    }

    function getUrlSelectionType(linkOption) {
        if (linkOption?.linkData?.linkType == MULTILINK_SUBLINK_TYPES.SHORT) {
            if (linkOption?.linkData?.products && linkOption?.linkData?.products?.length > 0) {
                return MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT
            }
    
            if (linkOption?.linkData?.collections && linkOption?.linkData?.collections?.length > 0) {
                return MULTILINK_SUBLINK_SELECTION_TYPE.COLLECTION
            }
    
            return MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM
        }
        return MULTILINK_SUBLINK_SELECTION_TYPE.LOOK
    }

    function closeLinkContentDialog() {
        setLinkContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function openLinkContentDialog(linkId) {
        let linkType = CONTENT_TYPES.LINK
        linksOptions.map(linkOption => {
            if (linkOption?.linkId === linkId && linkOption?.linkData?.linkType == MULTILINK_SUBLINK_TYPES.SHORT) {
                linkType = CONTENT_TYPES.SHORT
            }
        })
        setLinkContentDialogState(prev => ({
            ...prev,
            isOpen: true,
            data: {
                linkId,
            },
            linkType
        }))
    }
    
    return <>
        <PaydinTable
            visibleRowsCount={linksOptions.length}
            borderRadius='10px'
            totalCount={linksOptions.length}
            rowHeightPx={isMobile ? MOBILE_MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX : MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX}
            rowView={MultilinkExperiencesTableRowView}
            headerView={MultilinkExperiencesTableHeaderView}
            dataGenerator={getExperiencesData}
            noItemsImage={!isMobile ? <MdOutlineLinkOff className='multilinks-expeirences-table-no-items-image' /> : null}
            noItemsText={t('MULTILINK_EXPERIENCES_TABLE_NO_ITEMS_TO_DISPLAY_MESSAGE')}
            shouldPaginate={false}
            showTotalCount={false}
            showPagesSection={false}
        />
        <ContentDialog
            isDialogOpen={linkContentDialogState.isOpen}
            handleDialogClose={closeLinkContentDialog}
            data={linkContentDialogState.data}
            mode={VIEW_MODES.PREVIEW}
            contentType={linkContentDialogState.linkType}
        />
    </>
}
