import React, { useEffect, useRef, useState } from 'react'
import './MultilinkItem.css'
import { useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { TbArrowsSplit } from "react-icons/tb";
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import {
    CONTENT_TYPES,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY,
    NUMBER_TYPES,
    PAYDIN_MENU_OPENING_DIRECTIONS,
    VIEW_MODES
} from 'constants/general.constants'
import { formatNumber } from 'utils/utils'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { BrowserView, isMobile } from 'react-device-detect'
import { environment } from 'conf'
import { DeleteMultilinkAPI } from 'api/links/links'
import { copyToClipboard } from 'services/SystemService'
import { MdOpenInNew, MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsCalendar } from 'react-icons/bs'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import DrawerStyledButtonList from 'components/DrawerStyledButtonList/DrawerStyledButtonList'
import MultilinksImage2 from 'embeddedImages/MultilinksImage2'

/**
 * Represents a short item.
 * @param {string} shortId - The id of this short item 
 * @param {Image/string} image - The image of this short item 
 * @param {Image/string} influencerImage - The image of this short's influencer 
 * @param {number} createdAt - The epoch time number representing the creation date of this short
 * @param {number} views - The number of views for this short
 * @param {number} revenue - The revenue of this short
 * @param {string} currency - The currency of this short
 * @param {string} influencerUsername - The currency of this short
 * @param {function} onDeleteLink - A callback function to invoke after the short was deleted
 */
export default function MultilinkItem({
    multilinkId,
    createdAt,
    currency,
    title,
    linksOptions,
    splitterType,
    onDeleteMultilink = () => { },
    onUpdateMultilink = () => { },
    elementRef = null
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()
    const {
        showApprovalMessage,
        openDrawer,
        closeDrawer,
        openFreeTrialDialog
    } = useGeneralDataContext()

    const {
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()

    const [isMultilinkDeletionDialogOpen, setIsMultilinkDeletionDialogOpen] = useState(false)
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })
    const [multilinkContentDialogState, setMultilinkContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.PREVIEW
    })

    const multilinkItemTitlesContainerRef = useRef(null)
    const multilinkItemTitleRef = useRef(null)

    useEffect(() => {
        multilinkItemTitleRef.current.style.maxWidth = `${multilinkItemTitlesContainerRef?.current?.clientWidth}px`
    }, [])

    function getFormattedDate(epochTime) {
        const date = new Date(epochTime)
        return date.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function editMultilink() {
        if (isMobile) {
            history.goBack()
        } else {
            optionsMenuState.onClose()
        }

        setTimeout(() => {
            setMultilinkContentDialogState({
                isOpen: true,
                mode: VIEW_MODES.EDIT
            })
        }, 200)
    }

    function previewMultilink(closeDialog = false) {
        if (isMobile) {
            if (closeDialog) {
                history.goBack()
            }
        } else {
            if (closeDialog) {
                optionsMenuState.onClose()
            }
        }

        if (closeDialog) {
            setTimeout(() => {
                setMultilinkContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.PREVIEW
                })
            }, 200)
        } else {
            setMultilinkContentDialogState({
                isOpen: true,
                mode: VIEW_MODES.PREVIEW
            })
        }
    }

    async function copyMultilink(closeDialog = false) {
        if (isMobile && closeDialog) {
            closeDrawer()
        }

        const url = `${environment.frontend_url}${userDetails?.username}/${multilinkId}`
        await copyToClipboard(url)
        showApprovalMessage(t('MULTILINK_COPIED_TO_CLIPBOARD'))
    }

    function openDeleteMultilinkConfirmationDialog() {
        if (isMobile) {
            closeDrawer()
            setTimeout(() => {
                setIsMultilinkDeletionDialogOpen(true)
            }, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY)
        } else {
            optionsMenuState.onClose()
            setIsMultilinkDeletionDialogOpen(true)
            history.addBackHandler(() => setIsMultilinkDeletionDialogOpen(false))
        }
    }

    function deleteMultilink() {
        DeleteMultilinkAPI(multilinkId)
            .then(response => {
                closeDialog()
                onDeleteMultilink(multilinkId)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function closeDialog() {
        setIsMultilinkDeletionDialogOpen(false)
    }

    function openOptionsDrawer() {
        openDrawer(true, <>
            <div className="mobile-multilink-item-more-options-menu-container">
                <div className="mobile-multilink-item-more-options-menu-item-line">
                    <div className="mobile-multilink-item-more-options-menu-single-item" onClick={() => copyMultilink(true)}>
                        <MdOutlineContentCopy className="mobile-multilink-item-more-options-menu-single-item-image" />
                        <div className="mobile-multilink-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK')}</div>
                    </div>
                    <div className="mobile-multilink-item-more-options-menu-single-item" onClick={() => previewMultilink(true)}>
                        <MdOpenInNew className="mobile-multilink-item-more-options-menu-single-item-image" />
                        <div className="mobile-multilink-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN')}</div>
                    </div>
                </div>
                <DrawerStyledButtonList
                    buttons={[
                        ...(userDetails?.isAdmin ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            image: <MdOutlineEdit className="mobile-multilink-item-more-options-menu-section-item-image" />,
                            onClick: editMultilink
                        }] : []),
                        ...(userDetails?.isAdmin ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            image: <RiDeleteBin6Line className="mobile-multilink-item-more-options-menu-section-item-image" />,
                            onClick: openDeleteMultilinkConfirmationDialog,
                            color: 'red'
                        }] : [])
                    ]}
                />
            </div>
        </>)
    }

    function getTitle() {
        return !title || title?.length === 0 ? "" : title
    }

    function closeMultilinkContentDialog() {
        setMultilinkContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <div ref={elementRef} className={isMobile ? 'mobile-multilink-item-container' : 'multilink-item-container'}>
            <div className="multilink-item-content">
                <div className={isMobile ? 'mobile-multilink-item-details-container' : 'multilink-item-details-container'}>
                    <MultilinksImage2 className="multilink-item-image" onClick={() => previewMultilink(false)}/>
                    <div ref={multilinkItemTitlesContainerRef} className="multilink-item-details">
                        <div className="multilink-item-buttons">
                            <div className="multilink-item-copy-to-clipboard-button" onClick={() => copyMultilink(false)}>
                                <MdOutlineContentCopy className='multilink-item-copy-to-clipboard-button-image' />
                                <div className="multilink-item-copy-to-clipboard-button-text">{t('SHORTS_ITEM_COPY_TO_CLIPBOARD_BUTTON_TEXT')}</div>
                            </div>
                            <BiDotsVerticalRounded className={isMobile ? 'mobile-multilink-item-statistics-more-options-button' : 'multilink-item-statistics-more-options-button'} onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                        </div>
                        <div className="multilink-item-details-lower-row">
                            <div className="multilink-item-created-at-expired-container">
                                <div className="multilink-item-titles-container">
                                    <div ref={multilinkItemTitleRef} className={isMobile ? "mobile-multilink-item-title" : "multilink-item-title"} onClick={() => previewMultilink(false)}>{getTitle()}</div>
                                </div>
                            </div>
                            <div className='multilink-item-statistics' onClick={() => previewMultilink(false)}>
                                <div className="multilink-item-created-at-container">
                                    <div className="multilink-item-statistics-created-at-container multilink-item-detail-container">
                                        <BsCalendar className='multilink-item-statistics-created-at-image multilink-item-statistics-image' />
                                        <div className={`multilink-item-statistics-created-at-value ${isMobile ? 'mobile-multilink-item-statistics-value' : 'multilink-item-statistics-value'}`}>{getFormattedDate(createdAt)}</div>
                                    </div>
                                </div>
                                <div className="multilink-item-statistics-container">
                                    <div className="multilink-item-statistics-views-container multilink-item-detail-container">
                                        <TbArrowsSplit className='multilink-item-statistics-links-number-image multilink-item-statistics-image' />
                                        <div className={`multilink-item-statistics-views-value ${isMobile ? 'mobile-multilink-item-statistics-value' : 'multilink-item-statistics-value'}`}>{formatNumber(linksOptions?.length, NUMBER_TYPES.SHORT)}</div>
                                        <div className={`multilink-item-statistics-views-value-suffix ${isMobile ? 'mobile-multilink-item-statistics-value-suffix' : 'multilink-item-statistics-value-suffix'}`}>{t('MULTILINK_ITEM_NUMBER_OF_EXPERIENCES_SUFFIX')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BrowserView>
                <PaydinMenu
                    anchorElement={optionsMenuState.anchorElement}
                    isOpen={optionsMenuState.isOpen}
                    onClose={optionsMenuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.LEFT}
                    options={[
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN'),
                            onClick: previewMultilink
                        },
                        ...(userDetails?.isAdmin ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            onClick: editMultilink
                        }] : []),
                        ...(userDetails?.isAdmin ? [{
                            className: 'multilink-item-options-menu-delete-option',
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            onClick: openDeleteMultilinkConfirmationDialog
                        }] : [])
                    ]}
                />
            </BrowserView>
            <PaydinDialog
                title={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_TITLE')}
                message={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_MESSAGE')}
                isDialogOpen={isMultilinkDeletionDialogOpen}
                handleDialogClose={closeDialog}
                leftButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                rightButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                onRightButtonClick={deleteMultilink}
                closeOnRightClick={false}
                isLeftButtonWithLoader={false}
            />
            <ContentDialog
                isDialogOpen={multilinkContentDialogState.isOpen}
                handleDialogClose={closeMultilinkContentDialog}
                data={{
                    multilinkId: multilinkId
                }}
                mode={multilinkContentDialogState.mode}
                contentType={CONTENT_TYPES.MULTILINK}
                onDelete={onDeleteMultilink}
                onSaveSuccess={onUpdateMultilink}
            />
        </div>
    )
}