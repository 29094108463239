import React, { useState } from 'react'
import './StoreOrdersTableRowView.css'
import { isMobile } from 'react-device-detect'
import { TableCell, TableRow, tableCellClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getFormattedDateTime } from 'utils/dateUtils'
import { getCurrencySymbol } from 'services/currency'
import { formatNumber } from 'utils/utils'
import { LINK_TYPES, NUMBER_TYPES, ORDERS_TABLE_PRODUCT_LIST_GAP, ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX, ORDERS_TABLE_ROW_HEIGHT, ORDERS_TABLE_STATUS_OPTIONS, SHORT_URL_SELECTION_TYPE } from 'constants/general.constants'
import { styled } from '@mui/system'
import ImageBox from 'components/ImageBox/ImageBox'
import LinkUrlTypeTag from 'components/LinkUrlTypeTag/LinkUrlTypeTag'
import ShortsImage from 'embeddedImages/ShortsImage'
import { SiShopify } from "react-icons/si";
import { BiLink } from 'react-icons/bi'
import { SHOPBOARD_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { IoIosArrowDown } from 'react-icons/io'
import Loader from 'components/Loader/Loader'
import OrderProduct from 'components/OrderProduct/OrderProduct'
import { extractOrderProduct } from 'utils/dataUtils'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import { getStoreOrderDetails } from 'api/store/store'
import Tooltip from 'components/Tooltip/Tooltip'

/**
 * Represents a row view for the orders table
 * @param {object} rowData - The data object of a specific row
 */
export default function StoreOrdersTableRowView({
    rowData
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [isExpanded, setIsExpanded] = useState(false)
    const [isLoadingMoreDetails, setIsLoadingMoreDetails] = useState(true)
    const [orderProducts, setOrderProducts] = useState([])

    const productLineHeight = (ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX * rowData?.totalProductsCount) + (ORDERS_TABLE_PRODUCT_LIST_GAP * (rowData?.totalProductsCount - 1)) + 20
    const product3LineHeight = (ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX * 3) + (ORDERS_TABLE_PRODUCT_LIST_GAP * (rowData?.totalProductsCount - 1)) + 20

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide all borders
        '&:not(:last-child) td, & th': {
            border: 0,
        }
    }))

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            textAlign: 'center',
            padding: '0',
        },
    }))

    const orderProductsListStyle = {
        ...(rowData?.totalProductsCount > 3 ? {
            height: `${product3LineHeight}px`,
            overflowY: 'scroll'
        } : {
            height: `${productLineHeight}px`,
        }),
    }

    const orderProductsListLoaderStyle = {
        height: rowData?.totalProductsCount > 3 ? `${product3LineHeight}px` : `${productLineHeight}px`
    }

    function renderGoToLinkImage(linkId, linkType) {
        if (linkType === LINK_TYPES.SHORT) {
            return <ShortsImage className='store-orders-table-row-view-view-link-button' onClick={() => viewLink(linkId, linkType)} />
        }

        return <BiLink className='store-orders-table-row-view-view-link-button' onClick={() => viewLink(linkId, linkType)} />
    }

    function getStatusBackgroundColor(status) {
        switch (status) {
            case ORDERS_TABLE_STATUS_OPTIONS.PAID: return '#00af50'
            case ORDERS_TABLE_STATUS_OPTIONS.REFUNDED: return '#f4b184'
            // PARTIALLY REFUNDED
            default: return '#ffd95e'
        }
    }

    function getStatusTextColor(status) {
        switch (status) {
            case ORDERS_TABLE_STATUS_OPTIONS.PAID: return 'white'
            case ORDERS_TABLE_STATUS_OPTIONS.REFUNDED: return '#432704'
            // PARTIALLY REFUNDED
            default: return '#987c1a'
        }
    }

    function viewLink(linkId, linkType) {
        if (linkType === LINK_TYPES.LOOK) {
            rowData?.openLinkContentDialog(linkId)
        } else if (linkType === LINK_TYPES.SHORT) {
            rowData?.openShortContentDialog(linkId)
        } else if (linkType === LINK_TYPES.SHOPBOARD) {
            history.push(SHOPBOARD_PAGE_URL)
        }
    }

    function onExpandOrder() {
        setIsExpanded(prev => !prev)
        if (orderProducts.length === 0) {
            getStoreOrderDetails(rowData?.id)
                .then(orderProducts => {
                    setOrderProducts(orderProducts?.data?.map(orderProduct => extractOrderProduct(orderProduct)))
                })
                .catch(console.log)
                .finally(() => {
                    setIsLoadingMoreDetails(false)
                })
        }
    }

    function hasImage(image) {
        return ![null, undefined, ''].includes(image)
    }

    function getLinkImage() {
        return hasImage(rowData?.linkDetails?.linkImage) ? rowData?.linkDetails?.linkImage : rowData?.linkDetails?.linkDefaultImage
    }

    function getLinkSource(linkType) {
        switch (linkType) {
            case LINK_TYPES.LOOK: return 'Instant Store'
            case LINK_TYPES.SHORT: return 'Short'
            default: return 'Shop the Feed'
        }
    }

    function renderRowContent() {
        return isMobile ? <StyledTableCell padding="normal" align={'left'}>
            <div className='mobile-store-orders-table-row-view'>
                <IoIosArrowDown className={`mobile-store-orders-table-row-view-expand-button-image ${isExpanded ? 'expanded' : ''}`} onClick={onExpandOrder} />
                <div className='mobile-store-orders-table-row-view-content' onClick={() => viewLink(rowData?.linkDetails?.linkId, rowData?.linkDetails?.linkType)}>
                    <div className="mobile-store-orders-table-row-view-order-details">
                        <div className="mobile-store-orders-table-row-view-order-details-date">{getFormattedDateTime(new Date(rowData?.createdAt), false)}</div>
                        <div className='mobile-store-orders-table-row-view-items'>{t('ORDERS_TABLE_ROW_TOTAL_PRODUCTS_COUNT', { count: rowData?.totalProductsCount })}</div>
                        <div className='mobile-store-orders-table-row-view-total'>{`${getCurrencySymbol(rowData?.currency)}${formatNumber(rowData?.totalPrice, NUMBER_TYPES.PRICE)}`}</div>
                    </div>
                    <div className="mobile-store-orders-table-row-view-source">
                        {
                            rowData?.linkDetails ?
                                rowData?.linkDetails?.linkType == LINK_TYPES.SHOPBOARD ? <LinkUrlTypeTag
                                    type={LINK_TYPES.SHOPBOARD}
                                    isSmall={false}
                                /> : <>
                                        {
                                            getLinkImage() && <ImageBox
                                                    image={getLinkImage()}
                                                    className='store-orders-table-row-view-link-image'
                                                    isImageFitCover={true}
                                                    showBorder={true}
                                            />
                                        }
                                        <div className="store-orders-table-row-view-link-details">
                                            {
                                                rowData?.linkDetails?.linkTitle && <MultiLineText
                                                    text={rowData?.linkDetails?.linkTitle}
                                                    lines={2}
                                                    className='mobile-store-orders-table-row-view-link-title'
                                                />
                                            }
                                            {
                                                rowData?.linkDetails?.linkType === LINK_TYPES.SHORT && <LinkUrlTypeTag
                                                    type={rowData?.linkDetails?.urlType}
                                                    isSmall={true}
                                                />
                                            }
                                            {
                                                rowData?.linkDetails?.linkType === LINK_TYPES.LOOK && <LinkUrlTypeTag
                                                    type={rowData?.linkDetails?.linkType}
                                                    isSmall={true}
                                                />
                                            }
                                        </div>
                                    </> : <>
                                <LinkUrlTypeTag
                                    type={SHORT_URL_SELECTION_TYPE.ORGANIC}
                                    isSmall={true}
                                />
                            </>
                        }
                    </div>
                    <div className="mobile-store-orders-table-row-view-influencer">
                        {
                            rowData?.influencerImage ? <ImageBox
                                image={rowData?.influencerImage}
                                className='store-orders-table-row-view-influencer-image'
                                roundImage={true}
                                showBorder={true}
                            /> : <div className='store-orders-table-row-view-influencer-image'></div>
                        }
                    </div>
                </div>
            </div>
        </StyledTableCell> : <>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="store-orders-table-row-view store-orders-table-row-view-date-frame">
                    <IoIosArrowDown className={`store-orders-table-row-view-expand-button-image ${isExpanded ? 'expanded' : ''}`} onClick={onExpandOrder} />
                    <div className="store-orders-table-row-view-date-container">
                        <div className="store-orders-table-row-view-date">{getFormattedDateTime(new Date(rowData?.createdAt))}</div>
                        <div className='store-orders-table-row-view-status' style={{ backgroundColor: getStatusBackgroundColor(rowData?.status), color: getStatusTextColor(rowData?.status) }}>{rowData?.status}</div>
                    </div>
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="store-orders-table-row-view store-orders-table-row-view-source">
                    {
                        rowData?.linkDetails ? 
                            rowData?.linkDetails?.linkType == LINK_TYPES.SHOPBOARD ? <LinkUrlTypeTag
                                type={LINK_TYPES.SHOPBOARD}
                                isSmall={false}
                            /> : <>
                                {
                                    getLinkImage() && <ImageBox
                                        image={getLinkImage()}
                                        className='store-orders-table-row-view-link-image'
                                        isImageFitCover={true}
                                        showBorder={true}
                                    />
                                }
                                <div className="store-orders-table-row-view-link-details">
                                    {
                                        rowData?.linkDetails?.linkTitle && <MultiLineText
                                            text={rowData?.linkDetails?.linkTitle}
                                            lines={2}
                                            className='store-orders-table-row-view-link-title'
                                        />
                                    }
                                    {
                                        rowData?.linkDetails?.linkType === LINK_TYPES.SHORT && <LinkUrlTypeTag
                                            type={rowData?.linkDetails?.urlType}
                                            isSmall={true}
                                        />
                                    }
                                    {
                                        rowData?.linkDetails?.linkType === LINK_TYPES.LOOK && <LinkUrlTypeTag
                                            type={rowData?.linkDetails?.linkType}
                                            isSmall={true}
                                        />
                                    }
                                </div>
                            </> : 
                        <LinkUrlTypeTag
                            type={SHORT_URL_SELECTION_TYPE.ORGANIC}
                            isSmall={false}
                        />
                    }
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='store-orders-table-row-view store-orders-table-row-view-items'>{t('ORDERS_TABLE_ROW_TOTAL_PRODUCTS_COUNT', { count: rowData?.totalProductsCount })}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='store-orders-table-row-view store-orders-table-row-view-total'>{`${getCurrencySymbol(rowData?.currency)}${formatNumber(rowData?.totalPrice, NUMBER_TYPES.PRICE)}`}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'left'}>
                <div className="store-orders-table-row-view store-orders-table-row-view-influencer">
                    {
                        rowData?.influencerImage && <ImageBox
                            image={rowData?.influencerImage}
                            className='store-orders-table-row-view-influencer-image'
                            roundImage={true}
                            showBorder={true}
                        />
                    }
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className="store-orders-table-row-view store-orders-table-row-view-actions">
                    {
                        rowData?.linkDetails?.linkId ? <Tooltip
                            image={renderGoToLinkImage(rowData?.linkDetails?.linkId, rowData?.linkDetails?.linkType)}
                            tooltipText={t('STORE_ORDERS_TABLE_ROW_ACTIONS_OPEN_LINK_TOOLTIP_TEXT', { source: getLinkSource(rowData?.linkDetails?.linkType) })}
                            width='30px'
                            height='30px'
                            swapHorizontal={true}
                        /> : <div className='store-orders-table-row-view-view-link-button-placeholder'></div>
                    }
                    {
                        rowData?.url && <Tooltip
                            image={
                                <a href={rowData?.url} target='_blank'>
                                    <div className="store-orders-table-row-view-view-in-shopify-button-container">
                                        <SiShopify className='store-orders-table-row-view-view-in-shopify-button' />
                                    </div>
                                </a>
                            }
                            width='30px'
                            height='30px'
                            swapHorizontal={true}
                            tooltipText={t('STORE_ORDERS_TABLE_ROW_ACTIONS_OPEN_IN_SHOPIFY_TOOLTIP_TEXT')}
                        />
                    }
                </div>
            </StyledTableCell>
        </>
    }

    return (
        <>
            {
                isMobile ? <TableRow style={{ height: `${ORDERS_TABLE_ROW_HEIGHT}px` }}>
                    {renderRowContent()}
                </TableRow> : <StyledTableRow style={{ height: `${ORDERS_TABLE_ROW_HEIGHT}px` }}>
                    {renderRowContent()}
                </StyledTableRow>
            }
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={isMobile ? 1 : 6}>
                    <CollapsingElement
                        expanded={isExpanded}
                    >
                        {
                            isLoadingMoreDetails ? <div className="orders-table-row-view-more-details-loader-container" style={orderProductsListLoaderStyle}>
                                <Loader styles={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    inset: 0,
                                    margin: 'auto'
                                }} />
                            </div> : <div className={isMobile ? "mobile-orders-table-row-view-more-details" : "orders-table-row-view-more-details"} style={orderProductsListStyle}>
                                {
                                    orderProducts?.map(orderProduct => <OrderProduct
                                        key={orderProduct?.title + '__' + orderProduct?.variantTitle}
                                        title={orderProduct?.title}
                                        imageUrl={orderProduct?.imageUrl}
                                        variantTitle={orderProduct?.variantTitle}
                                        price={orderProduct?.price}
                                        quantity={orderProduct?.quantity}
                                        currency={rowData?.currency}
                                    />)
                                }
                            </div>
                        }
                    </CollapsingElement>
                </TableCell>
            </TableRow>
        </>
    )
}