import React from 'react'

export default function ErrorImage(props) {
    return (
        <svg {...props} version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 455.111 455.111" style={{ enableBackground: 'new 0 0 512 512' }}>
            <g>
                <circle cx="227.556" cy="227.556" r="227.556" fill="#d1403f" dataoriginal="#e24c4b" opacity="1">
                </circle>
                <path d="M455.111 227.556c0 125.156-102.4 227.556-227.556 227.556-72.533 0-136.533-32.711-177.778-85.333 38.4 31.289 88.178 49.778 142.222 49.778 125.156 0 227.556-102.4 227.556-227.556 0-54.044-18.489-103.822-49.778-142.222 52.623 41.243 85.334 105.243 85.334 177.777z" fill="#d1403f" data-original="#d1403f" opacity="1">
                </path>
                <path d="M331.378 331.378c-8.533 8.533-22.756 8.533-31.289 0l-72.533-72.533-72.533 72.533c-8.533 8.533-22.756 8.533-31.289 0-8.533-8.533-8.533-22.756 0-31.289l72.533-72.533-72.533-72.533c-8.533-8.533-8.533-22.756 0-31.289 8.533-8.533 22.756-8.533 31.289 0l72.533 72.533 72.533-72.533c8.533-8.533 22.756-8.533 31.289 0 8.533 8.533 8.533 22.756 0 31.289l-72.533 72.533 72.533 72.533c8.533 8.533 8.533 22.755 0 31.289z" fill="#ffffff" data-original="#ffffff">
                </path>
            </g>
        </svg>
    )
}
