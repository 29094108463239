import React, { useEffect, useRef, useState } from 'react'
import './MagicStorePage.css'
import { useTranslation } from 'react-i18next'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import { CONTENT_TYPES, PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS, RESIZE_IMAGE_MAX_HEIGHT, RESIZE_IMAGE_MAX_WIDTH, VIEW_MODES } from 'constants/general.constants'
import { resizeImage } from 'services/imageUtils'
import { LINKS_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import ProgressDialog from 'dialogs/ProgressDialog/ProgressDialog'
import PostcardImage from 'embeddedImages/PostcardImage'
import SparkleSmartphoneImage from 'embeddedImages/SparkleSmartphoneImage'
import MenuCheckImage from 'embeddedImages/MenuCheckImage'
import SmartStoreVideo from 'components/SmartStoreVideo'
import { isMobile } from 'react-device-detect'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'

export default function MagicStorePage() {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const {
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()
    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()

    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false)
    const [hasFinishedProcessingImage, setHasFinishedProcessingImage] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState('')
    const [newLinkData, setNewLinkData] = useState({})
    const [isContentDialogOpen, setIsContentDialogOpen] = useState(false)

    const fileInputRef = useRef(null)

    useEffect(() => {
        if (hasFinishedProcessingImage) {
            history.goBack()
            setTimeout(() => {
                setIsContentDialogOpen(true)
                setHasFinishedProcessingImage(false)
            }, 200)
        }
    }, [hasFinishedProcessingImage])

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    function closeProgressDialog() {
        setIsProgressDialogOpen(false)
    }

    function closeContentDialog() {
        setIsContentDialogOpen(false)
    }

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        setNewLinkData({
            images: [croppedImage]
        })
        setTimeout(() => { // this code prevents the progress dialog from closing
            setIsProgressDialogOpen(true)
        }, 200)
    }

    function goToLinks() {
        history.goBack()
        setTimeout(() => {
            history.push(LINKS_PAGE_URL)
        }, 200)
    }

    function uploadImageFromDevice() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            fileInputRef.current.value = null
            fileInputRef?.current?.click()
        }
    }

    return (
        <div className='magic-store-page'>
            <div className="magic-store-gradient-background"></div>
            <div className={isMobile ? "mobile-magic-store-page-content" : "magic-store-page-content"}>
                {
                    isMobile && <ContentDialogTopbar
                        title={t('MAGIC_STORE_PAGE_TOPBAR_TITLE')}
                        onBackButtonClick={() => history.goBack()}
                    />
                }
                <div className={isMobile ? "mobile-magic-store-page-title" : "magic-store-page-title"}>{t('MAGIC_STORE_PAGE_TITLE')}</div>
                <div className={isMobile ? "mobile-magic-store-page-body" : "magic-store-page-body"}>
                    <div className={isMobile ? "mobile-magic-store-page-left-section" : "magic-store-page-left-section"}>
                        <div className={isMobile ? "mobile-magic-store-page-left-section-title" : "magic-store-page-left-section-title"}>{t('MAGIC_STORE_PAGE_BODY_LEFT_SECTION_TITLE')}</div>
                        <div className="magic-store-page-left-section-content">
                            <div className="magic-store-page-left-section-content-option">
                                <PostcardImage className='magic-store-page-left-section-content-option-image' />
                                <div className={isMobile ? "mobile-magic-store-page-left-section-content-option-text" : "magic-store-page-left-section-content-option-text"}>{t('MAGIC_STORE_PAGE_BODY_LEFT_SECTION_OPTION_1_TEXT')}</div>
                            </div>
                            <div className="magic-store-page-left-section-content-option">
                                <SparkleSmartphoneImage className='magic-store-page-left-section-content-option-image' />
                                <div className={isMobile ? "mobile-magic-store-page-left-section-content-option-text" : "magic-store-page-left-section-content-option-text"}>{t('MAGIC_STORE_PAGE_BODY_LEFT_SECTION_OPTION_2_TEXT')}</div>
                            </div>
                            <div className="magic-store-page-left-section-content-option">
                                <MenuCheckImage className='magic-store-page-left-section-content-option-image' />
                                <div className={isMobile ? "mobile-magic-store-page-left-section-content-option-text" : "magic-store-page-left-section-content-option-text"}>{t('MAGIC_STORE_PAGE_BODY_LEFT_SECTION_OPTION_3_TEXT')}</div>
                            </div>
                        </div>
                        <div className={isMobile ? "mobile-magic-store-page-create-button" : "magic-store-page-create-button"} onClick={uploadImageFromDevice}>{t('MAGIC_STORE_PAGE_CREATE_BUTTON_TEXT')}</div>
                    </div>
                    <div className={isMobile ? "mobile-magic-store-page-right-section" : "magic-store-page-right-section"}>
                        <SmartStoreVideo className='magic-store-video-frame' />
                    </div>
                </div>
            </div>
            <input ref={fileInputRef} type="file" accept="image/jpeg,image/png,image/webp" onChange={onImageChange} style={{ display: 'none' }} />

            <ImageCropDialog
                isDialogOpen={isImageCropDialogOpen}
                handleDialogClose={closeImageCropDialog}
                image={cropperTempImage}
                aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                onCropDone={onApplyCrop}
                rightButtonText={t('MAGIC_STORE_PAGE_CROPPER_RIGHT_BUTTON_TEXT')}
            />
            <ProgressDialog
                isDialogOpen={isProgressDialogOpen}
                handleDialogClose={closeProgressDialog}
                setHasDoneProcessing={setHasFinishedProcessingImage}
            />
            <ContentDialog
                isDialogOpen={isContentDialogOpen}
                handleDialogClose={closeContentDialog}
                mode={VIEW_MODES.CREATE}
                data={{
                    linkData: newLinkData
                }}
                contentType={CONTENT_TYPES.LINK}
                onSaveSuccess={goToLinks}
            />
        </div>
    )
}