import ImageBox from 'components/ImageBox/ImageBox'
import React from 'react'
import './PreviewLinkProductsListItem.css'

export default function PreviewLinkProductsListItem({
    item
}) {
    return (
        <div className='preview-link-products-list-item'>
            <ImageBox
                image={item?.imageUrl}
                className='preview-link-products-list-item-image'
                showBorder={true}
                isImageFitCover={true}
            />
        </div>
    )
}