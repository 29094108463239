import React, { useState } from 'react'
import './ConfirmDisconnectDialog.css'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { Trans, useTranslation } from 'react-i18next'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useHistoryContext } from 'contexts/History'
import { isMobile } from 'react-device-detect'

export default function ConfirmDisconnectDialog({
    isDialogOpen,
    handleDialogClose,
    isDisconnecting = false,
    onDisconnectClick = () => { }
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [disconnectConfirmText, setDisconnectConfirmText] = useState('')

    return (
        <PaydinDialog
            isDialogOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            dialogLayout={<>
                <div className='disconnect-confirm-dialog'>
                    <div className="disconnect-confirm-dialog-titles-container">
                        <div className="disconnect-confirm-dialog-title">{t('ACCOUNT_PAGE_CONFIRM_DISCONNECT_STORE_DIALOG_TITLE')}</div>
                        <div className="disconnect-confirm-dialog-subtitle">
                            <Trans
                                i18nKey={'ACCOUNT_PAGE_CONFIRM_DISCONNECT_STORE_DIALOG_SUBTITLE'}
                                components={{
                                    p: <b />
                                }}
                            />
                        </div>
                    </div>
                    <EditFieldSectionInput
                        value={disconnectConfirmText}
                        setValue={setDisconnectConfirmText}
                        placeholder={t('ACCOUNT_PAGE_CONFIRM_DISCONNECT_STORE_DIALOG_CONFIRM_TEXT')}
                        enlargedTextField={true}
                        showTopSection={false}
                        id='confirm-text'
                    />
                    <div className={isMobile ? "mobile-disconnect-confirm-dialog-buttons" : "disconnect-confirm-dialog-buttons"}>
                        <LoaderButton
                            onClick={() => history.goBack()}
                            className="disconnect-confirm-dialog-left-button"
                            buttonText={t('ACCOUNT_PAGE_CONFIRM_DISCONNECT_STORE_DIALOG_LEFT_BUTTON_TEXT')}
                        />
                        <LoaderButton
                            isLoading={isDisconnecting}
                            loaderArchColor='grey'
                            onClick={onDisconnectClick}
                            isDisabled={disconnectConfirmText !== t('ACCOUNT_PAGE_CONFIRM_DISCONNECT_STORE_DIALOG_CONFIRM_TEXT')}
                            className="disconnect-confirm-dialog-right-button"
                            buttonText={t('ACCOUNT_PAGE_CONFIRM_DISCONNECT_STORE_DIALOG_RIGHT_BUTTON_TEXT')}
                        />
                    </div>
                </div>
            </>}
        />
    )
}