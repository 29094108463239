import React from 'react'
import './MobileButtonStrip.css'

export default function MobileButtonStrip({
    buttons = [],
    length = 4,
    paddingInline = '8px',
    hasBottomLine = false,
    stripButtonTextSize = '13px',
    isFixed = false,
    top = '0px'
}) {
    const stripStyles = {
        ...(isFixed ? {
            position: 'fixed',
            top,
            right: 0,
            left: 0,
            zIndex: 300,
            backgroundColor: 'white'
        } : {}),
        gridTemplateColumns: `repeat(${length}, 1fr)`,
        paddingInline
    }

    const stripButtonTextStyles = {
        fontSize: stripButtonTextSize
    }

    return (
        <div style={stripStyles} className={`mobile-button-strip ${hasBottomLine ? 'bottom-line' : ''}`}>
            {
                buttons.map(button => <div key={button?.text} onClick={button?.onClick} className={`mobile-button-strip-button ${button?.isDisabled ? 'disabled' : ''} ${button?.isSelected ? 'selected' : ''}`}>
                    {button?.image}
                    <div className="mobile-button-strip-button-text" style={stripButtonTextStyles}>{button?.text}</div>
                </div>)
            }
        </div>
    )
}