import React from 'react'
import './ContentDialogTopbar.css'
import BackButton from 'components/BackButton/BackButton'
import { isMobile } from 'react-device-detect'
import MultiLineText from 'components/MultiLineText/MultiLineText'

/**
 * Represents a topbar for all the view components: LinkView, ShortView and InfluencerView.
 * @param {string} title - The title of the topbar
 * @param {string} buttonText - The text for the button of the topbar
 * @param {boolean} isButtonDisabled - Determins whether the topbar's button is disabled or not
 * @param {function} onBackButtonClick - A callback to perform when the topbar's back button is clicked
 * @param {function} onButtonClick - A callback to perform when the topbar's button is clicked
 * @param {Node} rightElement - The right element of the topbar
 */
export default function ContentDialogTopbar({
    title = '',
    onBackButtonClick = () => { },
    rightElement = null,
    leftElement = null,
    showLeftElement = true,
}) {
    return (
        <div className={isMobile ? "mobile-page-view-topbar" : "page-view-topbar"} >
            <div style={{ width: isMobile ? '20%' : '33.3%' }}>
                {
                    showLeftElement && (
                        leftElement ?? <BackButton
                            disableHistoryPopOnClick={true}
                            onClick={onBackButtonClick}
                        />
                    )
                }
            </div>

            <div style={{ width: isMobile ? '60%' : '33.3%' }}>
                <MultiLineText
                    text={title}
                    className='page-view-title'
                    lines={1}
                    oneWord={true}
                />
            </div>

            <div style={{ width: isMobile ? '20%' : '33.3%' }}>
                { rightElement }
            </div>
        </div>
    )
}