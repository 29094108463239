import React from 'react'
import './ShopboardConnectComp.css'
import { SHOPBOARD_SOCIALS } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import SocialConnectButton from 'components/SocialConnectButton/SocialConnectButton'

export default function ShopboardConnectComp({
    social = SHOPBOARD_SOCIALS.INSTAGRAM
}) {
    const { t } = useTranslation()

    function getTitle(social) {
        switch (social) {
            case SHOPBOARD_SOCIALS.INSTAGRAM: return t('SHOPBOARD_CONNECT_INSTAGRAM_TITLE')
            case SHOPBOARD_SOCIALS.TIKTOK: return t('SHOPBOARD_CONNECT_TIKTOK_TITLE')
            default: return t('SHOPBOARD_CONNECT_PINTEREST_TITLE')
        }
    }

    function getSubtitle(social) {
        switch (social) {
            case SHOPBOARD_SOCIALS.INSTAGRAM: return t('SHOPBOARD_CONNECT_INSTAGRAM_SUBTITLE')
            case SHOPBOARD_SOCIALS.TIKTOK: return t('SHOPBOARD_CONNECT_TIKTOK_SUBTITLE')
            default: return t('SHOPBOARD_CONNECT_PINTEREST_SUBTITLE')
        }
    }

    return (
        <div className='shopboard-connect'>
            <div className="shopboard-connect-title">{getTitle(social)}</div>
            <div className="shopboard-connect-subtitle">{getSubtitle(social)}</div>
            <SocialConnectButton social={social} stretchWidth={false} />
        </div>
    )
}