import React from 'react'
import './DontHaveAccount.css'
import { SIGN_UP_REQUEST_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import { useTranslation } from 'react-i18next'

/**
 * Represents the line "Don't have an account? Sign up" inside authentication pages.
 */
export default function DontHaveAccount({
    navigationUrl = SIGN_UP_REQUEST_PAGE_URL
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    function navigate() {
        history.push(navigationUrl)
    }

    return (
        <div className="dont-have-account-container">
            <div className="dont-have-account-text">{t('DONT_HAVE_ACCOUNT_TEXT')}</div>
            <div className="dont-have-account-sign-up-button" onClick={navigate}>{t('DONT_HAVE_ACCOUNT_SIGN_UP_BUTTON_TEXT')}</div>
        </div>
    )
}