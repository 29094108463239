import React from 'react'
import './SettingsInput.css'

/**
 * Represents an input for a settings page.
 * @param {string} name - The name of the input
 * @param {string} value - The value of the input
 * @param {boolean} isDisabled - If true, disables the input, otherwise, enables the input
 * @param {string} prefix - A prefix for the input. If it's non-empty value, prepends it to the start of the input, otherwise, hides the prefix
 * @param {string} placeholder - A placeholder text for the input
 * @param {function} onValueChange - A function to perform whenever the input value was changed
 */
export default function SettingsInput({
    name,
    value,
    content,
    isDisabled = false,
    prefix = null,
    placeholder = '',
    onValueChange = () => { }
}) {
    return (
        <div className="settings-input-container">
            {
                content ?? (
                    <>
                        {prefix && <div className="settings-input-prefix">{prefix}</div>}
                        <input className="settings-input" disabled={isDisabled} placeholder={placeholder} value={value} name={name} onChange={onValueChange} />
                    </>
                )
            }
        </div>
    )
}