import React from 'react'
import './AppearanceOptionPicker.css'
import { APPEARANCE_OPTION_PICKER_DEFAULT_BORDER_WIDTH_PX } from 'constants/general.constants'

/**
 * Represents an options' picker for the store settings.
 * @param {DOMElement} selectedOption - The selected option's index
 * @param {function} setSelectedOption - A function for setting the selected option's index
 * @param {array} options - An options array to pick from
 */
export default function AppearanceOptionPicker({
    selectedOptionBorderColor,
    selectedOption,
    setSelectedOption,
    valueAsBorderRadius = false,
    gap = '10px',
    justifyContent = null,
    borderWidthPx = APPEARANCE_OPTION_PICKER_DEFAULT_BORDER_WIDTH_PX,
    options = []
}) {
    return (
        <div className='appearance-option-picker-container' style={{ gap, justifyContent }}>
            {
                options.map((option, index) => <div key={option?.key} className={`appearance-option-picker-option-container ${selectedOption === option?.key ? 'selected' : ''}`}>
                    <div
                        className={`appearance-option-picker-option ${selectedOption === index ? 'selected' : ''}`}
                        onClick={() => setSelectedOption(option?.key)}
                        style={{
                            border: `${borderWidthPx}px solid ${selectedOption === option?.key ? (selectedOptionBorderColor ?? '#cccccc') : '#cccccc'}`,
                            borderRadius: valueAsBorderRadius ? option?.value : 0,
                            ...(option?.styles)
                        }}
                    >
                        {option?.image}
                    </div>
                    {option?.text && <div className="appearance-option-picker-option-text" style={{ fontWeight: selectedOption === option?.key ? 600 : 400 }}>{option?.text}</div>}
                </div>
                )
            }
        </div>
    )
}