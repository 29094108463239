import React, { useEffect, useMemo, useState } from 'react'
import './PaydinTable.css'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { generateRandomInteger, stableSort } from 'utils/utils'
import Loader from 'components/Loader/Loader'
import { isMobile } from 'react-device-detect'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'

/**
 * Represents a basic table.
 * @param {string} headerBackgroundColor - The background color for the headers
 * @param {string} headerTextColor - The text color for the headers
 * @param {string} border - A border for the table
 * @param {string} borderRadius - A border radius for the table
 * @param {string} boxShadow - A box shadow for the table
 * @param {number} visibleRowsCount - The number of visible rows at a time in the table
 * @param {number} totalCount - The total quantity of items to display in the table
 * @param {number} rowHeightPx - The height of a single row in the table, in pixels
 * @param {function} dataGenerator - The function that generates data to display in the table
 * @param {Node} noItemsImage - The image to display when there are no items to display
 * @param {Node} noItemsText - The text to display when there are no items to display
 * @param {component} rowView - A generic view that represents a row in the table
 * @param {component} headerView - A generic view that represents a header in the table
 * @param {array} removedColumnIds - An array of column IDs to remove from display
 * @param {boolean} shouldPaginate - If true, the table will perform pagination, otherwise not
 */
export default function PaydinTable({
    headerBackgroundColor = '#dadada',
    headerTextColor = 'black',
    border = '',
    borderRadius = '0',
    boxShadow = '0 0 3px 3px #cccccc',
    visibleRowsCount = 5,
    totalCount = null,
    rowHeightPx = 0,
    dataGenerator = () => { },
    noItemsImage = [],
    noItemsText,
    rowView: RowView,
    headerView: HeaderView,
    hideHeaderInMobile = false,
    removedColumnIds,
    shouldPaginate = true,
    showTotalCount = true,
    showPagesSection = true,
}) {
    const [isDataLoading, setIsDataLoading] = useState(true)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [page, setPage] = useState(0)
    const [content, setContent] = useState([])
    const [shouldCheckItemsAvailability, setShouldCheckItemsAvailability] = useState(false)

    const visibleRows = useMemo(() => {
        return stableSort(content, getComparator(order, orderBy)).slice(
            page * visibleRowsCount,
            page * visibleRowsCount + visibleRowsCount,
        )
    }, [content, order, orderBy, page])

    const emptyRows = useMemo(() => {
        return (isDataLoading || (shouldCheckItemsAvailability && visibleRows.length === 0)) ? visibleRowsCount : (visibleRows.length === visibleRowsCount ? 0 : visibleRowsCount - (visibleRows.length % visibleRowsCount))
    }, [visibleRows])
    
    useEffect(() => {
        dataGenerator(visibleRowsCount * 2, 0, data => {
            setContent(data)
            setIsDataLoading(false)
            setShouldCheckItemsAvailability(true)
        }, error => {
            setIsDataLoading(false)
            setShouldCheckItemsAvailability(true)
        })
    }, [])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        const pagesLoadedCount = Math.ceil(content.length / visibleRowsCount)
        if ((newPage + 1) - pagesLoadedCount > 1) {
            return
        } else if ((newPage + 1) - pagesLoadedCount === 1 && totalCount > content.length) {
            setIsDataLoading(true)
        } else if (newPage + 1 === pagesLoadedCount && totalCount > content.length) {
            if (shouldPaginate) {
                dataGenerator(visibleRowsCount * 2, visibleRowsCount * (newPage + 1), data => {
                    setContent(prev => [...prev, ...data])
                    setIsDataLoading(false)
                })
            }
        }
        setPage(newPage)
    }

    function EnhancedTableHead({ order, orderBy, onRequestSort }) {
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property)
        }

        return (
            <TableHead>
                <TableRow>
                    <HeaderView
                        order={order}
                        orderBy={orderBy}
                        headerBackgroundColor={headerBackgroundColor}
                        headerTextColor={headerTextColor}
                        createSortHandler={createSortHandler}
                        removedColumnIds={removedColumnIds}
                    />
                </TableRow>
            </TableHead>
        );
    }

    function getDisplayedRowsLabelFunction() {
        return showTotalCount ?
            ({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}` :
            ({ from, to }) => `${from} - ${to}`
    }

    return (
        <Paper sx={{
            width: '100%',
            borderRadius,
            ...(boxShadow ? { boxShadow } : {}),
            position: 'relative'
        }}>
            <TableContainer sx={{
                ...(border ? { border } : {}),
                borderRadius
            }}>
                <Table>
                    {
                        !(hideHeaderInMobile && isMobile) && <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    }
                    <TableBody>
                        {
                            visibleRows.map(row => <RowView key={generateRandomInteger(1_000_000)} rowData={row} />)
                        }
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: rowHeightPx * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {showPagesSection && <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={totalCount ?? content?.length}
                rowsPerPage={visibleRowsCount}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={getDisplayedRowsLabelFunction()}
            />}
            {
                isDataLoading && <div className={isMobile ? "mobile-paydin-table-loader-container" : "paydin-table-loader-container"} style={{ height: `${rowHeightPx * visibleRowsCount}px` }}>
                    <Loader
                        styles={{
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            inset: 0,
                            margin: 'auto'
                        }}
                    />
                </div>
            }
            {
                (shouldCheckItemsAvailability && !isDataLoading && visibleRows.length === 0) && <div className={isMobile ? 'mobile-paydin-table-no-items-container' : 'paydin-table-no-items-container'}>
                    <NoItemsFound
                        className='paydin-table-no-items'
                        image={noItemsImage}
                        isEmbeddedIcon={true}
                        title={noItemsText}
                        showButton={false}
                    />
                </div>
            }
        </Paper>
    )
}