import React from 'react'

export default function PostcardImage(props) {
    return (
        <svg
            {...props}
            fill="none"
            height="512"
            viewBox="0 0 24 24"
            width="512"
        >
            <g
                fill="rgb(0,0,0)"
            >
                <path d="m9 10.75c-1.52 0-2.75-1.23-2.75-2.75s1.23-2.75 2.75-2.75 2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75zm0-4c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25z"></path><path d="m15 22.75h-6c-5.43 0-7.75-2.32-7.75-7.75v-6c0-5.43 2.32-7.75 7.75-7.75h4c.41 0 .75.34.75.75s-.34.75-.75.75h-4c-4.61 0-6.25 1.64-6.25 6.25v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-5c0-.41.34-.75.75-.75s.75.34.75.75v5c0 5.43-2.32 7.75-7.75 7.75z"></path><path d="m18 8.74994c-.41 0-.75-.34-.75-.75v-6c0-.3.18-.58.46-.69s.6-.05.82.16l2 2c.29.29.29.77 0 1.06s-.77.29-1.06 0l-.72-.72v4.19c0 .41-.34.75-.75.75z"></path><path d="m15.9999 4.74994c-.19 0-.38-.07-.53-.22-.29-.29-.29-.77 0-1.06l2-2c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-2 2c-.15.15-.34.22-.53.22z"></path><path d="m2.6698 19.7001c-.24 0-.48-.12-.62-.33-.23-.34-.14-.81.2-1.04l4.93-3.31c1.08-.72 2.57-.64 3.55.19l.33.29c.5.43 1.35.43 1.84 0l4.16-3.57c1.06-.91 2.73-.91 3.8 0l1.63 1.4c.31.27.35.74.08 1.06-.27.31-.75.35-1.06.08l-1.63-1.4c-.5-.43-1.34-.43-1.84 0l-4.16 3.57c-1.06.91-2.73.91-3.8 0l-.33-.29c-.46-.39-1.21999-.43-1.72999-.08l-4.92001 3.31c-.14.08-.29.12-.43.12z">
                </path>
            </g>
        </svg>
    )
}
