import PaydinTable from 'components/PaydinTable/PaydinTable'
import { MOBILE_STORE_ORDERS_TABLE_ROW_HEIGHT_PX, ORDERS_TABLE_HEADERS_IDS, ORDERS_TABLE_ROWS_PER_PAGE, STORE_ORDERS_TABLE_ROW_HEIGHT_PX, PRODUCTS_TABLE_ROW_HEIGHT_PX, STORE_ORDERS_TABLE_COUNT, STORE_ORDERS_TABLE_ROWS_PER_PAGE, VIEW_MODES, CONTENT_TYPES } from 'constants/general.constants'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { extractStoreOrderRowData } from 'utils/dataUtils'
import './StoreOrdersTable.css'
import { useTranslation } from 'react-i18next'
import { getStoreOrders } from 'api/store/store'
import StoreOrdersTableRowView from 'components/StoreOrdersTableRowView/StoreOrdersTableRowView'
import StoreOrdersTableHeaderView from 'components/StoreOrdersTableHeaderView/StoreOrdersTableHeaderView'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import { useHistoryContext } from 'contexts/History'

/**
 * Represents an orders table for a specific link.
 * @param {array} removedColumnIds - An array of column IDs to remove from display
 */
export default function StoreOrdersTable({
    removedColumnIds = []
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [contentDialogState, setContentDialogState] = useState({
        isOpen: false,
        type: CONTENT_TYPES.LINK
    })

    function createData(
        id,
        createdAt,
        totalProductsCount,
        totalPrice,
        status,
        url,
        influencerImage,
        linkDetails,
        currency
    ) {
        const data = {
            id,
            [ORDERS_TABLE_HEADERS_IDS.DATE]: createdAt,
            [ORDERS_TABLE_HEADERS_IDS.TOTAL_PRODUCTS_COUNT]: totalProductsCount,
            [ORDERS_TABLE_HEADERS_IDS.TOTAL_PRICE]: totalPrice,
            status,
            url,
            influencerImage,
            linkDetails,
            [ORDERS_TABLE_HEADERS_IDS.SOURCE]: linkDetails?.linkTitle,
            currency,
            openLinkContentDialog,
            openShortContentDialog
        }

        if (removedColumnIds.length > 0) {
            Object.keys(data).forEach(columnId => {
                if (removedColumnIds.includes(columnId)) {
                    delete data[columnId]
                }
            })
        }

        return data
    }

    function normalizeTableData(items) {
        const normalizedData = []

        for (const item of items) {
            normalizedData.push(
                createData(
                    item?.id,
                    item?.createdAt,
                    item?.totalProductsCount,
                    item?.totalPrice,
                    item?.status,
                    item?.url,
                    item?.influencerProfileImage,
                    item?.linkDetails,
                    item?.currency
                )
            )
        }

        return normalizedData
    }

    function getStoreOrdersData(count, skip, onSuccess = () => { }, onFailure = () => { }) {
        getStoreOrders(STORE_ORDERS_TABLE_COUNT, skip)
            .then(orders => {
                onSuccess(normalizeTableData(orders?.data ? orders?.data.map(order => extractStoreOrderRowData(order)) : []))
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function closeContentDialog() {
        setContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function openLinkContentDialog(linkId) {
        setContentDialogState({
            isOpen: true,
            type: CONTENT_TYPES.LINK,
            linkId
        })
    }

    function openShortContentDialog(shortId) {
        setContentDialogState({
            isOpen: true,
            type: CONTENT_TYPES.SHORT,
            linkId: shortId
        })
    }

    return (
        <>
            <PaydinTable
                visibleRowsCount={STORE_ORDERS_TABLE_ROWS_PER_PAGE}
                borderRadius='10px'
                rowHeightPx={isMobile ? MOBILE_STORE_ORDERS_TABLE_ROW_HEIGHT_PX : STORE_ORDERS_TABLE_ROW_HEIGHT_PX}
                rowView={StoreOrdersTableRowView}
                headerView={StoreOrdersTableHeaderView}
                hideHeaderInMobile={true}
                dataGenerator={getStoreOrdersData}
                noItemsImage={<HiOutlineShoppingBag className='orders-table-no-items-image' />}
                noItemsText={t('ORDERS_TABLE_NO_ITEMS_TO_DISPLAY_MESSAGE')}
                removedColumnIds={removedColumnIds}
                shouldPaginate={false}
                showTotalCount={false}
            />
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeContentDialog}
                data={{
                    linkId: contentDialogState.linkId
                }}
                mode={VIEW_MODES.PREVIEW}
                onDelete={() => history.goBack()}
                contentType={contentDialogState.type}
            />
        </>
    )
}
