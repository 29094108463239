import FloatingMenu from 'components/FloatingMenu/FloatingMenu'
import React, { useEffect, useRef, useState } from 'react'
import { BsPlusSquare } from 'react-icons/bs'
import './MinimizedAddButton.css'
import { MOBILE_DASHBOARD_TOPBAR_RIGHT_SECTION_BUTTON_HEIGHT_PX } from 'constants/general.constants'

/**
 * Represents a minimized (represented only as an image) add button.
 * Uses {@link FloatingMenu} to display a customizable menu when clicked.
 * @param {JSX} menuLayout - A custom jsx element to display as the menu's content. Ignores 'menuOptions' if provided
 * @param {array} menuOptions - An array of options to display as the menu's content. Ignored if 'menuLayout' is provided
 * @param {string} addImageClassName - A class name for the add button image
 * @param {boolean} adjustButtonTop - Determins whether the floating menu should be adjusted according to the add button's height or not
 */
export default function MinimizedAddButton({
    menuLayout = null,
    menuOptions = [],
    addImageClassName = '',
    adjustButtonTop = false,
    menuClassName = ''
}) {
    const [isAddOptionsMenuShown, setIsAddOptionsMenuShown] = useState(false)
    const [addButtonHeight, setAddButtonHeight] = useState(0)

    const addButtonImageRef = useRef(null)

    useEffect(() => {
        if (addButtonImageRef?.current) {
            setAddButtonHeight(addButtonImageRef?.current?.clientHeight)
        }
    }, [])

    function closeAddingOptionsMenu() {
        setIsAddOptionsMenuShown(false)
    }

    function openAddingOptionsMenu() {
        setIsAddOptionsMenuShown(true)
    }

    return (
        <div className='minimized-add-button-container'>
            <FloatingMenu
                menuClassName={menuClassName}
                menuLayout={menuLayout} 
                menuOptions={menuOptions} 
                isShown={isAddOptionsMenuShown} 
                hide={closeAddingOptionsMenu} 
                buttonHeight={adjustButtonTop ? addButtonHeight : MOBILE_DASHBOARD_TOPBAR_RIGHT_SECTION_BUTTON_HEIGHT_PX}
            />
            <div ref={addButtonImageRef}>
                <BsPlusSquare 
                    className={`minimized-add-button-image ${addImageClassName}`} 
                    onClick={openAddingOptionsMenu} 
                />
            </div>
        </div>
    )
}
