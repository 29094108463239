import React from 'react'

export default function CheckImage(props) {
    return (
        <svg {...props} version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 512 512' }} space="preserve">
            <g>
                <g strokeLinecap="round" strokeLinejoin="round">
                    <path fill="#11bb1d" d="M12 2C6.483 2 2 6.483 2 12s4.483 10 10 10 10-4.483 10-10S17.517 2 12 2z" opacity="1" dataoriginal="#66bb6a">
                    </path>
                    <path fill="#11bb1d" d="M12 2c-.163 0-.325.006-.486.014C16.792 2.282 21 6.655 21 12c0 5.345-4.208 9.718-9.486 9.986.161.008.323.014.486.014 5.517 0 10-4.483 10-10S17.517 2 12 2z" opacity="1" data-original="#4caf50">
                    </path>
                    <path fill="#e8f5e9" d="m17.146 8.146-6.5 6.5a.2.2 0 0 1-.292 0l-3.5-3.5a.5.5 0 0 0-.708 0 .5.5 0 0 0 0 .708l3.5 3.5c.468.467 1.24.467 1.708 0l6.5-6.5a.5.5 0 0 0 0-.708.5.5 0 0 0-.708 0z" opacity="1" data-original="#e8f5e9">
                    </path>
                </g>
            </g>
        </svg>
    )
}
