import React, { useEffect, useState } from 'react'
import './ShortView.css'
import {
    NO_IMAGE_PLACEHOLDER_URL,
    INPUT_VALIDATION_INDICATIONS,
    LINK_TYPES,
    VIEW_MODES,
    SHORT_URL_SELECTION_TYPE,
    CONTENT_TYPES,
    CREATE_LINK_REDIRECT_TO_PAGE_DELAY,
    SEPERATE_GO_BACK_FOR_SAFARY_DELAY,
} from 'constants/general.constants'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { useHistoryContext } from 'contexts/History'
import EditShortSection from 'components/EditShortSection/EditShortSection'
import { GetInfluencer, GetInfluencers } from 'api/influencers/influencers'
import { GetLinkDetailsAPI, createShortDetails, updateShortDetails } from 'api/links/links'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import { INFLUENCERS_PAGE_URL, SHORTS_PAGE_URL } from 'constants/routes.constants'
import { logger } from 'services/CloudLogger'
import { isInputValueValid } from 'services/SystemService'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import { addTimeToDate } from 'utils/dateUtils'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { extractCollectionData, extractLinkData, extractProductData } from 'utils/dataUtils'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import ContentDialogLoader from 'components/ContentDialogLoader/ContentDialogLoader'
import { isObjectEmpty } from 'utils/utils'

/**
 * epresents a portion of the screen that displays the short's edit/create panel.
 * @param {string} mode - The mode of this view, it determins whether it is creating or editing a short, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 */
export default function ShortView({
    mode,
    data
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        setRecentShorts,
        // isFreeUser,
        beginEditing,
        endEditing
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        setObjectToSave,
        setFinishedCreatingObject,
        setFinishedUpdatingObject
    } = useGeneralDataContext()

    const [isLoadingShort, setIsLoadingShort] = useState(true)
    const [isButtonProcessing, setIsButtonProcessing] = useState(false)
    const [isAbleToSaveShort, setIsAbleToSaveShort] = useState(false)
    const [shortPropertiesModified, setShortPropertiesModified] = useState(false)
    const [title, setTitle] = useState('')
    const [url, setUrl] = useState('')
    const [promocode, setPromocode] = useState('')
    const [influencers, setInfluencers] = useState([])
    const [originalInfluencerUsername, setOriginalInfluencerUsername] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [selectedInfluencerUsername, setSelectedInfluencerUsername] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [selectedInfluencerImage, setSelectedInfluencerImage] = useState(userDetails?.isAdmin ? '' : userDetails?.influencerUsername)
    const [shouldAddUTMs, setShouldAddUTMs] = useState(false)
    const [utmParams, setUtmParams] = useState({
        UTMSource: '',
        UTMMedium: '',
        UTMCampaign: '',
        UTMTerm: '',
        UTMContent: ''
    })
    const [urlInputValidationState, setUrlInputValidationState] = useState({
        status: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
        isValidating: false
    })
    const [shortData, setShortData] = useState(data?.shortData)

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { }
    })
    const [hasSavedInfluencer, setHasSavedInfluencer] = useState(false)
    const [isInfluencerChanged, setIsInfluencerChanged] = useState(false)
    const [urlSelectionType, setUrlSelectionType] = useState('')
    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedCollections, setSelectedCollections] = useState([])

    useEffect(() => {
        loadShortDetails()
    }, [])

    useEffect(() => {
        if (shortPropertiesModified) {
            beginEditing()
            addDiscardChangesDialogBackHandler()
        } else {
            endEditing()
        }
    }, [shortPropertiesModified])

    useEffect(() => {
        setIsAbleToSaveShort(
            shortPropertiesModified &&
            (
                (isCustomUrlTypeSelected() && urlInputValidationState.status === INPUT_VALIDATION_INDICATIONS.VALID) ||
                isProductUrlTypeSelected() ||
                isCollectionUrlTypeSelected()
            )
        )
    }, [shortPropertiesModified, urlInputValidationState, urlSelectionType])

    function populateShortData(shortData) {
        const shoppingRedirectUrl = shortData?.shoppingRedirectUrl ?? (shortData?.shopping_redirect_url ?? '')
        const shortTitle = shortData?.description ?? "" // consider the description as the shorts's title
        const utmParams = {
            UTMSource: shortData?.utmParams?.UTMSource ?? (shortData?.utm_params?.utm_source ?? ''),
            UTMMedium: shortData?.utmParams?.UTMMedium ?? (shortData?.utm_params?.utm_medium ?? ''),
            UTMCampaign: shortData?.utmParams?.UTMCampaign ?? (shortData?.utm_params?.utm_campaign ?? ''),
            UTMTerm: shortData?.utmParams?.UTMTerm ?? (shortData?.utm_params?.utm_term ?? ''),
            UTMContent: shortData?.utmParams?.UTMContent ?? (shortData?.utm_params?.utm_content ?? ''),
        }

        setShortData({
            linkId: shortData?.linkId ?? (shortData?.link_id ?? ''),
            images: [],
            description: shortData.description ?? '',
            currency: shortData?.currency ?? (shortData?.link_analytics?.currency ?? userDetails?.businessCurrency),
            analytics: {
                views: shortData?.analytics?.views ?? (shortData?.link_analytics?.visits ?? 0),
                checkouts: shortData?.analytics?.checkouts ?? (shortData?.link_analytics?.checkouts ?? 0),
                revenue: shortData?.analytics?.revenue ?? (shortData?.link_analytics?.revenue ?? 0),
                checkoutRequests: shortData?.analytics?.checkoutRequests ?? (shortData?.link_analytics?.requested_checkouts ?? 0)
            },
            promocode: shortData?.promocode ?? (shortData?.promocode?.code ?? ''),
            products: shortData?.products?.map(product => extractProductData(product)),
            title: shortTitle,
            shoppingRedirectUrl,
            influencerUsername: shortData?.influencerUsername ?? (shortData?.influencer_username),
            utmParams
        })

        if (userDetails?.isAdmin && shortData?.influencer_username) {
            loadInfluencer(shortData?.influencer_username, shortData?.promocode?.code)
        } else if (userDetails?.isAdmin && !shortData?.influencer_username) {
            GetInfluencers()
                .then(response => {
                    setInfluencers(response?.filter(influencer => influencer?.is_active)?.map(influencer => ({
                        image: influencer?.profile_image_url ?? NO_IMAGE_PLACEHOLDER_URL,
                        username: influencer?.username ?? '',
                        isActive: influencer?.is_active ?? false,
                        promocode: influencer?.promocode ?? ''
                    })))
                })
                .finally(() => {
                    setIsLoadingShort(false)
                })
        }

        setUrlSelectionType(getUrlSelectionType(shortData))
        setSelectedProducts(shortData?.products ? shortData?.products?.map(product => extractProductData(product)) : [])
        setSelectedCollections(shortData?.collections ? shortData?.collections?.map(collection => extractCollectionData(collection)) : [])
        setPromocode(shortData?.promocode?.code ?? '')
        setTitle(shortTitle)
        setUrl(shoppingRedirectUrl)
        setUtmParams(utmParams)
        setShouldAddUTMs(hasUtmParams(utmParams))
        setIsLoadingShort(false)
    }

    function loadShortDetails() {
        if (mode !== VIEW_MODES.CREATE) {
            if (data?.shortData && !isObjectEmpty(data?.shortData)) {
                populateShortData(data?.linkData)
            }
            GetLinkDetailsAPI(data?.linkId)
                .then(shortData => {
                    populateShortData(shortData)
                })
                .catch(error => {
                    console.log(error)
                    history.push(SHORTS_PAGE_URL)
                })
        } else {
            const utmParams = {
                UTMSource: userDetails?.settings?.utmParams?.UTMSource ?? '',
                UTMMedium: userDetails?.settings?.utmParams?.UTMMedium ?? '',
                UTMCampaign: userDetails?.settings?.utmParams?.UTMCampaign ?? '',
                UTMTerm: userDetails?.settings?.utmParams?.UTMTerm ?? '',
                UTMContent: userDetails?.settings?.utmParams?.UTMContent ?? '',
            }
            setUtmParams(utmParams)
            setShouldAddUTMs(hasUtmParams(utmParams))

            if (userDetails?.isAdmin) {
                GetInfluencers()
                    .then(response => {
                        if (data?.influencerUsername) {
                            setSelectedInfluencerUsername(data?.influencerUsername)
                        }
                        setInfluencers(response?.filter(influencer => influencer?.is_active)?.map(influencer => {
                            if (data?.influencerUsername === influencer?.username) {
                                setPromocode(influencer?.promocode ?? '')
                            }
                            return {
                                image: influencer?.profile_image_url ?? NO_IMAGE_PLACEHOLDER_URL,
                                username: influencer?.username ?? '',
                                isActive: influencer?.is_active ?? false,
                                promocode: influencer?.promocode ?? ''
                            }
                        }))
                    })
                    .finally(() => {
                        setIsLoadingShort(false)
                    })
            } else {
                setPromocode(userDetails?.promocode ?? '')
                setIsLoadingShort(false)
            }
        }
    }

    function loadInfluencer(influencerUsername, predefinedPromocode) {
        GetInfluencer(influencerUsername)
            .then(response => {
                setOriginalInfluencerUsername(influencerUsername)
                setSelectedInfluencerUsername(influencerUsername)
                setSelectedInfluencerImage(response?.profile_image_url)
                setInfluencers([{
                    image: response?.profile_image_url,
                    username: response?.username ?? '',
                    isActive: response?.is_active ?? false,
                    promocode: response?.promocode ?? ''
                }])
                setPromocode(predefinedPromocode ? predefinedPromocode : (response?.promocode ?? ''))
            })
            .finally(() => {
                setIsLoadingShort(false)
            })
    }

    function getUrlSelectionType(shortData) {
        if (shortData?.products && shortData?.products?.length > 0) {
            return SHORT_URL_SELECTION_TYPE.PRODUCT
        }

        if (shortData?.collections && shortData?.collections?.length > 0) {
            return SHORT_URL_SELECTION_TYPE.COLLECTION
        }

        return SHORT_URL_SELECTION_TYPE.CUSTOM
    }

    function hasUtmParams(utmParams) {
        return (
            (![null, undefined].includes(utmParams?.UTMSource) && utmParams?.UTMSource?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMMedium) && utmParams?.UTMMedium?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMCampaign) && utmParams?.UTMCampaign?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMTerm) && utmParams?.UTMTerm?.length > 0) ||
            (![null, undefined].includes(utmParams?.UTMContent) && utmParams?.UTMContent?.length > 0)
        )
    }

    function getTitle() {
        switch (mode) {
            case VIEW_MODES.CREATE: return t('SHORT_VIEW_CREATE_NEW_SHORT_TITLE')
            case VIEW_MODES.EDIT: return t('SHORT_VIEW_EDIT_SHORT_TITLE')
            default: return t('SHORT_VIEW_PREVIEW_SHORT_TITLE')
        }
    }

    function setShortTitle(title) {
        setShortPropertiesModified(true)
        setTitle(title)
    }

    function setShortPromocode(promocode) {
        setShortPropertiesModified(true)
        setPromocode(promocode)
    }

    function setShortURL(url) {
        setShortPropertiesModified(true)
        setUrl(url)
    }

    function setShortUTMSource(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setShortPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMSource: UTMValue
            }))
        }
    }

    function setShortUTMMedium(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setShortPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMMedium: UTMValue
            }))
        }
    }

    function setShortUTMCampaign(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setShortPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMCampaign: UTMValue
            }))
        }
    }

    function setShortUTMTerm(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setShortPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMTerm: UTMValue
            }))
        }
    }

    function setShortUTMContent(UTMValue) {
        if (isInputValueValid(UTMValue)) {
            setShortPropertiesModified(true)
            setUtmParams(prev => ({
                ...prev,
                UTMContent: UTMValue
            }))
        }
    }

    function getShortImage() {
        if (isProductUrlTypeSelected()) {
            return selectedProducts[0]?.imageUrl
        }

        if (isCollectionUrlTypeSelected()) {
            return selectedCollections[0]?.images?.medium?.src
        }

        return null
    }

    function prepareShortUrl(url) {
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            return 'https://' + url
        }

        return url
    }

    async function save() {
        setIsButtonProcessing(true)
        const productIds = selectedProducts?.map(product => product?.id)
        const collectionIds = selectedCollections?.map(collection => collection?.id)
        const promocodeToSave = {
            code: promocode
        }
        const urlToSave = prepareShortUrl(url)

        if (mode === VIEW_MODES.CREATE) {

            createShortDetails(userDetails.businessId, promocodeToSave, title, getShortImage(), productIds, collectionIds, urlToSave, selectedInfluencerUsername, utmParams, LINK_TYPES.SHORT)
                .then(newShort => {
                    setObjectToSave(extractLinkData(newShort))
                    setShortPropertiesModified(false)
                    history.goBackStepsNoPerform()
                    setTimeout(() => {
                        history.goBack()
                    }, SEPERATE_GO_BACK_FOR_SAFARY_DELAY)
                    setTimeout(() => {
                        setRecentShorts([])
                        if (window.location.pathname !== SHORTS_PAGE_URL && window.location.pathname !== INFLUENCERS_PAGE_URL) {
                            history.replace(SHORTS_PAGE_URL)
                        } else {
                            setFinishedCreatingObject(true)
                            setTimeout(() => {
                                setFinishedCreatingObject(false)
                            }, 200)
                        }
                    }, CREATE_LINK_REDIRECT_TO_PAGE_DELAY)
                }).catch((error) => {
                    logger.error(`Error - edit link failure - ${error}`)
                    showSaveDetailsErrorMessage()
                    setIsButtonProcessing(false)
                })
        } else {
            updateShortDetails(userDetails.businessId, data?.linkId, promocodeToSave, title, getShortImage(), productIds, collectionIds, urlToSave, selectedInfluencerUsername, utmParams, LINK_TYPES.SHORT)
                .then(updatedShort => {
                    setObjectToSave(extractLinkData(updatedShort))
                    setIsButtonProcessing(false)
                    showApprovalMessage(t('EDIT_SHORT_SECTION_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
                    setShortPropertiesModified(false)
                    history.goBackStepsNoPerform()
                    if (isInfluencerChanged) {
                        setHasSavedInfluencer(true)
                    }
                    setFinishedUpdatingObject(true)
                    setTimeout(() => {
                        setFinishedUpdatingObject(false)
                    }, 200)
                }).catch((error) => {
                    logger.error(`Error - edit link failure - ${error}`)
                    showSaveDetailsErrorMessage()
                    setIsButtonProcessing(false)
                })
        }
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showSaveDetailsErrorMessage() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_TITLE'),
            leftButtonText: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonText: null,
            rightButtonClickHandler: () => { },
            isRightButtonWithLoader: false
        }))
    }

    function addDiscardChangesDialogBackHandler() {
        setTimeout(() => {
            history.addBackHandler(() => {
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_TITLE'),
                    message: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_MESSAGE'),
                    leftButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
                    leftButtonClickHandler: addDiscardChangesDialogBackHandler,
                    rightButtonClickHandler: () => {
                        endEditing()
                        history.goBack()
                    },
                    isRightButtonWithLoader: false
                }))
            })
        }, 300)
    }

    function goBack() {
        history.goBack()
    }

    function renderTopbarSaveButton() {
        switch (mode) {
            case VIEW_MODES.CREATE: return t('SHORT_VIEW_TOPBAR_CREATE_BUTTON_TEXT')
            case VIEW_MODES.EDIT: return t('SHORT_VIEW_TOPBAR_SAVE_BUTTON_TEXT')
            default: return ''
        }
    }

    function resetUTMParams() {
        setUtmParams({
            UTMSource: '',
            UTMMedium: '',
            UTMCampaign: '',
            UTMTerm: '',
            UTMContent: ''
        })
        setShortPropertiesModified(true)
    }

    function setShouldAddUTMParams(value) {
        if (!value) {
            resetUTMParams()
        }

        setShouldAddUTMs(value)
    }

    function isProductUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.PRODUCT
    }

    function isCollectionUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.COLLECTION
    }

    function isCustomUrlTypeSelected() {
        return urlSelectionType === SHORT_URL_SELECTION_TYPE.CUSTOM
    }

    function renderContent() {
        return <EditShortSection
            mode={mode}
            influencers={influencers}
            originalInfluencerUsername={originalInfluencerUsername}
            selectedInfluencerUsername={selectedInfluencerUsername}
            setSelectedInfluencerUsername={setSelectedInfluencerUsername}
            title={title}
            setTitle={setShortTitle}
            url={url}
            setURL={setShortURL}
            promocode={promocode}
            setPromocode={setShortPromocode}
            shouldAddUTMs={shouldAddUTMs}
            setShouldAddUTMs={setShouldAddUTMParams}
            utmParams={utmParams}
            setUTMSource={setShortUTMSource}
            setUTMMedium={setShortUTMMedium}
            setUTMCampaign={setShortUTMCampaign}
            setUTMTerm={setShortUTMTerm}
            setUTMContent={setShortUTMContent}
            urlInputValidationState={urlInputValidationState}
            setUrlInputValidationState={setUrlInputValidationState}
            hasSavedInfluencer={hasSavedInfluencer}
            setIsInfluencerChanged={setIsInfluencerChanged}
            urlSelectionType={urlSelectionType}
            setUrlSelectionType={setUrlSelectionType}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            selectedCollections={selectedCollections}
            setSelectedCollections={setSelectedCollections}
            shortPropertiesModified={shortPropertiesModified}
        />
    }

    return (
        <div className={isMobile ? 'mobile-short-view-container' : 'short-view-container'}>
            <ContentDialogTopbar
                title={getTitle()}
                onBackButtonClick={goBack}
                rightElement={
                    <LoaderButton
                        className='page-view-topbar-save-button'
                        isDisabled={!isAbleToSaveShort || isButtonProcessing}
                        buttonText={renderTopbarSaveButton()}
                        isLoading={isButtonProcessing}
                        loaderCircleColor='#0095f6'
                        onClick={save}
                    />
                }
            />
            <div className={isMobile ? "mobile-short-view-content" : "short-view-content"} style={{ height: isMobile ? `100dvh` : `calc(var(--content-dialog-height) - var(--content-dialog-topbar-height))` }}>
                {isLoadingShort && <ContentDialogLoader />}
                {renderContent()}
            </div>
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={true}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                onLeftButtonClick={dialogState.leftButtonClickHandler}
            />
        </div>
    )
}