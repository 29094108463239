import Loader from '../../components/Loader/Loader'
import React, { useEffect, useRef, useState } from 'react'
import './ImageBox.css'
import { IMAGE_BOX_DEFAULT_BORDER_RADIUS_PX, IMAGE_LOADING_IMAGE_SRV_TEXT, NO_IMAGE_PLACEHOLDER_URL } from 'constants/general.constants'
import ImageDialog from 'dialogs/ImageDialog/ImageDialog'

/**
 * Represents an image container with an optional loder to diaplay while the image is being loaded.
 * @param {Image/string} image - The image object or image url of the requested image
 * @param {string} className - A class name for the image container
 * @param {string} imageClassName - A class name for the image
 * @param {string} alt - An alternative text for the image
 * @param {boolean} showImageLoader - Determins whether to show the loader or not
 * @param {boolean} showGreyLoaderBackground - Determins whether to show grey background to the loader or not
 * @param {boolean} showGreyImageBackground - Determins whether to show grey background to the image or not
 * @param {boolean} showImageBorderRadius - Determins whether to show the image's border radius or not
 * @param {number} borderRadiusPx - The border radius of this image
 * @param {number} border - The border of this image
 * @param {boolean} isImageFitCover - Determins whether the image object fit css preperty is cover (if true) or not
 * @param {number} containerHeightPx - Overrides the image's original height, in pixels
 * @param {number} containerWidthPx - Overrides the image's original width, in pixels
 * @param {number} loaderWidth - The width of the loader in pixels
 * @param {number} loaderHeight - The height of the loader in pixels
 * @param {number} loaderBorderWidth - The width of the loader's circumference in pixels
 * @param {boolean} isWidthPercentage - Determins whether the width of the loader will be set as percentage or not
 * @param {boolean} isHeightPercentage - Determins whether the height of the loader will be set as percentage or not
 * @param {boolean} enlargeImageOnClick - Determins whether a click on the image opens a large dialog that contains the image in the center of the screen or not
 * @param {function} onImageClickCallback - A callback to perform when the image was clicked
 * @param {boolean} roundImage - Determins whether the image will be round or squared
 * @param {object} styles - Additional styles for the image container
 */
export default function ImageBox({
    image,
    className = '',
    imageClassName = '',
    alt = '',
    showImageLoader = false,
    showGreyLoaderBackground = false,
    showGreyImageBackground = false,
    showImageBorderRadius = false,
    showImageMask = false,
    borderRadiusPx = IMAGE_BOX_DEFAULT_BORDER_RADIUS_PX,
    showBorder = false,
    border = null,
    isImageFitCover = false,
    containerHeightPx = null,
    containerWidthPx = null,
    loaderWidth = 30,
    loaderHeight = 30,
    loaderBorderWidth = 2,
    isWidthPercentage = false,
    isHeightPercentage = false,
    enlargeImageOnClick = false,
    onImageClickCallback = null,
    roundImage = false,
    styles = {}
}) {

    const [isImageLoading, setIsImageLoading] = useState(false)
    const [imageDialogState, setImageDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeImageDialog,
        imageUrl: ''
    })

    const imageRef = useRef(null)

    const containerStyles = {
        ...styles,
        ...(containerHeightPx != null ? { height: containerHeightPx } : {}),
        ...(containerWidthPx != null ? { width: containerWidthPx } : {}),
        cursor: enlargeImageOnClick || onImageClickCallback ? 'pointer' : 'initial'
    }

    useEffect(() => {
        if (showImageLoader) {
            if (image === IMAGE_LOADING_IMAGE_SRV_TEXT) {
                setIsImageLoading(true)
            } else if ([null, undefined].includes(image) || imageRef.current.complete) {
                setIsImageLoading(false)
            } else {
                imageRef.current.setAttribute('src', null)
                imageRef.current.setAttribute('src', image)
                setIsImageLoading(true)
            }
        }
    }, [image])

    function onImageLoad() {
        if (image !== IMAGE_LOADING_IMAGE_SRV_TEXT) {
            setIsImageLoading(false)
        }
    }

    function onImageError() {
        if (image !== IMAGE_LOADING_IMAGE_SRV_TEXT) {
            setIsImageLoading(false)
        }
    }

    function closeImageDialog() {
        setImageDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showImageDialog() {
        setImageDialogState(prev => ({
            ...prev,
            isOpen: true,
            imageUrl: image
        }))
    }

    function onImageClick(e) {
        if (enlargeImageOnClick) {
            showImageDialog()
        } else {
            onImageClickCallback?.(e)
        }
    }

    return (
        <div className={`image-box-container ${className}`} onClick={onImageClick} style={containerStyles}>
            {showImageMask && <div className="image-box-mask" style={{ borderRadius: `${borderRadiusPx}px` }}></div>}
            {
                (isImageLoading && showImageLoader) && <div className="image-box-loader-container" style={{ backgroundColor: showGreyLoaderBackground ? 'hsl(0, 0%, 90%)' : 'transparent' }}>
                    <Loader styles={{
                        width: `${loaderWidth}${isWidthPercentage ? '%' : 'px'}`,
                        height: `${loaderHeight}${isHeightPercentage ? '%' : 'px'}`,
                        borderWidth: `${loaderBorderWidth}px`
                    }} />
                </div>
            }
            <img ref={imageRef}
                src={image ? image : NO_IMAGE_PLACEHOLDER_URL}
                alt={alt}
                className={`image-box-image ${imageClassName}`}
                style={{
                    display: isImageLoading ? 'none' : 'block',
                    backgroundColor: showGreyImageBackground ? 'hsl(0, 0%, 90%)' : 'transparent',
                    borderRadius: roundImage ? '50%' : (showImageBorderRadius ? `${borderRadiusPx}px` : 0),
                    border: showBorder ? (border ?? '1px solid #cccccc') : '',
                    objectFit: isImageFitCover ? 'cover' : 'contain'
                }}
                onLoad={onImageLoad}
                onError={onImageError}
            />
            {
                enlargeImageOnClick && <ImageDialog
                    isDialogOpen={imageDialogState.isOpen}
                    handleDialogClose={imageDialogState.handleDialogClose}
                    image={imageDialogState.imageUrl}
                />
            }
        </div>
    )
}
