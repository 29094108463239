import React from 'react'
import './MultilinkExperiencesTableRowView.css'
import { isMobile } from 'react-device-detect'
import { TableCell, TableRow, tableCellClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getCurrencySymbol } from 'services/currency'
import { formatNumber } from 'utils/utils'
import { MOBILE_MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX, MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX, MULTILINK_SUBLINK_DEVICE_OPTIONS, NUMBER_TYPES, SHORT_URL_SELECTION_TYPE} from 'constants/general.constants'
import { styled } from '@mui/system'
import LinkUrlTypeTag from 'components/LinkUrlTypeTag/LinkUrlTypeTag'

/**
 * Represents a row view for the orders table
 * @param {object} rowData - The data object of a specific row
 */
export default function MultilinkExperiencesTableRowView({
    rowData
}) {
    const { t } = useTranslation()

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide all borders
        '&:not(:last-child) td, & th': {
            border: 0,
        }
    }))

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            textAlign: 'center',
            padding: '0'
        },
    }))

    function previewLink() {
        rowData.openLinkPreview(rowData.linkId)
    }

    function renderRowContent() {
        return <>
            <StyledTableCell padding="normal" align='right' onClick={previewLink}>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-items multilink-experiences-table-row-view-clickable'>{rowData?.name}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-items'>
                    <LinkUrlTypeTag
                        type={rowData?.type}
                        isSmall={true}
                        showTooltip={Object.keys(SHORT_URL_SELECTION_TYPE).includes(rowData?.type)}
                        tooltipText={rowData.url}
                    />
                </div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-items'>{MULTILINK_SUBLINK_DEVICE_OPTIONS[rowData?.device] ?? ""}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-items'>{t('MULTILINK_EXPERIENCES_TABLE_ROW_TRAFFIC_TEXT', { value: rowData?.traffic })}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-total'>{`${formatNumber(rowData?.clicks, NUMBER_TYPES.SHORT)}`}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-total'>{`${formatNumber(rowData?.sales, NUMBER_TYPES.SHORT)}`}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-total'>{`${getCurrencySymbol(rowData?.currency)}${formatNumber(rowData?.revenue, NUMBER_TYPES.SHORT)}`}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-total'>{`${formatNumber(rowData?.cr, NUMBER_TYPES.PERCENTAGE)}%`}</div>
            </StyledTableCell>
            <StyledTableCell padding="normal" align='right'>
                <div className='multilink-experiences-table-row-view multilink-experiences-table-row-view-total'>{`${getCurrencySymbol(rowData?.currency)}${formatNumber(rowData?.aov, NUMBER_TYPES.SHORT, true)}`}</div>
            </StyledTableCell>
        </>
    }


    return (
        <>
            {
                isMobile ? <StyledTableRow style={{ height: `${MOBILE_MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX}px` }}>
                    {renderRowContent()}
                </StyledTableRow> : <>
                    <StyledTableRow style={{ height: `${MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX}px` }}>
                        {renderRowContent()}
                    </StyledTableRow>
                </>
            }
        </>
    )
}