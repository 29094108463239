import PaydinChart from "components/PaydinChart/PaydinChart";
import { NUMBER_TYPES, PAYDIN_CHART_TYPES } from "constants/general.constants";
import React, { useEffect, useState } from "react"
import { getFormattedShortDate } from "utils/dateUtils"
import './ActivityChart.css'
import { isMobile } from "react-device-detect";
import { useUserDetailsContext } from "contexts/User";
import { formatNumber } from "utils/utils";

/**
 * Represents the activity chart for the analytics page.
 * @param {object} data - The raw data for the chart
 * @param {boolean} isDataLoading - Determins whether the data is being loaded or not
 * @param {string} containerBgColor - A background color for this chart
 */
export default function ActivityChart({
    data = {},
    timeRangeName = '',
    isDataLoading = false
}) {
    const { userDetails } = useUserDetailsContext()

    const [chartData, setChartData] = useState([])
    const [chartDataLabels, setChartDataLabels] = useState({
        ...(isClicksPermissionEnabled() ? {
            'Clicks': {
                label: 'Total Clicks',
                labelTooltip: 'Clicks',
                labelColor: '#2c78e7',
                totalValue: 0,
                selected: true,
                chartType: PAYDIN_CHART_TYPES.LINE,
                yAxisId: 'left'
            }
        } : {}),
        ...(isCrPermissionEnabled() ? {
            'CR': {
                label: 'CR',
                labelTooltip: 'CR',
                labelColor: '#8708a0',
                totalValue: 0,
                selected: !isClicksPermissionEnabled(),
                chartType: PAYDIN_CHART_TYPES.LINE,
                yAxisId: 'left'
            }
        } : {}),
        ...(isSalesPermissionEnabled() ? {
            'Sales': {
                label: 'Total Sales',
                labelTooltip: 'Sales',
                labelColor: 'green',
                totalValue: 0,
                selected: !isClicksPermissionEnabled(),
                chartType: PAYDIN_CHART_TYPES.LINE,
                yAxisId: 'left'
            }
        } : {})
    })
    const [isCalculatingData, setIsCalculatingData] = useState(true)

    useEffect(() => {
        normalizeChartData()
    }, [data])

    function isClicksPermissionEnabled() {
        return userDetails?.isAdmin || userDetails?.permissions?.clicksEnabled
    }

    function isCrPermissionEnabled() {
        return userDetails?.isAdmin || (userDetails?.permissions?.salesEnabled && userDetails?.permissions?.crEnabled)
    }

    function isSalesPermissionEnabled() {
        return userDetails?.isAdmin || userDetails?.permissions?.salesEnabled
    }

    function normalizeChartData() {
        if (data.length === 0) {
            return []
        }

        const dataArray = Object.entries(data).map(([date, analytics]) => ({
            date,
            clicks: analytics?.visits,
            sales: analytics?.checkouts
        }))

        dataArray.sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))

        let totalClicks = 0, totalSales = 0
        let dataLabels = { ...chartDataLabels }
        const normalizedData = dataArray?.map(dataObject => {
            totalClicks += dataObject?.clicks
            totalSales += dataObject?.sales
            return {
                date: new Date(dataObject?.date.split(' ')[0]),
                Clicks: dataObject?.clicks,
                Sales: dataObject?.sales,
                CR: dataObject?.clicks > 0 ? formatNumber((dataObject?.sales / dataObject?.clicks) * 100, NUMBER_TYPES.PERCENTAGE) : 0
            }
        })

        if (isClicksPermissionEnabled()) {
            dataLabels['Clicks'].totalValue = totalClicks
        }

        if (isSalesPermissionEnabled()) {
            dataLabels['Sales'].totalValue = totalSales
        }
        
        if (isCrPermissionEnabled()) {
            dataLabels['CR'].totalValue = totalClicks > 0 ? `${formatNumber((totalSales / totalClicks) * 100, NUMBER_TYPES.PERCENTAGE)}%` : '0%'
        }

        const filledData = fillZeroData(normalizedData)
        const formattedFilledData = filledData.map(data => ({ ...data, date: getFormattedShortDate(data.date) }))

        setChartData(formattedFilledData)
        setChartDataLabels(dataLabels)
        setIsCalculatingData(false)
    }

    function fillZeroData(normalizedData) {
        let filledData = []
        for (let i = 0; i < normalizedData?.length - 1; i++) {
            filledData.push(normalizedData[i])
            let current = new Date(normalizedData[i]?.date)
            const next = normalizedData[i + 1]?.date
            current.setDate(current.getDate() + 1)
            while (current < next) {
                filledData.push({
                    date: current,
                    Clicks: 0,
                    Sales: 0,
                    CR: 0
                })
                current = new Date(current)
                current.setDate(current.getDate() + 1)
            }
        }
        if (normalizedData?.length > 1) {
            filledData.push(normalizedData[normalizedData.length - 1])
        }
        return filledData
    }

    return (
        <div className={isMobile ? 'mobile-paydin-activity-chart-container' : 'paydin-activity-chart-container'}>
            <PaydinChart
                chartData={chartData}
                chartDataLabels={chartDataLabels}
                setChartDataLabels={setChartDataLabels}
                isDataLoading={isCalculatingData || isDataLoading}
                timeRangeName={timeRangeName}
                isMultiLabelSelect={false}
            />
        </div>
    )
}