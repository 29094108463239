import React from 'react'
import './PaydinCheckbox.css'
import { Checkbox } from '@mui/material'

/**
 * Represents a custom checkbox with a label.
 * @param {boolean} checked - The checkbox checked state
 * @param {function} setChecked - A function for setting the checkbox checked state
 * @param {string} label - The label of the checkbox
 * @param {string} labelClassName - A class name for the checkbox's label
 */
export default function PaydinCheckbox({
    checked,
    setChecked,
    label = '',
    labelClassName = '',
    onClick = () => { }
}) {
    function onCheckboxClick() {
        setChecked(!checked)
        onClick()
    }

    return (
        <div className='paydin-checkbox-container' onClick={onCheckboxClick}>
            <Checkbox
                checked={checked}
                sx={{
                    padding: '0px'
                }}
            />
            {label && <div className={`paydin-checkbox-label ${labelClassName}`}>{label}</div>}
        </div>
    )
}