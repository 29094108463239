import React, { useState } from 'react'
import './AddProductsFilters.css'
import { MdKeyboardArrowDown } from 'react-icons/md'
import FloatingElement from 'components/FloatingElement/FloatingElement'
import { useTranslation } from 'react-i18next'
import PaydinCheckbox from 'components/PaydinCheckbox/PaydinCheckbox'

/**
 * Represetns the filters for the {@link AddProductsDialog}
 * @param {object} filters - The filters data object
 * @param {function} setFilters - A function for setting the filters data object
 * @param {Ref} filtersRef - A reference for this filter component container
 * @param {function} onFilterChange - A function to perform whenever a filter has changed
 */
export default function AddProductsFilters({
    filters,
    setFilters,
    filtersRef,
    onFilterChange = () => { }
}) {
    const { t } = useTranslation()
    const [isFiltersMenuShown, setIsFiltersMenuShown] = useState(false)

    function renderFiltersMenu() {
        return <div className="add-products-filters-menu">
            <div className="add-products-filters-menu-filters">
                <div className="add-products-filters-menu-filters-section">
                    <div className="add-products-filters-menu-filters-section-title">{t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_STATUS_SECTION_TITLE')}</div>
                    <div className="add-products-filters-menu-filters-list">
                        <PaydinCheckbox
                            checked={filters?.active}
                            setChecked={setActiveSearchFilter}
                            label={t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_ACTIVE_OPTION_TEXT')}
                            labelClassName='add-products-filters-menu-filter-label'
                        />
                        <PaydinCheckbox
                            checked={filters?.draft}
                            setChecked={setDraftSearchFilter}
                            label={t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_DRAFT_OPTION_TEXT')}
                            labelClassName='add-products-filters-menu-filter-label'
                        />
                    </div>
                </div>
                <div className="add-products-filters-menu-filters-section">
                    <div className="add-products-filters-menu-filters-section-title">{t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_INVENTORY_SECTION_TITLE')}</div>
                    <div className="add-products-filters-menu-filters-list">
                        <PaydinCheckbox
                            checked={filters?.outOfStock}
                            setChecked={setOutOfStockSearchFilter}
                            label={t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_OUT_OF_STOCK_OPTION_TEXT')}
                            labelClassName='add-products-filters-menu-filter-label'
                        />
                    </div>
                </div>
                <div className="add-products-filters-menu-filters-section">
                    <div className="add-products-filters-menu-filters-section-title">{t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_OPTIONS_SECTION_TITLE')}</div>
                    <div className="add-products-filters-menu-filters-list">
                        <PaydinCheckbox
                            checked={filters?.searchInTags}
                            setChecked={setSearchInTagsSearchFilter}
                            label={t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_SEARCH_IN_TAGS_OPTION_TEXT')}
                            labelClassName='add-products-filters-menu-filter-label'
                        />
                    </div>
                </div>
            </div>
            <div className="add-products-filters-menu-clear-button" onClick={resetFilters}>{t('ADD_PRODUCTS_DIALOG_FILTERS_MENU_CLEAR_BUTTON_TEXT')}</div>
        </div>
    }

    function resetFilters() {
        setFilters({
            active: false,
            draft: false,
            outOfStock: false,
            searchInTags: false
        })
    }
    
    function openFiltersMenu() {
        setIsFiltersMenuShown(true)
    }
    
    function closeFiltersMenu() {
        setIsFiltersMenuShown(false)
    }
    
    function setActiveSearchFilter(value) {
        setFilters(prev => ({
            ...prev,
            active: value
        }))
        onFilterChange()
    }
    
    function setDraftSearchFilter(value) {
        setFilters(prev => ({
            ...prev,
            draft: value
        }))
        onFilterChange()
    }
    
    function setOutOfStockSearchFilter(value) {
        setFilters(prev => ({
            ...prev,
            outOfStock: value
        }))
        onFilterChange()
    }
    
    function setSearchInTagsSearchFilter(value) {
        setFilters(prev => ({
            ...prev,
            searchInTags: value
        }))
        onFilterChange()
    }

    function noFilterApplied() {
        let hasNoFilterApplied = true
        for (const filter in filters) {
            if (filters[filter]) {
                hasNoFilterApplied = false
            }
        }

        return hasNoFilterApplied
    }

    function getFilterStatus() {
        if (noFilterApplied()) {
            return t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_NO_FILTERS')
        }

        let filterStatus = ''

        if (filters.active) {
            filterStatus = t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_FILTER_ACTIVE')
        }
        if (filters.draft) {
            filterStatus = (filters.active ? t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_FILTER_ALL') : t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_FILTER_DRAFT'))
        }
        if (filters.outOfStock) {
            filterStatus += `${(filters.active || filters.draft) ? ', ' : ''}${t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_FILTER_OUT_OF_STOCK')}`
        }
        if (filters.searchInTags) {
            filterStatus += `${(filters.active || filters.draft || filters.outOfStock) ? ', ' : ''}${t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_FILTER_SEARCH_IN_TAGS')}`
        }

        return t('ADD_PRODUCTS_DIALOG_FILTERS_STATUS_ACTIVE_FILTERS_PREFIX') + filterStatus
    }
    
    return (
        <div ref={filtersRef} className="add-products-filters-status-container">
            <div className="add-products-filters-status" onClick={openFiltersMenu}>
                <div className="add-products-filters-status-text">{getFilterStatus()}</div>
                <MdKeyboardArrowDown className='add-products-filters-status-image' />
            </div>
            <FloatingElement
                isShown={isFiltersMenuShown}
                hide={closeFiltersMenu}
            >
                {renderFiltersMenu()}
            </FloatingElement>
        </div>
    )
}