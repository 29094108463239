import PaydinChart from 'components/PaydinChart/PaydinChart'
import { NUMBER_TYPES, PAYDIN_CHART_TYPES } from 'constants/general.constants'
import React, { useEffect, useState } from 'react'
import { getFormattedShortDate } from 'utils/dateUtils'
import './CommerceChart.css'
import { isMobile } from 'react-device-detect'
import { useUserDetailsContext } from 'contexts/User'
import { formatNumber } from 'utils/utils'

/**
 * Represents the commerce chart for the analytics page.
 * @param {string} currency - The currency symbol related to the given data
 * @param {object} data - The raw data for the chart
 * @param {boolean} isDataLoading - Determins whether the data is being loaded or not
 * @param {string} containerBgColor - A background color for this chart
 */
export default function CommerceChart({
    currency,
    data = {},
    timeRangeName = '',
    isDataLoading = false
}) {
    const { 
        userDetails,
    } = useUserDetailsContext()

    const [chartData, setChartData] = useState([])
    const [chartDataLabels, setChartDataLabels] = useState({
        ...(isRevenuePermissionEnabled() ? {
            'Revenue': {
                label: 'Total Revenue',
                labelTooltip: 'Revenue',
                labelColor: '#df9a3a',
                totalValue: 0,
                selected: true,
                chartType: PAYDIN_CHART_TYPES.BAR,
                yAxisId: 'left'
            }
        } : {}),
        ...(isSalesPermissionEnabled() ? {
            'Sales': {
                label: 'Total Sales',
                labelTooltip: 'Sales',
                labelColor: '#b834cf',
                totalValue: 0,
                selected: false,
                chartType: PAYDIN_CHART_TYPES.LINE,
                yAxisId: 'right'
            }
        } : {}),
        ...((isAovPermissionEnabled()) ? {
            'AOV': {
                label: 'Average Sale',
                labelTooltip: 'AOV',
                labelColor: '#434543',
                totalValue: 0,
                selected: true,
                chartType: PAYDIN_CHART_TYPES.BAR,
                yAxisId: 'left'
            }
        } : {}),
    })
    const [isCalculatingData, setIsCalculatingData] = useState(true)

    useEffect(() => {
        normalizeChartData()
    }, [data])

    function isRevenuePermissionEnabled() {
        return userDetails?.isAdmin || userDetails?.permissions?.revenueEnabled
    }

    function isAovPermissionEnabled() {
        return userDetails?.isAdmin || (userDetails?.permissions?.salesEnabled && userDetails?.permissions?.revenueEnabled && userDetails?.permissions?.aovEnabled)
    }

    function isSalesPermissionEnabled() {
        return userDetails?.isAdmin || userDetails?.permissions?.salesEnabled
    }

    function normalizeChartData() {
        if (data.length === 0) {
            return []
        }

        const dataArray = Object.entries(data).map(([date, analytics]) => ({
            date,
            sales: analytics.checkouts,
            revenue: analytics.revenue,
            totalTax: analytics.total_tax
        }))

        dataArray.sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))

        let totalRevenue = 0, totalSales = 0
        let dataLabels = { ...chartDataLabels }
        const normalizedData = dataArray?.map(dataObject => {
            const dailyRevenue = Number(dataObject.revenue.toFixed(2))
            totalRevenue += dailyRevenue
            totalSales += dataObject.sales

            return {
                date: new Date(dataObject?.date.split(' ')[0]),
                Revenue: dailyRevenue,
                Sales: dataObject.sales,
                AOV: dataObject.sales > 0 ? Number((dailyRevenue / dataObject.sales).toFixed(2)) : 0
            }
        })

        if (isRevenuePermissionEnabled()) {
            dataLabels['Revenue'].totalValue = `${currency}${formatNumber(totalRevenue, NUMBER_TYPES.SHORT)}`
        }

        if (isSalesPermissionEnabled()) {
            dataLabels['Sales'].totalValue = totalSales
        }

        if (isAovPermissionEnabled()) {
            dataLabels['AOV'].totalValue = `${currency}${totalSales > 0 ? formatNumber(totalRevenue / totalSales, NUMBER_TYPES.SHORT, true) : 0}`
        }

        const filledData = fillZeroData(normalizedData)
        const formattedFilledData = filledData.map(data => ({ ...data, date: getFormattedShortDate(data.date) }))

        setChartData(formattedFilledData)
        setChartDataLabels(dataLabels)
        setIsCalculatingData(false)
    }

    function fillZeroData(normalizedData) {
        let filledData = []
        for (let i = 0; i < normalizedData?.length - 1; i++) {
            filledData.push(normalizedData[i])
            let current = new Date(normalizedData[i]?.date)
            const next = normalizedData[i + 1]?.date
            current.setDate(current.getDate() + 1)
            while (current < next) {
                filledData.push({
                    date: current,
                    Revenue: 0,
                    Sales: 0,
                    AOV: 0
                })
                current = new Date(current)
                current.setDate(current.getDate() + 1)
            }
        }
        if (normalizedData?.length > 1) {
            filledData.push(normalizedData[normalizedData.length - 1])
        }
        return filledData
    }

    return (
        <div className={isMobile ? 'mobile-paydin-commerce-chart-container' : 'paydin-commerce-chart-container'}>
            <PaydinChart
                chartData={chartData}
                chartDataLabels={chartDataLabels}
                setChartDataLabels={setChartDataLabels}
                isDataLoading={isCalculatingData || isDataLoading}
                timeRangeName={timeRangeName}
            />
        </div>
    )
}