import Loader from 'components/Loader/Loader'
import React from 'react'

/**
 * Represents a loader for a value while it is being loading.
 */
export default function BusinessAnalyticsDataLoader() {
    return (
        <Loader
            styles={{
                width: '25px',
                height: '25px'
            }}
        />
    )
}