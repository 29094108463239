import React, { useState } from 'react'
import './PricingPlan.css'
import { formatNumber } from 'utils/utils'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useTranslation } from 'react-i18next'
import { CONTACT_US_PLANS, NUMBER_TYPES, PRICING_PLANS_ENTERPRISE_PLAN_CONTACT_US_EMAIL, PRICING_PLAN_DATA, UNAVAILABLE_PLANS } from 'constants/general.constants'
import { CancelPlanAPI, UpgradePlanAPI } from 'api/store/store'
import { useHistoryContext } from 'contexts/History'
import { GiConfirmed } from 'react-icons/gi'
import { HOME_PAGE_URL } from 'constants/routes.constants'

/**
 * Represents a pricing plan.
 * @param {string} name - The name of the pricing plan
 * @param {string} color - The color of this plan, shown as a colored bar on the top
 * @param {number} price - The price of this plan
 * @param {string} pricingPeriod - The subscription period of this plan, e.g. every week, month, year etc.
 * @param {array} features - The features of this plan
 * @param {string} description - The description of this plan
 * @param {number} ordinal - The ordinal number of this plan, preserve an order between pricing plans
 * @param {number} subtitle - The ordinal level of this plan
 */
export default function PricingPlan({
    name,
    color,
    price,
    pricingPeriod,
    features,
    description,
    ordinal,
    subtitle
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        isFreeUser,
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()
    const history = useHistoryContext()
    const { verify } = useAuthContext()

    const [isChangingPlan, setIsChangingPlan] = useState(false)

    function getButtonText() {
        const subscriptedPricingPlanName = Object.keys(PRICING_PLAN_DATA).find(pricingPlanName => PRICING_PLAN_DATA[pricingPlanName].name === userDetails?.subscriptionPlan.toLowerCase())
        const subscriptedPricingPlan = PRICING_PLAN_DATA[subscriptedPricingPlanName]

        if (ordinal < subscriptedPricingPlan.ordinal) {
            return t('PRICING_PLANS_PAGE_DOWNGRADE_BUTTON_TEXT')
        } else {
            return isFreeUser() && !isFreeUserAndTrialEnded() ? t('PRICING_PLANS_PAGE_UPGRADE_BUTTON_FREE_USER_TEXT') : t('PRICING_PLANS_PAGE_UPGRADE_BUTTON_TEXT')
        }
    }

    function getTitle() {
        const subscriptedPricingPlanName = Object.keys(PRICING_PLAN_DATA).find(pricingPlanName => PRICING_PLAN_DATA[pricingPlanName].name === name)
        const subscriptedPricingPlan = PRICING_PLAN_DATA[subscriptedPricingPlanName]

        return subscriptedPricingPlan.presentedName
    }

    function getButtonClickHandler() {
        if (name === PRICING_PLAN_DATA.FREE.name)
            return cancel
        return upgrade
    }

    function upgrade() {
        setIsChangingPlan(true)
        UpgradePlanAPI(name.toUpperCase(), userDetails?.businessId)
            .then(url => {
                window.location.href = url
            })
            .catch(error => {
                console.log(error)
                setIsChangingPlan(false)
            })
    }

    function cancel() {
        setIsChangingPlan(true)
        CancelPlanAPI(userDetails?.businessId)
            .then(() => {
                // TODO - replace with updating plan to FREE without verify
                verify(data => {
                    history.push(HOME_PAGE_URL)
                }, error => {
                    console.log(error)
                    history.push(HOME_PAGE_URL)
                })
            })
            .catch(error => {
                console.log(error)
                setIsChangingPlan(false)
            })
    }

    function renderPlanButton() {
        if (userDetails?.isAuthenticated && userDetails?.isAdmin) {
            if (name.toUpperCase() === userDetails?.subscriptionPlan) {
                return <div className='pricing-plan-active-plan-label'>{t('PRICING_PLANS_PAGE_ACTIVE_PLAN_LABEL')}</div>
            } else if (UNAVAILABLE_PLANS.includes(name)) {
                return <div className='pricing-plan-unavailable-plan-label'>{t('PRICING_PLANS_PAGE_UNAVAILABLE_PLAN_LABEL')}</div>
            } else if (CONTACT_US_PLANS.includes(name)) {
                return <a href={PRICING_PLANS_ENTERPRISE_PLAN_CONTACT_US_EMAIL} className='pricing-plan-contact-us-plan-label'>{t('PRICING_PLANS_PAGE_CONTACT_US_BUTTON_TEXT')}</a>
            } else {
                return <LoaderButton
                    buttonText={getButtonText()}
                    className='pricing-plan-bottom-button'
                    isLoading={isChangingPlan}
                    onClick={getButtonClickHandler()}
                />
            }
        }

        return <></>
    }

    return (
        <div className='pricing-plan-container'>
            <div className="pricing-plan-color-bar" style={{ backgroundColor: color }}></div>
            <div className="pricing-plan-content">
                <div className="pricing-plan-name">{getTitle(name)}</div>
                {
                    subtitle ? <div className='pricing-plan-subtitle' style={{ color }}>{subtitle}</div> : <div className="pricing-plan-pricing-method" style={{ visibility: price ? 'visible' : 'hidden' }}>
                        <div className="pricing-plan-price" style={{ color }}>${formatNumber(price, NUMBER_TYPES.PRICE)}</div>
                        <div className="pricing-plan-price-separator">/</div>
                        <div className="pricing-plan-pricing-period">{pricingPeriod}</div>
                    </div>
                }
                <div className="pricing-plan-separator"></div>
                {
                    features.map(feature => <div key={feature?.id} className='pricing-plan-feature-row'>
                        <GiConfirmed className='pricing-plan-feature-v' />
                        <div className='pricing-plan-feature'>{feature?.content}</div>
                    </div>
                    )
                }
                <div className="pricing-plan-bottom-section">
                    {
                        description && <div className='pricing-plan-description'>{description}</div>
                    }
                    {renderPlanButton()}
                </div>
            </div>
        </div>
    )
}