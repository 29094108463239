import React from 'react'
import './PaydinSelectRenderedValue.css'

/**
 * Represents a rendered display value of a selected option of {@link PaydinSelect}.
 */
export default function PaydinSelectRenderedValue({
    children
}) {
    return (
        <div className='paydin-select-rendered-value-container'>
            {children}
        </div>
    )
}