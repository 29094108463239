import PaydinChartControlSection from 'components/PaydinChartControlSection/PaydinChartControlSection'
import './PaydinChart.css'
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Bar, ComposedChart } from 'recharts'
import { PAYDIN_CHART_CONTENT_GAP_PX, PAYDIN_CHART_CONTENT_MOBILE_GAP_PX, PAYDIN_CHART_CONTENT_PADDING_PX, PAYDIN_CHART_GRAPH_HEIGHT_PX, PAYDIN_CHART_TYPES } from 'constants/general.constants'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Loader from 'components/Loader/Loader'
import { useTranslation } from 'react-i18next'

/**
 * Represents a chart with a title and a frame.
 * @param {string} title - The title for this chart
 * @param {array} chartData - An array of object representing the normalized data for each point of the chart. 
 * @param {object} chartDataLabels - An object containing configuration data for each chart point
 * @param {function} setChartDataLabels - A function for setting the configuration data for each chart point
 */
export default function PaydinChart({
    chartData = [],
    chartDataLabels = {},
    setChartDataLabels = () => { },
    isDataLoading = false,
    timeRangeName = '',
    isMultiLabelSelect = true
}) {
    const { t } = useTranslation()

    const [chartDimensPx, setChartDimensPx] = useState({
        width: 0,
        height: 0
    })
    const [labelsDimensPx, setLabelsDimensPx] = useState({
        width: 0,
        height: 0
    })

    const chartContainerRef = useRef(null)
    const chartLabelsRef = useRef(null)

    useEffect(() => {
        if (chartContainerRef?.current && chartLabelsRef?.current) {
            setChartDimensPx({
                width: chartContainerRef?.current?.clientWidth,
                height: chartContainerRef?.current?.clientHeight - chartLabelsRef?.current?.clientHeight
            })
            setLabelsDimensPx({
                width: chartLabelsRef?.current?.clientWidth,
                height: chartLabelsRef?.current?.clientHeight
            })
        }
        
    }, [])

    function onChartControlClick(label) {
        const dataLabels = { ...chartDataLabels }
        Object.keys(dataLabels).forEach(dataLabel => {
            if (dataLabels[dataLabel]?.label === label) {
                dataLabels[dataLabel].selected = !dataLabels[dataLabel].selected
            } else if (!isMultiLabelSelect) {
                dataLabels[dataLabel].selected = false
            }
        })
        setChartDataLabels(dataLabels)
    }

    function renderChart(chartType, dataKey, chartColor, yAxisId = "left") {
        if (chartType === PAYDIN_CHART_TYPES.LINE) {
            return <Line
                key={dataKey}
                yAxisId={yAxisId}
                scale='time'
                type="monotone"
                dataKey={dataKey}
                strokeWidth={4}
                stroke={chartColor}
                activeDot={{ r: 8 }}
                dot={chartData?.length === 1}
            />
        }

        return <Bar
            key={dataKey}
            dataKey={dataKey}
            barSize={20}
            yAxisId={yAxisId}
            fill={chartColor}
        />
    }

    return (
        <div className={isMobile ? 'mobile-paydin-chart-container' : 'paydin-chart-container'}>
            <div ref={chartContainerRef} className={isMobile ? "mobile-paydin-chart" : "paydin-chart"}>
                <div ref={chartLabelsRef} className={isMobile ? "mobile-paydin-chart-displayed-graphs-labels" : "paydin-chart-displayed-graphs-labels"}>
                    {
                        Object.keys(chartDataLabels).length > 0 && Object.keys(chartDataLabels).map(label => <PaydinChartControlSection
                            key={chartDataLabels[label]?.label}
                            title={chartDataLabels[label]?.label}
                            dotColor={chartDataLabels[label]?.labelColor}
                            value={chartDataLabels[label]?.totalValue}
                            selected={chartDataLabels[label]?.selected}
                            onClick={() => onChartControlClick(chartDataLabels[label]?.label)}
                        />)
                    }
                </div>
                <div className={isMobile ? "mobile-paydin-chart-content" : "paydin-chart-content"} style={{ width: `${chartDimensPx.width}px`, height: `${PAYDIN_CHART_GRAPH_HEIGHT_PX}px` }}>
                    {
                        (isDataLoading ? <Loader styles={{ width: '25px', height: '25px', position: 'absolute', inset: 0, margin: 'auto' }} /> : (chartData.length > 0 && Object.keys(chartDataLabels).length > 0) && <ComposedChart
                            width={chartDimensPx.width}
                            height={PAYDIN_CHART_GRAPH_HEIGHT_PX}
                            data={chartData}
                        >
                            <CartesianGrid strokeDasharray="3 3" chartWidth={chartDimensPx.width} />
                            <XAxis
                                dataKey="date"
                                tickMargin={10}
                                interval={'preserveStartEnd'}
                                minTickGap={30}
                            />
                            <YAxis yAxisId="left" orientation="left" />
                            <YAxis yAxisId='right' orientation='right' />
                            <Tooltip />
                            {
                                Object.keys(chartDataLabels)
                                    .filter(rawLabel => chartDataLabels[rawLabel]?.selected)
                                    .map(label => renderChart(chartDataLabels[label]?.chartType, label, chartDataLabels[label]?.labelColor, chartDataLabels[label]?.yAxisId))
                            }
                        </ComposedChart>)
                    }
                </div>
                {
                    !isDataLoading && (chartData?.length === 0 && <div className="paydin-chart-no-data-frame" style={{ height: `${PAYDIN_CHART_GRAPH_HEIGHT_PX + labelsDimensPx.height + ((PAYDIN_CHART_CONTENT_PADDING_PX * 2) + 15) + (isMobile ? PAYDIN_CHART_CONTENT_MOBILE_GAP_PX : PAYDIN_CHART_CONTENT_GAP_PX)}px` }}>
                        <div className={isMobile ? "mobile-paydin-chart-no-data-message" : "paydin-chart-no-data-message"}>{t('PAYDIN_CHART_NO_DATA_MESSAGE', { timeRange: timeRangeName })}</div>
                    </div>)
                }
            </div>
        </div>
    )
}