import React from 'react'
import './PreviewItemDataSection.css'
import { isMobile } from 'react-device-detect'

/**
 * Represents a section for displaying data in ShortView and LinkView pages
 * @param {string} title - The title of the section
 * @param {boolean} NoBackgroundColor - If true, the background color of this section is removed
 * @param {boolean} hasPadding - If true, this section has padding
 * @param {string} width - The string representing the width for this section
 */
export default function PreviewItemDataSection({
    children,
    title = '',
    NoBackgroundColor = false,
    hasPadding = true,
    width
}) {
    return (
        <div className={isMobile ? 'mobile-preview-item-data-section' : 'preview-item-data-section'} style={{ width: width ?? null, backgroundColor: NoBackgroundColor && 'transparent', padding: !hasPadding && '0' }}>
            {title && <div className='preview-item-data-section-title'>{title}</div>}
            {children}
        </div>
    )
}