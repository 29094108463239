import React, { useEffect, useState } from 'react'
import './UTMForm.css'
import UTMFormInput from 'components/UTMFormInput/UTMFormInput'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/Tooltip/Tooltip'
import { isMobile } from 'react-device-detect'
import PaydinSwitch from 'components/PaydinSwitch/PaydinSwitch'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import ExpandHideButton from 'components/ExpandHideButton/ExpandHideButton'

/**
 * Represents a form for assigning UTM parameters.
 * @param {string} UTMSource - The UTM source parameter
 * @param {function} setUTMSource - A function for setting the UTM source parameter
 * @param {string} UTMMedium - The UTM source parameter
 * @param {function} setUTMMedium - A function for setting the UTM medium parameter
 * @param {string} UTMCampaign - The UTM source parameter
 * @param {function} setUTMCampaign - A function for setting the UTM campaign parameter
 * @param {string} UTMTerm - The UTM source parameter
 * @param {function} setUTMTerm - A function for setting the UTM term parameter
 * @param {string} UTMContent - The UTM source parameter
 * @param {function} setUTMContent - A function for setting the UTM content parameter
 */
export default function UTMForm({
    inlinePadding = '10px',
    shouldDisplayControlBar = true,
    inputsHasPadding = true,
    shouldAddUTMs = false,
    setShouldAddUTMs = () => { },
    UTMSource = '',
    setUTMSource = () => { },
    UTMMedium = '',
    setUTMMedium = () => { },
    UTMCampaign = '',
    setUTMCampaign = () => { },
    UTMTerm = '',
    setUTMTerm = () => { },
    UTMContent = '',
    setUTMContent = () => { }
}) {
    const { t } = useTranslation()

    const [isUTMParamsShown, setIsUTMParamsShown] = useState(false)

    useEffect(() => {
        generateUTMTag()
    }, [UTMSource, UTMMedium, UTMCampaign, UTMTerm, UTMContent])

    function generateUTMTag() {
        if (!UTMSource && !UTMMedium && !UTMCampaign && !UTMTerm && !UTMContent) {
            return '-'
        }

        return `?${UTMSource ? `utm_source=${UTMSource}` : ''}${(UTMMedium) ? `${UTMSource ? '&' : ''}utm_medium=${UTMMedium}` : ''}${(UTMCampaign) ? `${(UTMSource || UTMMedium) ? '&' : ''}utm_campaign=${UTMCampaign}` : ''}${(UTMTerm) ? `${(UTMSource || UTMMedium || UTMCampaign) ? '&' : ''}utm_term=${UTMTerm}` : ''}${(UTMContent) ? `${(UTMSource || UTMMedium || UTMCampaign || UTMTerm) ? '&' : ''}utm_content=${UTMContent}` : ''}`
    }

    function setShouldAddUTMParameters(value) {
        setIsUTMParamsShown(true)
        setShouldAddUTMs(value)
    }

    function toggleUTMParams() {
        setIsUTMParamsShown(prev => !prev)
    }

    function isUTMInputsShown() {
        if (shouldDisplayControlBar) {
            return isUTMParamsShown && shouldAddUTMs
        }
        return shouldAddUTMs
    }

    return (
        <div className='utm-form-container'>
            {
                shouldDisplayControlBar && <div className={isMobile ? "mobile-utm-form-control-bar-container" : "utm-form-control-bar-container"}>
                    <PaydinSwitch
                        checked={shouldAddUTMs}
                        setChecked={setShouldAddUTMParameters}
                        label={t('UTM_FORM_UTMS_SWITCH_LABEL')}
                        labelClassName='utm-form-switch-label'
                    />
                    {
                        shouldAddUTMs ? <ExpandHideButton
                            isExpanded={isUTMInputsShown()}
                            onClick={toggleUTMParams}
                        /> : <div style={{ width: '50px' }}></div>
                    }
                </div>
            }
            <CollapsingElement expanded={isUTMInputsShown()}>
                <div className="utm-form-inputs" style={{ padding: inputsHasPadding ? '10px' : '0', paddingInline: inlinePadding }}>
                    <UTMFormInput
                        title={t('UTM_FORM_SOURCE_INPUT_TITLE')}
                        placeholder={t('UTM_FORM_SOURCE_INPUT_PLACEHOLDER')}
                        value={UTMSource}
                        setValue={(setUTMSource)}
                    />
                    <UTMFormInput
                        title={t('UTM_FORM_MEDIUM_INPUT_TITLE')}
                        placeholder={t('UTM_FORM_MEDIUM_INPUT_PLACEHOLDER')}
                        value={UTMMedium}
                        setValue={(setUTMMedium)}
                    />
                    <UTMFormInput
                        title={t('UTM_FORM_CAMPAIGN_INPUT_TITLE')}
                        placeholder={t('UTM_FORM_CAMPAIGN_INPUT_PLACEHOLDER')}
                        value={UTMCampaign}
                        setValue={(setUTMCampaign)}
                        inputClassName={isMobile ? 'mobile-utm-form-campaign-input' : 'utm-form-campaign-input'}
                    />
                    <UTMFormInput
                        title={t('UTM_FORM_TERM_INPUT_TITLE')}
                        placeholder={t('UTM_FORM_TERM_INPUT_PLACEHOLDER')}
                        value={UTMTerm}
                        setValue={(setUTMTerm)}
                    />
                    <UTMFormInput
                        title={t('UTM_FORM_CONTENT_INPUT_TITLE')}
                        placeholder={t('UTM_FORM_CONTENT_INPUT_PLACEHOLDER')}
                        value={UTMContent}
                        setValue={(setUTMContent)}
                    />
                    <div className="utm-form-utm-tag-preview-container">
                        <div className="utm-form-utm-tag-preview-title-container">
                            <div className="utm-form-utm-tag-preview-title">{t('UTM_FORM_UTM_TAG_PREVIEW_TITLE')}</div>
                            <Tooltip tooltipText={t('UTM_FORM_UTM_TAG_PREVIEW_TOOLTIP_TEXT')} />
                        </div>
                        <div className="utm-form-utm-tag-preview">{generateUTMTag()}</div>
                    </div>
                </div>
            </CollapsingElement>
        </div>
    )
}