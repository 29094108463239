import React, { useEffect, useState } from 'react'
import './SettingsPage.css'
import { isMobile } from 'react-device-detect'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import AccountSection from 'components/AccountSection/AccountSection'
import { ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME, ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME, ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME, ACCOUNT_PAGE_URL_PARAMS_INPUT_NAME, DASHBOARD_SIDEBAR_USER_MENU_WIDTH, PRICING_PLAN_DATA, SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR, SETTINGS_PAGE_ANALYTICS_SECTION_ID, SETTINGS_PAGE_AUTO_SWAP_DEFAULT_VALUE, SETTINGS_PAGE_SHIPPING_AND_RETURN_SECTION_ID, SETTINGS_PAGE_UTM_SECTION_ID } from 'constants/general.constants'
import SettingsInput from 'components/SettingsInput/SettingsInput'
import PaydinSwitch from 'components/PaydinSwitch/PaydinSwitch'
import UTMForm from 'components/UTMForm/UTMForm'
import { useUserDetailsContext } from 'contexts/User'
import { Trans, useTranslation } from 'react-i18next'
import { UpdateStoreSettings } from 'api/store/store'
import { useHistoryContext } from 'contexts/History'
import { isInputValueValid, scrollToId } from 'services/SystemService'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import AutoSwapRadioGroup from 'components/AutoSwapRadioGroup/AutoSwapRadioGroup'
import { HOME_PAGE_URL, PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import SaveChangesStrip from 'components/SaveChangesStrip/SaveChangesStrip'
import LoaderButton from 'components/LoaderButton/LoaderButton'

export default function SettingsPage() {
    const { t } = useTranslation()
    const {
        userDetails,
        setUserDetails,
        hasShopifyStore,
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()
    const history = useHistoryContext()

    const originalShippingAndReturnUrl = (userDetails?.settings?.shippingAndReturnsUrl && userDetails?.settings?.shippingAndReturnsUrl !== '') ? userDetails?.settings?.shippingAndReturnsUrl.replace(t('HTTPS_HOST_URL_PREFIX'), '') : ''

    const [inputState, setInputState] = useState({
        shippingAndReturnsUrl: originalShippingAndReturnUrl,
        facebookPixelId: userDetails?.settings?.facebookPixelId,
        googleAnalyticsId: userDetails?.settings?.googleAnalyticsId,
        utmParams: { ...userDetails?.settings?.utmParams },
        autoSwapEnabled: userDetails?.settings?.autoSwapEnabled
    })
    const [saveChangesStripState, setSaveChangesStripState] = useState({
        isSaving: false,
        isShown: false
    })
    const [saveButtonState, setSaveButtonState] = useState({
        isAbleToSave: false,
        isSaving: false
    })
    const [mobileSaveButtonState, setMobileSaveButtonState] = useState({
        isAbleToSave: false,
        isSaving: false
    })
    const [shouldAddUTMs, setShouldAddUTMs] = useState(hasUTMParams())
    const [shouldTestUTMParams, setShouldTestUTMParams] = useState(false)
    const [approvalMessageState, setApprovalMessageState] = useState({
        isMessageShown: false,
        text: ''
    })
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    useEffect(() => {
        if (hasShopifyStore()) {
            setShouldTestUTMParams(true)
            scrollToId()
        } else {
            history.replace(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        setSaveChangesStripState(prev => ({
            ...prev,
            isShown:
                ![originalShippingAndReturnUrl, ''].includes(inputState.shippingAndReturnsUrl) ||
                ![userDetails?.settings?.facebookPixelId].includes(inputState.facebookPixelId) ||
                ![userDetails?.settings?.googleAnalyticsId].includes(inputState.googleAnalyticsId) ||

                ![userDetails?.settings?.autoSwapEnabled].includes(inputState.autoSwapEnabled) ||

                ![userDetails?.settings?.utmParams?.UTMSource].includes(inputState.utmParams?.UTMSource) ||
                ![userDetails?.settings?.utmParams?.UTMMedium].includes(inputState.utmParams?.UTMMedium) ||
                ![userDetails?.settings?.utmParams?.UTMCampaign].includes(inputState.utmParams?.UTMCampaign) ||
                ![userDetails?.settings?.utmParams?.UTMTerm].includes(inputState.utmParams?.UTMTerm) ||
                ![userDetails?.settings?.utmParams?.UTMContent].includes(inputState.utmParams?.UTMContent)
        }))
    }, [inputState])

    useEffect(() => {
        if (shouldTestUTMParams && !shouldAddUTMs) {
            resetUTMParams()
        }
    }, [shouldAddUTMs])

    useEffect(() => {
        setSaveButtonState(prev => ({
            ...prev,
            isAbleToSave: saveChangesStripState.isShown
        }))
        setMobileSaveButtonState(prev => ({
            ...prev,
            isAbleToSave: saveChangesStripState.isShown
        }))
    }, [saveChangesStripState])

    function resetUTMParams() {
        setInputState(prev => ({
            ...prev,
            utmParams: {
                UTMSource: '',
                UTMMedium: '',
                UTMCampaign: '',
                UTMTerm: '',
                UTMContent: ''
            }
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function hasUTMParams() {
        return userDetails?.settings?.utmParams?.UTMSource.length > 0 ||
            userDetails?.settings?.utmParams?.UTMMedium.length > 0 ||
            userDetails?.settings?.utmParams?.UTMCampaign.length > 0 ||
            userDetails?.settings?.utmParams?.UTMTerm.length > 0 ||
            userDetails?.settings?.utmParams?.UTMContent.length > 0
    }

    function showApprovalMessage(text) {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: true,
            text
        }))
    }

    function resetData() {
        setInputState({
            shippingAndReturnsUrl: originalShippingAndReturnUrl,
            facebookPixelId: userDetails?.settings?.facebookPixelId,
            googleAnalyticsId: userDetails?.settings?.googleAnalyticsId,
            utmParams: { ...userDetails?.settings?.utmParams },
            autoSwapEnabled: userDetails?.settings?.autoSwapEnabled
        })
    }

    function onStripSaveClick() {
        setSaveChangesStripState(prev => ({
            ...prev,
            isSaving: true
        }))
        saveBusinessSettings()
    }

    function onSaveButtonClick() {
        setSaveButtonState(prev => ({
            ...prev,
            isSaving: true
        }))
        saveBusinessSettings()
    }

    function onMobileSaveButtonClick() {
        setMobileSaveButtonState(prev => ({
            ...prev,
            isSaving: true
        }))
        saveBusinessSettings()
    }

    function saveBusinessSettings() {
        const finalShippingAndReturnUrl = inputState.shippingAndReturnsUrl ? t('HTTPS_HOST_URL_PREFIX') + inputState.shippingAndReturnsUrl : ''

        UpdateStoreSettings(userDetails?.businessId, finalShippingAndReturnUrl, inputState.facebookPixelId, inputState.googleAnalyticsId, inputState.utmParams, inputState.autoSwapEnabled)
            .then(_ => {
                setSaveChangesStripState({
                    isSaving: false,
                    isShown: false
                })
                showApprovalMessage(t('SETTINGS_PAGE_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
                setUserDetails(prev => ({
                    ...prev,
                    settings: {
                        shippingAndReturnsUrl: inputState.shippingAndReturnsUrl,
                        facebookPixelId: inputState.facebookPixelId,
                        googleAnalyticsId: inputState.googleAnalyticsId,
                        utmParams: inputState.utmParams,
                        autoSwapEnabled: inputState.autoSwapEnabled
                    }
                }))
            })
            .catch(error => {
                console.log(error)
                showDialog(t('SETTINGS_PAGE_UPLOAD_GENERAL_ERROR_DIALOG_TITLE'), t('SETTINGS_PAGE_UPLOAD_GENERAL_ERROR_DIALOG_RIGHT_BUTTON_TEXT'))
            })
            .finally(() => {
                setSaveChangesStripState(prev => ({
                    ...prev,
                    isSaving: false
                }))
                setSaveButtonState(prev => ({
                    ...prev,
                    isSaving: false
                }))
                setMobileSaveButtonState(prev => ({
                    ...prev,
                    isSaving: false
                }))
            })
    }

    function showDialog(title, leftButtonText, rightButtonText, rightButtonClickHandler = () => { }) {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title,
            leftButtonText,
            rightButtonText,
            rightButtonClickHandler
        }))
    }

    function handleInputChange(e) {
        switch (e.target.name) {
            case ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME: {
                setInputState(prev => ({
                    ...prev,
                    shippingAndReturnsUrl: e.target.value
                }))
                break
            }

            case ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    facebookPixelId: e.target.value
                }))
                break

            case ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    googleAnalyticsId: e.target.value
                }))
                break
        }
    }

    function setAutoSwap(value) {
        setInputState(prev => {
            return {
                ...prev,
                autoSwapEnabled: value
            }
        })
    }

    function setUTMSource(value) {
        if (isInputValueValid(value)) {
            setInputState(prev => ({
                ...prev,
                utmParams: {
                    ...prev.utmParams,
                    UTMSource: value
                }
            }))
        }
    }

    function setUTMMedium(value) {
        if (isInputValueValid(value)) {
            setInputState(prev => ({
                ...prev,
                utmParams: {
                    ...prev.utmParams,
                    UTMMedium: value
                }
            }))
        }
    }

    function setUTMTerm(value) {
        if (isInputValueValid(value)) {
            setInputState(prev => ({
                ...prev,
                utmParams: {
                    ...prev.utmParams,
                    UTMTerm: value
                }
            }))
        }
    }

    function setUTMCampaign(value) {
        if (isInputValueValid(value)) {
            setInputState(prev => ({
                ...prev,
                utmParams: {
                    ...prev.utmParams,
                    UTMCampaign: value
                }
            }))
        }
    }

    function setUTMContent(value) {
        if (isInputValueValid(value)) {
            setInputState(prev => ({
                ...prev,
                utmParams: {
                    ...prev.utmParams,
                    UTMContent: value
                }
            }))
        }
    }

    function hideApprovalMassage() {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: false
        }))
    }

    function navigateToPricingPlans() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    return (
        <div className={isMobile ? 'mobile-settings-page-container' : 'settings-page-container'}>
            <SaveChangesStrip
                isStripShown={saveChangesStripState.isShown}
                isSaving={saveChangesStripState.isSaving}
                onDiscardClick={resetData}
                onSaveClick={onStripSaveClick}
            />
            {
                isMobile ? <ContentDialogTopbar
                    title={t('SETTINGS_PAGE_TITLE')}
                    onBackButtonClick={() => history.goBack()}
                    rightElement={<>
                        <LoaderButton
                            className='page-view-topbar-save-button'
                            buttonText={t('LINK_VIEW_TOPBAR_SAVE_BUTTON_TEXT')}
                            isLoading={mobileSaveButtonState.isSaving}
                            loaderCircleColor='#0095f6'
                            isDisabled={!mobileSaveButtonState.isAbleToSave || mobileSaveButtonState.isSaving}
                            onClick={onMobileSaveButtonClick}
                        />
                    </>}
                /> : <div className="settings-page-title dashboard-page-title">{t('SETTINGS_PAGE_TITLE')}</div>
            }
            <div className="settings-page-content">
                <AccountSection
                    title={t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_TITLE')}
                    id={SETTINGS_PAGE_ANALYTICS_SECTION_ID}
                    sectionContentClassName='account-page-analytics-integrations-section'
                >
                    <>
                        <div className="settings-page-input-section">
                            <div className='settings-page-subtitle-input-container'>
                                <div className="settings-page-subtitle-input-titles-container">
                                    <div className="settings-page-input-title">{t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_FACEBOOK_PIXEL_ID_TITLE')}</div>
                                </div>
                                <SettingsInput
                                    name={ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME}
                                    value={inputState.facebookPixelId}
                                    placeholder={t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_FACEBOOK_PIXEL_ID_INPUT_PLACEHOLDER')}
                                    onValueChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="settings-page-input-section">
                            <div className='settings-page-subtitle-input-container'>
                                <div className="settings-page-subtitle-input-titles-container">
                                    <div className="settings-page-input-title">{t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_GOOGLE_ANALYTICS_ID_TITLE')}</div>
                                </div>
                                <SettingsInput
                                    name={ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME}
                                    value={inputState.googleAnalyticsId}
                                    placeholder={t('SETTINGS_PAGE_ANALYTICS_INTEGRATIONS_SECTION_GOOGLE_ANALYTICS_ID_INPUT_PLACEHOLDER')}
                                    onValueChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </>
                </AccountSection>
                <AccountSection
                    title={
                        <Trans
                            i18nKey='SETTINGS_PAGE_AUTO_SWAP_SECTION_TITLE'
                            components={{
                                sup: <div className='superscript' />
                            }}
                        />
                    }
                    sectionContentClassName='store-settings-page-shipping-and-returns-section'
                >
                    <div className="settings-page-auto-swap-subsection settings-pages-subsection-title">
                        <Trans
                            i18nKey='SETTINGS_PAGE_AUTO_SWAP_SUBSECTION_TITLE'
                            components={{
                                sup: <div className='superscript' />
                            }}
                        />
                    </div>
                    {
                        isFreeUserAndTrialEnded() ?
                            <div className='settings-page-auto-swap-section-try-for-free-button' onClick={navigateToPricingPlans}>{t('SETTINGS_PAGE_AUTO_SWAP_SECTION_TRY_FOR_FREE_BUTTON_TEXT')}</div> :
                            <AutoSwapRadioGroup
                                autoSwapEnabled={inputState.autoSwapEnabled}
                                setAutoSwap={setAutoSwap}
                            />
                    }
                </AccountSection>
                <AccountSection
                    title={t('SETTINGS_PAGE_UTM_PARAMETERS_SECTION_TITLE')}
                    id={SETTINGS_PAGE_UTM_SECTION_ID}
                    sectionContentClassName='account-page-utm-parameters-section'
                >
                    <div className="settings-page-input-section">
                        <div className='account-page-utm-params-subtitle-input-container settings-page-subtitle-input-container'>
                            <div className="settings-page-subtitle-input-titles-container">
                                <div className="settings-page-input-title-container">
                                    <div className="settings-page-input-title">{t('SETTINGS_PAGE_UTM_PARAMETERS_SECTION_UTM_PARAMETERS_TITLE')}</div>
                                    <PaydinSwitch
                                        checked={shouldAddUTMs}
                                        setChecked={setShouldAddUTMs}
                                        labelClassName='account-page-add-utms-switch-label'
                                    />
                                </div>
                                <div className="settings-page-input-subtitle">{t('ACCOUNT_PAGE_URL_PARAMETERS_SECTION_UTM_VALUES_SUBTITLE')}</div>
                            </div>
                        </div>
                        <UTMForm
                            shouldDisplayControlBar={false}
                            inputsHasPadding={false}
                            shouldAddUTMs={shouldAddUTMs}
                            UTMSource={inputState.utmParams?.UTMSource}
                            setUTMSource={setUTMSource}
                            UTMMedium={inputState.utmParams?.UTMMedium}
                            setUTMMedium={setUTMMedium}
                            UTMCampaign={inputState.utmParams?.UTMCampaign}
                            setUTMCampaign={setUTMCampaign}
                            UTMTerm={inputState.utmParams?.UTMTerm}
                            setUTMTerm={setUTMTerm}
                            UTMContent={inputState.utmParams?.UTMContent}
                            setUTMContent={setUTMContent}
                        />
                    </div>
                </AccountSection>
                <AccountSection
                    title={t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURNS_SECTION_TITLE')}
                    id={SETTINGS_PAGE_SHIPPING_AND_RETURN_SECTION_ID}
                    sectionContentClassName='store-settings-page-shipping-and-returns-section'
                >
                    <div className='settings-page-subtitle-input-container'>
                        <div className="settings-page-input-subtitle">{t('SETTINGS_PAGE_SHOP_SHIPPING_AND_RETURN_SECTION_REDIRECT_SUBTITLE')}</div>
                        <SettingsInput
                            name={ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME}
                            value={inputState.shippingAndReturnsUrl}
                            prefix={t('HTTPS_HOST_URL_PREFIX')}
                            onValueChange={handleInputChange}
                        />
                    </div>
                </AccountSection>
                {
                    !isMobile && <LoaderButton
                        className={isMobile ? 'mobile-save-changes-button' : 'save-changes-button'}
                        isLoading={saveButtonState.isSaving}
                        isDisabled={!saveButtonState.isAbleToSave || saveButtonState.isSaving}
                        buttonText={t('SETTINGS_PAGE_SAVE_CHANGES_BUTTON_TEXT')}
                        onClick={onSaveButtonClick}
                    />
                }
                <PaydinDialog
                    title={dialogState.title}
                    isDialogOpen={dialogState.isOpen}
                    handleDialogClose={dialogState.handleDialogClose}
                    leftButtonText={dialogState.leftButtonText}
                    rightButtonText={dialogState.rightButtonText}
                    leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                    rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                    onRightButtonClick={dialogState.rightButtonClickHandler}
                    isLeftButtonWithLoader={false}
                    dialogLayout={dialogState.dialogLayout}
                />
                <ApprovalMessage
                    isMessageShown={approvalMessageState.isMessageShown}
                    closeMessage={hideApprovalMassage}
                    text={approvalMessageState.text}
                />
            </div>
        </div>
    )
}