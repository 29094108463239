import React, { useEffect, useRef, useState } from 'react'
import './RunningMessagesStrip.css'
import { RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_DURATION, RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_IN_NAME, RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_OUT_NAME, RUNNING_MESSAGES_STRIP_TEXT_NO_ANIMATION_NAME, RUNNING_MESSAGES_STRIP_TEXT_SWAP_DELAY_MILLISECONDS } from 'constants/general.constants'
import { isMobile } from 'react-device-detect'

/**
 * Represents a strip of swapping messages.
 * @param {string} backgroundColor - The background color of this strip
 * @param {string} fontColor - The font color of this strip
 * @param {array} messages - The list of messages to display
 */
export default function RunningMessagesStrip({
    backgroundColor,
    fontColor,
    messages = [],
}) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [displayMessageState, setDisplayMessageState] = useState({
        current: ''
    })

    const currentMessageRef = useRef(null)
    const intervalRef = useRef(null)

    useEffect(() => {
        if (!messages || messages.length === 0) {
            setDisplayMessageState({ current: '' })
            return
        }

        if (messages.length === 1) {
            setDisplayMessageState({ current: messages[0] })
            return
        }

        if (intervalRef.current)
            clearInterval(intervalRef.current)

        currentMessageRef.current.style.animationName = RUNNING_MESSAGES_STRIP_TEXT_NO_ANIMATION_NAME
        setCurrentIndex(0)
        if (messages.length > 0) {
            intervalRef.current = setInterval(() => {
                setCurrentIndex(prev => (prev + 1) % messages.length)
            }, RUNNING_MESSAGES_STRIP_TEXT_SWAP_DELAY_MILLISECONDS)
        }
    }, [messages])

    useEffect(() => {
        if (messages?.length > 1) {
            setDisplayMessageState({
                current: messages[currentIndex]
            })
        }
    }, [currentIndex])

    useEffect(() => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                if (currentMessageRef?.current?.style?.animationName)
                    currentMessageRef.current.style.animationName = RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_OUT_NAME
                setTimeout(() => {
                    if (currentMessageRef?.current) {
                        currentMessageRef.current.innerText = displayMessageState.current
                        currentMessageRef.current.style.animationName = RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_IN_NAME
                    }
                }, RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_DURATION)
            })
        }, 50)
    }, [displayMessageState])

    return (
        <div className='running-messages-strip-container' style={{ backgroundColor, color: fontColor }}>
            <div ref={currentMessageRef} className={`${isMobile ? "mobile-running-messages-strip-current-message" : "running-messages-strip-current-message"} ${isMobile ? "mobile-running-message" : "running-message"}`}></div>
        </div>
    )
}