import React, { useMemo } from 'react'
import {
    Cell,
    Pie,
    PieChart
} from 'recharts'
import './PaydinPieChart.css'
import { useTranslation } from 'react-i18next'

/**
 * Represents a pie chart.
 * @param {array} chartData - The data for the pie chart
 * @param {string/number} dimens - The dimensions for the pie chart
 * @param {boolean} showLegend - Determins whether the pie chart legend is shown or not
 * @param {function} pieValue - A function for rendering the pie chart chunk label
 */
export default function PaydinPieChart({
    chartData = [],
    dimens = 400,
    showLegend = true,
    pieValue = () => { }
}) {
    const { t } = useTranslation()

    const displayableData = useMemo(() => chartData.filter(data => data?.value > 0), [chartData])

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
        const RADIAN = Math.PI / 180
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)

        return (
            <text x={x} y={y} fill="white" textAnchor='middle' dominantBaseline="central">
                {pieValue(value)}
            </text>
        )
    }

    return (
        <div className="paydin-pie-chart-container">
            <div className='paydin-pie-chart-pie-container' style={{ height: `${dimens}px` }}>
                {
                    displayableData.length > 0 ? <PieChart width={dimens} height={dimens}>
                        <Pie
                            data={displayableData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={(dimens / 2) - 15}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {displayableData.map((entry, index) => {
                                return <Cell key={`cell-${index}`} fill={entry?.color} />
                            })}
                        </Pie>
                    </PieChart> : <div className='paydin-pie-chart-no-data-container' style={{ height: `${dimens}px` }}>
                        <div className='paydin-pie-chart-no-data-message'>{t('PAYDIN_PIE_CHART_NO_DATA_MESSAGE')}</div>
                    </div>
                }
            </div>
            {
                (showLegend && displayableData.length > 0) && <div className="paydin-pie-chart-legend">
                    {
                        chartData.map(entry => <div key={entry?.name} className='paydin-pie-chart-legend-item'>
                            <div className="paydin-pie-chart-legend-item-color" style={{ backgroundColor: entry?.color }}></div>
                            <div className="paydin-pie-chart-legend-item-title">{entry?.name}</div>
                        </div>)
                    }
                </div>
            }
        </div>
    )
}