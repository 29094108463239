import React from 'react'

export default function TrendsImage(props) {
    return (
        <svg 
            {...props} 
            clipRule="evenodd" 
            fillRule="evenodd" 
            height="512" 
            strokeLinejoin="round" 
            strokeMiterlimit="2" 
            viewBox="0 0 48 48" 
            width="512" 
        >
            <g transform="translate(-336 -144)">
                <g id="Icon">
                    <path d="m366.305 179.962 9.867 9.866c1.561 1.561 4.095 1.561 5.656 0s1.561-4.095 0-5.656l-9.866-9.867c1.922-2.969 3.038-6.508 3.038-10.305 0-3.716-1.069-7.184-2.916-10.114-.295-.467-.913-.607-1.38-.313-.467.295-.607.913-.312 1.38 1.652 2.621 2.608 5.723 2.608 9.047 0 9.383-7.617 17-17 17s-17-7.617-17-17 7.617-17 17-17c3.324 0 6.426.956 9.047 2.608.467.295 1.085.155 1.38-.312.294-.467.154-1.085-.313-1.38-2.93-1.847-6.398-2.916-10.114-2.916-10.486 0-19 8.514-19 19s8.514 19 19 19c3.797 0 7.336-1.116 10.305-3.038zm7.816-.669-2.828 2.828 6.293 6.293c.78.781 2.048.781 2.828 0 .781-.78.781-2.048 0-2.828zm-1.414-1.414-1.935-1.935c-.841 1.039-1.789 1.987-2.828 2.828l1.935 1.935z"/>
                    <path d="m371.586 147-17.586 17.586s-3.293-3.293-3.293-3.293c-.39-.391-1.024-.391-1.414 0l-5.898 5.898c-.258-1.022-.395-2.09-.395-3.191 0-7.175 5.825-13 13-13 2.237 0 4.344.566 6.182 1.563.485.263 1.093.083 1.356-.402s.083-1.093-.402-1.356c-2.122-1.151-4.553-1.805-7.136-1.805-8.279 0-15 6.721-15 15s6.721 15 15 15 15-6.721 15-15c0-2.583-.654-5.014-1.805-7.136-.263-.485-.871-.665-1.356-.402s-.665.871-.402 1.356c.997 1.838 1.563 3.945 1.563 6.182 0 7.175-5.825 13-13 13-5.291 0-9.849-3.168-11.877-7.709l5.877-5.877s3.293 3.293 3.293 3.293c.39.391 1.024.391 1.414 0l18.293-18.293v3.586c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1h-6c-.552 0-1 .448-1 1s.448 1 1 1z"/>
                </g>
            </g>
        </svg>
    )
}