import LoaderPage from 'pages/LoaderPage/LoaderPage'
import React from 'react'

export default function ContentDialogLoader() {
    return (
        <LoaderPage
            styles={{ backgroundColor: '#ffffffaa', height: '100%' }}
            marginBottom='0px'
            isFullScreen={true}
            disableOverflow={false}
        />
    )
}