import React from 'react'
import './BaseLayout.css'
import GeneralLayout from 'layouts/GeneralLayout/GeneralLayout'
import BaseTopBar from 'components/BaseTopBar/BaseTopBar'
import { ACCOUNT_VERIFICATION_PAGE_URL } from 'constants/routes.constants'
import { NO_BOTTOM_PADDING_PAGES } from 'constants/general.constants'

/**
 * Represents a layout for all authentication pages.
 */
export default function BaseLayout({ children }) {

    function getLayoutClassName() {
        if (window.location.pathname.startsWith(ACCOUNT_VERIFICATION_PAGE_URL)) return 'no-padding-page'
        return ''
    }

    function isBottomPaddingPage() {
        return !NO_BOTTOM_PADDING_PAGES.includes(window.location.pathname)
    }
    
    return (
        <GeneralLayout>
            <BaseTopBar />
            <div className={`base-layout-content ${getLayoutClassName()}`} style={{ paddingBottom: isBottomPaddingPage() ? '20dvh' : 0 }}>
                {children}
            </div>
        </GeneralLayout>
    )
}