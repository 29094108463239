import React, { useState } from 'react'
import './CreateNewPasswordPage.css'
import { useTranslation } from 'react-i18next'
import { BOXED_MESSAGE_TYPES } from 'constants/general.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { getQueryVariable } from 'services/SystemService'
import { LOGIN_PAGE_URL, RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY } from 'constants/routes.constants'
import { ResetPasswordAPI } from 'api/auth/auth'
import { useHistoryContext } from 'contexts/History'
import PasswordInput from 'components/PasswordInput/PasswordInput'

export default function CreateNewPasswordPage() {
    const history = useHistoryContext()
    const { t } = useTranslation()

    const [isAbleToResetPassword, setIsAbleToResetPassword] = useState(false)
    const [inputState, setInputState] = useState({
        password: ''
    })
    const [boxedMessageState, setBoxedMessageState] = useState({
        isOpen: false,
        message: '',
        type: BOXED_MESSAGE_TYPES.ERROR
    })
    const [isResetPasswordInProgress, setIsResetPasswordInProgress] = useState(false)

    function createNewPassword() {
        setIsResetPasswordInProgress(true)
        const resetPasswordToken = getQueryVariable(RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY, "")
        ResetPasswordAPI(resetPasswordToken, inputState.password)
            .then(() => {
                history.replace(LOGIN_PAGE_URL)
            }).catch(error => {
                console.log(error)
                setIsResetPasswordInProgress(false)
                setBoxedMessageState({
                    isOpen: true,
                    message: t('CREATE_NEW_PASSWORD_PAGE_RESET_PASSWORD_ERROR_DIALOG_TITLE'),
                    type: BOXED_MESSAGE_TYPES.ERROR
                })
            })
    }

    function setPassword(value) {
        setInputState(prev => ({
            ...prev,
            password: value
        }))
    }

    return (
        <div className='create-new-password-page-container'>
            <form className="create-new-password-page-content">
                <h1 className="create-new-password-page-title">{t('CREATE_NEW_PASSWORD_PAGE_TITLE')}</h1>
                {
                    boxedMessageState.isOpen && <BoxedMessage
                        message={boxedMessageState.isOpen}
                        type={boxedMessageState.type} />
                }
                <div className="create-new-password-page-password-input-button-section">
                    <PasswordInput
                        title={t('SIGN_UP_PAGE_PASSWORD_INPUT_PLACEHOLDER')}
                        value={inputState.password}
                        setValue={setPassword}
                        setValidationStatus={setIsAbleToResetPassword}
                    />
                    <LoaderButton
                        className='create-new-password-reset-password-button'
                        buttonText={t('CREATE_NEW_PASSWORD_PAGE_RESET_PASSWORD_BUTTON_TEXT')}
                        isLoading={isResetPasswordInProgress}
                        isDisabled={!isAbleToResetPassword}
                        onClick={createNewPassword}
                        renderAsButton={true} />
                </div>
            </form>
        </div>
    )
}