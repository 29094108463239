import React from 'react'
import './FloatingElement.css'

/**
 * Represents an element which has a modal behind it.
 * @param {boolean} isShown - Determins whether the element is shown or not
 * @param {function} hide - A function for hiding the element
 */
export default function FloatingElement({
    children,
    isShown,
    hide = () => { }
}) {
    return (<>
        {isShown && <div className='floating-element-container'>
            {children}
            <div className="floating-element-modal" onClick={hide}></div>
        </div>}
    </>)
}