import { Alert, AlertTitle, Button } from '@mui/material'
import { ALERT_MESSAGE_TYPES } from 'constants/general.constants'
import React from 'react'
import './AlertMessage.css'

export default function AlertMessage({
    title = '',
    message = '',
    type = ALERT_MESSAGE_TYPES.INFO,
    background = '',
    buttonText = '',
    onButtonClick = () => { }
}) {
    return (
        <Alert
            severity={type}
            sx={{
                width: '100%',
                backgroundColor: background
            }}
            action={<>
                {
                    buttonText && <div className='alert-message-button-container'>
                        <Button color="inherit" size="small" onClick={onButtonClick}>
                            {buttonText}
                        </Button>
                    </div>
                }
            </>}
        >
            {title && <AlertTitle sx={{ fontWeight: 600 }}>{title}</AlertTitle>}
            {message}
        </Alert>
    )
}