import React, { useEffect, useRef, useState } from 'react'
import './OptionValueMapperEntry.css'
import { FaArrowRightLong } from "react-icons/fa6"
import CheckImage from 'embeddedImages/CheckImage'
import ErrorImage from 'embeddedImages/ErrorImage'
import PaydinColorPicker from 'components/PaydinColorPicker/PaydinColorPicker'
import { isMobile } from 'react-device-detect'
import MultiLineText from 'components/MultiLineText/MultiLineText'

/**
 * Represents an entry inside a {@link OptionValueMapper}.
 * @param {string} optionValueName - The entry name
 * @param {string} optionValue - The entry value 
 * @param {boolean} isColor - Determins whether the entry is of type color or not
 * @param {function} updateOptionMap - A function for updating the option map
 */
export default function OptionValueMapperEntry({
    optionValueName,
    optionValue,
    isColor,
    updateOptionMap = () => { }
}) {
    const [selectedOptionValue, setSelectedOptionValue] = useState(optionValue)
    
    useEffect(() => {
        setSelectedOptionValue(optionValue)
    }, [optionValue])

    const updateTimeoutRef = useRef(null)

    function handleValueChange(value, hasUpdateDelay = false) {
        if (hasUpdateDelay) {
            if (updateTimeoutRef?.current) {
                clearTimeout(updateTimeoutRef?.current)
            }
            updateTimeoutRef.current = setTimeout(() => {
                updateOptionMap(value)
            }, 300)
        } else {
            updateOptionMap(value)
        }
        setSelectedOptionValue(value)
    }

    return (
        <div className={isMobile ? 'mobile-option-value-mapper-value-container' : 'option-value-mapper-value-container'}>
            <div className={isMobile ? "mobile-option-value-mapper-value-name-section" : "option-value-mapper-value-name-section"} style={{ width: (isMobile && !isColor) && '45vw' }}>
                <MultiLineText
                    text={optionValueName}
                    className={isMobile ? 'mobile-option-value-mapper-value-name-section-title' : 'option-value-mapper-value-name-section-title'}
                    lines={1}
                    oneWord={true}
                />
                <FaArrowRightLong className='option-value-mapper-value-name-section-image' />
            </div>
            <div className={isMobile ? "mobile-option-value-mapper-value-value-section" : "option-value-mapper-value-value-section"} style={{ width: (isMobile && !isColor) && '55vw' }}>
                {isColor && <PaydinColorPicker
                    color={selectedOptionValue}
                    setColor={value => handleValueChange(value, false)}
                    showDescription={false}
                    dimens={isMobile ? 25 : 30}
                />}
                <input type='text' disabled={isColor} className={`${isMobile ? 'mobile-option-value-mapper-value-value-section-input' : 'option-value-mapper-value-value-section-input'} ${!selectedOptionValue ? 'error' : ''}`} style={{ width: (isMobile && !isColor) && '100%' }} value={selectedOptionValue} onChange={e => handleValueChange(e.target.value, true)} />
                {!isMobile && (selectedOptionValue ? <CheckImage className="option-value-mapper-value-value-section-status-image" /> : <ErrorImage className="option-value-mapper-value-value-section-status-image" />)}
            </div>
        </div>
    )
}