import { environment } from "conf"
import {
    APPEARANCE_SETTINGS_OPTIONS_API_URL_SUFFIX,
    AUTH_API_URL,
    AUTH_STORE_CONNECT_API_URL,
    AUTH_STORE_CONNECT_API_URL_PREFIX,
    CANCEL_PRICING_PLAN_API_URL,
    LOGIN_CONNECT_STORE_API_ROUTE,
    REGISTER_CONNECT_STORE_API_ROUTE,
    STORE_API_BASE_URL,
    STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX,
    STORE_CONNECT_API_URL,
    STORE_DISCONNECT_API_URL,
    STORE_ORDERS_API_URL_SUFFIX,
    STORE_ORDER_DETAILS_API_URL_SUFFIX,
    STORE_SETTINGS_API_URL_PREFIX,
    STORE_SETTINGS_API_URL_SUFFIX,
    STORE_SETTINGS_PRODUCT_SYNC_API_URL_SUFFIX,
    STORE_SETTINGS_THEME_UPDATE_API_URL_SUFFIX,
    UPGRADE_PRICING_PLAN_API_URL
} from "constants/api.constants"
import { request_auth } from "services/RequestWrapper"

const baseUrl = environment.base_url + STORE_API_BASE_URL

export function AuthConnectStoreAPI(auth) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                auth
            }
        }

        const url = environment.base_url + AUTH_STORE_CONNECT_API_URL_PREFIX + AUTH_STORE_CONNECT_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function ConnectStoreAPI(shopUrl) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                shop_url: shopUrl
            }
        }

        const url = environment.base_url + AUTH_STORE_CONNECT_API_URL_PREFIX + STORE_CONNECT_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function UpgradePlanAPI(planName, businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                store_subscription_plan: planName,
                business_id: businessId
            }
        }

        const url = environment.base_url + UPGRADE_PRICING_PLAN_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function CancelPlanAPI(businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }

        const url = environment.base_url + CANCEL_PRICING_PLAN_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function DisconnectStore(businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }

        const url = environment.base_url + AUTH_STORE_CONNECT_API_URL_PREFIX + STORE_DISCONNECT_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve()
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Updates the business's settings.

    Method: [PUT]
    URL: /admin/v1/store/<business_id>/settings

    Body params:
    ============
        (*) businessId - The business id
        (*) shippingAndReturnUrl - The shipping and return URL of the business
        (*) facebookPixelId - The id of the business for facebook pixel analytics
        (*) googleAnalyticsId - The id of the business for google analytics
        (*) utmParams - The UTM parameters object for monitoring and analyzing shopeaks app visits
*/
export function UpdateStoreSettings(businessId, shippingAndReturnUrl, facebookPixelId, googleAnalyticsId, utmParams, autoSwapEnabled) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                settings: {
                    shipping_and_return_url: shippingAndReturnUrl,
                    facebook_pixel_id: facebookPixelId,
                    google_analytics_id: googleAnalyticsId,
                    auto_swap_enabled: autoSwapEnabled,
                    utm_params: {
                        utm_source: utmParams?.UTMSource ?? '',
                        utm_medium: utmParams?.UTMMedium ?? '',
                        utm_campaign: utmParams?.UTMCampaign ?? '',
                        utm_term: utmParams?.UTMTerm ?? '',
                        utm_content: utmParams?.UTMContent ?? '',
                    }
                }
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}/${businessId}${STORE_SETTINGS_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Updates the business's appearance settings.

    Method: [PUT]
    URL: /admin/v1/store/<business_id>/appearance

    Body params:
    ============
        (*) businessId - The business id
        (*) logoUrl - The url of the new logo
*/
export function UpdateStoreAppearanceSettings(businessId, logoUrl) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                appearance: {
                    logo_url: logoUrl
                }
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}/${businessId}${STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Performs a full products and inventory sync to all the user's stores.

    Method: [POST]
    URL: /admin/v1/store/sync
*/
export function SyncProducts(businessId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                business_id: businessId
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}${STORE_SETTINGS_PRODUCT_SYNC_API_URL_SUFFIX}`

        request_auth(url, options, false, null)
            .then(resolve)
            .catch(reject)
    })
}

/*
    Updates the link's theme customization settings.

    Method: [PUT]
    URL: /admin/v1/store/<business_id>/theme
*/
export function UpdateLinkStyles(businessId, theme) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: {
                theme: {
                    checkout_button_shape: theme?.checkoutButtonShape,
                    checkout_button_background_color: theme?.checkoutButtonBackgroundColor,
                    checkout_button_font_color: theme?.checkoutButtonFontColor,
                    checkout_button_border_color: theme?.checkoutButtonBorderColor,
                    cart_button_icon: theme?.cartButtonImage,
                    is_discount_strip_shown: theme?.isDiscountStripShown,
                    discount_background_color: theme?.discountBackgroundColor,
                    discount_font_color: theme?.discountFontColor,
                    is_shipping_and_sales_strip_shown: theme?.isSalesAndDiscountStripShown,
                    shipping_and_sales_strip_background_color: theme?.salesAndDiscountStripBackgroundColor,
                    shipping_and_sales_strip_font_color: theme?.salesAndDiscountStripFontColor,
                    shipping_and_sales_strip_sentences: [
                        theme?.salesAndDiscountStripSentence1,
                        theme?.salesAndDiscountStripSentence2
                    ],
                    image_border_radius: theme?.imageBorderRadius,
                    is_preorder_text_shown: theme?.isPreorderTextShown,
                    preorder_text: theme?.preorderText

                }
            }
        }

        const url = `${environment.base_url}${STORE_SETTINGS_API_URL_PREFIX}/${businessId}${STORE_SETTINGS_THEME_UPDATE_API_URL_SUFFIX}`

        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Verifies the user's online store or creates it (when needed) with the given 'auth' params came from the store's provider.

    Method: [POST]
    URL: /admin/v1/store/auth/verify

    Body params:
    ============
        (*) auth - The auth parameter given by the store provider
*/
export function verifyStore(auth) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                auth
            }
        }

        const url = baseUrl + AUTH_API_URL
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Registers and connects to the user's online store.

    Method: [POST]
    URL: /admin/v1/store/auth/register/connect

    Body params:
    ============
        (*) auth - The auth parameter given by the store provider
*/
export function registerAndConnectStore(auth, username, email, password) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                auth,
                username,
                email,
                password
            }
        }

        const url = baseUrl + REGISTER_CONNECT_STORE_API_ROUTE
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Logs in and connects to the user's online store.

    Method: [POST]
    URL: /admin/v1/store/auth/login/connect

    Body params:
    ============
        (*) auth - The auth parameter given by the store provider
*/
export function loginAndConnectStore(auth, username, password) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                auth,
                username,
                password
            }
        }

        const url = baseUrl + LOGIN_CONNECT_STORE_API_ROUTE
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retreives the business' options map json.

    Method: [GET]
    URL: /admin/v1/store/appearance/options
*/
export function getOptionsMap() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = baseUrl + STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX + APPEARANCE_SETTINGS_OPTIONS_API_URL_SUFFIX
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    updates the business' options map json.

    Method: [PUT]
    URL: /admin/v1/store/appearance/options
*/
export function updateOptionsMap(optionMap) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            body: optionMap
        }

        const url = baseUrl + STORE_APPEARANCE_SETTINGS_API_URL_SUFFIX + APPEARANCE_SETTINGS_OPTIONS_API_URL_SUFFIX
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves all the store orders.

    Method: [GET]
    URL: /admin/v1/store/orders
*/
export function getStoreOrders(count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = baseUrl + STORE_ORDERS_API_URL_SUFFIX + `?count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves a store order by its ID.

    Method: [GET]
    URL: /admin/v1/store/orders/<orderId>/details
*/
export function getStoreOrderDetails(orderId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = baseUrl + STORE_ORDERS_API_URL_SUFFIX + `/${orderId}` + STORE_ORDER_DETAILS_API_URL_SUFFIX
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}